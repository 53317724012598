<template>
    <div id="relatorio-velocidade-media">
        <vias-formulario-relatorio-velocidade-media
            id="formulario"
            @buscarRelatorio="buscarRelatorio($event)"
        ></vias-formulario-relatorio-velocidade-media>

        <vias-card class="mt-4" column v-if="mostrar_relatorio">
            <vias-button
                variant="outline-primary"
                type="button"
                @click="emitirPdf()"
            >
                Emitir PDF
            </vias-button>
        </vias-card>

        <vias-card
            class="mb-4 mt-4"
            v-if="
                mostrar_relatorio &&
                relatorio &&
                relatorio.busca &&
                relatorio.busca.formato_visualizacao === 'hora'
            "
        >
            <div class="d-flex">
                <vias-checkbox
                    class="w-auto h-auto p-0 m-0"
                    afterText="Apresentar informações de 15 em 15 minutos"
                    v-model="informacoes_em_quinze_minutos"
                    switch
                ></vias-checkbox>
            </div>
        </vias-card>

        <vias-relatorio-velocidade-media
            v-if="mostrar_relatorio"
            class="mt-4"
            :identificadores="relatorio.identificadores"
            :graficoRegistros="relatorio.graficos.registros"
            :tabelaRegistros="relatorio.tabelas.registros"
            :graficoVelocidadeMedia="relatorio.graficos.velocidade_media"
            :tabelaVelocidadeMedia="relatorio.tabelas.velocidade_media"
            :busca="relatorio.busca"
            @alterarFormatoDeVisualizacao="alterarFormatoDeVisualizacao($event)"
            @alterarFormatoDeVisualizacaoParaBaixo="
                alterarFormatoDeVisualizacaoParaBaixo($event)
            "
        ></vias-relatorio-velocidade-media>
    </div>
</template>

<script>
import ViasFormularioRelatorioVelocidadeMediaVue from "@/components/velocidade-media/relatorios/velocidade-media/ViasFormularioRelatorioVelocidadeMedia.vue";
import ViasRelatorioVelocidadeMediaVue from "@/components/velocidade-media/relatorios/velocidade-media/ViasRelatorioVelocidadeMedia.vue";
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasCheckbox from "@/components/shared/ViasCheckbox.vue";
import moment from "moment";

export default {
    name: "relatorio-velocidade-media",
    components: {
        "vias-formulario-relatorio-velocidade-media":
            ViasFormularioRelatorioVelocidadeMediaVue,
        "vias-relatorio-velocidade-media": ViasRelatorioVelocidadeMediaVue,
        "vias-card": ViasCard,
        "vias-button": ViasButton,
        "vias-checkbox": ViasCheckbox,
    },
    data: () => ({
        formulario: null,
        relatorio: {
            busca: null,
            identificadores_simplificados: null,
            identificadores: null,
            graficos: {
                registros: null,
                velocidade_media: null,
            },
            tabelas: {
                registros: null,
                velocidade_media: null,
            },
        },
        mostrar_relatorio: false,
        informacoes_em_quinze_minutos: false,
        formatos_moment: {
            ano: "MMMM, YYYY",
            mes: "DD, dddd",
            semana: "DD, dddd",
            dia: "HH",
            hora: "HH:mm",
        },
        formatos_moment_simplificados: {
            ano: "MMMM, YYYY",
            mes: "DD",
            semana: "DD",
            dia: "HH",
            hora: "mm",
        },
    }),
    methods: {
        buscarRelatorio(formulario) {
            this.$notificacao.open("Buscando relatório, aguarde...");

            this.formulario = formulario;
            this.relatorio.busca = Object.assign({}, formulario);

            this.$velocidade_media
                .buscarRelatorioVelocidadeMedia(formulario)
                .then((res) => res.data)
                .then((relatorio) => {
                    this.dados_brutos = relatorio;
                    this.tratarRelatorio();
                })
                .then(() => {
                    this.$notificacao.close("Procedimento finalizado");
                });
        },
        tratarRelatorio() {
            let chavesBrutas = null;
            let registrosBrutos = null;

            if (
                this.relatorio.busca.formato_visualizacao === "hora" &&
                this.informacoes_em_quinze_minutos
            ) {
                const resultado = this.tratarRelatorioParaQuinzeMinutos();
                chavesBrutas = resultado.chaves;
                registrosBrutos = resultado.registros;
            } else {
                chavesBrutas = Object.keys(this.dados_brutos.grafico);
                registrosBrutos = Object.values(this.dados_brutos.grafico);
            }

            this.relatorio.identificadores = chavesBrutas.map((identificador) =>
                moment(identificador).format(
                    this.formatos_moment[this.formulario.formato_visualizacao],
                ),
            );
            this.relatorio.identificadores_simplificados = chavesBrutas.map(
                (identificador) =>
                    moment(identificador).format(
                        this.formatos_moment_simplificados[
                            this.formulario.formato_visualizacao
                        ],
                    ),
            );

            this.relatorio.graficos.registros = registrosBrutos.map(
                (registro) => registro.quantidade,
            );
            this.relatorio.tabelas.registros = registrosBrutos.map(
                (registro, i) => ({
                    numero_linha: i + 1,
                    identificador: moment(chavesBrutas[i]).format(
                        this.formatos_moment[
                            this.formulario.formato_visualizacao
                        ],
                    ),
                    registros: registro.quantidade,
                }),
            );

            this.relatorio.graficos.velocidade_media = registrosBrutos.map(
                (registro) => registro.media_de_velocidade,
            );
            this.relatorio.tabelas.velocidade_media = registrosBrutos.map(
                (registro, i) => ({
                    numero_linha: i + 1,
                    identificador: moment(chavesBrutas[i]).format(
                        this.formatos_moment[
                            this.formulario.formato_visualizacao
                        ],
                    ),
                    velocidade_media: registro.media_de_velocidade,
                }),
            );

            this.mostrar_relatorio = true;
        },
        emitirPdf() {
            this.$notificacao.open(
                "Estamos emitindo o PDF solicitado, aguarde um momento por favor...",
            );

            this.$velocidade_media
                .emitirPdfRelatorioVelocidadeMedia(this.relatorio.busca)
                .then((res) => res.data)
                .then(({ mensagem }) => {
                    this.$notificacao.close(mensagem, "success");
                });
        },
        tratarRelatorioParaQuinzeMinutos() {
            let i = 0;
            const chaves = Object.keys(this.dados_brutos.grafico).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.push(valor);
                    }

                    i++;

                    if (i == 3) {
                        i = 0;
                    }
                    return temp;
                },
                [],
            );

            i = 0;
            const registros = Object.values(this.dados_brutos.grafico).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.somatoria = valor;
                    }

                    if (i > 0) {
                        temp.somatoria = {
                            media_de_velocidade:
                                (temp.somatoria.media_de_velocidade +
                                    valor.media_de_velocidade) /
                                2,
                            quantidade:
                                temp.somatoria.quantidade + valor.quantidade,
                        };
                    }

                    if (i == 2) {
                        temp.registros.push(temp.somatoria);
                        temp.somatoria = null;
                        i = 0;
                        return temp;
                    }
                    i++;
                    return temp;
                },
                {
                    registros: [],
                    somatoria: null,
                },
            ).registros;

            return {
                chaves,
                registros,
            };
        },
        alterarFormatoDeVisualizacao(formato) {
            this.formulario.formato_visualizacao = formato;
            this.buscarRelatorio(this.formulario);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            if (this.relatorio.busca.formato_visualizacao !== "hora") {
                this.formulario.formato_visualizacao =
                    this.definirNovoFormatoDeVisualizacao();
                this.formulario.data = [
                    moment(this.relatorio.busca.data[0])
                        .set(
                            this.definirAtributoASerAlteradoDoMoment(),
                            this.relatorio.identificadores_simplificados[index],
                        )
                        .format("YYYY-MM-DDTHH:mm:ss"),
                    moment(this.relatorio.busca.data[1])
                        .set(
                            this.definirAtributoASerAlteradoDoMoment(),
                            this.relatorio.identificadores_simplificados[index],
                        )
                        .format("YYYY-MM-DDTHH:mm:ss"),
                ];

                this.buscarRelatorio(this.formulario);
            }
        },
        definirNovoFormatoDeVisualizacao() {
            const formatosDeVisualizacao = {
                ano: "mes",
                mes: "dia",
                semana: "dia",
                dia: "hora",
            };
            return formatosDeVisualizacao[
                this.relatorio.busca.formato_visualizacao
            ];
        },
        definirAtributoASerAlteradoDoMoment() {
            const atributosMoment = {
                ano: "month",
                mes: "date",
                semana: "date",
                dia: "hour",
            };
            return atributosMoment[this.relatorio.busca.formato_visualizacao];
        },
    },
    watch: {
        informacoes_em_quinze_minutos() {
            this.tratarRelatorio();
        },
    },
};
</script>
