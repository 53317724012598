<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <datepicker
            :id="id"
            class="w-100"
            v-model="data_selecionada"
            :dark="theme"
            @update:model-value="atualizarData($event)"
            format="dd/MM/yyyy HH:mm"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            month-name-format="long"
            week-start="0"
            week-numbers="iso"
            week-num-name="sem"
            auto-apply
            range
            :partial-range="true"
            :preset-ranges="presets_range"
        ></datepicker>
    </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import {
    endOfMonth,
    endOfYear,
    endOfWeek,
    endOfDay,
    startOfMonth,
    startOfYear,
    startOfWeek,
    startOfDay,
} from "date-fns";

export default {
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: `id-${Math.floor(Math.random() * 1000)}`,
        },
    },
    computed: {
        theme() {
            return this.$store.state.theme === "dark-theme";
        },
    },
    components: {
        Datepicker,
    },
    data: () => ({
        data_selecionada: [],
        presets_range: [
            {
                label: "Hoje",
                range: [startOfDay(new Date()), endOfDay(new Date())],
            },
            {
                label: "Esta semana",
                range: [startOfWeek(new Date()), endOfWeek(new Date())],
            },
            {
                label: "Este mês",
                range: [startOfMonth(new Date()), endOfMonth(new Date())],
            },
            {
                label: "Este ano",
                range: [startOfYear(new Date()), endOfYear(new Date())],
            },
        ],
    }),
    methods: {
        atualizarData() {
            if (this.data_selecionada) {
                this.$emit("update:modelValue", [
                    moment(this.data_selecionada[0]).format(
                        "YYYY-MM-DDTHH:mm:ssZ",
                    ),
                    moment(this.data_selecionada[1]).format(
                        "YYYY-MM-DDTHH:mm:ssZ",
                    ),
                ]);
                return;
            }
            this.$emit("update:modelValue", null);
        },
    },
    created() {
        if (this.modelValue) {
            this.data_selecionada = this.modelValue;
        } else {
            this.data_selecionada = [];
        }
    },
    watch: {
        modelValue() {
            if (this.modelValue) {
                this.data_selecionada = this.modelValue;
            } else {
                this.data_selecionada = [];
            }
        },
    },
};
</script>
