import { cores, border } from "@/services/variables.service";

const VariablesPlugin = {
    install(app) {
        app.config.globalProperties.$variaveis = {
            cores,
            border,
        };
    },
};

export default VariablesPlugin;
