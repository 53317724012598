<template>
    <div class="form-group">
        <label :class="theme" for="textarea" v-if="label">
            {{ label }} <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <textarea
            @change="updateValue"
            id="textarea"
            class="form-control form-textarea"
            :class="[hasText, theme]"
            v-model="value"
            :required="isRequired"
            @input="changeHeight"
            ref="textarea"
        ></textarea>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["label", "modelValue", "required"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        isRequired() {
            return typeof this.required !== "undefined";
        },
        hasText() {
            return this.value !== undefined &&
                this.value !== null &&
                this.value.toString().length > 0
                ? "has-text"
                : "";
        },
    },
    data() {
        return {
            value: this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
    methods: {
        updateValue() {
            this.$emit("update:modelValue", this.value);
        },
    },
};
</script>

<style></style>
