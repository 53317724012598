import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "mapHtml",
  class: "w-100 h-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    (_ctx.ready)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 64))
}