<template>
    <vias-bloco-retratil
        texto="Filtro de equipamentos"
        abertura="baixo"
        :posicao="getPosicao"
    >
        <div class="filtro-mapa" :class="theme">
            <div class="row">
                <div class="col col-12">
                    <label>
                        <input
                            type="checkbox"
                            v-model="todos_selecionados"
                            :indeterminate="algunsTiposEquipamentosSelecionados"
                            @change="altenarSelecaoTodosEquipamentos()"
                        />
                        <span class="input-checkbox"></span>
                        Todos
                    </label>
                </div>
            </div>
            <div
                class="row ml-4"
                v-for="tipo in tipos_equipamentos"
                :key="tipo.value"
            >
                <div class="col col-12">
                    <label>
                        <input
                            type="checkbox"
                            v-model="tipos_equipamentos_selecionados"
                            :value="tipo.value"
                            @change="alterarTiposDeEquipamentosSelecionados()"
                        />
                        {{ tipo.text }}
                    </label>
                </div>
            </div>
        </div>
    </vias-bloco-retratil>
</template>

<script>
import { useStore } from "@/storeTs";
import ViasBlocoRetratil from "./ViasBlocoRetratil.vue";
import {
    SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS,
    SET_TIPOS_EQUIPAMENTOS,
} from "@/storeTs/type-mutations";

export default {
    name: "ViasFiltroDeEquipamentos",
    props: {
        formulario: Boolean,
    },
    emits: ["update:tipos-equipamentos-selecionados"],
    components: {
        ViasBlocoRetratil,
    },
    data: () => ({
        tipos_equipamentos: [],
        tipos_equipamentos_selecionados: [],
        todos_selecionados: false,
    }),
    computed: {
        getPosicao() {
            if (this.$props.formulario) {
                return "p-map-top-left-formulario";
            }
            return "p-map-top-left";
        },
        algunsTiposEquipamentosSelecionados() {
            return (
                this.tipos_equipamentos_selecionados.length > 0 &&
                this.tipos_equipamentos_selecionados.length <
                    this.tipos_equipamentos.length
            );
        },
    },
    methods: {
        buscarTiposDeEquipamentos() {
            this.$equipamento
                .buscarTiposDeEquipamentos()
                .then((res) => res.data)
                .then((tiposEquipamentos) => {
                    this.tipos_equipamentos = tiposEquipamentos
                        .filter((tipo) => {
                            return tipo.toLowerCase() !== "todos";
                        })
                        .map((tipo) => ({
                            value: tipo
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toUpperCase(),
                            text: this.capitalize(tipo),
                        }));
                    this.buscarTiposDeEquipamentosSelecionados();
                });
        },
        buscarTiposDeEquipamentosSelecionados() {
            if (this.store.state.primeiraBuscaDeTiposDeEquipamentos) {
                this.tipos_equipamentos_selecionados = this.tipos_equipamentos
                    .filter((tipo) => {
                        return tipo.value !== "RADAR ESTATICO";
                    })
                    .map((tipo) => tipo.value);
                this.store.commit(
                    SET_TIPOS_EQUIPAMENTOS,
                    this.tipos_equipamentos_selecionados,
                );
                this.store.commit(SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS, false);
            } else {
                this.tipos_equipamentos_selecionados =
                    this.store.getters.getTiposEquipamentosSelecionados;
            }
            this.verificarSeTodosEstaoSelecionados();
        },
        capitalize(str) {
            str = str.toLowerCase();
            return str.split(" ").reduce((texto, str) => {
                str = str.charAt(0).toUpperCase() + str.slice(1);
                return `${texto} ${str}`;
            }, "");
        },
        altenarSelecaoTodosEquipamentos() {
            if (this.todos_selecionados) {
                this.tipos_equipamentos_selecionados =
                    this.tipos_equipamentos.map(
                        (tipoEquipamento) => tipoEquipamento.value,
                    );
            } else {
                this.tipos_equipamentos_selecionados = [];
            }
        },
        verificarSeTodosEstaoSelecionados() {
            this.todos_selecionados =
                this.tipos_equipamentos_selecionados.length ===
                this.tipos_equipamentos.length;
        },
        alterarTiposDeEquipamentosSelecionados() {
            this.verificarSeTodosEstaoSelecionados();

            let tiposEquipamentosSelecionadosParaEnvio = Object.assign(
                [],
                this.tipos_equipamentos_selecionados,
            );

            if (this.todos_selecionados) {
                tiposEquipamentosSelecionadosParaEnvio.push("TODOS");
            }

            this.store.commit(
                SET_TIPOS_EQUIPAMENTOS,
                tiposEquipamentosSelecionadosParaEnvio,
            );

            this.$emit(
                "update:tipos-equipamentos-selecionados",
                tiposEquipamentosSelecionadosParaEnvio,
            );
        },
    },
    mounted() {
        this.buscarTiposDeEquipamentos();
        this.buscarTiposDeEquipamentosSelecionados();
    },
    setup() {
        const store = useStore();

        return {
            store,
        };
    },
};
</script>
