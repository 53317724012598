<template>
    <div class="radio-button" :class="isColumn">
        <div v-for="option in options" :key="option">
            <input
                @change="updateValue()"
                v-model="value"
                type="radio"
                :name="hasName"
                :id="criarId(option)"
                :value="option.value"
            />
            <label :for="criarId(option)" :class="theme">
                <div class="dot"></div>
                <div class="text">{{ option.text }}</div>
            </label>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["column", "options", "modelValue", "name"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        isColumn() {
            return typeof this.column != "undefined" ? "column" : "";
        },
        hasName() {
            if (this.$props.name) {
                return this.$props.name;
            }
            return `name-radio-button-${Math.round(Math.random() * 10000)}`;
        },
    },
    data() {
        return {
            value: this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
    methods: {
        criarId(opcao) {
            return `${this.hasName}-${opcao.text}`;
        },
        updateValue() {
            this.$emit("update:modelValue", this.value);
            this.$emit("customChange", this.value);
        }
    },
};
</script>
