<template>

<form @submit.prevent="adicionarUsuario()">
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Nome"
                type="text"
                v-model="nome"
                id="cadastro-usuario-nome"
                required
            ></ViasInput>
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Usuario"
                type="text"
                v-model="usuario"
                id="cadastro-usuario-usuario"
                required
            ></ViasInput>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12">
            <ViasInput
                label="E-mail"
                type="email"
                v-model="email"
                id="cadastro-usuario-email"
                required
            ></ViasInput>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Senha"
                type="password"
                v-model="senha"
                id="cadastro-usuario-senha"
                required
            ></ViasInput>
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Confirmar de senha"
                type="password"
                v-model="confirmarSenha"
                id="cadastro-usuario-confirmar-de-senha"
                required
            ></ViasInput>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol>
            <ViasCheckbox
                after-text="Super usuário"
                v-model="superUsuario"
                id="cadastro-usuario-super-usuario"
                switch
            ></ViasCheckbox>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol>
            <ViasButton variant="primary" id="cadastro-usuario-botao-adicionar">
                Adicionar
            </ViasButton>
        </ViasCol>
    </ViasRow>
</form>

</template>

<script lang="ts" setup>
import { ref, defineEmits } from "vue";
import { UsuarioParaCadastro } from "../models/UsuarioParaCadastro";
import usuarioService from "../services/UsuarioService";
import notificacaoService from "@/domains/Notificacao/services/notificacaoService";

const emit = defineEmits<{
    usuarioAdicionado: []
}>();

const nome = ref("");
const usuario = ref("");
const email = ref("");
const senha = ref("");
const confirmarSenha = ref("");
const superUsuario = ref(false);

function criarJsonDeNovoUsuario(): UsuarioParaCadastro {
    return {
        nome: nome.value,
        username: usuario.value,
        email: email.value,
        senha: senha.value,
        confirmar_senha: confirmarSenha.value,
        super_usuario: superUsuario.value
    }
}

async function adicionarUsuario() {
    notificacaoService.notificarCarregamento(
        "Cadastrando novo usuário",
        "Aguarde um momento, por favor..."
    );

    const novoUsuario = criarJsonDeNovoUsuario();

    await usuarioService.adicionarUsuario(novoUsuario);

    notificacaoService.notificarSucesso(
        "Sucesso!",
        `O usuário "${ novoUsuario.nome }" foi adicionado com sucesso!`
    );

    emit("usuarioAdicionado");
}
</script>