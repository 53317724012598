import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faXmark,
    faScroll,
    faRecycle,
    faTachometerAlt,
    faRoad,
    faRoute,
    faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSignOutAlt,
    faVideo,
    faCircleExclamation,
    faBars,
    faSun,
    faMoon,
} from "@fortawesome/free-solid-svg-icons";
import {
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import {
    faAngleUp,
    faEdit,
    faCheck,
    faTrashAlt,
    faEllipsisVertical,
    faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";

library.add(
    faXmark,
    faScroll,
    faRecycle,
    faTachometerAlt,
    faRoad,
    faRoute,
    faCog,
);
library.add(faSignOutAlt, faVideo, faCircleExclamation, faBars, faSun, faMoon);
library.add(
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faAngleDown,
);
library.add(
    faAngleUp,
    faEdit,
    faCheck,
    faTrashAlt,
    faEllipsisVertical,
    faCircleInfo,
);
library.add(faInfoCircle);

library.add(faCircle, faDotCircle);
