<template>

<form @submit.prevent="buscarRelatorioDeFluxoVeicular()">
    <ViasRow>
        <ViasCol :cols="12" :md="6">
            <ViasRow class="mb-3">
                <ViasCol :cols="12">
                    <h2 class="mb-2">Período da busca</h2>
                    <ViasRadioButton
                        name="relatorio-fluxo-veicular-periodo-busca"
                        :options="opcoesDePeriodo"
                        v-model="formulario.periodo"
                    />
                </ViasCol>
            </ViasRow>
            <ViasRow class="mb-3" v-if="!isPeriodoSelecionado('completo')">
                <ViasCol :cols="12">
                    <ViasInputDateAndHour
                        v-if="isPeriodoSelecionado('hora')"
                        label="Selecione a data e a hora"
                        v-model="formulario.data_hora"
                    />
                    <ViasInputDate
                        v-if="isPeriodoSelecionado('dia')"
                        label="Selecione a data"
                        v-model="formulario.data_hora"
                    />
                    <ViasInputWeek
                        v-if="isPeriodoSelecionado('semana')"
                        label="Selecione a semana"
                        v-model="formulario.data_hora"
                    />
                    <ViasInputMonthAndYear
                        v-if="isPeriodoSelecionado('mes')"
                        label="Selecione o mês"
                        v-model="formulario.data_hora"
                    />
                    <ViasInputYear
                        v-if="isPeriodoSelecionado('ano')"
                        label="Selecione o ano"
                        v-model="formulario.data_hora"
                    />
                </ViasCol>
            </ViasRow>
            <ViasRow class="mb-3">
                <ViasCol :cols="12">
                    <h2>Apresentar resultado por equipamento</h2>
                    <ViasCheckbox
                        before-text="Não"
                        after-text="Sim"
                        v-model="formulario.por_equipamento"
                        switch
                    />
                </ViasCol>
            </ViasRow>
            <ViasRow class="mb-3">
                <ViasCol :cols="12">
                    <h2>Apresentar resultado por faixas</h2>
                    <ViasCheckbox
                        before-text="Não"
                        after-text="Sim"
                        v-model="formulario.por_faixa"
                        switch
                    />
                </ViasCol>
            </ViasRow>
        </ViasCol>
        <ViasCol :cols="12" :md="6">
            <ViasRow>
                <ViasCol :cols="12">
                    <ViasVueSelect
                        label="Selecione um grupo"
                        :options="opcoesDeGrupos"
                        v-model="formulario.grupo_id"
                    />
                </ViasCol>
            </ViasRow>
            <ViasRow>
                <ViasCol :cols="12">
                    <ViasMapaEquipamentosComListaDeSelecao
                        v-model:faixas-selecionadas="formulario.faixas"
                    />
                </ViasCol>
            </ViasRow>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasButton variant="primary">
                Buscar
            </ViasButton>
            <ViasButton
                class="ml-2"
                variant="outline-primary"
                type="button"
                @click="abrirModalDeAgendamentoDeRelatorio()"
            >
                Agendar
            </ViasButton>
        </ViasCol>
    </ViasRow>
</form>

<ViasModal
    title="Agendamento de Relatório de Fluxo Veicular"
    :show-button="false"
    v-model="modalDeAgendamentoAberto"
>
    <FormularioAgendamentoRelatorioFluxoVeicular
        :por-equipamento="formulario.por_equipamento"
        :por-faixa="formulario.por_faixa"
        :periodo-da-busca="formulario.periodo"
        :grupo="formulario.grupo_id"
        :faixas="formulario.faixas"
        :horario="formulario.data_hora"
        @relatorio-agendado="fecharModalDeAgendamentoDeRelatorioDeFluxoVeicular()"
    />
</ViasModal>

</template>

<script lang="ts" setup>
import ViasCheckbox from '@/components/shared/ViasCheckbox.vue';
import ViasRadioButton from '@/components/shared/ViasRadioButton.vue';
import ViasInputDateAndHour from '@/components/shared/ViasInputDateAndHour.vue';
import ViasInputDate from '@/components/shared/ViasInputDate.vue';
import ViasInputWeek from '@/components/shared/ViasInputWeek.vue';
import ViasInputMonthAndYear from '@/components/shared/ViasInputMonthAndYear.vue';
import ViasInputYear from '@/components/shared/ViasInputYear.vue';
import ViasVueSelect from '@/components/shared/ViasVueSelect.vue';
import gruposService from '@/domains/Grupos/services/GruposService';
import fluxoService from '../services/FluxoService';
import moment from 'moment';
import { onMounted, ref, defineExpose, defineEmits, defineModel } from 'vue';
import { FormularioParaBuscarRelatorioDeFluxoVeicular } from '../models/FormularioParaBuscarRelatorioDeFluxoVeicular';
import { ISelectOption } from '@/interfaces/select/ISelectOption';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';
import velocidadeService from '@/domains/Velocidade/services/VelocidadeService';
import { PeriodoDeRelatorio } from '../models/PeriodoDoRelatorioDeFluxoVeicular';
import ViasMapaEquipamentosComListaDeSelecao from '@/components/shared/mapa/ViasMapaEquipamentosComListaDeSelecao.vue';
import FormularioAgendamentoRelatorioFluxoVeicular from './FormularioAgendamentoRelatorioFluxoVeicular.vue';

const emit = defineEmits<{
    apresentarRelatorio: [e: any],
    apresentarRelatorioPorFaixa: [e: any],
    apresentarRelatorioPorEquipamento: [e: any],
    apresentarRelatorioPorFaixaEPorEquipamento: [e: any]
}>();

const opcoesDePeriodo = ref<ISelectOption[]>([
    {
        text: "Hora",
        value: "hora"
    },
    {
        text: "Dia",
        value: "dia"
    },
    {
        text: "Semana",
        value: "semana"
    },
    {
        text: "Mês",
        value: "mes"
    },
    {
        text: "Ano",
        value: "ano"
    },
    {
        text: "Completo",
        value: "completo"
    }
]);
const opcoesDeGrupos = ref<ISelectOption[]>([
    {
        text: "Nenhum grupo selecionado",
        value: null
    }
]);

const formulario = ref<FormularioParaBuscarRelatorioDeFluxoVeicular>({
    por_equipamento: false,
    por_faixa: false,
    data_hora: moment().format("YYYY-MM-DDTHH:mm:ss"),
    faixas: [],
    grupo_id: null,
    periodo: "completo"
});
const ultimaBusca = defineModel<FormularioParaBuscarRelatorioDeFluxoVeicular>("ultimaBusca");

const modalDeAgendamentoAberto = ref(false);

async function buscarGrupos() {
    const { data } = await gruposService.buscarGrupos();
    opcoesDeGrupos.value = opcoesDeGrupos.value.concat(data.map((grupo) => ({
        text: grupo.nome,
        value: grupo.id
    })));
}

function isPeriodoSelecionado(periodo: PeriodoDeRelatorio) {
    return formulario.value.periodo === periodo;
}

async function buscarRelatorioDeFluxoVeicular(relatorioAtualizado = false) {
    notificacaoService.notificarCarregamento(
        "Buscando relatório de fluxo veicular, aguarde um momento por favor..."
    );

    Promise.all([
        fluxoService.buscarRelatorioDeFluxoVeicular(formulario.value),
        velocidadeService.buscarFluxoPorVelocidadeSeparadoPorClasse(formulario.value)
    ]).then(([responseFluxo, responseVelocidades]) => {
        ultimaBusca.value = Object.assign({}, formulario.value);
        enviarRelatorioParaApresentacao(responseFluxo.data, responseVelocidades.data, relatorioAtualizado);
        
        notificacaoService.notificarSucesso("Relatório de fluxo veicular solicitado foi gerado!");
    });
}

function enviarRelatorioParaApresentacao(relatorioDeFluxoGerado: any, relatorioDeVelocidadesGerado: any, relatorioAtualizado: boolean) {
    const relatorios = {
        fluxo: relatorioDeFluxoGerado,
        velocidades: relatorioDeVelocidadesGerado,
        relatorioAtualizado
    };
    if (isRelatorioPorFaixaEPorEquipamento()) {
        emit("apresentarRelatorioPorFaixaEPorEquipamento", relatorios);
        return;
    }
    if (isRelatorioPorEquipamento()) {
        emit("apresentarRelatorioPorEquipamento", relatorios);
        return;
    }
    if(isRelatorioPorFaixa()) {
        emit("apresentarRelatorioPorFaixa", relatorios);
        return;
    }
    emit("apresentarRelatorio", relatorios);
}

function isRelatorioPorEquipamento() {
    return formulario.value.por_equipamento;
}

function isRelatorioPorFaixa() {
    return formulario.value.por_faixa;
}

function isRelatorioPorFaixaEPorEquipamento() {
    return isRelatorioPorEquipamento() && isRelatorioPorFaixa();
}

onMounted(() => {
    buscarGrupos();
});

function atualizarFormularioEBuscarRelatorio(
    novoPeriodo: PeriodoDeRelatorio,
    novaData?: string
): void {
    formulario.value.periodo = novoPeriodo;

    if (novaData) {
        formulario.value.data_hora = novaData;
    }
    
    buscarRelatorioDeFluxoVeicular(true);
}

function abrirModalDeAgendamentoDeRelatorio() {
    modalDeAgendamentoAberto.value = true;
}

function fecharModalDeAgendamentoDeRelatorioDeFluxoVeicular() {
    modalDeAgendamentoAberto.value = false;
}

defineExpose({
    atualizarFormularioEBuscarRelatorio
});
</script>