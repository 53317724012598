export const formatar = (n) =>
    new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 2 }).format(n);

export const formatarDinheiro = (n) =>
    n.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

export const tratarTempoParaHoraMinutoSegundo = (segundos) => {
    const horas = adicionarZeroCasoNumeroSejaMenorQue10(
        parseInt(segundos / 3600),
    );
    segundos %= 3600;
    const minutos = adicionarZeroCasoNumeroSejaMenorQue10(
        parseInt(segundos / 60),
    );
    segundos %= 60;
    segundos = adicionarZeroCasoNumeroSejaMenorQue10(parseInt(segundos));

    return `${horas}:${minutos}:${segundos}`;
};

export const adicionarZeroCasoNumeroSejaMenorQue10 = (n) =>
    n > 9 ? n : `0${n}`;
