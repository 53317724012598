<template>
    <div class="bloco-retratil" :class="posicao">
        <div class="p-relative" v-if="abertura === 'cima'">
            <div class="target" :class="{ show: show }">
                <slot></slot>
            </div>
            <vias-button
                type="button"
                size="sm"
                :variant="variante"
                @click="toggleTarget()"
                >{{ texto }}</vias-button
            >
        </div>
        <div class="p-relative" v-else>
            <vias-button
                type="button"
                size="sm"
                :variant="variante"
                @click="toggleTarget()"
                >{{ texto }}</vias-button
            >
            <div class="target" :class="{ show: show }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ViasButton from "@/components/shared/ViasButton.vue";

export default {
    props: {
        texto: {
            type: String,
            required: true,
        },
        variante: {
            type: String,
            required: false,
            default: "primary",
        },
        posicao: {
            type: String,
            required: false,
            default: "p-map-bottom-left",
        },
        abertura: {
            type: String,
            required: false,
            default: "cima",
        },
    },
    components: {
        ViasButton,
    },
    data: () => ({
        show: false,
    }),
    methods: {
        toggleTarget() {
            this.show = !this.show;
        },
    },
};
</script>
