<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-button variant="primary" type="submit"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div id="relatorio-total-de-infracoes-por-enquadramento" v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div class="grafico-de-relatorio p-6">
                <vias-line-chart
                    :labels="labels"
                    :datasets="equipamento.enquadramentos"
                    unidade_datalabels="autuações lavradas"
                    eixo_x="Mês"
                    eixo_y="Total de autuações lavradas"
                ></vias-line-chart>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import { ref } from "vue";
import {
    buscarTotalDeInfracoesLavradas,
    emitirPdfDoTotalDeInfracoesLavradas,
} from "@/services/integrated-management.service";
import { open, close } from "@/services/notification.service";
import { cores } from "@/services/variables.service";
import moment from "moment";

const relatorio = ref(null);
const labels = ref([]);

const buscarRelatorio = () => {
    open("Buscando relatório, aguarde um momento por favor...");
    buscarTotalDeInfracoesLavradas()
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = [];
            const coresEmArray = [
                cores.primary,
                cores.danger,
                cores.success,
                cores.warning,
            ];
            let separarLabels = true;
            let i = 0;
            for (const [sitio, informacoes] of Object.entries(dados)) {
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    enquadramentos: [],
                };

                i = 0;
                for (const [enquadramento, meses] of Object.entries(
                    informacoes.grafico,
                )) {
                    if (separarLabels) {
                        labels.value = Object.keys(meses).map((mes) =>
                            moment(mes).format("MMMM, YYYY"),
                        );
                        separarLabels = false;
                    }

                    equipamento.enquadramentos.push({
                        label: enquadramento,
                        data: Object.values(meses),
                        backgroundColor: coresEmArray[i],
                        borderColor: coresEmArray[i],
                    });
                    i++;
                }

                relatorio.value.push(equipamento);
            }
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDoTotalDeInfracoesLavradas()
        .then((res) => res.data.mensagem)
        .then((mensagem) => {
            close(mensagem, "success");
        });
};
</script>
