import { State } from "@/storeTs";
import axios from "@/plugins/axios.plugin";
import { Module } from "vuex";
import {
    BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO,
    EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO,
} from "./type-actions";

export const gestaoIntegradaMapaModule: Module<State, State> = {
    actions: {
        [BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO](context, dados: any) {
            let url = `/vias/v1/equipamentos/${dados.equipamento}/dashboard`;

            if (dados.data_hora) {
                url += `?data_hora=${dados.data_hora}`;
            }

            return axios.get(url);
        },
        [EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO](context, dados: any) {
            let url = `/vias/v1/equipamentos/${dados.equipamento}/dashboard/pdf`;

            if (dados.data_hora) {
                url += `?data_hora=${dados.data_hora}`;
            }

            return axios.get(url);
        },
    },
};
