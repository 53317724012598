import { Module } from "vuex";
import { State } from "..";
import {
    BUSCAR_AFERICOES,
    BUSCAR_AFERICOES_SEM_UTILIZAR_FILTRO,
    BUSCAR_EQUIPAMENTOS,
    BUSCAR_EQUIPAMENTOS_ESTATICOS,
    BUSCAR_EQUIPAMENTO_POR_ID,
    BUSCAR_TIPOS_DE_EQUIPAMENTOS,
} from "./type-actions";
import axios from "../../plugins/axios.plugin.js";
import { IEquipamentoAfericao } from "@/interfaces/equipamento/IEquipamentoAfericao";
import { AxiosResponse } from "axios";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { IEquipamentoCompleto } from "@/interfaces/equipamento/IEquipamentoCompleto";

export const equipamentosModule: Module<State, State> = {
    actions: {
        [BUSCAR_EQUIPAMENTOS]({
            rootState,
        }): Promise<AxiosResponse<IEquipamento[], IEquipamento[]>> {
            if (rootState.tipos_equipamentos_selecionados.length > 0) {
                const query = rootState.tipos_equipamentos_selecionados
                    .map((tipo) => `tipos=${tipo}`)
                    .join("&");
                return axios.get("/vias/v1/equipamentos/fixo?" + query);
            }
            return new Promise((resolve) =>
                resolve({
                    status: 200,
                    statusText: "ok",
                    headers: {},
                    config: {},
                    data: [],
                }),
            );
        },
        [BUSCAR_EQUIPAMENTOS_ESTATICOS]({
            rootState,
        }): Promise<AxiosResponse<IEquipamento[], IEquipamento[]>> {
            return axios.get(
                `/vias/v1/equipamentos/estaticos?data=${rootState.dataBuscaEquipamentosEstaticos}`,
            );
        },
        [BUSCAR_TIPOS_DE_EQUIPAMENTOS]() {
            return axios.get("/vias/v1/equipamentos/tipos");
        },
        [BUSCAR_AFERICOES]({
            rootState,
        }): Promise<
            AxiosResponse<IEquipamentoAfericao[], IEquipamentoAfericao[]>
        > {
            if (rootState.tipos_equipamentos_selecionados.length > 0) {
                const query = rootState.tipos_equipamentos_selecionados
                    .map((tipo) => `tipos=${tipo}`)
                    .join("&");
                return axios.get("/vias/v1/equipamentos/afericoes?" + query);
            }
            return new Promise((resolve) =>
                resolve({
                    status: 200,
                    statusText: "ok",
                    headers: {},
                    config: {},
                    data: [],
                }),
            );
        },
        [BUSCAR_AFERICOES_SEM_UTILIZAR_FILTRO](): Promise<
            AxiosResponse<IEquipamentoAfericao[], IEquipamentoAfericao[]>
        > {
            return axios.get("/vias/v1/equipamentos/afericoes");
        },
        [BUSCAR_EQUIPAMENTO_POR_ID](
            context,
            id: number,
        ): Promise<AxiosResponse<IEquipamentoCompleto, IEquipamentoCompleto>> {
            return axios.get(`/vias/v1/equipamentos/${id}`);
        },
    },
};
