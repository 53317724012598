<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template v-slot:header>
                    <div class="row row-between">
                        <h2 class="card-title">Novo grupo</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaApresentacaoDeGruposCadastrados()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>

                <ViasRow>
                    <ViasCol>
                        <FormularioNovoGrupo
                            @novo-grupo="adicionarNovoGrupo($event)"
                        ></FormularioNovoGrupo>
                    </ViasCol>
                </ViasRow>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import FormularioNovoGrupo from "./components/FormularioNovoGrupo.vue";
import { INovoGrupo } from "./interfaces/INovoGrupo";
import { useStore } from "@/storeTs";
import { ADICIONAR_NOVO_GRUPO } from "@/storeTs/gestao-integrada/grupos/actions-type";
import { useNotificacao } from "@/hooks/notificacao.hook";

export default defineComponent({
    name: "NovoGrupoView",
    components: {
        FormularioNovoGrupo,
    },
    methods: {
        voltarParaApresentacaoDeGruposCadastrados() {
            this.router.push("/gestao-integrada/grupos");
        },
        adicionarNovoGrupo(novoGrupo: INovoGrupo) {
            this.notificarCarregamento(
                "Estamos enviandos os dados para adicionar o novo grupo, aguarde um momento por favor...",
            );
            this.store.dispatch(ADICIONAR_NOVO_GRUPO, novoGrupo).then((res) => {
                if (res.status === 201) {
                    this.notificar(
                        `O grupo "${novoGrupo.nome}" foi criado com sucesso!`,
                        "success",
                    );
                    this.router.push("/gestao-integrada/grupos");
                }
            });
        },
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
