import { FormularioParaBuscarRelatorioDeFluxoVeicular } from "../models/FormularioParaBuscarRelatorioDeFluxoVeicular";

class ValidadorDeRelatorioDeFluxoVeicular {
    
    isRelatorioPorEquipamento(buscaRealizada: FormularioParaBuscarRelatorioDeFluxoVeicular) {
        return buscaRealizada.por_equipamento;
    }

    isRelatorioPorFaixa(buscaRealizada: FormularioParaBuscarRelatorioDeFluxoVeicular) {
        return buscaRealizada.por_faixa;
    }

    isRelatorioPorEquipamentoEPorFaixa(buscaRealizada: FormularioParaBuscarRelatorioDeFluxoVeicular) {
        return this.isRelatorioPorEquipamento(buscaRealizada) && this.isRelatorioPorFaixa(buscaRealizada);
    }

}

const validadorDeRelatorioDeFluxoVeicular = new ValidadorDeRelatorioDeFluxoVeicular();

export default validadorDeRelatorioDeFluxoVeicular;