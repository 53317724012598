<template>

<div v-if="formulario">

    <ViasRow>
        <ViasCol :cols="12">
            <h2>Informações do agendamento</h2>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Período em que o relatório será extraído"
                id="agendamento-relatorio-selecao-periodo"
                v-model="periodo"
                :options="opcoesDePeriodo"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow v-if="isPeriodoSemanalSelecionado">
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Dia da semana"
                id="agendamento-relatorio-selecao-dia-semana"
                v-model="diaDaSemana"
                :options="opcoesDeDiaDaSemana"
                @change="atualizarDiaDaSemana()"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow v-else-if="isPeriodoMensalSelecionado">
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Dia do mês"
                id="agendamento-relatorio-selecao-dia-mes"
                v-model="diaDoMes"
                :options="opcoesDeDiaDoMes"
                @change="atualizarDiaDoMes()"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasInputTime
                label="Horário da emissão do relatório"
                id="agendamento-relatorio-horario"
                v-model="formulario.hora"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <h2>O relatório deve ser do mesmo dia da emissão</h2>
            <ViasCheckbox
                before-text="Não"
                after-text="Sim"
                id="agendamento-relatorio-relatorio-no-mesmo-dia-da-emissao"
                v-model="oRelatorioDeveSerDoMesmoDiaDaEmissao"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow v-if="!oRelatorioDeveSerDoMesmoDiaDaEmissao">
        <ViasCol :cols="6">
            <ViasInput
                label="Quantos dias/meses antes da data da emissão?"
                type="number"
                placeholder="Ex: 5"
                v-model="periodoAntesDaDataDeEmissaoDoRelatorio"
            />
        </ViasCol>
        <ViasCol :cols="6">
            <ViasSelect
                label="Dias ou meses?"
                :options="opcoesDePeriodosDaEmissao"
                v-model="formulario.atributo_de_calculo"
            />
        </ViasCol>
    </ViasRow>

</div>

</template>

<script lang="ts" setup>
import ViasVueSelect from '@/components/shared/ViasVueSelect.vue';
import { ParametrosAgendamentoDeRelatorio } from '../models/ParametrosAgendamentoDeRelatorio';
import { computed, ref, defineModel, watch } from 'vue';
import { ISelectOption } from '@/interfaces/select/ISelectOption';
import ViasInputTime from '@/components/shared/ViasInputTime.vue';
import ViasCheckbox from '@/components/shared/ViasCheckbox.vue';
import ViasSelect from '@/components/shared/ViasSelect.vue';

const opcoesDePeriodo = ref<ISelectOption[]>([
    {
        text: "Diário",
        value: "diario"
    },
    {
        text: "Semanal",
        value: "semanal"
    },
    {
        text: "Mensal",
        value: "mensal"
    }
]);

const opcoesDeDiaDaSemana = ref<ISelectOption[]>([
    {
        text: "Segunda-feira",
        value: 0
    },
    {
        text: "Terça-feira",
        value: 1
    },
    {
        text: "Quarta-feira",
        value: 2
    },
    {
        text: "Quinta-feira",
        value: 3
    },
    {
        text: "Sexta-feira",
        value: 4
    },
    {
        text: "Sábado",
        value: 5
    },
    {
        text: "Domingo",
        value: 6
    }
]);

const opcoesDeDiaDoMes = ref<ISelectOption[]>(new Array(31).fill(0).map((n, i) => ({
    text: `Dia ${i + 1}`,
    value: i + 1
})));

const periodo = ref<"diario" | "semanal" | "mensal">("diario");
const diaDaSemana = ref(0);
const diaDoMes = ref(1);

const isPeriodoSemanalSelecionado = computed(() => periodo.value === "semanal");
const isPeriodoMensalSelecionado = computed(() => periodo.value === "mensal");

const oRelatorioDeveSerDoMesmoDiaDaEmissao = ref(true);
const periodoAntesDaDataDeEmissaoDoRelatorio = ref("");

const opcoesDePeriodosDaEmissao = ref<ISelectOption[]>([
    {
        text: "Dias",
        value: "days"
    },
    {
        text: "Meses",
        value: "months"
    }
]);

const formulario = defineModel<ParametrosAgendamentoDeRelatorio>();

function atualizarOperandoDoRelatorio() {
    if (formulario.value) {
        if (oRelatorioDeveSerDoMesmoDiaDaEmissao.value) {
            formulario.value.operando = 0;
        } else {
            formulario.value.operando = parseInt(periodoAntesDaDataDeEmissaoDoRelatorio.value);
        }
    }
}

function atualizarDiaDaSemana() {
    if (formulario.value) {
        formulario.value.dia_da_semana = diaDaSemana.value;
    }
}

function atualizarDiaDoMes() {
    if (formulario.value) {
        formulario.value.dia_do_mes = diaDoMes.value;
    }
}

watch(() => oRelatorioDeveSerDoMesmoDiaDaEmissao.value, () => {
    atualizarOperandoDoRelatorio();
});

watch(() => periodoAntesDaDataDeEmissaoDoRelatorio.value, () => {
    atualizarOperandoDoRelatorio();
});

watch(() => periodo.value, () => {
    if (formulario.value) {
        if (periodo.value === "diario") {
            formulario.value.diario = true;
            formulario.value.dia_do_mes = null;
            formulario.value.dia_da_semana = null;
            return;
        }
        if (periodo.value === "mensal") {
            formulario.value.diario = false;
            formulario.value.dia_do_mes = diaDoMes.value;
            formulario.value.dia_da_semana = null;
            return;
        }
        if (periodo.value === "semanal") {
            formulario.value.diario = false;
            formulario.value.dia_do_mes = null;
            formulario.value.dia_da_semana = diaDaSemana.value;
            return;
        }
    }
});
</script>