<template>

<form @submit.prevent="agendarRelatorioDeFluxoVeicular()">

    <FormularioAgendamentoRelatorio v-model="formulario"/>

    <hr class="mb-4">

    <ViasRow class="mb-4">
        <ViasCol :cols="12">
            <h2>Informações do relatório</h2>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <h2 class="mb-2">Período da busca</h2>
            <ViasRadioButton
                name="agendamento-relatorio-fluxo-veicular-periodo"
                id="agendamento-relatorio-fluxo-veicular-periodo"
                :options="opcoesDePeriodo"
                v-model="formulario.parametros.periodo"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasInputTime
                label="Horário do relatório"
                id="agendamento-relatorio-fluxo-veicular-horario"
                v-model="formulario.parametros.hora"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <h2>Gerar relatório por equipamento</h2>
            <ViasCheckbox
                before-text="Não"
                after-text="Sim"
                v-model="formulario.parametros.por_equipamento"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <h2>Gerar relatório por faixa</h2>
            <ViasCheckbox
                before-text="Não"
                after-text="Sim"
                v-model="formulario.parametros.por_faixa"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Grupo"
                id="agendamento-relatorio-fluxo-veicular-grupo"
                v-model="formulario.parametros.grupo_id"
                :options="opcoesDeGrupo"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasMapaEquipamentosComListaDeSelecao
                v-model:faixas-selecionadas="formulario.parametros.faixas"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasButton variant="primary" type="submit">
                Agendar
            </ViasButton>
        </ViasCol>
    </ViasRow>

</form>

</template>

<script lang="ts" setup>
import { onMounted, ref, defineEmits } from 'vue';
import { ParametrosAgendamentoParaRelatorioFluxoVeicular } from '../models/ParametrosAgendamentoParaRelatorioFluxoVeicular';
import fluxoService from '../services/FluxoService';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';
import FormularioAgendamentoRelatorio from '@/domains/AgendamentoDeRelatorio/components/FormularioAgendamentoRelatorio.vue';
import { ISelectOption } from '@/interfaces/select/ISelectOption';
import moment from 'moment';
import ViasInputTime from '@/components/shared/ViasInputTime.vue';
import ViasVueSelect from '@/components/shared/ViasVueSelect.vue';
import gruposService from '@/domains/Grupos/services/GruposService';
import ViasMapaEquipamentosComListaDeSelecao from '@/components/shared/mapa/ViasMapaEquipamentosComListaDeSelecao.vue';
import ViasCheckbox from '@/components/shared/ViasCheckbox.vue';
import ViasRadioButton from '@/components/shared/ViasRadioButton.vue';

const emit = defineEmits<{
    relatorioAgendado: []
}>();

const opcoesDePeriodo = ref<ISelectOption[]>([
    {
        text: "Hora",
        value: "hora"
    },
    {
        text: "Dia",
        value: "dia"
    },
    {
        text: "Semana",
        value: "semana"
    },
    {
        text: "Mês",
        value: "mes"
    },
    {
        text: "Ano",
        value: "ano"
    },
    {
        text: "Completo",
        value: "completo"
    }
]);
const opcoesDeGrupo = ref<ISelectOption[]>([{
    text: "Nenhum grupo selecionado",
    value: null
}]);

const formulario = ref<ParametrosAgendamentoParaRelatorioFluxoVeicular>({
    diario: true,
    dia_da_semana: null,
    dia_do_mes: null,
    hora: moment().format("HH:mm:ss"),
    relatorio: "Gráfico de Fluxo",
    operando: 0,
    atributo_de_calculo: "days",
    parametros: {
        periodo: "hora",
        hora: moment().format("HH:mm:ss"),
        grupo_id: null,
        faixas: [],
        por_equipamento: false,
        por_faixa: false
    }
});

async function agendarRelatorioDeFluxoVeicular() {
    notificacaoService.notificarCarregamento(
        "Estamos agendando a emissão do relatório, aguarde um momento por favor..."
    );
    await fluxoService.agendarEmissaoDoPdfDoRelatorioDeFluxoVeicular(
        formulario.value
    );
    notificacaoService.notificarSucesso("Relatório agendado com sucesso!");
    emit("relatorioAgendado");
    reiniciarFormulario();
}

function reiniciarFormulario(): void {
    formulario.value = {
        diario: true,
        dia_da_semana: null,
        dia_do_mes: null,
        hora: moment().format("HH:mm:ss"),
        relatorio: "Gráfico de Fluxo",
        operando: 0,
        atributo_de_calculo: "days",
        parametros: {
            periodo: "hora",
            hora: moment().format("HH:mm:ss"),
            grupo_id: null,
            faixas: [],
            por_equipamento: false,
            por_faixa: false
        }
    }
}

async function buscarGrupos() {
    const { data } = await gruposService.buscarGrupos();
    opcoesDeGrupo.value = opcoesDeGrupo.value.concat(data.map((grupo) => ({
        text: grupo.nome,
        value: grupo.id
    })));
}

onMounted(() => {
    buscarGrupos();
});
</script>