<template>
    <div class="form-group datepicker">
        <label :for="id" :class="isLabelValid">{{ label }}</label>
        <datepicker
            :id="id"
            class="w-100"
            :class="[isValid]"
            v-model="value"
            :dark="theme"
            @update:model-value="atualizarData"
            format="dd/MM/yyyy, HH:mm"
            :disabled="disabled"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            month-name-format="long"
            auto-apply
            week-start="0"
            week-numbers="iso"
            week-num-name="sem"
            :closeOnAutoApply="false"
            :range="{ partialRange: false }"
        />
        <small v-if="hasDetail" :id="getDetailId" class="detail">
            {{ detail }}
        </small>
    </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
    name: "FotoInputDateAndTime",
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: `id-${Math.floor(Math.random() * 1000)}`,
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: null,
        },
        detail: {
            type: String,
            default: null,
        },
    },
    components: {
        Datepicker,
    },
    data: () => ({
        value: [],
    }),
    computed: {
        theme() {
            return this.$store.state.theme === "dark-theme";
        },
        isValid() {
            if (this.valid === true) {
                return "valid";
            }
            if (this.valid === false) {
                return "invalid";
            }
            return "";
        },
        hasDetail() {
            return this.detail;
        },
        isLabelValid() {
            if (this.valid === true) {
                return "text-success";
            }
            if (this.valid === false) {
                return "text-danger";
            }
            return "";
        },
    },
    methods: {
        atualizarData() {
            if (this.value == null) {
                this.value = [];
            } else {
                this.value = [
                    moment(this.value[0]).format("YYYY-MM-DDTHH:mm:ss"),
                    moment(this.value[1]).format("YYYY-MM-DDTHH:mm:ss"),
                ];
            }
            this.$emit("update:modelValue", this.value);
            this.$emit("change", this.value);
        },
    },
    created() {
        this.value = this.modelValue;
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
};
</script>
