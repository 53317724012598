import axios from "@/plugins/axios.plugin";
import {
    ADICIONAR_NOVA_PLACA_DE_WHITELIST,
    EDITAR_PLACA_DE_WHITELIST,
    BUSCAR_AUDITORIA_DE_WHITELIST_POR_ID,
    BUSCAR_PLACAS_DE_WHITELIST,
    BUSCAR_PLACA_DE_WHITELIST_POR_ID,
    REMOVER_PLACA_DE_WHITELIST,
} from "./type-actions";
import { Module } from "vuex";
import { State } from "@/storeTs";
import { INovaPlacaNaWhitelist } from "@/interfaces/whitelist/INovaPlacaNaWhitelist";
import { IPlacaDaWhitelist } from "@/interfaces/whitelist/IPlacaDaWhitelist";

export const configuracaoWhitelistModule: Module<State, State> = {
    actions: {
        [BUSCAR_PLACAS_DE_WHITELIST]() {
            return axios.get("/vias/v1/whitelists");
        },
        [BUSCAR_PLACA_DE_WHITELIST_POR_ID](context, id: number) {
            return axios.get(`/vias/v1/whitelists/${id}`);
        },
        [ADICIONAR_NOVA_PLACA_DE_WHITELIST](
            context,
            novaWhitelist: INovaPlacaNaWhitelist,
        ) {
            return axios.post("/vias/v1/whitelists", novaWhitelist);
        },
        [EDITAR_PLACA_DE_WHITELIST](
            context,
            whitelistAtualizada: IPlacaDaWhitelist,
        ) {
            return axios.put(
                `/vias/v1/whitelists/${whitelistAtualizada.id}`,
                whitelistAtualizada,
            );
        },
        [REMOVER_PLACA_DE_WHITELIST](context, idWhitelist: number) {
            return axios.delete(`/vias/v1/whitelists/${idWhitelist}`);
        },
        [BUSCAR_AUDITORIA_DE_WHITELIST_POR_ID](context, idWhitelist: number) {
            return axios.get(`/vias/v1/whitelists/${idWhitelist}/audit-logs`);
        },
    },
};
