<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template v-slot:header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Grupos</h2>
                        <router-link
                            class="btn btn-primary"
                            to="/gestao-integrada/grupos/novo-grupo"
                        >
                            Adicionar
                        </router-link>
                    </div>
                </template>
                <ViasTable
                    class="mt-4"
                    :colunas="colunas"
                    :linhas="grupos"
                    :carregando="primeiraBusca"
                    com-opcoes
                >
                    <template #opcoes="{ linha }">
                        <div class="d-flex justify-content-end">
                            <router-link
                                class="btn btn-outline-primary btn-sm m-2"
                                :to="
                                    '/gestao-integrada/grupos/editar-grupo/' +
                                    linha.id
                                "
                            >
                                Editar
                            </router-link>
                            <ViasButton
                                variant="none-danger"
                                size="sm"
                                @click="removerGrupo(linha.id, linha.nome)"
                            >
                                Remover
                            </ViasButton>
                        </div>
                    </template>
                </ViasTable>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { useStore } from "@/storeTs";
import {
    BUSCAR_GRUPOS_CADASTRADOS,
    REMOVER_GRUPO,
} from "@/storeTs/gestao-integrada/grupos/actions-type";
import { defineComponent } from "vue";
import { IGrupoParaApresentacao } from "./interfaces/IGrupoParaApresentacao";
import { IGrupo } from "@/interfaces/IGrupo";
import moment from "moment";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { useNotificacao } from "@/hooks/notificacao.hook";

export default defineComponent({
    name: "GruposView",
    data: () => ({
        primeiraBusca: true,
        grupos: [] as IGrupoParaApresentacao[],
        colunas: [
            {
                titulo: "#",
                referencia: "numero_da_linha",
            },
            {
                titulo: "Nome",
                referencia: "nome",
            },
            {
                titulo: "Criado por",
                referencia: "criado_por",
            },
            {
                titulo: "Data de criação",
                referencia: "data_de_criacao",
            },
        ] as IColunaTabela[],
    }),
    methods: {
        buscarGrupos() {
            this.store
                .dispatch(BUSCAR_GRUPOS_CADASTRADOS)
                .then((res) => {
                    this.grupos = res.data.map(
                        (grupo: IGrupo, i: number): IGrupoParaApresentacao => ({
                            id: grupo.id,
                            numero_da_linha: i + 1,
                            nome: grupo.nome,
                            data_de_criacao: moment(
                                grupo.data_de_criacao,
                            ).format("DD/MM/YYYY HH:mm:ss"),
                            criado_por: grupo.criador_por,
                        }),
                    );
                })
                .finally(() => {
                    this.primeiraBusca = false;
                });
        },
        removerGrupo(id: number, nomeDoGrupo: string) {
            this.questionarAcao(
                `Tem certeza que deseja remover o grupo "${nomeDoGrupo}"? Após fazer isso, não será possível recuperá-lo.`,
            ).then((confirmacaoDeRemocao) => {
                if (confirmacaoDeRemocao) {
                    this.notificarCarregamento(
                        `Estamos removendo o grupo "${nomeDoGrupo}", aguarde um momento por favor...`,
                    );
                    this.store.dispatch(REMOVER_GRUPO, id).then((res) => {
                        if (res.status === 204) {
                            this.notificar(
                                `O grupo "${nomeDoGrupo}" foi removido com sucesso!`,
                                "success",
                            );
                            this.buscarGrupos();
                        }
                    });
                }
            });
        },
    },
    created() {
        this.buscarGrupos();
    },
    setup() {
        const store = useStore();
        const { notificar, notificarCarregamento, questionarAcao } =
            useNotificacao();

        return {
            store,
            notificar,
            notificarCarregamento,
            questionarAcao,
        };
    },
});
</script>
