import { PeriodoDeRelatorio } from "@/domains/Fluxo/models/PeriodoDoRelatorioDeFluxoVeicular";
import moment, { Moment } from "moment/moment";


function setTimeOnDatetime(datetime: string | Moment, time: string, returnFormat: string = "YYYY-MM-DDTHH:mm:ss"): string {
    const timeFractioned  = time.split(":");
    return moment(datetime)
        .set("hours", parseInt(timeFractioned[0]))
        .set("minutes", parseInt(timeFractioned[1]))
        .set("seconds", parseInt(timeFractioned[2]))
        .format(returnFormat);
}

function extrairHorarioDaData(datahora: string) {
    return moment(datahora).format("HH:mm");
}

function shapeDatetimeToSpecificFormat(datetime: string, format: PeriodoDeRelatorio) {
    if (format === "hora") {
        return moment(datetime).format("HH:mm");
    }
    if (format === "dia") {
        return moment(datetime).format("HH:00");
    }
    if (format === "semana") {
        return moment(datetime).format("DD, dddd");
    }
    if (format === "mes") {
        return moment(datetime).format("DD, dddd");
    }
    if (format === "ano") {
        return moment(datetime).format("MMMM");
    }
    if (format === "completo") {
        return moment(datetime).format("YYYY");
    }
    return datetime;
}

function shapeDatetimeToSpecificSimpleFormat(datetime: string, format: PeriodoDeRelatorio) {
    if (format === "hora") {
        return moment(datetime).format("HH:mm");
    }
    if (format === "dia") {
        return moment(datetime).format("HH:00");
    }
    if (format === "semana") {
        return moment(datetime).format("DD");
    }
    if (format === "mes") {
        return moment(datetime).format("DD");
    }
    if (format === "ano") {
        return moment(datetime).format("MMMM");
    }
    if (format === "completo") {
        return moment(datetime).format("YYYY");
    }
    return datetime;
}

function translateDatetime(datetime: string, format: PeriodoDeRelatorio) {
    if (format === "hora") {
        return moment(datetime).format("HH:00");
    }
    if (format === "dia") {
        return moment(datetime).format("DD, dddd");
    }
    if (format === "semana") {
        return moment(datetime).format("[semana] ww");
    }
    if (format === "mes") {
        return moment(datetime).format("MMMM");
    }
    if (format === "ano") {
        return moment(datetime).format("YYYY");
    }
    if (format === "completo") {
        return "Contrato";
    }
    return datetime;
}

const datetimeService = {
    setTimeOnDatetime,
    extrairHorarioDaData,
    shapeDatetimeToSpecificFormat,
    shapeDatetimeToSpecificSimpleFormat,
    translateDatetime
};

export default datetimeService;