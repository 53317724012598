<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Rotas cadastradas</h2>
                        <router-link
                            class="btn btn-primary"
                            to="/velocidade-media/configuracao/nova-rota"
                        >
                            Adicionar
                        </router-link>
                    </div>
                </template>
                <ViasTable
                    class="mt-4"
                    :carregando="primeiraBusca"
                    :linhas="rotas"
                    :colunas="colunas"
                    com-opcoes
                >
                    <template #opcoes="{ linha }">
                        <div
                            class="d-flex justify-content-end align-items-center"
                        >
                            <router-link
                                class="btn btn-outline-primary btn-sm m-2"
                                :to="
                                    '/velocidade-media/configuracao/editar-rota/' +
                                    linha.id
                                "
                            >
                                Editar
                            </router-link>
                            <ViasButton
                                variant="none-danger"
                                size="sm"
                                @click="removerRota(linha)"
                                >Remover</ViasButton
                            >
                        </div>
                    </template>
                </ViasTable>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { IRotaVelocidadeMediaApresentacao } from "./interfaces/IRotaVelocidadeMediaApresentacao";
import { IRotaVelocidadeMedia } from "./interfaces/IRotaVelocidadeMedia";
import { useStore } from "@/storeTs";
import {
    BUSCAR_ROTAS_VELOCIDADE_MEDIA,
    REMOVER_ROTA_VELOCIDADE_MEDIA,
} from "@/storeTs/velocidade-media/configuracao/type-actions";
import { defineComponent } from "vue";
import { useNotificacao } from "@/hooks/notificacao.hook";

export default defineComponent({
    name: "ConfiguracaoVelocidadeMediaView",
    data: () => ({
        colunas: [
            {
                titulo: "#",
                referencia: "numeroDaLinha",
            },
            {
                titulo: "Nome",
                referencia: "nome",
            },
            {
                titulo: "Distância",
                referencia: "distancia",
            },
            {
                titulo: "Velocidade máxima",
                referencia: "velocidadeMaxima",
            },
            {
                titulo: "Tempo de percurso",
                referencia: "tempoDePercurso",
            },
        ] as IColunaTabela[],
        rotas: [] as IRotaVelocidadeMediaApresentacao[],
        primeiraBusca: true,
    }),
    methods: {
        buscarRotas() {
            this.store.dispatch(BUSCAR_ROTAS_VELOCIDADE_MEDIA).then((res) => {
                this.rotas = res.data.map(
                    (
                        rota: IRotaVelocidadeMedia,
                        i: number,
                    ): IRotaVelocidadeMediaApresentacao => ({
                        id: rota.id,
                        numeroDaLinha: i + 1,
                        nome: rota.nome,
                        distancia: `${(rota.distancia_entre_equipamentos / 1000).toLocaleString("pt-BR")} km`,
                        velocidadeMaxima: `${rota.limite_de_velocidade} km/h`,
                        tempoDePercurso: `${rota.tempo_entre_equipamentos.toLocaleString("pt-BR")} segundos`,
                    }),
                );
                this.primeiraBusca = false;
            });
        },
        removerRota(rota: IRotaVelocidadeMediaApresentacao) {
            this.questionarAcao(
                `Deseja realmente remover a rota "${rota.nome}"?Após removê-la, não será possível recuperá-la.`,
            ).then((isConfirmado) => {
                if (isConfirmado) {
                    this.notificarCarregamento(
                        `Estamos removendo a rota "${rota.nome}", aguarde um momento por favor...`,
                    );
                    this.store
                        .dispatch(REMOVER_ROTA_VELOCIDADE_MEDIA, rota.id)
                        .then((res) => {
                            if (res.status === 204) {
                                this.notificar(
                                    `A rota "${rota.nome}" foi removida com sucesso!`,
                                    "success",
                                );
                                this.buscarRotas();
                            }
                        });
                }
            });
        },
    },
    created() {
        this.buscarRotas();
    },
    setup() {
        const store = useStore();
        const { questionarAcao, notificar, notificarCarregamento } =
            useNotificacao();

        return {
            store,
            questionarAcao,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
