export const BUSCAR_OCORRENCIA_ESPECIFICA = "BUSCAR_OCORRENCIA_ESPECIFICA";
export const GERAR_LINKS_IMAGENS = "GERAR_LINKS_IMAGENS";

export const BUSCAR_ROTAS_VELOCIDADE_MEDIA = "BUSCAR_ROTAS_VELOCIDADE_MEDIA";
export const BUSCAR_TODAS_AS_ROTAS_VELOCIDADE_MEDIA =
    "BUSCAR_TODAS_AS_ROTAS_VELOCIDADE_MEDIA";
export const REMOVER_ROTA_VELOCIDADE_MEDIA = "REMOVER_ROTA_VELOCIDADE_MEDIA";
export const ADICIONAR_ROTA_VELOCIDADE_MEDIA =
    "ADICIONAR_ROTA_VELOCIDADE_MEDIA";
export const ATUALIZAR_ROTA_VELOCIDADE_MEDIA =
    "ATUALIZAR_ROTA_VELOCIDADE_MEDIA";

export const BUSCAR_EQUIPAMENTOS = "BUSCAR_EQUIPAMENTOS";

export const BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS =
    "BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS";

export const BUSCAR_ROTAS_TEMPO_DE_PERCURSO = "BUSCAR_ROTAS_TEMPO_DE_PERCURSO";
export const BUSCAR_TODAS_AS_ROTAS_TEMPO_DE_PERCURSO =
    "BUSCAR_TODAS_AS_ROTAS_TEMPO_DE_PERCURSO";
export const ADICIONAR_ROTA_TEMPO_DE_PERCURSO =
    "ADICIONAR_ROTA_TEMPO_DE_PERCURSO";
export const REMOVER_ROTA_TEMPO_DE_PERCURSO = "REMOVER_ROTA_TEMPO_DE_PERCURSO";
export const ATUALIZAR_ROTA_TEMPO_DE_PERCURSO =
    "ATUALIZAR_ROTA_TEMPO_DE_PERCURSO";
export const VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO =
    "VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO";

export const BUSCAR_ROTAS_ORIGEM_E_DESTINO = "BUSCAR_ROTAS_ORIGEM_E_DESTINO";
export const ADICIONAR_ROTAS_ORIGEM_E_DESTINO =
    "ADICIONAR_ROTAS_ORIGEM_E_DESTINO";
export const REMOVER_ROTA_ORIGEM_E_DESTINO = "REMOVER_ROTA_ORIGEM_E_DESTINO";
export const ALTERAR_ROTA_ORIGEM_E_DESTINO = "ALTERAR_ROTA_ORIGEM_E_DESTINO";
export const CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO =
    "CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO";
export const VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO =
    "VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO";
export const BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO =
    "BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO";

export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const ALTERAR_SENHA = "ALTERAR_SENHA";
export const BUSCAR_USUARIOS = "BUSCAR_USUARIOS";
export const ALTERAR_SENHA_DO_USUARIO_ATUAL = "ALTERAR_SENHA_DO_USUARIO_ATUAL";
export const BUSCAR_AUDITORIA_DE_USUARIOS = "BUSCAR_AUDITORIA_DE_USUARIOS";

export const BUSCAR_RESULTADOS = "BUSCAR_RESULTADOS";
export const BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO =
    "BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO";
export const BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO =
    "BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO";
export const BUSCAR_RESULTADOS_ORIGEM_E_DESTINO =
    "BUSCAR_RESULTADOS_ORIGEM_E_DESTINO";
export const BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO =
    "BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO";
export const EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO =
    "EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO";

export const BUSCAR_TIPOS_DE_EQUIPAMENTOS = "BUSCAR_TIPOS_DE_EQUIPAMENTOS";
