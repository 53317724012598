<template>
    <ViasGoogleMapsRota
        v-for="rota in rotas"
        :key="rota.id"
        :inicio="rota.inicio"
        :fim="rota.fim"
        :cor-da-rota="rota.cor"
        :box="rota.box"
        @click="clickRota(rota)"
    />
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, defineEmits } from "vue";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import ViasGoogleMapsRota from "../ViasGoogleMapsRota.vue";

interface Props {
    rotas?: IRotaMapa[];
}

withDefaults(defineProps<Props>(), {
    rotas: () => [],
});

const emits = defineEmits<{
    clickRota: [rota: IRotaMapa];
}>();

async function clickRota(rota: IRotaMapa) {
    emits("clickRota", rota);
}
</script>
