<template>
    <LayoutApresentarRegistros
        titulo="Horários de Pico"
        :colunas="colunas"
        :linhas="horariosCadastrados"
        :primeira-busca="primeiraBusca"
        link-adicionar="/configuracao-geral/horarios/adicionar-horario"
        link-editar="/configuracao-geral/horarios/editar-horario/"
        @click-remover="removerHorario($event)"/>
</template>

<script lang="ts" setup>
import horariosService from "../services/HorariosService";
import LayoutApresentarRegistros from '@/layouts/LayoutApresentarRegistros.vue';
import { Horario } from '@/domains/Horarios/models/Horario';
import { HorarioApresentacao } from '@/domains/Horarios/models/HorarioApresentacao';
import { useNotificacao } from '@/hooks/notificacao.hook';
import { IColunaTabela } from '@/interfaces/IColunaTabela';
import { onMounted, ref } from 'vue';

const horariosCadastrados = ref<HorarioApresentacao[]>([]);
const colunas = ref<IColunaTabela[]>([
    {
        titulo: "Nome",
        referencia: "nome",
        tipo: "texto",
    },
    {
        titulo: "Público?",
        referencia: "publica",
        tipo: "texto",
    },
    {
        titulo: "Horário inicial",
        referencia: "inicial",
        tipo: "texto",
    },
    {
        titulo: "Horário final",
        referencia: "final",
        tipo: "texto",
    },
]);
const primeiraBusca = ref<boolean>(true);

const { questionarAcao, notificar, notificarCarregamento } = useNotificacao();

async function buscarHorariosCadastrados() {
    const response = await horariosService.buscarHorarios();
    horariosCadastrados.value = response.data.map((horario): HorarioApresentacao => ({
        ...horario,
        publica: horario.usuario_id === null ? "Pública" : "Privada"
    }));
    primeiraBusca.value = false;
}

async function removerHorario(horario: Horario) {
    const isConfirmado = await questionarAcao(`
        Deseja realmente remover o horário "${horario.nome}"? Após fazer isso, não será possível recuperá-lo.
    `)

    if (isConfirmado) {
        notificarCarregamento(`Removendo o horário "${horario.nome}", aguarde um momento por favor...`);
        const response = await horariosService.removerHorario(horario.id);
        if (response.status === 204) {
            notificar("Horário removido com sucesso!", "success");
            buscarHorariosCadastrados();
        }
    }
}

onMounted(() => {
    buscarHorariosCadastrados();
});
</script>