<template>
    <ViasCard>
        <template #header>
            <div class="flex aling-items-center justify-content-between">
                <h2 class="title">
                    Editar placa "{{ placaDaWhitelist.placa || "-------" }}" na
                    whitelist
                </h2>
                <ViasButton
                    variant="outline-primary"
                    @click="retornarAApresentacaoDeTodasAsPlacasDeWhitelist()"
                >
                    Voltar
                </ViasButton>
            </div>
        </template>
        <FormularioEditarPlacaWhitelist
            :placa="placaDaWhitelist"
            @editar-placa-da-whitelist="editarPlacaDaWhitelist($event)"
        />
    </ViasCard>
</template>

<script lang="ts" setup>
import { useStore } from "@/storeTs";
import { useRouter } from "vue-router";
import FormularioEditarPlacaWhitelist from "./formulario/FormularioEditarPlacaWhitelist.vue";
import {
    EDITAR_PLACA_DE_WHITELIST,
    BUSCAR_PLACA_DE_WHITELIST_POR_ID,
} from "@/storeTs/configuracao/whitelist/type-actions";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { onMounted, ref } from "vue";
import { IPlacaDaWhitelist } from "@/interfaces/whitelist/IPlacaDaWhitelist";

const store = useStore();
const router = useRouter();
const { notificar, notificarCarregamento } = useNotificacao();

let idPlacaWhitelist = 0;
const placaDaWhitelist = ref<IPlacaDaWhitelist>({
    id: 0,
    placa: "",
    veiculo: "",
    descricao: "",
});

function buscarPlaca() {
    store
        .dispatch(BUSCAR_PLACA_DE_WHITELIST_POR_ID, idPlacaWhitelist)
        .then((res) => res.data)
        .then((placa: IPlacaDaWhitelist) => {
            placaDaWhitelist.value = {
                id: placa.id,
                placa: placa.placa,
                veiculo: placa.veiculo,
                descricao: placa.descricao,
            };
        });
}

function editarPlacaDaWhitelist(placaAtualizada: IPlacaDaWhitelist) {
    notificarCarregamento(
        "Estamos enviando a novas informações sobre placa para ser atualizada na whitelist, aguarde um momento por favor...",
    );
    store.dispatch(EDITAR_PLACA_DE_WHITELIST, placaAtualizada).then((res) => {
        if (res.status === 204) {
            notificar("Placa atualizada com sucesso na whitelist!", "success");
            retornarAApresentacaoDeTodasAsPlacasDeWhitelist();
        }
    });
}

function retornarAApresentacaoDeTodasAsPlacasDeWhitelist() {
    router.push("/configuracao-geral/whitelist");
}

onMounted(() => {
    try {
        idPlacaWhitelist = parseInt(
            router.currentRoute.value.params.id.toString(),
        );
    } catch {
        retornarAApresentacaoDeTodasAsPlacasDeWhitelist();
    }

    buscarPlaca();
});
</script>
