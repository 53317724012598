<template>
<ViasRow>
    <ViasCol>
        <ViasCard>
            <template #header>
                <div
                    class="d-flex justify-content-between align-items-center"
                >
                    <h2>{{ titulo }}</h2>
                    <ViasButton
                        variant="outline-primary"
                        @click="voltarParaAPaginaAnterior()"
                    >
                        {{ textoBotaoVoltar }}
                    </ViasButton>
                </div>
            </template>
            <form @submit.prevent="enviarFormulario()">
                <slot></slot>
                
                <ViasRow class="mt-4">
                    <ViasCol>
                        <slot name="botoesFormulario" :textoBotaoEnviar="textoBotaoEnviar">
                            <ViasButton variant="primary">
                                {{ textoBotaoEnviar }}
                            </ViasButton>
                        </slot>
                    </ViasCol>
                </ViasRow>
            </form>
        </ViasCard>
    </ViasCol>
</ViasRow>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, withDefaults } from 'vue';
import { useRouter } from 'vue-router';

interface Props {
    titulo: string;
    textoBotaoEnviar: string;
    textoBotaoVoltar: string;
}

withDefaults(defineProps<Props>(), {
    titulo: "",
    textoBotaoEnviar: "Adicionar",
    textoBotaoVoltar: "Voltar"
});
const emits = defineEmits(["submit"]);
const router = useRouter();

function voltarParaAPaginaAnterior() {
    router.back();
}

function enviarFormulario() {
    emits("submit");
}
</script>