import axios from "axios";
import { store } from "@/storeTs";
import { close } from "@/services/notification.service";

const config = {
    baseURL:
        process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_URL_BACKEND
            : "http://10.76.77.91/api/",
    headers: {
        "Cache-Control": "no-cache"
    }
};

const http = axios.create(config);

let subscribers: Function[] = [];

http.interceptors.request.use((config) => {
    if (store.state.access_token && config.headers)
        config.headers.Authorization = store.getters.doneToken;

    if (store.state.contrato_selecionado && config.headers)
        config.headers["contrato-id"] = store.state.contrato_selecionado.id;

    return config;
});

http.interceptors.response.use(
    (response) => response,
    (error) => {
        try {
            const {
                config,
                response: {
                    status,
                    data: { message },
                },
            } = error;

            const originalRequest = config;

            if (
                status == 401 &&
                (message.toLowerCase() == "unauthorized" ||
                    message == "Token inválido")
            ) {
                store.dispatch("atualizarTokenDeAcesso").then(({ status }) => {
                    if (status == 200) {
                        store.dispatch("onRefreshed");
                        subscribers.forEach((cb) => {
                            cb();
                        });
                        subscribers = [];
                    } else {
                        close("Por favor, faça o login novamente.", "warning");
                    }
                });

                const requestSubscriber = new Promise((resolve) => {
                    subscribers.push(() => resolve(http(originalRequest)));
                });

                return requestSubscriber;
            } else if (
                status == 401 &&
                message.toLowerCase() == "senha atual invalida"
            ) {
                close("A senha atual está incorreta!", "error");
            } else if (status == 400) {
                const {
                    response: {
                        data: { message },
                    },
                } = error;

                close(message, "error");
            } else if (status == 403) {
                const {
                    response: {
                        data: { message },
                    },
                } = error;

                close(message, "error");
            } else if (status == 500) {
                close(
                    "Erro interno no sistema! Caso o erro persista, entre em contato com os responsáveis.",
                    "error",
                );
            } else {
                close(
                    "Houve algum erro na comunicação com o servidor. Caso persista, entre em contato com o suporte.",
                    "error",
                );
            }
        } catch {
            close(
                "Ocorreu algum problema na comunicação. Caso o erro persista, entre em contato com o suporte.",
                "error",
            );
        }

        throw error;
    },
);

export default http;
