<template>
    <div class="row" :class="[isGap]">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ViasRowComponent",
    props: {
        gap: {
            type: Boolean,
            default: false,
        },
        around: {
            type: Boolean,
            default: false,
        },
        evenly: {
            type: Boolean,
            default: false,
        },
        between: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isGap() {
            if (this.gap) {
                return "row-gap";
            }
            return "";
        },
        isAround() {
            if (this.around) {
                return "row-around";
            }
            return "";
        },
        isEvenly() {
            if (this.evenly) {
                return "row-evenly";
            }
            return "";
        },
        isBetween() {
            if (this.between) {
                return "row-between";
            }
            return "";
        },
        isCenter() {
            if (this.center) {
                return "row-center";
            }
            return "";
        },
    },
});
</script>
