<template>

<form @submit.prevent="buscarRelatorio()">
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                before-text="Equipamentos"
                after-text="Grupos"
                v-model="buscarPorGrupo"
                switch
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasVueSelect
                v-if="buscarPorGrupo"
                label="Selecione um grupo"
                v-model="formulario.grupo_id"
                :options="opcoesDeGrupos"
            />
            <ViasVueSelect
                v-else
                label="Selecione um equipamento"
                v-model="formulario.equipamento_id"
                :options="opcoesDeEquipamentos"
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasInputMonthAndYear
                label="Mês e ano da busca"
                v-model="formulario.data"
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                after-text="Buscar apenas equipamentos com falha"
                v-model="formulario.apenas_falhas"
                switch
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                after-text="Ordernar os equipamentos pelo volume médio diário (VMD)"
                v-model="formulario.ordenar_por_vmd"
                switch
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasButton variant="primary">
                Buscar
            </ViasButton>
            <ViasButton
                class="ml-2"
                variant="outline-primary"
                @click="abrirModalParaAgendamentoDeRelatorio()"
                type="button"
            >
                Agendar
            </ViasButton>
        </ViasCol>
    </ViasRow>
</form>

<ViasModal
    title="Agendamento do relatório de volume médio diário"
    v-model="isModalAgendamentoAberto"
    :show-button="false"
>
    <FormularioAgendamentoRelatorioVolumeMedioDiario
        @relatorio-agendado="fecharModalDeAgendamentoDeRelatorio()"
    />
</ViasModal>

</template>

<script lang="ts" setup>
import { onMounted, ref, defineEmits, defineModel } from 'vue';
import { FormularioBuscaRelatorioVolumeMedioDiario } from '../models/FormularioBuscaRelatorioVolumeMedioDiario';
import moment from 'moment';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';
import volumeMedioDiarioService from '../services/VolumeMedioDiarioService';
import ViasCheckbox from '@/components/shared/ViasCheckbox.vue';
import ViasVueSelect from '@/components/shared/ViasVueSelect.vue';
import { ISelectOption } from '@/interfaces/select/ISelectOption';
import equipamentoService from '@/domains/Equipamentos/services/EquipamentoService';
import gruposService from '@/domains/Grupos/services/GruposService';
import ViasInputMonthAndYear from '@/components/shared/ViasInputMonthAndYear.vue';
import { RelatorioVolumeMedioDiario } from '../models/RelatorioVolumeMedioDiario';
import FormularioAgendamentoRelatorioVolumeMedioDiario from './FormularioAgendamentoRelatorioVolumeMedioDiario.vue';

const emit = defineEmits<{
    apresentarRelatorio: [e: RelatorioVolumeMedioDiario]
}>();

const ultimaBusca = defineModel<FormularioBuscaRelatorioVolumeMedioDiario>("ultimaBusca");

const formulario = ref<FormularioBuscaRelatorioVolumeMedioDiario>({
    data: moment().format("YYYY-MM-DD"),
    apenas_falhas: false,
    ordenar_por_vmd: false,
    grupo_id: null,
    equipamento_id: null
});

const buscarPorGrupo = ref(false);
const opcoesDeEquipamentos = ref<ISelectOption[]>([]);
const opcoesDeGrupos = ref<ISelectOption[]>([]);

const isModalAgendamentoAberto = ref(false);

async function buscarRelatorio(): Promise<void> {
    notificacaoService.notificarCarregamento("Buscando relatório de volume médio diário, aguarde um momento por favor...");
    const formularioParaBuscarRelatorio = gerarFormularioParaBuscarPorGrupoOuPorEquipamento();
    const { data } = await volumeMedioDiarioService.buscarRelatorioVolumeMedioDiario(
        formularioParaBuscarRelatorio
    );
    emit("apresentarRelatorio", data);
    ultimaBusca.value = formularioParaBuscarRelatorio;
    notificacaoService.notificarSucesso("Relatório gerado com sucesso!");
}

function gerarFormularioParaBuscarPorGrupoOuPorEquipamento():
    FormularioBuscaRelatorioVolumeMedioDiario {
    if (buscarPorGrupo.value) {
        return {
            data: formulario.value.data,
            apenas_falhas: formulario.value.apenas_falhas,
            ordenar_por_vmd: formulario.value.ordenar_por_vmd,
            grupo_id: formulario.value.grupo_id
        };
    } else {
        return {
            data: formulario.value.data,
            apenas_falhas: formulario.value.apenas_falhas,
            ordenar_por_vmd: formulario.value.ordenar_por_vmd,
            equipamento_id: formulario.value.equipamento_id
        };
    }
}

async function buscarEquipamentos(): Promise<void> {
    const { data } = await equipamentoService.buscarEquipamentos();
    opcoesDeEquipamentos.value = data.map((equipamento) => ({
        text: `${equipamento.sitio}, ${equipamento.posicao.endereco}`,
        value: equipamento.id
    }));
}

async function buscarGrupos(): Promise<void> {
    const { data } = await gruposService.buscarGrupos();
    opcoesDeGrupos.value = data.map((grupo) => ({
        text: grupo.nome,
        value: grupo.id
    }));
}

function abrirModalParaAgendamentoDeRelatorio(): void {
    isModalAgendamentoAberto.value = true;
}

function fecharModalDeAgendamentoDeRelatorio(): void {
    isModalAgendamentoAberto.value = false;
}

onMounted(() => {
    buscarEquipamentos();
    buscarGrupos();
});
</script>