<template>
    <div class="form-group datepicker">
        <label :for="id">{{ label || "Data e hora" }}</label>
        <datepicker
            :id="id"
            class="w-100"
            v-model="data"
            :dark="isDarkTheme"
            @update:model-value="atualizarData"
            format="dd/MM/yyyy HH 'horas'"
            :disabled="disabled"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            month-name-format="long"
            :enable-minutes="false"
            week-start="0"
            week-numbers="iso"
            week-num-name="sem"
            auto-apply
        />
    </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
    props: ["modelValue", "label"],
    components: {
        Datepicker
    },
    data() {
        return {
            data: moment(this.modelValue).format("YYYY-MM-DDTHH:mm:ssZ"),
            id: `id-${Math.round(Math.random() * 100000)}-input-date-and-hour`,
        };
    },
    computed: {
        isDarkTheme() {
            return this.$store.state.theme === "dark-theme";  
        },
    },
    methods: {
        atualizarData() {
            this.$emit("update:modelValue", moment(this.data).format("YYYY-MM-DDTHH:mm:ss"));
        },
    },
    watch: {
        modelValue() {
            this.data = moment(this.modelValue).format("YYYY-MM-DDTHH:mm:ssZ");
        },
    },
};
</script>
