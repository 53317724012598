<template>
    <div class="table-block" :data-cy="dataCy">
        <div class="row">
            <div class="col pl-0 pr-0">
                <vias-input
                    type="text"
                    label="Pesquisar"
                    v-model="busca"
                    data-cy-input="tabela-padrao-input-pesquisa"
                    @input="organizarLinhas"
                ></vias-input>
            </div>
        </div>
        <div class="divisao-tabela">
            <table class="table table-hover" :class="[hasSize, theme]">
                <thead>
                    <tr>
                        <th
                            v-for="coluna in colunas"
                            :key="coluna"
                            @click="adicionarOrdenacao(coluna.referencia)"
                        >
                            <div>
                                <span>{{ coluna.titulo }}</span>
                                <font-awesome-icon
                                    v-if="ordenacao.coluna == coluna.referencia"
                                    class="flecha-de-ordenacao"
                                    :icon="`fas fa-angle-${ordenacao.direcao}`"
                                ></font-awesome-icon>
                            </div>
                        </th>
                        <th v-if="hasOptions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="linha in linhas_organizadas_em_pacotes[pagina]"
                        :key="linha"
                    >
                        <vias-td
                            v-for="coluna in colunas"
                            :key="[coluna, linha]"
                            :texto="linha[coluna.referencia]"
                            :tipo="coluna.tipo"
                            :unidade="coluna.unidade"
                            :variante="coluna.variante"
                            :icone="coluna.icone"
                            :evento="coluna.evento"
                            :numero_linha="linha.numero_linha"
                            :referencia="coluna.referencia"
                            :linha="linha"
                        ></vias-td>
                        <td v-if="hasOptions">
                            <slot name="opcoes" :linha="linha"></slot>
                        </td>
                    </tr>
                    <tr
                        v-if="linhas_organizadas_em_pacotes.length == 0"
                        data-cy="tabela-padrao-carregamento"
                    >
                        <td class="text-center" :colSpan="colsLoading">
                            <div
                                v-if="hasLoading"
                                class="d-flex justify-content-center w-100"
                            >
                                <div
                                    class="spinner-carregando"
                                    :class="theme"
                                ></div>
                            </div>
                            <p v-else class="text-center" :class="theme">
                                Não há resultados a apresentar.
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bloco-paginacao pt-4">
            <vias-pagination
                :atual="pagina"
                :quantidade="total_de_paginas"
                @mudarDePagina="mudarDePagina"
            ></vias-pagination>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import ViasInputVue from "./ViasInput.vue";
import ViasPaginationVue from "./ViasPagination.vue";
import ViasTdVue from "./ViasTd.vue";
export default {
    props: [
        "colunas",
        "linhas",
        "carregando",
        "comOpcoes",
        "size",
        "dataCy",
        "dataCyPesquisa",
    ],
    components: {
        "vias-td": ViasTdVue,
        "vias-input": ViasInputVue,
        "vias-pagination": ViasPaginationVue,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        hasLoading() {
            return typeof this.carregando == "boolean" && this.carregando;
        },
        hasOptions() {
            return typeof this.comOpcoes != "undefined";
        },
        colsLoading() {
            return (
                this.colunas.length +
                (typeof this.comOpcoes != "undefined" ? 1 : 0)
            );
        },
        hasSize() {
            return `table-${this.size || "md"}`;
        },
    },
    data: () => ({
        busca: "",
        linhas_organizadas_em_pacotes: [],
        quantidade_de_linhas_por_pacote: 10,
        pagina: 0,
        total_de_paginas: 1,
        ordenacao: {
            coluna: null,
            direcao: null,
        },
    }),
    created() {
        this.ordenacao = {
            coluna: this.colunas[0].referencia,
            direcao: "down",
        };
        this.organizarLinhas();
    },
    watch: {
        linhas() {
            this.organizarLinhas();
        },
    },
    methods: {
        organizarLinhas() {
            const linhasOrdenadas = this.ordenarLinhas();
            const linhasFiltradasEOrdenadas =
                this.filtrarLinhasOrdenadas(linhasOrdenadas);
            const pacotes = [];
            let pacote = [];

            for (let i = 0; i < linhasFiltradasEOrdenadas.length; i++) {
                pacote.push(linhasFiltradasEOrdenadas[i]);
                if (pacote.length == this.quantidade_de_linhas_por_pacote) {
                    pacotes.push(pacote);
                    pacote = [];
                }
            }

            if (pacote.length > 0) pacotes.push(pacote);

            this.linhas_organizadas_em_pacotes = pacotes;
            this.total_de_paginas = pacotes.length;
        },
        ordenarLinhas() {
            if (this.ordenacao.coluna) {
                let linhasCopiadas = Object.assign(this.linhas, []);
                return linhasCopiadas.sort((a, b) => {
                    if (this.ordenacao.direcao == "up") {
                        return a[this.ordenacao.coluna] <
                            b[this.ordenacao.coluna]
                            ? 1
                            : -1;
                    } else {
                        return a[this.ordenacao.coluna] >
                            b[this.ordenacao.coluna]
                            ? 1
                            : -1;
                    }
                });
            } else {
                return this.linhas;
            }
        },
        filtrarLinhasOrdenadas(linhasOrdenadas) {
            const regex = new RegExp(this.busca, "i");
            return linhasOrdenadas.filter((linha) =>
                this.colunas.reduce((liberado, coluna) => {
                    const linhaFormatada = this.formatarLinha(linha, coluna);
                    return liberado ? liberado : regex.exec(linhaFormatada);
                }, false),
            );
        },
        formatarLinha(linha, coluna) {
            let texto = linha[coluna.referencia];
            if (coluna.tipo == "numero")
                return coluna.unidade
                    ? `${this.$numero.formatar(texto)} ${coluna.unidade}`
                    : this.$numero.formatar(texto);
            else if (coluna.tipo == "data_hora")
                return moment(texto).format("DD/MM/YYYY HH:mm:ss");
            else if (coluna.tipo == "dinheiro") {
                const textoValidado = texto ? texto : 0.0;
                return this.$numero.formatarDinheiro(textoValidado);
            }
            return texto;
        },
        mudarDePagina(pagina) {
            this.pagina = pagina;
        },
        adicionarOrdenacao(coluna) {
            if (coluna == this.ordenacao.coluna) {
                if (this.ordenacao.direcao == "down")
                    this.ordenacao.direcao = "up";
                else this.ordenacao.direcao = "down";
            } else {
                this.ordenacao = {
                    coluna: coluna,
                    direcao: "down",
                };
            }
            this.organizarLinhas();
        },
    },
};
</script>
