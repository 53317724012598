import jsPDF from "jspdf";

export const emitirPdf = (relatorio, titulo, largura) => {
    const doc = new jsPDF("p", "pt", "a4");
    const docWidth = doc.internal.pageSize.getWidth();
    doc.html(relatorio, {
        callback: (doc) => {
            doc.save(`${titulo}.pdf`);
        },
        autoPaging: "text",
        width: docWidth - 19.83002832861189801699716713881 * 2,
        margin: new Array(4).fill(19.83002832861189801699716713881),
        windowWidth: largura,
    });
};
