<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-date
                        v-model="data_busca"
                        :max="moment().format('YYYY-MM-DDT00:00:00Z')"
                        label="Data da busca"
                    ></vias-input-date>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button type="submit" variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div
                class="row"
                v-for="mes in equipamento.meses"
                :key="mes.nome + equipamento.sitio"
            >
                <div class="col col-12">
                    <div class="grafico-de-relatorio">
                        <div class="cabecalho">
                            <h3 class="text-capitalize">{{ mes.nome }}</h3>
                        </div>
                        <vias-line-chart
                            :labels="labels"
                            :datasets="[mes.grafico]"
                            eixo_x="Classes"
                            eixo_y="Total de infrações"
                            :elements="mes.opcoes.elements"
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasInputDate from "@/components/shared/ViasInputDate.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import { ref } from "vue";
import moment from "moment";
import {
    buscarRelatorioDeExcessoDeVelocidadePorClasse,
    emitirPdfDeExcessoDeVelocidadePorClasse,
} from "@/services/integrated-management.service";
import { close, open } from "@/services/notification.service";
import { cores } from "@/services/variables.service";
import { ordenarArrayCrescente } from "@/services/array.service";

const data_busca = ref(moment().format("YYYY-MM-DDTHH:mm:ssZ"));

const relatorio = ref(null);

const labels = ref([]);

const buscarRelatorio = () => {
    open("Buscando relatório, por favor aguarde um momento...");
    buscarRelatorioDeExcessoDeVelocidadePorClasse(data_busca.value)
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = [];
            labels.value = [];
            let definirLabels = true;

            for (const [sitio, informacoes] of ordenarArrayCrescente(
                Object.entries(dados),
                "0",
            )) {
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    meses: [],
                };

                for (const [mes, resultado] of ordenarArrayCrescente(
                    Object.entries(informacoes.grafico),
                    "0",
                )) {
                    if (definirLabels) {
                        labels.value = Object.keys(resultado);
                        definirLabels = false;
                    }

                    if (resultado) {
                        equipamento.meses.push({
                            nome: moment(mes).format("MMMM, YYYY"),
                            grafico: {
                                label: "Total de infrações",
                                data: Object.values(resultado),
                                backgroundColor: cores.primary,
                                borderColor: cores.primary,
                            },
                            opcoes: {
                                elements: {
                                    line: {
                                        fill: true,
                                    },
                                },
                            },
                        });
                        continue;
                    }

                    equipamento.meses.push({
                        nome: moment(mes).format("MMMM, YYYY"),
                        grafico: {
                            label: "Total de infrações",
                            data: new Array(16).fill(null),
                            backgroundColor: cores.primary,
                            borderColor: cores.primary,
                        },
                        opcoes: {
                            elements: {
                                line: {
                                    fill: true,
                                },
                            },
                        },
                    });
                }

                relatorio.value.push(equipamento);
            }
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDeExcessoDeVelocidadePorClasse(data_busca.value)
        .then((res) => res.data.mensagem)
        .then((mensagem) => {
            close(mensagem, "success");
        });
};
</script>
