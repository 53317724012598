<template>

<ViasRow>
    <ViasCol cols="12">
        <ViasCard>
            <FormularioRelatorioDeFluxoVeicular
                ref="formulario"
                v-model:ultima-busca="buscaRealizadaParaORelatorio"
                @apresentar-relatorio="apresentarRelatorio($event)"
                @apresentar-relatorio-por-equipamento="apresentarRelatorioPorEquipamento($event)"
                @apresentar-relatorio-por-faixa="apresentarRelatorioPorFaixa($event)"
                @apresentar-relatorio-por-faixa-e-por-equipamento="apresentarRelatorioPorFaixaEPorEquipamento($event)"
            />
        </ViasCard>
    </ViasCol>
</ViasRow>

<div
    v-if="getRelatorioFluxoGerado && getRelatorioVelocidadesGerado && buscaRealizadaParaORelatorio"
    class="d-flex flex-column g-4"
>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCard>
                <ViasButton variant="outline-primary" block @click="emitirPdfDaUltimaBusca()">
                    Emitir PDF
                </ViasButton>
            </ViasCard>
        </ViasCol>
    </ViasRow>
    <ViasRow v-if="isRelatorioPorEquipamento">
        <ViasCol :cols="12">
            <OpcoesDeEquipamentosDoRelatorioDeFluxoVeicular
                :possiveis-equipamentos="possiveisEquipamentosParaApresentarRelatorio"
                :possiveis-enderecos="possiveisEnderecosParaApresentarRelatorio"
                v-model:equipamento-selecionado="equipamentoSelecionado"
            />
        </ViasCol>
    </ViasRow>
    <ViasRow v-if="isRelatorioPorFaixa">
        <ViasCol :cols="12">
            <OpcoesDeFaixasDoRelatorioDeFluxoVeicular
                :possiveis-faixas="getPossiveisFaixas"
                v-model:faixa-selecionada="faixaSelecionada"
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCard>
                <OpcoesDeMudancaDoRelatorioDeFluxoVeicular
                    v-model:mostrar-os-graficos-em-colunas="mostrarOsGraficosEmColunas"
                    v-model:mostrar-os-valores-nos-graficos="mostrarOsValoresNosGraficos"
                    v-model:mostrar-os-valores-dos-graficos-por-categoria-de-veiculo="
                        mostrarOsValoresDosGraficosPorCategoriaDeVeiculo
                    "
                    v-model:mostrar-graficos-de-pizza="mostrarGraficosDePizza"
                    v-model:mostrar-valores-acumulados-na-vertical="mostrarValoresAcumuladosNaVertical"
                />
            </ViasCard>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCard titulo="Fluxo">
                <BoxDeFluxoRelatorioDeFluxoVeicular
                    :mostrar-grafico-de-pizza="mostrarGraficosDePizza"
                    :mostrar-grafico-em-coluna="mostrarOsGraficosEmColunas"
                    :mostrar-grafico-por-categoria-de-veiculo="mostrarOsValoresDosGraficosPorCategoriaDeVeiculo"
                    :mostrar-valores-no-grafico="mostrarOsValoresNosGraficos"
                    :mostrar-valores-acumulados-na-vertical="mostrarValoresAcumuladosNaVertical"
                    :periodo-de-busca="buscaRealizadaParaORelatorio.periodo"
                    :data-hora-da-busca="buscaRealizadaParaORelatorio.data_hora"
                    :relatorio="getRelatorioFluxoGerado"
                    @alterar-formato-de-visualizacao-para-cima="alterarRelatorioParaCima($event)"
                    @alterar-formato-de-visualizacao-para-baixo="alterarRelatorioParaBaixo($event)"
                />
            </ViasCard>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCard titulo="Média de Velocidade">
                <BoxDeMediaDeVelocidadeRelatorioDeFluxoVeicular
                    :mostrar-grafico-de-pizza="mostrarGraficosDePizza"
                    :mostrar-grafico-em-coluna="mostrarOsGraficosEmColunas"
                    :mostrar-grafico-por-categoria-de-veiculo="mostrarOsValoresDosGraficosPorCategoriaDeVeiculo"
                    :mostrar-valores-no-grafico="mostrarOsValoresNosGraficos"
                    :mostrar-valores-acumulados-na-vertical="mostrarValoresAcumuladosNaVertical"
                    :periodo-de-busca="buscaRealizadaParaORelatorio.periodo"
                    :data-hora-da-busca="buscaRealizadaParaORelatorio.data_hora"
                    :relatorio="getRelatorioFluxoGerado"
                    @alterar-formato-de-visualizacao-para-cima="alterarRelatorioParaCima($event)"
                    @alterar-formato-de-visualizacao-para-baixo="alterarRelatorioParaBaixo($event)"
                />
            </ViasCard>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol :cols="12">
            <ViasCard titulo="Fluxo por Velocidades">
                <BoxDeVelocidadesRelatorioDeFluxoVeicular
                    :mostrar-grafico-em-coluna="mostrarOsGraficosEmColunas"
                    :mostrar-valores-no-grafico="mostrarOsValoresNosGraficos"
                    :periodo-de-busca="buscaRealizadaParaORelatorio.periodo"
                    :data-hora-da-busca="buscaRealizadaParaORelatorio.data_hora"
                    :relatorio="getRelatorioVelocidadesGerado"
                    @alterar-formato-de-visualizacao-para-cima="alterarRelatorioParaCima($event)"
                />
            </ViasCard>
        </ViasCol>
    </ViasRow>
</div>

</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import FormularioRelatorioDeFluxoVeicular from '../components/FormularioRelatorioDeFluxoVeicular.vue';
import { RelatorioDeFluxoVeicular } from '../models/RelatorioDeFluxoVeicular';
import OpcoesDeMudancaDoRelatorioDeFluxoVeicular from '../components/OpcoesDeMudancaDoRelatorioDeFluxoVeicular.vue';
import validadorDeRelatorioDeFluxoVeicular from '../composables/ValidadorDeRelatorioFluxoVeicular';
import { FormularioParaBuscarRelatorioDeFluxoVeicular } from '../models/FormularioParaBuscarRelatorioDeFluxoVeicular';
import ViasButton from '@/components/shared/ViasButton.vue';
import BoxDeFluxoRelatorioDeFluxoVeicular from '../components/BoxDeFluxoRelatorioDeFluxoVeicular.vue';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';
import fluxoService from '../services/FluxoService';
import OpcoesDeEquipamentosDoRelatorioDeFluxoVeicular from '../components/OpcoesDeEquipamentosDoRelatorioDeFluxoVeicular.vue';
import OpcoesDeFaixasDoRelatorioDeFluxoVeicular from '../components/OpcoesDeFaixasDoRelatorioDeFluxoVeicular.vue';
import BoxDeMediaDeVelocidadeRelatorioDeFluxoVeicular from '../components/BoxDeMediaDeVelocidadeRelatorioDeFluxoVeicular.vue';
import BoxDeVelocidadesRelatorioDeFluxoVeicular from '../components/BoxDeVelocidadesRelatorioDeFluxoVeicular.vue';
import { PeriodoDeRelatorio } from '../models/PeriodoDoRelatorioDeFluxoVeicular';

const buscaRealizadaParaORelatorio = ref<FormularioParaBuscarRelatorioDeFluxoVeicular>();
const relatorioFluxo = ref<RelatorioDeFluxoVeicular>();
const relatorioVelocidades = ref<any>();

const mostrarOsGraficosEmColunas = ref(false);
const mostrarOsValoresNosGraficos = ref(true);
const mostrarOsValoresDosGraficosPorCategoriaDeVeiculo = ref(false);
const mostrarGraficosDePizza = ref(true);
const mostrarValoresAcumuladosNaVertical = ref(true);

const equipamentoSelecionado = ref<string>("");
const faixaSelecionada = ref<string>("");

const possiveisEquipamentosParaApresentarRelatorio = ref<string[]>([]);
const possiveisEnderecosParaApresentarRelatorio = ref<{ [key: string]: string }>({});
const possiveisFaixasParaApresentarRelatorio = ref<string[]>([]);
const possiveisFaixasPorEquipamentoParaApresentarRelatorio = ref<{ [key: string]: string[] }>({});

const formulario = ref<any>();

const getRelatorioFluxoGerado = computed(() => {
    if (!relatorioFluxo.value || !buscaRealizadaParaORelatorio.value) {
        return null;
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorEquipamentoEPorFaixa(buscaRealizadaParaORelatorio.value) &&
        equipamentoSelecionado.value &&
        faixaSelecionada.value
    ) {
        return relatorioFluxo.value.grafico[equipamentoSelecionado.value]?.grafico[faixaSelecionada.value];
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorEquipamento(buscaRealizadaParaORelatorio.value) &&
        equipamentoSelecionado.value
    ) {
        return relatorioFluxo.value.grafico[equipamentoSelecionado.value]?.grafico
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorFaixa(buscaRealizadaParaORelatorio.value) &&
        faixaSelecionada.value
    ) {
        return relatorioFluxo.value.grafico[faixaSelecionada.value];
    }
    return relatorioFluxo.value.grafico;
});

const getRelatorioVelocidadesGerado = computed(() => {
    if (!relatorioVelocidades.value || !buscaRealizadaParaORelatorio.value) {
        return null
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorEquipamentoEPorFaixa(buscaRealizadaParaORelatorio.value) &&
        equipamentoSelecionado.value &&
        faixaSelecionada.value
    ) {
        return relatorioVelocidades.value[equipamentoSelecionado.value]?.grafico[faixaSelecionada.value];
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorEquipamento(buscaRealizadaParaORelatorio.value) &&
        equipamentoSelecionado.value
    ) {
        return relatorioVelocidades.value[equipamentoSelecionado.value]?.grafico
    }
    if (
        validadorDeRelatorioDeFluxoVeicular.isRelatorioPorFaixa(buscaRealizadaParaORelatorio.value) &&
        faixaSelecionada.value
    ) {
        return relatorioVelocidades.value[faixaSelecionada.value];
    }
    return relatorioVelocidades.value;
});

const isRelatorioPorEquipamento = computed(() => {
    return buscaRealizadaParaORelatorio.value?.por_equipamento;
});

const isRelatorioPorFaixa = computed(() => {
    return buscaRealizadaParaORelatorio.value?.por_faixa;
});

const getPossiveisFaixas = computed(() => {
    if (!isRelatorioPorFaixa.value) {
        return [];
    }
    if (isRelatorioPorEquipamento.value) {
        return possiveisFaixasPorEquipamentoParaApresentarRelatorio.value[equipamentoSelecionado.value];
    }
    return possiveisFaixasParaApresentarRelatorio.value;
});

function apresentarRelatorio({ fluxo, velocidades }: any) {
    relatorioFluxo.value = fluxo;
    relatorioVelocidades.value = velocidades;
}

function apresentarRelatorioPorEquipamento({ fluxo, velocidades, relatorioAtualizado }: any) {
    possiveisEquipamentosParaApresentarRelatorio.value = Object.keys(fluxo.grafico);
    possiveisEnderecosParaApresentarRelatorio.value = possiveisEquipamentosParaApresentarRelatorio.value.reduce((enderecos, equipamento) => {
        enderecos[equipamento] = fluxo.grafico[equipamento].endereco;
        return enderecos;
    }, {});
    
    if (!relatorioAtualizado) {
        equipamentoSelecionado.value = possiveisEquipamentosParaApresentarRelatorio.value[0];
    }

    relatorioFluxo.value = fluxo;
    relatorioVelocidades.value = velocidades;
}

function apresentarRelatorioPorFaixa({ fluxo, velocidades, relatorioAtualizado }: any) {
    possiveisFaixasParaApresentarRelatorio.value = Object.keys(fluxo.grafico);
    
    if (!relatorioAtualizado) {
        faixaSelecionada.value = possiveisFaixasParaApresentarRelatorio.value[0];
    }

    relatorioFluxo.value = fluxo;
    relatorioVelocidades.value = velocidades;
}

function apresentarRelatorioPorFaixaEPorEquipamento({ fluxo, velocidades, relatorioAtualizado }: any) {
    possiveisEquipamentosParaApresentarRelatorio.value = Object.keys(fluxo.grafico);
    possiveisEquipamentosParaApresentarRelatorio.value.forEach((equipamento: string) => {
        possiveisFaixasPorEquipamentoParaApresentarRelatorio.value[equipamento] = Object.keys(fluxo.grafico[equipamento].grafico);
    });
    possiveisEnderecosParaApresentarRelatorio.value = possiveisEquipamentosParaApresentarRelatorio.value.reduce((enderecos, equipamento) => {
        enderecos[equipamento] = fluxo.grafico[equipamento].endereco;
        return enderecos;
    }, {});

    if (!relatorioAtualizado) {
        equipamentoSelecionado.value = possiveisEquipamentosParaApresentarRelatorio.value[0];
        faixaSelecionada.value = possiveisFaixasPorEquipamentoParaApresentarRelatorio.value[equipamentoSelecionado.value]["0"];
    }

    relatorioFluxo.value = fluxo;
    relatorioVelocidades.value = velocidades;
}

async function emitirPdfDaUltimaBusca() {
    if (!buscaRealizadaParaORelatorio.value) {
        return;
    }

    notificacaoService.notificarCarregamento("Emitindo o PDF do relatório, aguarde um momento...");
    const { data } = await fluxoService.emitirPdfDoRelatorioDeFluxoVeicular(buscaRealizadaParaORelatorio.value);
    notificacaoService.notificarSucesso(data.mensagem);
}

function alterarRelatorioParaCima(novoPeriodo: PeriodoDeRelatorio) {
    formulario.value.atualizarFormularioEBuscarRelatorio(novoPeriodo);
}

function alterarRelatorioParaBaixo({ periodo, dataHora }) {
    formulario.value.atualizarFormularioEBuscarRelatorio(periodo, dataHora);
}

watch(() => equipamentoSelecionado.value, () => {
    if (isRelatorioPorEquipamento.value && isRelatorioPorFaixa.value) {
        faixaSelecionada.value = possiveisFaixasPorEquipamentoParaApresentarRelatorio.value[equipamentoSelecionado.value][0];
    }
});
</script>