<template>
    <ViasMapaGeral @click-equipamento="mostrarPainelDoEquipamento($event)" />

    <vias-modal
        :showButton="false"
        :title="titleModal"
        v-model="mostrar_modal"
        @hidding="removerSelecaoDoEquipamento()"
    >
        <vias-painel-do-equipamento
            :equipamento="equipamento_selecionado"
        />
    </vias-modal>
</template>

<script>
import ViasModalVue from "@/components/shared/ViasModal.vue";
import ViasPainelDoEquipamentoVue from "@/components/gestao-integrada/mapa/ViasPainelDoEquipamento.vue";
import { BUSCAR_EQUIPAMENTOS } from "@/store/tipo-acoes";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";

export default {
    name: "mapa-gestao-integrada",
    components: {
        "vias-modal": ViasModalVue,
        "vias-painel-do-equipamento": ViasPainelDoEquipamentoVue,
        ViasMapaGeral,
    },
    computed: {
        titleModal() {
            return this.equipamento_selecionado
                ? `Painel do Equipamento<br>${this.equipamento_selecionado.sitio} - ${this.equipamento_selecionado.posicao.endereco}`
                : "";
        },
    },
    data: () => ({
        equipamentos: [],
        equipamento_selecionado: null,
        mostrar_modal: false,
    }),
    methods: {
        atualizarTiposDeEquipamentosSelecionados(tiposEquipamentos) {
            this.tipos_equipamentos_selecionados = tiposEquipamentos;
            this.buscarEquipamentos();
        },
        buscarEquipamentos() {
            this.$store
                .dispatch(BUSCAR_EQUIPAMENTOS)
                .then((res) => res.data)
                .then((equipamentos) => {
                    this.equipamentos = equipamentos.map((equipamento) => {
                        return {
                            ...equipamento,
                            ...this.$mapa.tratarEquipamento(equipamento),
                        };
                    });
                });
        },
        mostrarPainelDoEquipamento(equipamento) {
            this.mostrar_modal = true;
            this.equipamento_selecionado = equipamento;
        },
        removerSelecaoDoEquipamento() {
            this.equipamento_selecionado = null;
        },
    },
    created() {
        this.buscarEquipamentos();
    },
    watch: {
        mostrar_modal() {
            if (!this.mostrar_modal) {
                this.equipamento_selecionado = null;
            }
        },
    },
};
</script>
