import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViasInput = _resolveComponent("ViasInput")!
  const _component_ViasCol = _resolveComponent("ViasCol")!
  const _component_ViasRow = _resolveComponent("ViasRow")!
  const _component_ViasMapaGeral = _resolveComponent("ViasMapaGeral")!
  const _component_BoxEquipamentoAfericaoParaFormulario = _resolveComponent("BoxEquipamentoAfericaoParaFormulario")!
  const _component_ViasButton = _resolveComponent("ViasButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.salvarGrupo()), ["prevent"]))
  }, [
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              label: "Nome",
              id: "editar-grupo-nome",
              modelValue: _ctx.nome,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nome) = $event)),
              type: "text",
              valid: _ctx.isNomeValido,
              detail: _ctx.getNomeMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, { class: "position-relative mapa-formulario-height" }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasMapaGeral, {
              onClickEquipamento: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alternarSelecaoDeEquipamento($event))),
              "ids-equipamentos-selecionados": _ctx.idsEquipamentosSelecionados
            }, null, 8, ["ids-equipamentos-selecionados"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, { class: "mt-4 max-h-300px overflow-y-auto" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipamentos, (equipamento) => {
          return (_openBlock(), _createBlock(_component_ViasCol, {
            key: equipamento.id,
            cols: 12,
            sm: 6,
            md: 4,
            lg: 3
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BoxEquipamentoAfericaoParaFormulario, {
                afericao: equipamento,
                modelValue: _ctx.afericoesSelecionadas,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.afericoesSelecionadas) = $event)),
                onChange: ($event: any) => (
                        _ctx.verificarSeTodasAsAfericoesEstaoSelecionadas(
                            equipamento,
                        )
                    ),
                id: 'editar-grupo-equipamento-' + equipamento.id
              }, null, 8, ["afericao", "modelValue", "onChange", "id"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.isAfericoesSelecionadasValido === false)
      ? (_openBlock(), _createBlock(_component_ViasRow, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasCol, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getAfericoesSelecionadasMessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ViasRow, { class: "mt-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasButton, {
              variant: "primary",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Salvar")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}