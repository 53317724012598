import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_GRUPOS_CADASTRADOS,
    ADICIONAR_NOVO_GRUPO,
    EDITAR_GRUPO,
    BUSCAR_GRUPO_POR_ID,
    REMOVER_GRUPO,
} from "./actions-type";
import { INovoGrupo } from "@/views/GestaoIntegrada/Grupos/interfaces/INovoGrupo";
import http from "@/http";

export const gestaoIntegradaGruposModule: Module<State, State> = {
    actions: {
        [BUSCAR_GRUPOS_CADASTRADOS]() {
            return http.get("/vias/v1/equipamentos/grupos");
        },
        [BUSCAR_GRUPO_POR_ID](context, id: number) {
            return new Promise((resolve, reject) => {
                http.get("/vias/v1/equipamentos/grupos")
                    .then((res) => {
                        const grupos = res.data;
                        for (const grupo of grupos) {
                            if (grupo.id === id) {
                                res.data = grupo;
                                resolve(res);
                            }
                        }
                        reject(null);
                    })
                    .catch(() => {
                        reject(null);
                    });
            });
        },
        [ADICIONAR_NOVO_GRUPO](context, novoGrupo: INovoGrupo) {
            return http.post("/vias/v1/equipamentos/grupos", novoGrupo);
        },
        [EDITAR_GRUPO](context, grupoEditado: any) {
            return http.put(
                "/vias/v1/equipamentos/grupos/" + grupoEditado.id,
                grupoEditado,
            );
        },
        [REMOVER_GRUPO](context, id: number) {
            return http.delete(`/vias/v1/equipamentos/grupos/${id}`);
        },
    },
};
