import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_ROTAS_VELOCIDADE_MEDIA,
    BUSCAR_TODAS_AS_ROTAS_VELOCIDADE_MEDIA,
    ADICIONAR_ROTA_VELOCIDADE_MEDIA,
    EDITAR_ROTA_VELOCIDADE_MEDIA,
    REMOVER_ROTA_VELOCIDADE_MEDIA,
    BUSCAR_ROTA_POR_ID_VELOCIDADE_MEDIA,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const velocidadeMediaConfiguracaoModule: Module<State, State> = {
    actions: {
        [BUSCAR_ROTAS_VELOCIDADE_MEDIA]() {
            return axios.get("/vias/v1/velocidade-media/rotas");
        },
        [BUSCAR_TODAS_AS_ROTAS_VELOCIDADE_MEDIA]() {
            return axios.get("/vias/v1/velocidade-media/rotas/todas");
        },
        [BUSCAR_ROTA_POR_ID_VELOCIDADE_MEDIA](context, idRota: number) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/vias/v1/velocidade-media/rotas")
                    .then((res) => {
                        for (const rota of res.data) {
                            if (rota.id === idRota) {
                                res.data = rota;
                                resolve(res);
                                return;
                            }
                        }
                        reject("Rota não encontrada.");
                    })
                    .catch(() => {
                        reject("Erro na busca da rota.");
                    });
            });
        },
        [ADICIONAR_ROTA_VELOCIDADE_MEDIA](context, novaRota: any) {
            return axios.post("/vias/v1/velocidade-media/rotas", novaRota);
        },
        [EDITAR_ROTA_VELOCIDADE_MEDIA](context, rota: any) {
            return axios.put(
                `/vias/v1/velocidade-media/rotas/${rota.id}`,
                rota,
            );
        },
        [REMOVER_ROTA_VELOCIDADE_MEDIA](context, id: number) {
            return axios.put(`/vias/v1/velocidade-media/rotas/${id}/desativar`);
        },
    },
};
