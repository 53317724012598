<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Rotas cadastradas</h2>
                        <router-link
                            class="btn btn-primary"
                            to="/tempo-de-percurso/configuracao/nova-rota"
                        >
                            Adicionar
                        </router-link>
                    </div>
                </template>
                <ViasTable
                    class="mt-4"
                    :carregando="primeiraBusca"
                    :linhas="rotas"
                    :colunas="colunas"
                    com-opcoes
                >
                    <template #opcoes="{ linha }">
                        <div
                            class="d-flex justify-content-end align-items-center"
                        >
                            <router-link
                                class="btn btn-outline-primary btn-sm m-2"
                                :to="
                                    '/tempo-de-percurso/configuracao/editar-rota/' +
                                    linha.id
                                "
                            >
                                Editar
                            </router-link>
                            <ViasButton
                                variant="none-danger"
                                size="sm"
                                @click="removerRota(linha)"
                            >
                                Remover
                            </ViasButton>
                        </div>
                    </template>
                </ViasTable>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/storeTs";
import { useRouter } from "vue-router";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import {
    BUSCAR_ROTAS_TEMPO_DE_PERCURSO,
    REMOVER_ROTA_TEMPO_DE_PERCURSO,
} from "@/storeTs/tempo-de-percurso/configuracao/type-actions";
import { IRotaTempoDePercursoApresentacao } from "./interfaces/IRotaTempoDePercursoApresentacao";
import { IRotaTempoDePercurso } from "./interfaces/IRotaTempoDePercurso";
import moment from "moment";
import { useNotificacao } from "@/hooks/notificacao.hook";

export default defineComponent({
    name: "ConfiguracaoTempoDePercursoView",
    data: () => ({
        primeiraBusca: true,
        rotas: [] as IRotaTempoDePercursoApresentacao[],
        colunas: [
            {
                titulo: "#",
                referencia: "numeroDaLinha",
            },
            {
                titulo: "Nome",
                referencia: "nome",
            },
            {
                titulo: "Tempo do percurso",
                referencia: "tempoDoPercurso",
            },
            {
                titulo: "Criado em",
                referencia: "criadoEm",
            },
        ] as IColunaTabela[],
    }),
    methods: {
        buscarRotas() {
            this.store.dispatch(BUSCAR_ROTAS_TEMPO_DE_PERCURSO).then((res) => {
                this.rotas = res.data.map(
                    (
                        rota: IRotaTempoDePercurso,
                        i: number,
                    ): IRotaTempoDePercursoApresentacao => ({
                        id: rota.id,
                        numeroDaLinha: i + 1,
                        nome: rota.nome,
                        tempoDoPercurso: `${rota.tempo_entre_equipamentos_total} segundos`,
                        criadoEm: moment(rota.criada_em).format(
                            "DD/MM/YYYY HH:mm:ss",
                        ),
                    }),
                );
                this.primeiraBusca = false;
            });
        },
        removerRota(rota: IRotaTempoDePercursoApresentacao) {
            this.questionarAcao(
                `Deseja realmente remover a rota "${rota.nome}"? Após fazer isso, não será possível recuperá-la.`,
            ).then((isConfirmado) => {
                if (isConfirmado) {
                    this.notificarCarregamento(
                        `Estamos removendo a rota "${rota.nome}", aguarde um momento por favor...`,
                    );
                    this.store
                        .dispatch(REMOVER_ROTA_TEMPO_DE_PERCURSO, rota.id)
                        .then((res) => {
                            if (res.status === 204) {
                                this.notificar(
                                    `A rota "${rota.nome}" foi removida com sucesso!`,
                                    "success",
                                );
                                this.buscarRotas();
                            }
                        });
                }
            });
        },
    },
    created() {
        this.buscarRotas();
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { questionarAcao, notificar, notificarCarregamento } =
            useNotificacao();

        return {
            store,
            router,
            questionarAcao,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
