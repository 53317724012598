<template>
    <div
        class="card"
        :class="[isColumn, theme, { 'hover-event': hasHoverEvent }]"
    >
        <div class="card-header">
            <slot name="header">
                <h2
                    class="card-title"
                    :class="theme"
                    v-html="titulo"
                    v-if="titulo"
                ></h2>
            </slot>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "vias-card",
    props: ["titulo", "hoverEvent", "column"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        hasHoverEvent() {
            return this.hoverEvent == undefined ? true : this.hoverEvent;
        },
        isColumn() {
            return typeof this.column == "string" ? "column" : "";
        },
    },
};
</script>
