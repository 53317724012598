<script setup>
import ViasGroupButtons from "@/components/shared/ViasGroupButtons.vue";
import {
    defineProps,
    ref,
    computed,
    onBeforeUnmount,
    onMounted,
    watch,
} from "vue";

const props = defineProps(["equipamento", "nivelDeServico"]);

const botoesCameras = ref([]);

const camerasDisponiveis = ref([]);
const cameraSelecionada = ref(0);

const urlCamera = ref("#");

const alertClass = computed(
    () =>
        ({
            A: "alert-success",
            B: "alert-success",
            C: "alert-warning",
            D: "alert-warning",
            E: "alert-danger",
            F: "alert-danger",
        })[props.nivelDeServico],
);

const alterarCamera = (e) => (cameraSelecionada.value = e.index);

const mostrarNivelDeServico = ref(true);

const buscarCamerasDoEquipamento = async () => {
    const cameras = [];
    if (props.equipamento && props.equipamento.cameras.length > 0) {
        if (props.equipamento.sitio.startsWith("FM")) {
            cameras.push({
                nome: "Camera 1",
                url: `https://viassjc.sistemasfotosensores.com/api/camera/${props.equipamento.cameras[0].ip}/10021`
            });
            cameras.push({
                nome: "Camera 2",
                url: `https://viassjc.sistemasfotosensores.com/api/camera/${props.equipamento.cameras[0].ip}/10022`
            })
        } else {
            props.equipamento.cameras.forEach((camera, i) => {
                cameras.push({
                    nome: `Camera ${i + 1}`,
                    url: `https://viassjc.sistemasfotosensores.com/api/camera/${camera.ip}/50`
                });
            });
        }
        camerasDisponiveis.value = cameras;
    }
};

const isCameraExistente = (i) => {
    return typeof i === "number" && camerasDisponiveis.value[i];
};

watch(props, async () => {
    cameraSelecionada.value = null;
    urlCamera.value = "#";
    if (props.equipamento) {
        await buscarCamerasDoEquipamento();
        botoesCameras.value = camerasDisponiveis.value.map((camera) => ({
            variant: "secondary",
            text: camera.nome,
            type: "button",
        }));

        cameraSelecionada.value = 0;
    } else {
        urlCamera.value = "#";
    }
});

watch(
    () => cameraSelecionada.value,
    () => {
        urlCamera.value = "#";
        if (isCameraExistente(cameraSelecionada.value)) {
            setTimeout(() => {
                urlCamera.value =
                    camerasDisponiveis.value[cameraSelecionada.value].url;
            }, 100);
        }
    },
);

onMounted(() => {
    if (props.equipamento) {
        mostrarNivelDeServico.value = !(props.equipamento.tipo == "MCI");
    }
});

onBeforeUnmount(() => {
    urlCamera.value = "#";
});
</script>

<template>
    <div class="camera">
        <vias-group-buttons
            :buttons="botoesCameras"
            :button-active="cameraSelecionada"
            @button-click="alterarCamera"
        ></vias-group-buttons>
        <div class="imagem">
            <img
                :src="urlCamera"
                alt="Câmera do equipamento"
            />
            <div
                class="sem-camera w-100 d-flex align-items-center justify-content-center"
            >
                <i class="fas fa-video-slash"></i>
            </div>
        </div>
        <div
            class="alert text-center"
            :class="alertClass"
            v-if="mostrarNivelDeServico"
        >
            Nível de serviço: {{ nivelDeServico }}
        </div>
    </div>
</template>
