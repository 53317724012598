<template>
    <ViasMapaGeral
        :equipamentos-por-propriedade="equipamentos"
        :habilitar-todos-os-equipamentos="false"
        :habilitar-filtro-de-equipamentos="false"
        :rotas="rotas"
        @click-rota="apresentarUltimasOcorrencias($event)"
    />

    <vias-modal :showButton="false" :title="titleModal" v-model="mostrar_modal">
        <vias-ultimas-ocorrencias
            v-if="rota_selecionada"
            :rota="rota_selecionada"
        ></vias-ultimas-ocorrencias>
    </vias-modal>
</template>

<script>
import ViasModalVue from "@/components/shared/ViasModal.vue";
import ViasUltimasOcorrenciasVue from "@/components/velocidade-media/mapa/ViasUltimasOcorrencias.vue";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";

export default {
    name: "mapa-velocidade-media",
    components: {
        ViasMapaGeral,
        "vias-modal": ViasModalVue,
        "vias-ultimas-ocorrencias": ViasUltimasOcorrenciasVue,
    },
    computed: {
        titleModal() {
            return this.rota_selecionada
                ? `As últimas 10 infrações da rota "${this.rota_selecionada.nome}"`
                : "";
        },
    },
    data: () => ({
        equipamentos: [],
        rotas: [],
        rota_selecionada: null,
        mostrar_modal: false,
    }),
    created() {
        this.buscarRotas();
    },
    methods: {
        buscarRotas() {
            this.$velocidade_media
                .buscarRotas()
                .then((res) => res.data)
                .then((rotas) => {
                    rotas.forEach((rota) => {
                        this.adicionarEquipamento(rota.primeiro_equipamento);
                        this.adicionarEquipamento(rota.segundo_equipamento);
                        this.rotas = [
                            ...this.rotas,
                            this.$mapa.tratarRotaDeVelocidadeMedia(rota),
                        ];
                    });
                });
        },
        adicionarEquipamento(equipamento) {
            if (
                this.equipamentos.filter((e) => e.id == equipamento.id)
                    .length == 0
            ) {
                this.equipamentos = [...this.equipamentos, equipamento];
            }
        },
        apresentarUltimasOcorrencias(rota) {
            this.mostrar_modal = true;
            this.rota_selecionada = rota;
        },
    },
    watch: {
        mostrar_modal() {
            if (this.mostrar_modal == false) {
                this.rota_selecionada = null;
            }
        },
    },
};
</script>
