import Swal, { SweetAlertIcon } from "sweetalert2";
import { useCores } from "./cores.hook";

const { listaDeCores } = useCores();

type Notificador = {
    notificar: (titulo: string, icone: SweetAlertIcon) => void;
    notificarComTexto: (
        titulo: string,
        texto: string,
        icone: SweetAlertIcon,
    ) => void;
    notificarCarregamento: (titulo: string) => void;
    questionarAcao: (texto: string) => Promise<boolean>;
    apresentarOpcoesDeEscolha: (
        texto: string,
        opcao1: string,
        opcao2: string,
    ) => Promise<string>;
    fecharPopup: () => void;
};

export function useNotificacao(): Notificador {
    const notificar = (titulo: string, icone: SweetAlertIcon): void => {
        Swal.fire({
            title: titulo,
            icon: icone,
            showConfirmButton: true,
            confirmButtonText: "Ok",
            confirmButtonColor: listaDeCores().success,
        });
    };

    const notificarComTexto = (
        titulo: string,
        texto: string,
        icone: SweetAlertIcon,
    ): void => {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: icone,
            showConfirmButton: true,
            confirmButtonText: "Ok",
            confirmButtonColor: listaDeCores().success,
        });
    };

    const notificarCarregamento = (titulo: string): void => {
        Swal.fire({
            title: titulo,
            showConfirmButton: false,
        });
        Swal.showLoading();
    };

    const questionarAcao = (
        texto: string,
        textoConfirmacao: string = "Sim",
        textoRecusa: string = "Não",
    ): Promise<boolean> => {
        return new Promise((resolve) => {
            Swal.fire({
                title: "Aviso!",
                text: texto,
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: textoConfirmacao,
                confirmButtonColor: listaDeCores().primary,
                showDenyButton: true,
                denyButtonText: textoRecusa,
                denyButtonColor: listaDeCores().danger,
            }).then((res) => {
                if (res.isConfirmed) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    };

    const apresentarOpcoesDeEscolha = (
        texto: string,
        opcao1: string,
        opcao2: string,
        corDosBotoes: string = "primary",
    ): Promise<string> => {
        corDosBotoes = corDosBotoes.toLowerCase();
        return new Promise((resolve) => {
            Swal.fire({
                text: texto,
                icon: "question",
                showConfirmButton: true,
                confirmButtonColor: listaDeCores()[corDosBotoes],
                confirmButtonText: opcao1,
                showDenyButton: true,
                denyButtonColor: listaDeCores()[corDosBotoes],
                denyButtonText: opcao2,
            }).then((res) => {
                if (res.isConfirmed) {
                    resolve(opcao1);
                }

                if (res.isDenied) {
                    resolve(opcao2);
                }
            });
        });
    };

    const fecharPopup = (): void => {
        Swal.close();
    };

    return {
        notificar,
        notificarComTexto,
        notificarCarregamento,
        questionarAcao,
        apresentarOpcoesDeEscolha,
        fecharPopup,
    };
}
