<template>

<ViasTable
    :carregando="primeiraBuscaDeUsuarios"
    :linhas="usuarios"
    :colunas="colunas"
    @alterar-status-do-usuario="alterarStatusDoUsuario($event)"
    com-opcoes
>
    <template #opcoes="{linha}">
        <div
            class="d-flex align-items-center justify-content-end no-wrap"
        >
            <router-link
                :to="
                    '/configuracao/usuarios/editar-usuario/' +
                    linha.id
                "
                class="btn btn-outline-primary btn-sm"
            >
                Editar
            </router-link>
        </div>
    </template>
</ViasTable>

</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import usuarioService from '../services/UsuarioService';
import { UsuarioParaApresentacaoEmTabela } from '../models/UsuarioParaApresentacaoEmTabela';
import { IColunaTabela } from '@/interfaces/IColunaTabela';

const usuarios = ref<UsuarioParaApresentacaoEmTabela[]>([]);
const colunas = ref<IColunaTabela[]>([
    {
        titulo: "Nome",
        referencia: "nome",
    },
    {
        titulo: "Usuário",
        referencia: "usuario",
    },
    {
        titulo: "E-mail",
        referencia: "email",
    },
    {
        titulo: "É super usuário?",
        referencia: "superUsuario",
    },
    {
        titulo: "Último acesso",
        referencia: "ultimoLogin",
    },
    {
        titulo: "Ativo?",
        referencia: "ativo",
        tipo: "switch",
        evento: "alterarStatusDoUsuario",
    },
]);

const primeiraBuscaDeUsuarios = ref<boolean>(true);

async function buscarUsuarios() {
    usuarios.value = await usuarioService.buscarUsuariosParaApresentacaoEmTabela();
    primeiraBuscaDeUsuarios.value = false;
}

async function alterarStatusDoUsuario({linha}: any) {
    usuarioService.alterarStatusDoUsuario(linha.id);
}

onMounted(() => {
    buscarUsuarios();
});
</script>