import http from "@/http";
import { RelatorioDeFluxoDasUltimas24Horas } from "../models/RelatorioDeFluxoDasUltimas24Horas";
import { AxiosResponse } from "axios";
import { FormularioParaBuscarRelatorioDeFluxoVeicular } from "../models/FormularioParaBuscarRelatorioDeFluxoVeicular";
import { RelatorioDeFluxoVeicular } from "../models/RelatorioDeFluxoVeicular";
import validadorDeTipo from "@/core/composables/ValidadorDeTipo";
import { ParametrosAgendamentoParaRelatorioFluxoVeicular } from "../models/ParametrosAgendamentoParaRelatorioFluxoVeicular";

class FluxoService {

    private rotaBase = "/vias/v1/graficos/fluxo";

    public buscarRelatorioDeFluxoDasUltimas24Horas(): 
        Promise<AxiosResponse<RelatorioDeFluxoDasUltimas24Horas>> {
        return http.get(`${this.rotaBase}/vinte-quatro`);
    }

    public buscarRelatorioDeFluxoVeicular(formulario: FormularioParaBuscarRelatorioDeFluxoVeicular):
        Promise<AxiosResponse<RelatorioDeFluxoVeicular>> {
        const params = new URLSearchParams();
        Object.entries(formulario).forEach((entrada) => {
            if (this.isEntradaValidaParaRelatorioDeFluxoVeicular(entrada[1])) {
                this.adicionarEntradaNosParametrosParaRelatorioDeFluxoVeicular(params, entrada);
            }
        });
        return http.get(this.rotaBase, {
            params: params
        });
    }

    public emitirPdfDoRelatorioDeFluxoVeicular(formulario: FormularioParaBuscarRelatorioDeFluxoVeicular) {
        const params = new URLSearchParams();
        Object.entries(formulario).forEach((entrada) => {
            if (this.isEntradaValidaParaRelatorioDeFluxoVeicular(entrada[1])) {
                this.adicionarEntradaNosParametrosParaRelatorioDeFluxoVeicular(params, entrada);
            }
        });
        return http.get(`${this.rotaBase}/pdf`, {
            params: params
        });
    }

    public agendarEmissaoDoPdfDoRelatorioDeFluxoVeicular(
        formulario: ParametrosAgendamentoParaRelatorioFluxoVeicular
    ) {
        return http.post(`${this.rotaBase}/pdf/agendar`, formulario);
    }

    private isEntradaValidaParaRelatorioDeFluxoVeicular(entrada) {
        if (validadorDeTipo.isNull(entrada)) {
            return false;
        }
        if (validadorDeTipo.isBoolean(entrada)) {
            return entrada;
        }
        if (validadorDeTipo.isNumber(entrada)) {
            return entrada > 0;
        }
        if (validadorDeTipo.isString(entrada) || validadorDeTipo.isArray(entrada)) {
            return entrada.length > 0;
        }
        return false;
    }

    private adicionarEntradaNosParametrosParaRelatorioDeFluxoVeicular(parametros: URLSearchParams, entrada: any[]): void {
        if (validadorDeTipo.isArray(entrada[1])) {
            entrada[1].forEach((valorDeEntrada) => {
                parametros.append(entrada[0], valorDeEntrada);
            });
            return;
        }
        parametros.append(entrada[0], entrada[1]);
    }

}

const fluxoService = new FluxoService();

export default fluxoService;