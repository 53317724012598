import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_OCORRENCIA_ESPECIFICA,
    GERAR_LINKS_IMAGENS,
} from "./type-actions";
import _axios from "@/plugins/axios.plugin";
import { AxiosInstance } from "axios";

const axios: AxiosInstance = _axios;

export const velocidadeMediaOcorrenciaEspecificaModule: Module<State, State> = {
    actions: {
        [BUSCAR_OCORRENCIA_ESPECIFICA](context, id: number) {
            return axios.get(
                `/vias/v1/velocidade-media/historico/infracoes/${id}`,
            );
        },
        [GERAR_LINKS_IMAGENS](context, id: number) {
            return {
                primeiro: axios.getUri({
                    url: `/vias/v1/velocidade-media/historico/infracoes/${id}/imagens`,
                    params: {
                        equipamento: "primeiro",
                    },
                }),
                segundo: axios.getUri({
                    url: `/vias/v1/velocidade-media/historico/infracoes/${id}/imagens`,
                    params: {
                        equipamento: "segundo",
                    },
                }),
            };
        },
    },
};
