<template>

<div class="overflow-x-auto">
    <table class="table table-sm">
        <thead>
            <tr>
                <th>Faixa</th>
                <th>Sentido</th>
                <th v-for="dia in diasDisponiveis" :key="dia">{{ dia }}</th>
                <th>Total</th>
                <th>VMD</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="[chave, valores] in linhas" :key="chave" :class="definirVariante(chave)">
                <td>{{ chave }}</td>
                <td>{{ valores.sentido }}</td>
                <td v-for="dia in diasDisponiveis" :key="dia">
                    {{ formatarNumero(valores[dia]) }}
                </td>
                <td>{{ formatarNumero(valores.total) }}</td>
                <td>{{ formatarNumero(valores.vmd) }}</td>
            </tr>
        </tbody>
    </table>
</div>

</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { VolumeMedioDiarioDoEquipamento } from '../models/VolumeMedioDiarioDoEquipamento';
import numerosService from '@/domains/Numeros/services/NumerosService';

interface Props {
    volumeMedioDiarioDoEquipamento: VolumeMedioDiarioDoEquipamento;
}

const props = defineProps<Props>();

const linhas = computed(() => {
    return Object.entries(props.volumeMedioDiarioDoEquipamento).filter(([chave]) => {
        return chave !== "endereco";
    });
});

const diasDisponiveis = computed(() => {
    return Object.keys(props.volumeMedioDiarioDoEquipamento.total).filter((chave) => {
        return !["total", "vmd", "sentido"].includes(chave); 
    });
});

function definirVariante(chave: string): string {
    if (chave === "total") {
        return "text-danger";
    }
    if (chave === "vmd") {
        return "bg-primary";
    }
    return "";
}

function formatarNumero(numero: number | string): string {
    if (typeof numero === "number") {
        return numerosService.formatarNumero(numero);
    }
    return numero;
}
</script>