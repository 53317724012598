import { Module } from "vuex";
import { State } from "..";
import { BUSCAR_INFORMACOES_DA_ROTA } from "./type-actions";
import { ICoordenadasRota } from "@/interfaces/ICoordenadasRota";

export const mapaModule: Module<State, State> = {
    actions: {
        [BUSCAR_INFORMACOES_DA_ROTA](
            context,
            coordenadasRota: ICoordenadasRota,
        ) {
            return new Promise((resolve) => {
                const resposta = {
                    tempoDePercurso: 0,
                    distancia: 0,
                };

                const platform = new (<any>window).H.service.Platform({
                    apikey: "oScfuXmMRO5pAR92al55nKZaNowqIcgUeglmWAHO0ag",
                });

                platform.getRoutingService(null, 8).calculateRoute(
                    {
                        routingMode: "fast",
                        transportMode: "car",
                        origin: `${coordenadasRota.origem.lat},${coordenadasRota.origem.lng}`,
                        destination: `${coordenadasRota.destino.lat},${coordenadasRota.destino.lng}`,
                        return: "summary",
                    },
                    (result) => {
                        if (result.routes.length) {
                            result.routes[0].sections.forEach((section) => {
                                resposta.tempoDePercurso =
                                    section.summary.duration;
                                resposta.distancia = section.summary.length;
                            });
                        }

                        resolve(resposta);
                    },
                );
            });
        },
    },
};
