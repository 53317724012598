import { AxiosResponse } from "axios";
import { FormularioBuscaRelatorioVolumeMedioDiario } from "../models/FormularioBuscaRelatorioVolumeMedioDiario";
import { RelatorioVolumeMedioDiario } from "../models/RelatorioVolumeMedioDiario";
import http from "@/http";
import validadorDeTipo from "@/core/composables/ValidadorDeTipo";
import moment from "moment";
import { ParametrosAgendamentoParaRelatorioVolumeMedioDiario } from "../models/ParametrosAgendamentoParaRelatorioVolumeMedioDiario";

class VolumeMedioDiarioService {

    private rotaBase = "/vias/v1/relatorios/volume-medio-diario";

    public buscarRelatorioVolumeMedioDiario(formulario: FormularioBuscaRelatorioVolumeMedioDiario):
        Promise<AxiosResponse<RelatorioVolumeMedioDiario>> {
        const params = this.gerarParametrosParaRelatorioDeVolumeMedioDiario(formulario);

        return http.get(this.rotaBase, {
            params: params
        });
    }

    public emitirPdfRelatorioVolumeMedioDiario(formulario: FormularioBuscaRelatorioVolumeMedioDiario):
        Promise<AxiosResponse> {
        const params = this.gerarParametrosParaRelatorioDeVolumeMedioDiario(formulario);

        return http.get(`${this.rotaBase}/pdf`, {
            params: params
        });
    }

    public emitirExcelRelatorioVolumeMedioDiario(formulario: FormularioBuscaRelatorioVolumeMedioDiario):
        Promise<AxiosResponse> {
        const params = this.gerarParametrosParaRelatorioDeVolumeMedioDiario(formulario);

        return http.get(`${this.rotaBase}/excel`, {
            params: params
        });
    }

    public agendarEmissaoPdfRelatorioVolumeMedioDiario(
        formulario: ParametrosAgendamentoParaRelatorioVolumeMedioDiario
    ): Promise<AxiosResponse> {
        return http.post(`${this.rotaBase}/pdf/agendar`, formulario);
    }

    private gerarParametrosParaRelatorioDeVolumeMedioDiario(formulario: FormularioBuscaRelatorioVolumeMedioDiario):
        URLSearchParams {
        const params = new URLSearchParams();

        Object.entries(formulario).forEach(([chave, valor]) => {
            if (this.isEntradaValidaParaRelatorioVolumeMedioDiario(valor)) {
                this.adicionarValorNosParametrosParaRelatorioDeVolumeMedioDiario(params, chave, valor);
            }
        });

        return params;
    }

    private isEntradaValidaParaRelatorioVolumeMedioDiario(valor?: null | boolean | string | number) {
        if (validadorDeTipo.isNumber(valor)) {
            return true;
        }

        if (validadorDeTipo.isDatetime(valor)) {
            return true;
        }

        if (validadorDeTipo.isBoolean(valor)) {
            return true;
        }

        return false;
    }

    private adicionarValorNosParametrosParaRelatorioDeVolumeMedioDiario(
        params: URLSearchParams,
        chave: string,
        valor: any
    ): void {
        if (chave === "data") {
            params.append(chave, moment(valor).format("YYYY-MM-DDT00:00:00"));
        } else {
            params.append(chave, valor);
        }
    }
}

const volumeMedioDiarioService = new VolumeMedioDiarioService();

export default volumeMedioDiarioService;
