<template>
    <div
        class="box-rota"
        v-if="mostrarBox"
        :style="{ bottom: posicaoBox.bottom, left: posicaoBox.left }"
    >
        <slot name="box">
            <div v-html="box"></div>
        </slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, toRaw } from "vue";
import { google } from "google-maps";
import { useStore } from "@/storeTs";
import { SET_MOSTRANDO_BOX } from "@/storeTs/type-mutations";

export default defineComponent({
    name: "ViasGoogleMapsRota",
    emits: ["click"],
    props: {
        inicio: {
            type: Object,
            required: false,
        },
        fim: {
            type: Object,
            required: false,
        },
        corDaRota: {
            type: String,
            default: "#7380EC",
        },
        box: {
            type: String,
            required: false,
        },
        noBox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            directionsService: null as google.maps.DirectionsService | null,
            directionsRenderer: null as google.maps.DirectionsRenderer | null,
            polylineOptions: {
                strokeColor: this.corDaRota,
                strokeWeight: 10,
                strokeOpacity: 1,
                zIndex: 1,
            },
            polylines: [] as google.maps.Polyline[],
            mostrarBox: false,
        };
    },
    computed: {
        posicaoBox(): any {
            return {
                bottom: "20px",
                left: "20px",
            };
        },
        menuAberto(): boolean {
            return this.store.state.menu_open;
        },
        boxJaSendoApresentada(): boolean {
            return this.store.state.mostrandoBox;
        },
    },
    methods: {
        iniciarRota() {
            this.directionsService =
                new this.googleApi.maps.DirectionsService();
            this.criarRota();
        },
        criarRota() {
            if (this.directionsService) {
                if (this.inicio && this.fim) {
                    this.directionsService.route(
                        this.gerarConfiguracaoDaRota(),
                        (response, status) => {
                            if (status === "OK") {
                                this.renderizarPolylines(response);
                            }
                        },
                    );
                } else {
                    this.removerPolylinesExistentes();
                }
            }
        },
        gerarConfiguracaoDaRota(): google.maps.DirectionsRequest {
            return {
                origin: this.inicio,
                destination: this.fim,
                provideRouteAlternatives: false,
                travelMode: "DRIVING" as google.maps.TravelMode,
            };
        },
        removerPolylinesExistentes() {
            for (let i = 0; i < this.polylines.length; i++) {
                toRaw(this.polylines[i]).setMap(null);
            }
        },
        async renderizarPolylines(response: google.maps.DirectionsResult) {
            this.removerPolylinesExistentes();
            const legs = response.routes[0].legs;
            for (let i = 0; i < legs.length; i++) {
                const steps = legs[i].steps;

                for (let j = 0; j < steps.length; j++) {
                    const zIndex = this.getZIndexForBackgroundPolyline();
                    const nextSegment = steps[j].path;
                    const strokePolyline = new this.googleApi.maps.Polyline({
                        ...this.polylineOptions,
                        strokeColor: "#000",
                        strokeWeight: 12,
                        zIndex: zIndex - 1,
                    });
                    const stepPolyline = new this.googleApi.maps.Polyline({
                        ...this.polylineOptions,
                        strokeColor: this.corDaRota,
                        zIndex: zIndex,
                    });
                    for (let k = 0; k < nextSegment.length; k++) {
                        strokePolyline.getPath().push(nextSegment[k]);
                        stepPolyline.getPath().push(nextSegment[k]);
                    }
                    strokePolyline.setMap(this.map);
                    this.polylines.push(strokePolyline);
                    stepPolyline.setMap(this.map);
                    stepPolyline.addListener("click", () => {
                        this.$emit("click");
                    });
                    stepPolyline.addListener("mouseover", () => {
                        if (
                            !this.boxJaSendoApresentada &&
                            !this.noBox &&
                            this.box
                        ) {
                            this.mostrarBox = true;
                            this.store.commit(SET_MOSTRANDO_BOX, true);
                        }
                    });
                    stepPolyline.addListener("mousemove", (e) => {
                        this.posicaoMouse = {
                            top: e.domEvent.y,
                            left: e.domEvent.x,
                        };
                    });
                    stepPolyline.addListener("mouseout", () => {
                        if (this.mostrarBox) {
                            this.mostrarBox = false;
                            this.store.commit(SET_MOSTRANDO_BOX, false);
                        }
                    });
                    this.polylines.push(stepPolyline);
                }
            }
        },
        getZIndexForBackgroundPolyline() {
            if (this.corDaRota.toUpperCase() === "#7380EC") {
                return 25;
            }
            if (this.corDaRota.toUpperCase() === "#52BE80") {
                return 50;
            }
            if (this.corDaRota.toUpperCase() === "#ECDF73") {
                return 75;
            }
            if (this.corDaRota.toUpperCase() === "#EC7380") {
                return 100;
            }
            return 1;
        },
    },
    created() {
        this.iniciarRota();
    },
    beforeUnmount() {
        this.removerPolylinesExistentes();
    },
    watch: {
        inicio() {
            this.criarRota();
        },
        fim() {
            this.criarRota();
        },
        corDaRota() {
            this.criarRota();
        },
    },
    setup() {
        const store = useStore();
        const googleApi = inject("googleApi") as any;
        const map = inject("map") as any;
        const posicaoMouse = ref({
            top: 0,
            left: 0,
        }) as any;

        return {
            store,
            googleApi,
            map,
            posicaoMouse,
        };
    },
});
</script>
