<template>

<vias-card class="h-100 d-flex">
    <div class="d-flex h-100 nowrap">
        <div
            class="barra-vertical mr-6"
            :class="varianteBarraVertical"
        />
        <div
            class="left d-flex flex-column justify-content-between h-100"
        >
            <h3>
                <slot name="cabecalho"/>
            </h3>
            <h1>
                <slot name="valorPrincipal"/>
            </h1>
            <h4>
                <slot name="valorSecundario"/>
            </h4>
            <small class="text-muted">
                <slot name="legenda"/>
            </small>
            <slot name="rodape"/>
        </div>
    </div>
</vias-card>

</template>

<script lang="ts" setup>
import { computed, defineProps, withDefaults } from 'vue';

interface Props {
    variante: string;
}

const props = withDefaults(defineProps<Props>(), {
    variante: "success"
});

const varianteBarraVertical = computed(() => `barra-vertical-${props.variante}`);
</script>