<template>
    <form @submit.prevent="salvarRota()" class="mt-4">
        <ViasRow>
            <ViasCol class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                    :rotas="rota ? [rota] : []"
                    @clickEquipamento="alternarSelecaoDeEquipamento($event)"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow class="my-4 max-h-300px overflow-y-auto">
            <ViasCol
                v-for="equipamento in equipamentos"
                :key="equipamento.id"
                col="12"
                sm="6"
                md="4"
                lg="3"
            >
                <div
                    class="border border-radius-1 p-4"
                    :class="{
                        'bg-success': isEquipamentoJaSelecionado(equipamento),
                    }"
                >
                    <h3
                        :class="{
                            'text-dark':
                                isEquipamentoJaSelecionado(equipamento),
                        }"
                    >
                        {{ equipamento.sitio }}
                    </h3>
                    <div class="mt-3">
                        <p class="text-muted">
                            {{ equipamento.posicao.endereco }}
                        </p>
                    </div>
                    <div class="mt-3">
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            class="mr-2"
                            @click="adicionarEquipamentoComoOrigem(equipamento)"
                            size="sm"
                        >
                            Origem
                        </ViasButton>
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            @click="
                                adicionarEquipamentoComoDestino(equipamento)
                            "
                            size="sm"
                        >
                            Destino
                        </ViasButton>
                    </div>
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-2" v-if="origem && destino">
            <ViasCol>
                <h3 class="mb-4">
                    Rota do equipamento "{{ origem.sitio }}", cujo endereço é
                    {{ origem.posicao.endereco }}, ao equipamento "{{
                        destino.sitio
                    }}", com o endereço {{ destino.posicao.endereco }}
                </h3>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasInput
                            class="mt-4"
                            type="text"
                            :id="'rota-' + origem.sitio + '-' + destino.sitio"
                            label="Nome"
                            v-model="nome"
                            :valid="isNomeValido"
                            :detail="getNomeMessage"
                            required
                        ></ViasInput>
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasInput
                            type="number"
                            label="Tempo de percurso esperado (em segundos)"
                            v-model="tempoDePercurso"
                            required
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasCheckbox
                            v-model="publica"
                            after-text="Pública"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow v-if="!publica">
                    <ViasCol
                        v-for="usuario in usuarios"
                        :key="usuario.id"
                        cols="12"
                        md="4"
                        class="p-0"
                    >
                        <ViasCheckbox
                            v-model="ids_usuarios_com_acesso"
                            :value="usuario.id"
                            :after-text="usuario.nome"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol class="p-0" cols="12" md="6">
                        <h3 class="pl-3 pr-3">Origem</h3>
                        <h4 class="pl-3 pr-3 mb-3">
                            {{ origem.sitio }} - {{ origem.posicao.endereco }}
                        </h4>
                        <ViasCheckbox
                            v-for="faixa in origem.afericoes"
                            v-model="ids_faixas_origem"
                            :key="faixa.id"
                            :value="faixa.id"
                            :after-text="'Faixa ' + faixa.faixa"
                            switch
                        />
                    </ViasCol>
                    <ViasCol class="p-0" cols="12" md="6">
                        <h3 class="pl-3 pr-3">Destino</h3>
                        <h4 class="pl-3 pr-3 mb-3">
                            {{ destino.sitio }} - {{ destino.posicao.endereco }}
                        </h4>
                        <ViasCheckbox
                            v-for="faixa in destino.afericoes"
                            v-model="ids_faixas_destino"
                            :key="faixa.id"
                            :value="faixa.id"
                            :after-text="'Faixa ' + faixa.faixa"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isOrigemValida === false">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getOrigemMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isDestinosValida === false">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getDestinosMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="validado" class="mt-4">
            <ViasCol>
                <ViasTable
                    :colunas="colunasValidacao"
                    :linhas="validacoes"
                    no-filter
                ></ViasTable>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol>
                <ViasButton variant="primary" class="mr-4">Salvar</ViasButton>
                <ViasButton
                    variant="outline-primary"
                    type="button"
                    @click="validarRota()"
                >
                    Validar
                </ViasButton>
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts">
import { useNotificacao } from "@/hooks/notificacao.hook";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import { useStore } from "@/storeTs";
import { PropType, defineComponent } from "vue";
import { BUSCAR_INFORMACOES_DA_ROTA } from "@/storeTs/mapa/type-actions";
import { IRotaOrigemEDestino } from "../interfaces/IRotaOrigemEDestino";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { IValidacaoOrigemEDestino } from "../interfaces/IValidacaoOrigemEDestino";
import {
    CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO,
    VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO,
} from "@/storeTs/origem-e-destino/configuracao/type-actions";
import { BUSCAR_EQUIPAMENTO_POR_ID } from "@/storeTs/equipamentos/type-actions";
import { helpers, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { BUSCAR_EQUIPAMENTOS, BUSCAR_USUARIOS } from "@/store/tipo-acoes";
import { IEquipamentoCompleto } from "@/interfaces/equipamento/IEquipamentoCompleto";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { Usuario } from "@/domains/Usuarios/models/Usuario";

export default defineComponent({
    name: "FormularioNovaRotaOrigemEDestino",
    emits: ["salvarRota"],
    props: {
        rotaSelecionada: {
            type: Object as PropType<IRotaOrigemEDestino>,
        },
    },
    components: {
        ViasMapaGeral,
    },
    data: () => ({
        validacoes: [] as IValidacaoOrigemEDestino[],
        colunasValidacao: [
            {
                titulo: "Nome da rota",
                referencia: "rota",
            },
            {
                titulo: "Fluxo na origem",
                referencia: "passagensNaOrigem",
            },
            {
                titulo: "Fluxo no destino",
                referencia: "passagensNoDestino",
            },
            {
                titulo: "Porcentagem de fluxo em ambos",
                referencia: "porcentagemDePassagemEmAmbos",
            },
        ] as IColunaTabela[],
        validado: false,
        origem: null as null | IEquipamentoCompleto,
        destino: null as null | IEquipamentoCompleto,
        nome: "",
        publica: true,
        equipamentos: [] as IEquipamento[],
        usuarios: [] as Usuario[],
        idsEquipamentosSelecionados: [] as number[],
        ids_usuarios_com_acesso: [] as number[],
        ids_faixas_origem: [] as number[],
        ids_faixas_destino: [] as number[],
        tempoDePercurso: null as null | number,
        rota: null as null | IRotaMapa,
    }),
    computed: {
        isNomeValido(): boolean | null {
            if (this.v$.nome.$dirty) {
                return !this.v$.nome.$invalid;
            }
            return null;
        },
        getNomeMessage(): string {
            if (this.v$.nome.$dirty) {
                return this.v$.nome.$errors[0]?.$message;
            }
            return "";
        },
        isOrigemValida(): boolean | null {
            if (this.v$.origem.$dirty) {
                return !this.v$.origem.$invalid;
            }
            return null;
        },
        getOrigemMessage(): string | null {
            if (this.v$.origem.$dirty) {
                return this.v$.origem.$errors[0]?.$message;
            }
            return "";
        },
        isDestinosValida(): boolean | null {
            if (this.v$.destino.$dirty) {
                return !this.v$.destino.$invalid;
            }
            return null;
        },
        getDestinosMessage(): string {
            if (this.v$.destino.$dirty) {
                return this.v$.destino.$errors[0]?.$message;
            }
            return "";
        },
    },
    methods: {
        buscarEquipamentos() {
            this.store.dispatch(BUSCAR_EQUIPAMENTOS).then((res) => {
                this.equipamentos = res.data;
                this.atualizarSelecaoDeEquipamentos();
            });
        },
        buscarUsuarios() {
            this.store.dispatch(BUSCAR_USUARIOS).then((res) => {
                this.usuarios = res.data;
            });
        },
        isEquipamentoJaSelecionado(equipamento: IEquipamento) {
            if (this.origem?.id === equipamento.id) {
                return true;
            }

            if (this.destino?.id === equipamento.id) {
                return true;
            }

            return false;
        },
        adicionarEquipamentoComoOrigem(equipamento: IEquipamento) {
            new Promise((resolve) => {
                if (this.origem?.id === equipamento.id) {
                    this.origem = null;
                    resolve(true);
                } else if (this.destino?.id === equipamento.id) {
                    this.questionarAcao(
                        `O equipamento "${equipamento.sitio}" está selecionado como destino, deseja alterar para origem?`,
                    ).then((isConfirmado) => {
                        if (isConfirmado) {
                            this.destino = null;
                            this.store
                                .dispatch(
                                    BUSCAR_EQUIPAMENTO_POR_ID,
                                    equipamento.id,
                                )
                                .then((res) => {
                                    this.origem = res.data;
                                    this.ids_faixas_origem = [];
                                    resolve(true);
                                });
                            resolve(true);
                        }
                    });
                } else {
                    this.store
                        .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                        .then((res) => {
                            this.origem = res.data;
                            this.ids_faixas_origem = [];
                            resolve(true);
                        });
                }
            }).finally(() => {
                this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
            });
        },
        adicionarEquipamentoComoDestino(equipamento: IEquipamento) {
            new Promise((resolve) => {
                if (this.destino?.id === equipamento.id) {
                    this.destino = null;
                    resolve(true);
                } else if (this.origem?.id === equipamento.id) {
                    this.questionarAcao(
                        `O equipamento "${equipamento.sitio}" está selecionado como origem, deseja alterar para destino?`,
                    ).then((isConfirmado) => {
                        if (isConfirmado) {
                            this.origem = null;
                            this.store
                                .dispatch(
                                    BUSCAR_EQUIPAMENTO_POR_ID,
                                    equipamento.id,
                                )
                                .then((res) => {
                                    this.destino = res.data;
                                    this.ids_faixas_destino = [];
                                    resolve(true);
                                });
                            resolve(true);
                        }
                    });
                } else {
                    this.store
                        .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                        .then((res) => {
                            this.destino = res.data;
                            this.ids_faixas_destino = [];
                            resolve(true);
                        });
                }
            }).finally(() => {
                this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
            });
        },
        atualizarSelecaoDeEquipamentos() {
            this.idsEquipamentosSelecionados = [
                this.origem?.id || 0,
                this.destino?.id || 0,
            ];

            this.atualizarInformacoesSobrePossiveisDestinos();
            this.atualizarRota();
        },
        atualizarInformacoesSobrePossiveisDestinos() {
            if (this.origem) {
                this.store
                    .dispatch(
                        CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO,
                        this.origem.id,
                    )
                    .then((res) => {
                        const entradas = Object.entries(
                            res.data.destinos.porcentagens,
                        );
                        this.equipamentos = this.equipamentos.map((eq) => {
                            if (eq.id === this.origem?.id) {
                                return {
                                    ...eq,
                                    texto_box: `${res.data.origem.quantidade} de fluxo`,
                                };
                            } else {
                                const entrada: any = entradas.find(
                                    (entrada) => entrada[0] == eq.id.toString(),
                                );

                                if (!entrada) {
                                    return eq;
                                }

                                return {
                                    ...eq,
                                    texto_box: `${parseInt(entrada[1].toString()).toFixed(2)}%`,
                                };
                            }
                        });
                    });
                return;
            }
            this.equipamentos = this.equipamentos.map((eq) => ({
                ...eq,
            }));
        },
        atualizarRota() {
            if (this.origem && this.destino) {
                this.rota = {
                    id: 1,
                    inicio: {
                        lat: this.origem.posicao.latitude,
                        lng: this.origem.posicao.longitude,
                    },
                    fim: {
                        lat: this.destino.posicao.latitude,
                        lng: this.destino.posicao.longitude,
                    },
                };
            }
        },
        atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso() {
            this.atualizarSelecaoDeEquipamentos();
            this.buscarSugestaoDeTempoDePercurso();
        },
        async buscarSugestaoDeTempoDePercurso() {
            if (!this.rota?.inicio) {
                return;
            }
            if (!this.rota?.fim) {
                return;
            }
            const response = await this.store.dispatch(
                BUSCAR_INFORMACOES_DA_ROTA,
                {
                    origem: this.rota.inicio,
                    destino: this.rota.fim,
                },
            );
            this.tempoDePercurso = response.tempoDePercurso;
        },
        alternarSelecaoDeEquipamento(equipamento: IEquipamento) {
            if (this.origem?.id === equipamento.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" está selecionado como origem, deseja remover a seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.origem = null;
                        this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
                    }
                });
                return;
            } else if (this.destino?.id === equipamento.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" está selecionado como destino, deseja remover a seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.destino = null;
                        this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
                    }
                });
                return;
            } else {
                this.apresentarOpcoesDeEscolha(
                    `Deseja adicionar o equipamento "${equipamento.sitio}" como origem ou como destino?`,
                    "Origem",
                    "Destino",
                ).then((opcaoEscolhida) => {
                    if (opcaoEscolhida === "Origem") {
                        this.adicionarEquipamentoComoDestino(equipamento);
                        this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
                        return;
                    }

                    if (opcaoEscolhida === "Destino") {
                        this.adicionarEquipamentoComoDestino(equipamento);
                        this.atualizarSelecaoDeEquipamentosComBuscaDeSugestaoDeTempoDePercurso();
                        return;
                    }
                });
            }
        },
        async validarRota() {
            const isFormularioValido = await this.v$.$validate();

            if (isFormularioValido) {
                this.notificarCarregamento(
                    "Validando a rota selecionada, aguarde um momento por favor...",
                );
                this.store
                    .dispatch(VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO, [
                        {
                            nome: this.nome,
                            tempo_entre_equipamentos: this.tempoDePercurso,
                            primeiro_equipamento: {
                                equipamento_id: this.origem?.id,
                                faixas: this.ids_faixas_origem,
                            },
                            segundo_equipamento: {
                                equipamento_id: this.destino?.id,
                                faixas: this.ids_faixas_destino,
                            },
                            publica: this.publica,
                            usuarios_ids: this.publica
                                ? []
                                : this.ids_usuarios_com_acesso,
                        },
                    ])
                    .then((res) => {
                        this.validacoes = Object.keys(res.data).map(
                            (rota): IValidacaoOrigemEDestino => ({
                                rota: rota,
                                passagensNaOrigem:
                                    res.data[rota].origem + " de fluxo",
                                passagensNoDestino:
                                    res.data[rota].destino + " de fluxo",
                                porcentagemDePassagemEmAmbos:
                                    (
                                        (res.data[rota].destino /
                                            res.data[rota].origem) *
                                        100
                                    ).toFixed(2) + "%",
                            }),
                        );
                        this.validado = true;
                        this.fecharPopup();
                    });
            }
        },
        async salvarRota() {
            const isFormularioValido = await this.v$.$validate();

            if (isFormularioValido) {
                this.notificarCarregamento(
                    "Buscando informações da rota, aguarde um momento por favor...",
                );
                const novaRota = {
                    nome: this.nome,
                    primeiro_equipamento: {
                        equipamento_id: this.origem?.id,
                        faixas: this.ids_faixas_origem,
                    },
                    segundo_equipamento: {
                        equipamento_id: this.destino?.id,
                        faixas: this.ids_faixas_destino,
                    },
                    publica: this.publica,
                    usuarios_ids: this.publica
                        ? []
                        : this.ids_usuarios_com_acesso,
                    tempo_entre_equipamentos: this.tempoDePercurso,
                };
                this.$emit("salvarRota", novaRota);
            }
        },
    },
    created() {
        this.buscarEquipamentos();
        this.buscarUsuarios();
    },
    watch: {
        rotaSelecionada() {
            const runner = setInterval(() => {
                if (this.rotaSelecionada) {
                    if (this.equipamentos.length > 0) {
                        clearInterval(runner);
                        const promiseOrigem = this.store.dispatch(
                            BUSCAR_EQUIPAMENTO_POR_ID,
                            this.rotaSelecionada?.primeiro_equipamento
                                .equipamento.id,
                        );
                        const promiseDestino = this.store.dispatch(
                            BUSCAR_EQUIPAMENTO_POR_ID,
                            this.rotaSelecionada?.segundo_equipamento
                                .equipamento.id,
                        );

                        Promise.all([promiseOrigem, promiseDestino]).then(
                            ([resOrigem, resDestino]) => {
                                this.origem = resOrigem.data;
                                this.destino = resDestino.data;
                                this.nome = this.rotaSelecionada?.nome || "";
                                this.ids_faixas_origem =
                                    this.rotaSelecionada?.primeiro_equipamento.afericoes.map(
                                        (afericao) => afericao.id,
                                    ) || [];
                                this.ids_faixas_destino =
                                    this.rotaSelecionada?.segundo_equipamento.afericoes.map(
                                        (afericao) => afericao.id,
                                    ) || [];
                                this.publica =
                                    this.rotaSelecionada?.publica || true;
                                this.ids_usuarios_com_acesso =
                                    this.rotaSelecionada?.usuarios.map(
                                        (usuario) => usuario.id,
                                    ) || [];
                                this.tempoDePercurso =
                                    this.rotaSelecionada
                                        ?.tempo_entre_equipamentos || null;
                                this.v$.$validate();
                                this.atualizarInformacoesSobrePossiveisDestinos();
                                this.atualizarSelecaoDeEquipamentos();
                            },
                        );
                    }
                } else {
                    this.nome = "";
                    this.origem = null;
                    this.destino = null;
                }
            }, 500);
        },
    },
    validations() {
        return {
            origem: {
                required: helpers.withMessage(
                    "É necessário que seja selecionado uma origem para a rota",
                    required,
                ),
            },
            destino: {
                required: helpers.withMessage(
                    "É necessário que seja selecionado um destino para a rota",
                    required,
                ),
            },
            nome: {
                required: helpers.withMessage(
                    "É necessário que a rota possua um nome",
                    required,
                ),
                minLength: helpers.withMessage(
                    "O nome da rota precisa ter, pelo menos, 5 caracteres",
                    minLength(5),
                ),
            },
        };
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();
        const {
            notificar,
            apresentarOpcoesDeEscolha,
            questionarAcao,
            notificarCarregamento,
            fecharPopup,
        } = useNotificacao();

        return {
            store,
            v$,
            notificar,
            apresentarOpcoesDeEscolha,
            questionarAcao,
            notificarCarregamento,
            fecharPopup,
        };
    },
});
</script>
