<template>

<ViasRow>
    <ViasCol>
        <ViasCard>
            <template #header>
                <div
                    class="d-flex justify-content-between align-items-center"
                >
                    <h2>Editar usuário</h2>
                    <ViasButton
                        variant="outline-primary"
                        @click="
                            voltarParaApresentacaoDeUsuariosCadastrados()
                        "
                    >
                        Voltar
                    </ViasButton>
                </div>
            </template>
            <ViasRow>
                <ViasCol>
                    <FormularioEdicaoDeUsuario
                        :id-usuario="idUsuarioSelecionado"
                        @usuario-salvo="voltarParaApresentacaoDeUsuariosCadastrados()"
                    />
                </ViasCol>
            </ViasRow>
        </ViasCard>
    </ViasCol>
</ViasRow>

</template>

<script lang="ts" setup>
import FormularioEdicaoDeUsuario from '../components/FormularioEdicaoDeUsuario.vue';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const idUsuarioSelecionado = ref(0);

function voltarParaApresentacaoDeUsuariosCadastrados() {
    router.push("/configuracao/usuarios");
}

onMounted(() => {
    try {
        idUsuarioSelecionado.value = parseInt(router.currentRoute.value.params.id.toString());
    } catch {
        voltarParaApresentacaoDeUsuariosCadastrados();
    }
});
</script>