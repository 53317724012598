<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-date
                        v-model="data_hora"
                        label="Data da busca"
                        :max="moment().format('YYYY-MM-DDTHH:mm:ssZ')"
                    ></vias-input-date>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button variant="primary" type="submit"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <div class="overflow-x-auto">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th rowspan="2">Equipamento</th>
                            <th rowspan="2">Data</th>
                            <th
                                :colspan="equipamento.indices.length"
                                class="text-center"
                            >
                                Faixa de Horário
                            </th>
                        </tr>
                        <tr>
                            <th
                                v-for="i in equipamento.indices.length"
                                :key="i"
                                class="border-radius-0"
                            >
                                {{ ajustarNumeroParaDuasCasas(i - 1) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="table-border-bottom border-right">
                                {{ equipamento.sitio }}
                                <br />
                                {{ equipamento.endereco }}
                            </td>
                            <td>{{ equipamento.data }}</td>
                            <td
                                v-for="indice in equipamento.indices"
                                :key="indice + equipamento.sitio"
                                :style="{
                                    'background-color': definirCorDaCelula(
                                        indice,
                                        equipamento.porcentagem_maxima,
                                    ),
                                    color: definirCorDoTexto(indice),
                                }"
                            >
                                {{
                                    indice === "Nulo"
                                        ? indice
                                        : indice.toFixed(2)
                                }}{{ indice === "Nulo" ? "" : "%" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasInputDate from "@/components/shared/ViasInputDate.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import {
    buscarRelatorioDeCalorDeIndiceDeLeituraDePlaca,
    emitirPdfDeCalorDeIndiceDeLeituraDePlaca,
} from "@/services/integrated-management.service";
import { close, open } from "@/services/notification.service";
import moment from "moment";
import { ref } from "vue";
import { ordenarArrayCrescente } from "@/services/array.service";

const data_hora = ref(moment().format("YYYY-MM-DDTHH:mm:ssZ"));

const relatorio = ref(null);

const buscarRelatorio = () => {
    open("Buscando relatório, por favor aguarte um momento...");
    buscarRelatorioDeCalorDeIndiceDeLeituraDePlaca(data_hora.value)
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = null;
            return dados;
        })
        .then((dados) => {
            const novoRelatorio = [];
            for (const [sitio, informacoes] of ordenarArrayCrescente(
                Object.entries(dados.equipamentos),
                "0",
            )) {
                const indices = Object.values(informacoes.grafico);
                const porcentagem_maxima = informacoes.grafico.maximo;
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    indices: new Array(24).fill(0).map((n, i) => {
                        if (indices[i] === null) {
                            return "Nulo";
                        }
                        return indices[i];
                    }),
                    data: moment(dados.data).format("DD/MM/YYYY"),
                    porcentagem_maxima,
                };

                novoRelatorio.push(equipamento);
            }
            relatorio.value = novoRelatorio;
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const ajustarNumeroParaDuasCasas = (n) => {
    if (typeof n !== "number") {
        return n;
    }
    return n < 10 ? `0${n}` : n;
};

const definirCorDaCelula = (valor, maximo) => {
    if (typeof valor !== "number") {
        return null;
    }

    const cores = [
        "#205CAA",
        "#276DAC",
        "#2E7DAE",
        "#358EB0",
        "#3B9FB2",
        "#56C798",
        "#6ED377",
        "#FFC973",
        "#FCC484",
        "#FFB493",
        "#FFA48C",
        "#FF9485",
        "#FA7068",
    ];

    if (valor === maximo) {
        return cores[cores.length - 1];
    }

    const percentual = valor / maximo;
    const indice = Math.floor(percentual * (cores.length - 1));
    return cores[percentual === 0 ? indice : indice + 1];
};

const definirCorDoTexto = (valor) => {
    if (typeof valor !== "number") {
        return null;
    }

    return "#16182E";
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDeCalorDeIndiceDeLeituraDePlaca(data_hora.value)
        .then((res) => res.data)
        .then(({ mensagem }) => {
            close(mensagem, "success");
        });
};
</script>
