import { Module } from "vuex";
import { State } from "..";
import { IMonitor } from "./IMonitor";
import {
    BUSCAR_CREDENCIAIS_ZONEMINDER,
    BUSCAR_MONITORES_ZONEMINDER,
} from "./type-actions";
import moment from "moment";
import axios from "axios";

export interface StateCameras {
    credenciais: string | null;
    ultima_busca_das_credenciais: string | null;
    monitores: IMonitor[];
    ultima_busca_dos_monitores: string | null;
}

export const camerasModule: Module<StateCameras, State> = {
    state: {
        credenciais: null,
        ultima_busca_das_credenciais: null,
        monitores: [],
        ultima_busca_dos_monitores: null,
    },
    actions: {
        async [BUSCAR_CREDENCIAIS_ZONEMINDER]({ state }) {
            const momentUltimaBusca = moment(
                state.ultima_busca_das_credenciais,
            );
            const diff = moment().diff(momentUltimaBusca, "hour");

            if (!state.credenciais || (state.credenciais && diff > 1)) {
                const { data } = await axios.post(
                    "https://zoneminder.sistemasfotosensores.com/zm/api/host/login.json?user=viewer&pass=foto@123",
                );
                state.credenciais = data.credentials;
                state.ultima_busca_das_credenciais = moment().format(
                    "YYYY-MM-DDTHH:mm:ss",
                );
            }

            return state.credenciais;
        },
        async [BUSCAR_MONITORES_ZONEMINDER]({ state, dispatch }) {
            const credenciais = await dispatch(BUSCAR_CREDENCIAIS_ZONEMINDER);
            const momentUltimaBusca = moment(state.ultima_busca_dos_monitores);
            const diff = moment().diff(momentUltimaBusca, "hour");

            if (state.monitores.length == 0 || (state.monitores && diff > 1)) {
                const { data } = await axios.get(
                    `https://zoneminder.sistemasfotosensores.com/zm/api/monitors.json?${credenciais}`,
                );
                state.monitores = data.monitors
                    .filter((monitor) => !monitor.Monitor.Deleted)
                    .map((monitor) => ({
                        id: monitor.Monitor.Id,
                        sitio: monitor.Monitor.Name.split("-")[1],
                    }));
                state.ultima_busca_dos_monitores = moment().format(
                    "YYYY-MM-DDTHH:mm:ss",
                );
            }

            return state.monitores;
        },
    },
};
