<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-date
                        v-model="data_busca"
                        :max="moment().format('YYYY-MM-DDT00:00:00Z')"
                        label="Data da busca"
                    ></vias-input-date>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button type="submit" variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div id="relatorio-volume-diario-de-trafego" v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div class="row" v-for="mes in equipamento.meses" :key="mes.mes">
                <div class="col">
                    <div class="grafico-de-relatorio">
                        <div class="cabecalho">
                            <h3 class="text-capitalize">{{ mes.mes }}</h3>
                        </div>
                        <vias-line-chart
                            :labels="labels"
                            :datasets="mes.faixas"
                            unidade_datalabels="fluxo"
                            eixo_x="Horários"
                            eixo_y="Total de fluxo"
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasInputDate from "@/components/shared/ViasInputDate.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import moment from "moment";
import { cores } from "@/services/variables.service";
import {
    buscarRelatorioDeVolumeDiarioDeTrafego,
    emitirPdfDeVolumeDiarioDeTrafego,
} from "@/services/integrated-management.service";
import { open, close } from "@/services/notification.service";
import { ref } from "vue";
import { ordenarArrayCrescente } from "@/services/array.service";

const relatorio = ref(null);

const formatarNumero = (numero) =>
    numero < 10 ? `0${numero} horas` : `${numero} horas`;

const labels = ref(new Array(24).fill(0).map((dado, i) => formatarNumero(i)));

const data_busca = ref(moment().format("YYYY-MM-DDT00:00:00Z"));

const buscarRelatorio = () => {
    open("Buscando o relatório, aguarde um momento por favor...");
    buscarRelatorioDeVolumeDiarioDeTrafego(data_busca.value)
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = [];
            const coresEmArray = [
                cores.primary,
                cores.danger,
                cores.success,
                cores.warning,
            ];
            for (const [sitio, informacoes] of ordenarArrayCrescente(
                Object.entries(dados),
                "0",
            )) {
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    meses: [],
                };

                delete informacoes.endereco;

                for (const [mes, faixas] of ordenarArrayCrescente(
                    Object.entries(informacoes),
                    "0",
                )) {
                    const dadosDoMes = {
                        mes: moment(mes).format("MMMM, YYYY"),
                        faixas: [],
                    };

                    let i = 0;
                    for (const [nFaixa, passagens] of ordenarArrayCrescente(
                        Object.entries(faixas),
                        "0",
                    )) {
                        dadosDoMes.faixas.push({
                            label: `Faixa ${nFaixa}`,
                            data: Object.entries(passagens).reduce(
                                (resultado, passagem) => {
                                    const tempResultado = [passagem[1]];
                                    if (resultado.length < passagem[0]) {
                                        for (
                                            let i = 0;
                                            i < passagem[0] - resultado.length;
                                            i++
                                        ) {
                                            tempResultado.unshift(null);
                                        }
                                    }
                                    return resultado.concat(tempResultado);
                                },
                                [],
                            ),
                            backgroundColor: coresEmArray[i],
                            borderColor: coresEmArray[i],
                        });
                        i++;
                    }

                    equipamento.meses.push(dadosDoMes);
                }

                relatorio.value.push(equipamento);
            }
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDeVolumeDiarioDeTrafego(data_busca.value)
        .then((res) => res.data.mensagem)
        .then((mensagem) => {
            close(mensagem, "success");
        });
};
</script>
