<template>
    <ViasGoogleMapsPolygon
        v-for="area in areasCadastradas"
        :key="area.id"
        :vertices="area.vertices"
        :cor="area.cor"
    ></ViasGoogleMapsPolygon>
</template>

<script lang="ts" setup>
import { IArea } from "@/interfaces/mapa/areas/IArea";
import { BUSCAR_AREAS_CADASTRADAS } from "@/storeTs/configuracao/areas/type-actions";
import { onMounted, ref } from "vue";
import { useStore } from "@/storeTs";
import ViasGoogleMapsPolygon from "./ViasGoogleMapsPolygon.vue";

const store = useStore();

const areasCadastradas = ref<IArea[]>();

function buscarAreas() {
    store.dispatch(BUSCAR_AREAS_CADASTRADAS).then((res) => {
        areasCadastradas.value = res.data;
    });
}

onMounted(() => {
    buscarAreas();
});
</script>
