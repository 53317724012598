<template>

<ViasCard titulo="Infrações nas últimas 24 horas">
    <ViasLineChart
        :labels="horarios"
        :datasets="[dataset]"
        eixo_x="Horário"
        eixo_y="Total de infrações"
    />
</ViasCard>

</template>

<script lang="ts" setup>
import { DatasetGraficoDeLinha } from '@/components/shared/Graficos/GraficoDeLinha/models/DatasetGraficoDeLinha';
import ViasLineChart from '@/components/shared/ViasLineChart.vue';
import coresService from '@/core/composables/CoresService';
import datetimeService from '@/core/composables/DatetimeService';
import { RelatorioInfracoesNasUltimas24Horas } from '@/domains/InfracoesGerais/models/RelatorioInfracoesNasUltimas24Horas';
import infracoesGeraisService from '@/domains/InfracoesGerais/services/InfracoesGeraisService';
import { onMounted, ref, defineExpose } from 'vue';

const horarios = ref<string[]>([]);
const dataset = ref<DatasetGraficoDeLinha>({
    label: "Infrações",
    data: [],
    borderColor: coresService.listarCoresDisponiveis().danger,
    backgroundColor: coresService.listarCoresDisponiveis().danger
});
const relatorioDeInfracoesDasUltimas24Horas = ref<RelatorioInfracoesNasUltimas24Horas>({});

async function buscarInfracoesDasUltimas24Horas() {
    const { data } = await infracoesGeraisService.buscarRelatorioDeInfracoesNasUltimas24Horas();
    relatorioDeInfracoesDasUltimas24Horas.value = data;
    gerarOsHorariosDoGrafico();
    gerarOsValoresDoGrafico();
}

function gerarOsHorariosDoGrafico() {
    const datasDoRelatorio = Object.keys(relatorioDeInfracoesDasUltimas24Horas.value);
    horarios.value = datasDoRelatorio.map((data) => datetimeService.extrairHorarioDaData(data));
}

function gerarOsValoresDoGrafico() {
    dataset.value.data = Object.values(relatorioDeInfracoesDasUltimas24Horas.value);
}

onMounted(() => {
    buscarInfracoesDasUltimas24Horas();
});

function atualizarInformacoes() {
    buscarInfracoesDasUltimas24Horas();
}

defineExpose({
    atualizarInformacoes
});
</script>