<template>
    <div class="form-group" :class="hasClass">
        <label :for="hasId">{{ label }}</label>
        <select
            class="select-control"
            :class="[hasOptionSelected, isValid]"
            :id="hasId"
            @change="atualizarValor"
            v-model="value"
            :required="hasRequired"
        >
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :disabled="option.disabled"
            >
                {{ option.text }}
            </option>
        </select>
        <small v-if="hasDetail" :id="getDetailId" class="detail">{{
            detail
        }}</small>
    </div>
</template>

<script>
export default {
    name: "FotoSelect",
    props: [
        "options",
        "label",
        "id",
        "class",
        "modelValue",
        "required",
        "valid",
        "detail",
    ],
    emits: ["update:modelValue"],
    data: () => ({
        value: "",
    }),
    computed: {
        isValid() {
            if (this.valid === true) {
                return "valid";
            }
            if (this.valid === false) {
                return "invalid";
            }
            return "";
        },
        hasDetail() {
            return this.detail;
        },
        hasId() {
            return this.id ? this.id : `id-${Math.floor(Math.random() * 1000)}`;
        },
        hasRequired() {
            return typeof this.required !== "undefined";
        },
        hasOptionSelected() {
            return this.value !== undefined && this.value !== null
                ? "option-selected"
                : "";
        },
        hasClass() {
            if (this.class) {
                return [...this.class.split(" "), this.label ? "" : "no-label"];
            }
            return [this.label ? "" : "no-label"];
        },
    },
    methods: {
        atualizarValor() {
            this.$emit("update:modelValue", this.value);
        },
    },
    created() {
        this.value = this.modelValue;
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
};
</script>

<style></style>
