<script setup>
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import { store } from "@/storeTs";
import { defineProps, watchEffect, computed, ref } from "vue";
import { formatar } from "@/services/numbers.service";
import moment from "moment";
import ViasExplicacaoMedia from "@/components/shared/explicacoes/ViasExplicacaoMedia.vue";
import ViasExplicacaoMediana from "@/components/shared/explicacoes/ViasExplicacaoMediana.vue";
import ViasExplicacaoPercentil from "@/components/shared/explicacoes/ViasExplicacaoPercentil.vue";

const props = defineProps(["velocidade"]);

const theme = computed(() => store.state.theme);

const media = ref(0.0);

const mediana = ref(0.0);

const percentil = ref(0.0);

const labels = ref([]);

const datasetMedia = ref({
    label: "Média",
    data: [],
    borderColor: "#FF7782",
    backgroundColor: "#FF7782",
});

const datasetMediana = ref({
    label: "Mediana",
    data: [],
    borderColor: "#7380EC",
    backgroundColor: "#7380EC",
});

const datasetPercentil = ref({
    label: "Percentil",
    data: [],
    borderColor: "#52BE80",
    backgroundColor: "#52BE80",
});

watchEffect(() => {
    if (props.velocidade) {
        media.value = formatar(props.velocidade.media);
        mediana.value = formatar(props.velocidade.mediana);
        percentil.value = formatar(props.velocidade.percentil);

        labels.value = Object.keys(props.velocidade.grafico).map((key) =>
            moment(key).format("HH:mm:ss"),
        );

        datasetMedia.value.data = [];
        datasetMediana.value.data = [];
        datasetPercentil.value.data = [];

        Object.values(props.velocidade.grafico).map((value) => {
            datasetMedia.value.data.push(value.media);
            datasetMediana.value.data.push(value.mediana);
            datasetPercentil.value.data.push(value.percentil);
        });
    } else {
        media.value = 0;
        mediana.value = 0;
        percentil.value = 0;

        labels.value = [];

        datasetMedia.value.data = [];
        datasetMediana.value.data = [];
        datasetPercentil.value.data = [];
    }
});
</script>

<template>
    <div
        class="grafico-box d-flex flex-column border border-radius-1"
        :class="theme"
    >
        <h2 class="titulo text-center border-bottom p-2">Velocidade</h2>
        <div class="d-flex">
            <div class="d-flex flex-column w-100 w-lg-25 border">
                <h2 class="media border-bottom h-lg-33 p-4">
                    Média
                    <ViasExplicacaoMedia/>
                    <strong>{{ media }} km/h</strong>
                </h2>
                <h2 class="mediana border-bottom h-lg-33 p-4">
                    Mediana
                    <ViasExplicacaoMediana/>
                    <strong>{{ mediana }} km/h</strong>
                </h2>
                <h2 class="percentil h-lg-33 p-4">
                    Percentil
                    <ViasExplicacaoPercentil/>
                    <strong>{{ percentil }} km/h (1%)</strong>
                </h2>
            </div>
            <div
                class="grafico w-100 w-lg-75 d-flex align-items-center justify-content-center"
            >
                <vias-line-chart
                    class="w-100 h-100"
                    :labels="labels"
                    :datasets="[datasetMedia, datasetMediana, datasetPercentil]"
                    eixo_x="Horário"
                    eixo_y="Velocidade (km/h)"
                    :habilitar-apresentacao-total="false"
                ></vias-line-chart>
            </div>
        </div>
    </div>
</template>
