<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Nova rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDasRotas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <FormularioNovaRotaTempoDePercurso
                    @adicionar-rota="adicionarRota($event)"
                ></FormularioNovaRotaTempoDePercurso>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { useNotificacao } from "@/hooks/notificacao.hook";
import { useStore } from "@/storeTs";
import { ADICIONAR_ROTA_TEMPO_DE_PERCURSO } from "@/storeTs/tempo-de-percurso/configuracao/type-actions";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { INovaRotaTempoDePercurso } from "./interfaces/INovaRotaTempoDePercurso";
import FormularioNovaRotaTempoDePercurso from "./components/FormularioNovaRotaTempoDePercurso.vue";

export default defineComponent({
    name: "NovaRotaTempoDePercursoView",
    components: {
        FormularioNovaRotaTempoDePercurso,
    },
    methods: {
        voltarParaAApresentacaoDasRotas() {
            this.router.push("/tempo-de-percurso/configuracao");
        },
        adicionarRota(novaRota: INovaRotaTempoDePercurso) {
            this.notificarCarregamento(
                "Estamos enviando os dados para adicionar a nova rota, aguarde um momento por favor...",
            );
            this.store
                .dispatch(ADICIONAR_ROTA_TEMPO_DE_PERCURSO, novaRota)
                .then((res) => {
                    if (res.status === 201) {
                        this.notificar(
                            "Rota adicionada com sucesso!",
                            "success",
                        );
                        this.voltarParaAApresentacaoDasRotas();
                    }
                });
        },
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            store,
            router,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
