<template>

<ViasRow>
    <ViasCol :cols="12" class="d-flex align-items-center gap-3">
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('completo')"
            >Contrato</vias-button
        >
        <font-awesome-icon
            icon="fas fa-angle-right"
            v-if="buscaPorAno"
        />
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('ano')"
            v-if="buscaPorAno"
            >{{ anoDaBusca }}</vias-button
        >
        <font-awesome-icon
            icon="fas fa-angle-right"
            v-if="buscaPorMes"
        />
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('mes')"
            v-if="buscaPorMes"
            >{{ mesDaBusca }}</vias-button
        >
        <font-awesome-icon
            icon="fas fa-angle-right"
            v-if="buscaPorSemana"
        />
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('semana')"
            v-if="buscaPorSemana"
            >{{ semanaDaBusca }}</vias-button
        >
        <font-awesome-icon
            icon="fas fa-angle-right"
            v-if="buscaPorDia"
        />
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('dia')"
            v-if="buscaPorDia"
            >{{ diaDaBusca }}</vias-button
        >
        <font-awesome-icon
            icon="fas fa-angle-right"
            v-if="buscaPorHora"
        />
        <vias-button
            variant="link"
            @click="alterarFormatoDeVisualizacao('hora')"
            v-if="buscaPorHora"
            >{{ horaDaBusca }}</vias-button
        >
    </ViasCol>
</ViasRow>

</template>

<script lang="ts" setup>
import datetimeService from "@/core/composables/DatetimeService";
import { computed, defineProps, defineEmits } from "vue";
import { PeriodoDeRelatorio } from "../models/PeriodoDoRelatorioDeFluxoVeicular";

interface Props {
    periodoDeBusca: PeriodoDeRelatorio;
    dataHoraDaBusca: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    alterarFormatoDeVisualizacao: [periodo: PeriodoDeRelatorio]
}>();

const buscaPorAno = computed(() => ["ano","mes", "semana", "dia", "hora"].includes(props.periodoDeBusca));
const buscaPorMes = computed(() => ["mes", "semana", "dia", "hora"].includes(props.periodoDeBusca));
const buscaPorSemana = computed(() => ["semana", "dia", "hora"].includes(props.periodoDeBusca));
const buscaPorDia = computed(() => ["dia", "hora"].includes(props.periodoDeBusca));
const buscaPorHora = computed(() => ["hora"].includes(props.periodoDeBusca));

const anoDaBusca = computed(() => datetimeService.translateDatetime(props.dataHoraDaBusca, "ano"));
const mesDaBusca = computed(() => datetimeService.translateDatetime(props.dataHoraDaBusca, "mes"));
const semanaDaBusca = computed(() => datetimeService.translateDatetime(props.dataHoraDaBusca, "semana"));
const diaDaBusca = computed(() => datetimeService.translateDatetime(props.dataHoraDaBusca, "dia"));
const horaDaBusca = computed(() => datetimeService.translateDatetime(props.dataHoraDaBusca, "hora"));

function alterarFormatoDeVisualizacao(periodo: PeriodoDeRelatorio) {
    emit("alterarFormatoDeVisualizacao", periodo);
}
</script>