import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

export const open = (title) => {
    Swal.fire({
        title: title,
        allowOutsideClick: false,
    });
    Swal.showLoading();
};

export const close = (title, icon = "success") => {
    Swal.close();
    Swal.fire({
        title: title,
        icon: icon,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
            toast
                .getElementsByClassName("swal2-title")[0]
                .setAttribute("data-cy", "popup-title");
        },
    });
};

export const question = (aviso) => {
    return new Promise((resolve) => {
        Swal.fire({
            title: "Aviso!",
            text: aviso,
            icon: "question",
            confirmButtonText: "Sim",
            denyButtonText: "Não",
            showDenyButton: true,
            didOpen(popup) {
                popup
                    .getElementsByClassName("swal2-confirm")[0]
                    .setAttribute("data-cy", "popup-confirm");
                popup
                    .getElementsByClassName("swal2-deny")[0]
                    .setAttribute("data-cy", "popup-deny");
                popup
                    .getElementsByClassName("swal2-cancel")[0]
                    .setAttribute("data-cy", "popup-cancel");
            },
        }).then((res) => {
            resolve(res.isConfirmed);
        });
    });
};
