<template>
    <div class="group-buttons">
        <vias-button
            v-for="(button, i) in buttons"
            :key="button"
            :variant="button.variant"
            :type="button.type"
            :active="buttonActive == i"
            @clickEvent="clickEvent(i)"
            block
            >{{ button.text }}</vias-button
        >
    </div>
</template>

<script>
import ViasButtonVue from "./ViasButton.vue";
export default {
    props: ["buttons", "buttonActive"],
    emits: ["buttonClick"],
    components: {
        "vias-button": ViasButtonVue,
    },
    methods: {
        clickEvent(i) {
            this.$emit("buttonClick", { button: this.buttons[i], index: i });
        },
    },
};
</script>

<style></style>
