import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mostrarBox)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "box-rota",
        style: _normalizeStyle({ bottom: _ctx.posicaoBox.bottom, left: _ctx.posicaoBox.left })
      }, [
        _renderSlot(_ctx.$slots, "box", {}, () => [
          _createElementVNode("div", { innerHTML: _ctx.box }, null, 8, _hoisted_1)
        ])
      ], 4))
    : _createCommentVNode("", true)
}