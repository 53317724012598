<template>
    <ViasCard>
        <template #header>
            <div class="flex justify-content-between align-items-center">
                <h2 class="title">Placas cadastradas</h2>
                <router-link
                    to="/configuracao-geral/whitelist/adicionar-placa"
                    class="btn btn-primary"
                >
                    Adicionar placa
                </router-link>
            </div>
        </template>
        <ViasTable
            class="mt-4"
            :colunas="colunasWhitelist"
            :linhas="linhasWhitelist"
            :carregando="primeiraBusca"
            com-opcoes
        >
            <template #opcoes="{ linha }">
                <div class="d-flex justify-content-end align-items-center">
                    <router-link
                        class="btn btn-outline-primary btn-sm m-2"
                        :to="'/configuracao-geral/whitelist/editar-placa/' + linha.id"
                    >
                        Editar
                    </router-link>
                    <ViasButton
                        variant="none-danger"
                        size="sm"
                        @click="removerPlaca(linha)"
                        >Remover</ViasButton
                    >
                </div>
            </template>
        </ViasTable>
    </ViasCard>
</template>

<script lang="ts" setup>
import { useNotificacao } from "@/hooks/notificacao.hook";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { IPlacaDaWhitelist } from "@/interfaces/whitelist/IPlacaDaWhitelist";
import { useStore } from "@/storeTs";
import {
    BUSCAR_PLACAS_DE_WHITELIST,
    REMOVER_PLACA_DE_WHITELIST,
} from "@/storeTs/configuracao/whitelist/type-actions";
import { onMounted, ref } from "vue";

const store = useStore();
const { notificar, notificarCarregamento, questionarAcao } = useNotificacao();

const colunasWhitelist = ref<IColunaTabela[]>([
    {
        titulo: "#",
        referencia: "numero_da_linha",
    },
    {
        titulo: "Placa",
        referencia: "placa",
    },
    {
        titulo: "Veículo",
        referencia: "veiculo",
    },
    {
        titulo: "Descrição",
        referencia: "descricao",
    },
]);
const linhasWhitelist = ref<any[]>([]);
const primeiraBusca = ref(true);

function buscarPlacas() {
    store
        .dispatch(BUSCAR_PLACAS_DE_WHITELIST)
        .then((res) => res.data)
        .then((placas: IPlacaDaWhitelist[]) => {
            linhasWhitelist.value = placas.map((placa, i) => ({
                ...placa,
                numero_da_linha: i + 1,
            }));
            primeiraBusca.value = false;
        });
}

function removerPlaca(placa: IPlacaDaWhitelist) {
    questionarAcao(
        `Deseja realmente remover a placa "${placa.placa}" da whitelist? Após fazer isso, não será possível recuperá-la.`,
    ).then((isConfirmado: boolean) => {
        if (isConfirmado) {
            notificarCarregamento(
                `Estamos removendo a placa "${placa.placa}" da whitelist, aguarde um momento por favor...`,
            );
            store.dispatch(REMOVER_PLACA_DE_WHITELIST, placa.id).then(() => {
                notificar(
                    `A placa "${placa.placa}" foi removida com sucesso!`,
                    "success",
                );
                buscarPlacas();
            });
        }
    });
}

onMounted(() => {
    buscarPlacas();
});
</script>
