<template>
    <form class="d-flex flex-column">
        <div class="row">
            <div class="col cols-12">
                <h3>Equipamentos estáticos</h3>
            </div>
        </div>
        <div class="row" v-if="habilitarFiltroDeDataDosEquipamentosEstaticos">
            <div class="col cols-12">
                <ViasInputDate
                    v-model="dataDaBusca"
                    label="Data dos equipamentos estáticos"
                    @change="atualizarDataDaBuscaNoStore()"
                />
            </div>
        </div>
        <div class="row">
            <div class="col cols-12">
                <ViasCheckbox
                    v-model="apresentarEquipamentosEstaticos"
                    after-text="Apresentar equipamentos estáticos"
                    @change="atualizarApresentarEquipamentosEstaticosNoStore()"
                    switch
                />
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import { useStore } from "@/storeTs";
import {
    SET_APRESENTAR_EQUIPAMENTOS_ESTATICOS,
    SET_DATA_BUSCA_EQUIPAMENTOS_ESTATICOS,
} from "@/storeTs/type-mutations";
import moment from "moment";
import { ref, withDefaults, defineProps } from "vue";
import ViasInputDate from "../ViasInputDate.vue";
import ViasCheckbox from "../ViasCheckbox.vue";

interface Props {
    habilitarFiltroDeDataDosEquipamentosEstaticos?: boolean;
}

withDefaults(defineProps<Props>(), {
    habilitarFiltroDeDataDosEquipamentosEstaticos: true
});

const store = useStore();

const dataDaBusca = ref(store.state.dataBuscaEquipamentosEstaticos);
const apresentarEquipamentosEstaticos = ref(
    store.state.apresentarEquipamentosEstaticos,
);

function atualizarDataDaBuscaNoStore() {
    store.commit(
        SET_DATA_BUSCA_EQUIPAMENTOS_ESTATICOS,
        moment(dataDaBusca.value).format("YYYY-MM-DD"),
    );
}

function atualizarApresentarEquipamentosEstaticosNoStore() {
    store.commit(
        SET_APRESENTAR_EQUIPAMENTOS_ESTATICOS,
        apresentarEquipamentosEstaticos.value,
    );
}
</script>
