<script setup>
import ViasModal from "../shared/ViasModal.vue";
import ViasInput from "../shared/ViasInput.vue";
import ViasButton from "../shared/ViasButton.vue";
import { defineProps, defineEmits, watch, ref } from "vue";
import Swal from "sweetalert2";
import { store } from "@/storeTs";
import { ALTERAR_SENHA_DO_USUARIO_ATUAL } from "@/store/tipo-acoes";
import { close, open } from "@/services/notification.service";

const props = defineProps(["modelValue"]);

const emits = defineEmits(["update:modelValue"]);

const mostrarModal = ref(false);

const senhaAtual = ref("");
const novaSenha = ref("");
const confirmarSenha = ref("");

const salvarNovaSenha = () => {
    if (novaSenha.value !== confirmarSenha.value) {
        Swal.fire({
            title: "Aviso!",
            text: "Sua nova senha deve ser igual a confirmação dela!",
            icon: "warning",
        });

        return;
    }

    open("Enviando os dados para atualização de sua senha, aguarde...");

    store
        .dispatch(ALTERAR_SENHA_DO_USUARIO_ATUAL, {
            senha_atual: senhaAtual.value,
            nova_senha: novaSenha.value,
            confirmar_senha: confirmarSenha.value,
        })
        .then(() => {
            close("Senha alterada com sucesso!", "success");
            emits("update:modelValue", false);
            reiniciarFormulario();
        });
};

const cancelarAlteracao = () => {
    reiniciarFormulario();
    emits("update:modelValue", false);
};

const reiniciarFormulario = () => {
    senhaAtual.value = "";
    novaSenha.value = "";
    confirmarSenha.value = "";
};

watch(props, () => {
    mostrarModal.value = props.modelValue;
});

watch(mostrarModal, () => {
    if (!mostrarModal.value) emits("update:modelValue", false);
});
</script>

<template>
    <vias-modal
        :show-button="false"
        v-model="mostrarModal"
        title="Alterar senha"
    >
        <form id="formulario-alterar-senha" @submit.prevent="salvarNovaSenha">
            <div class="row">
                <div class="col">
                    <vias-input
                        type="password"
                        label="Senha atual"
                        v-model="senhaAtual"
                        data-cy-input="update-password-input-actual-password"
                        required
                    ></vias-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-input
                        type="password"
                        label="Nova senha"
                        v-model="novaSenha"
                        data-cy-input="update-password-input-new-password"
                        required
                    ></vias-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-input
                        type="password"
                        label="Confirmar senha"
                        v-model="confirmarSenha"
                        data-cy-input="update-password-input-confirm-password"
                        required
                    ></vias-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-button
                        type="submit"
                        variant="primary"
                        data-cy="update-password-save"
                        >Salvar nova senha</vias-button
                    >
                    <vias-button
                        type="button"
                        @click="cancelarAlteracao"
                        data-cy="update-password-cancel"
                        variant="none-primary"
                        >Cancelar</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-modal>
</template>
