<template>
    <bar-chart
        ref="barChart"
        :chart-data="data"
        :options="options"
        class="bar-chart"
    ></bar-chart>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { BarChart } from "vue-chart-3";
import { mapState } from "vuex";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(...registerables, ChartDataLabels, zoomPlugin);
export default {
    name: "vias-bar-chart",
    props: {
        labels: {
            type: Array,
        },
        datasets: {
            type: Array,
        },
        eixo_x: {
            type: String,
        },
        eixo_y: {
            type: String,
        },
        optionsEixoX: {
            type: Object,
        },
        stepSizeX: {
            type: Number,
        },
        stacked: {
            type: Boolean,
        },
        apresentarDatalabels: {
            type: Boolean,
        },
        unidade_datalabels: {
            type: String,
        },
        habilitarApresentacaoTotal: {
            type: Boolean,
            default: true,
        },
        isGraficoAcumulado: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["alterarFormatoDeVisualizacao"],
    components: {
        "bar-chart": BarChart,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
    },
    data: () => ({
        data: {
            labels: [],
            datasets: [],
        },
        options: {},
    }),
    created() {
        this.verificarEAtualizarGrafico();
    },
    methods: {
        verificarEAtualizarGrafico() {
            this.data = {
                labels: this.verificarLabels(),
                datasets: this.verificarDatasets(),
            };

            this.options = {
                color:
                    this.theme == "dark-theme"
                        ? this.$variaveis.cores.background
                        : this.$variaveis.cores.dark,
                scales: {
                    x: {
                        ...this.optionsEixoX,
                        stacked: !!this.stacked,
                        ticks: {
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                            stepSize: this.stepSizeX || 0,
                        },
                        title: {
                            display: typeof this.eixo_x == "string",
                            text: this.habilitarApresentacaoTotal
                                ? `${this.eixo_x} - Total: ${this.calcularTotalDasColunas()}`
                                : this.eixo_x,
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                    },
                    y: {
                        stacked: !!this.stacked,
                        ticks: {
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                        title: {
                            display: typeof this.eixo_y == "string",
                            text: this.eixo_y,
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                        min: 0,
                    },
                },
                plugins: {
                    datalabels: {  
                        formatter: (value) => {
                            if (this.unidade_datalabels) {
                                return `${this.$numero.formatar(value)} ${this.unidade_datalabels}`
                            }
                            return this.$numero.formatar(value);
                        },
                        font: (context) => {
                            var w = context.chart.width;
                            return {
                                size: w < 512 ? 12 : 14,
                                weight: "bold",
                            };
                        },
                        align: "end",
                        anchor: "end",
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true,
                            },
                            drag: {
                                enabled: true,
                            },
                            mode: "x",
                        },
                    },
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {
                        const index = elements[0].index;
                        this.$emit("alterarFormatoDeVisualizacao", index);
                    }
                },
            };

            if (this.$refs.barChart) {
                this.$refs.barChart.chartInstance.data = this.data;
                this.$refs.barChart.update();
            }
        },
        verificarLabels() {
            return this.labels != null ? this.labels : [];
        },
        verificarDatasets() {
            return this.datasets != null && this.datasets.length > 0
                ? this.tratarDatalabelDosDatasets()
                : [];
        },
        tratarDatalabelDosDatasets() {
            const newDatasets = [];
            this.datasets.forEach((dataset) => {
                newDatasets.push({
                    ...dataset,
                    backgroundColor: this.hexToRgbA(
                        dataset.backgroundColor,
                        0.7,
                    ),
                    borderColor: dataset.backgroundColor,
                    borderWidth: 2,
                    datalabels: {
                        display:
                            typeof this.$props.apresentarDatalabels ===
                            "boolean"
                                ? this.$props.apresentarDatalabels
                                : true,
                        color: dataset.backgroundColor,
                    },
                });
            });

            return newDatasets;
        },
        calcularTotalDasColunas() {
            let total = 0;
            if (this.isGraficoAcumulado) {
                this.data.datasets.forEach((dataset) => {
                    const dataDataset = dataset.data;
                    total += dataDataset[dataDataset.length - 1];
                });
            } else {
                this.data.datasets.forEach((dataset) => {
                    dataset.data.forEach((quantidade) => {
                        total += quantidade;
                    });
                });
            }
            return this.$numero.formatar(total);
        },
        hexToRgbA(hex, alpha = 1) {
            var c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    "," +
                    alpha +
                    ")"
                );
            }
            throw new Error("Bad Hex");
        },
    },
    watch: {
        labels() {
            this.verificarEAtualizarGrafico();
        },
        datasets() {
            this.verificarEAtualizarGrafico();
        },
        theme() {
            this.verificarEAtualizarGrafico();
        },
        eixo_x() {
            this.verificarEAtualizarGrafico();
        },
        eixo_y() {
            this.verificarEAtualizarGrafico();
        },
        apresentarDatalabels() {
            this.verificarEAtualizarGrafico();
        },
        stacked() {
            this.verificarEAtualizarGrafico();
        },
    },
};
</script>
