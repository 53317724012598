<template>
    <div class="row">
        <div class="col">
            <div class="form-group mb-0">
                <label :for="id">{{ label }}</label>
                <datepicker
                    :id="id"
                    class="w-100"
                    v-model="value"
                    :dark="theme"
                    @update:model-value="atualizarData"
                    format="dd/MM/yyyy, HH:mm"
                    :disabled="disabled"
                    locale="pt-BR"
                    select-text="OK"
                    cancel-text="Cancelar"
                    month-name-format="long"
                    auto-apply
                    week-start="0"
                    week-numbers="iso"
                    week-num-name="sem"
                ></datepicker>
            </div>
        </div>
    </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
    name: "FotoInputDateAndTime",
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: `id-${Math.floor(Math.random() * 1000)}`,
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Datepicker,
    },
    data: () => ({
        value: "",
    }),
    computed: {
        theme() {
            return this.$store.state.theme === "dark-theme";
        },
    },
    methods: {
        atualizarData() {
            this.$emit(
                "update:modelValue",
                this.value
                    ? moment(this.value).format("YYYY-MM-DDTHH:mm:ssZ")
                    : null,
            );
            this.$emit(
                "change",
                this.value
                    ? moment(this.value).format("YYYY-MM-DDTHH:mm:ssZ")
                    : null,
            );
        },
    },
    created() {
        this.value = this.modelValue;
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
};
</script>
