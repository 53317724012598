<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-date
                        label="Data da busca"
                        v-model="data_busca"
                    ></vias-input-date>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button variant="primary" type="submit"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div v-if="mostrar_relatorio">
        <vias-card column class="mt-4">
            <vias-button
                variant="outline-primary"
                type="button"
                @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>

        <vias-card
            column
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div class="grafico-de-relatorio p-6">
                <vias-line-chart
                    :labels="labels"
                    :datasets="[equipamento.dataset]"
                    eixo_x="Horários"
                    eixo_y="Total de infrações"
                ></vias-line-chart>
            </div>
        </vias-card>
    </div>
</template>

<script>
import ViasInputDate from "@/components/shared/ViasInputDate.vue";
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import moment from "moment";
import {
    buscarRelatorioDeTotalDeOcorrenciasPorEquipamento,
    emitirPdfDeRelatorioDeTotalDeOcorrenciasPorEquipamento,
} from "@/services/integrated-management.service";
import { close, open } from "@/services/notification.service";
import { cores } from "@/services/variables.service";

export default {
    components: {
        ViasInputDate,
        ViasCard,
        ViasButton,
        ViasLineChart,
    },
    data: () => ({
        data_busca: moment().format("YYYY-MM-DDTHH:mm:ss"),
        data_busca_realizada: null,
        mostrar_relatorio: false,
        relatorio: null,
        labels: [],
    }),
    methods: {
        buscarRelatorio() {
            open("Buscando relatório, por favor, aguarde um momento...");
            this.data_busca_realizada = this.data_busca;
            buscarRelatorioDeTotalDeOcorrenciasPorEquipamento(this.data_busca)
                .then((res) => res.data)
                .then((dados) => {
                    this.relatorio = [];
                    let setarLabels = true;
                    for (const [sitio, informacoes] of Object.entries(dados)) {
                        if (setarLabels) {
                            setarLabels = false;
                            this.labels = Object.keys(informacoes.grafico).map(
                                (horario) => moment(horario).format("HH"),
                            );
                        }

                        this.relatorio.push({
                            sitio,
                            endereco: informacoes.endereco,
                            dataset: {
                                label: "Infrações",
                                data: Object.values(informacoes.grafico),
                                backgroundColor: cores.danger,
                                borderColor: cores.danger,
                            },
                        });
                    }
                })
                .then(() => {
                    this.mostrar_relatorio = true;
                    close("Procedimento finalizado!");
                });
        },
        emitirPdf() {
            open("Emitindo PDF do relatório, por favor, aguarde um momento...");
            emitirPdfDeRelatorioDeTotalDeOcorrenciasPorEquipamento(
                this.data_busca_realizada,
            )
                .then((res) => res.data)
                .then(({ mensagem }) => {
                    close(mensagem);
                });
        },
    },
};
</script>
