<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "fathers-view",
};
</script>

<style></style>
