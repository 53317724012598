<template>

<form @submit.prevent="agendarRelatorioDeOrigemEDestino()">
    <FormularioAgendamentoRelatorio v-model="formulario"/>

    <hr class="mb-4">
    
    <ViasRow class="mb-4">
        <ViasCol :cols="12">
            <h2>Informações do relatório</h2>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol>
            <div class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    :rotas="[getCoordenadasRota]"
                    :idsEquipamentosSelecionados="
                        [formulario.parametros.origem_id, formulario.parametros.destino_id]
                    "
                    @click-equipamento="selecionarEquipamento($event)"
                />
            </div>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Selecionar rota já cadastrada"
                v-model="rotaCadastradaSelecionada"
                :options="opcoesRotasCadastradas"
                @change="atualizarEquipamentosComRotaSelecionada()"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <HorariosSelect
                v-model="horarioSelecionado"
                @change="atualizarValoresDeHorarioInicialEFinal($event)"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="6">
            <ViasInputTime
                label="Horário inicial"
                v-model="formulario.parametros.data_inicial"
                @change="removerSelecaoDoHorarioPredefinido()"
            />
        </ViasCol>
        <ViasCol :cols="6">
            <ViasInputTime
                label="Horário final"
                v-model="formulario.parametros.data_final"
                @change="removerSelecaoDoHorarioPredefinido()"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasButton variant="primary">Agendar</ViasButton>
        </ViasCol>
    </ViasRow>
</form>

</template>

<script lang="ts" setup>
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import ViasInputTime from "@/components/shared/ViasInputTime.vue";
import ViasVueSelect from "@/components/shared/ViasVueSelect.vue";
import FormularioAgendamentoRelatorio from "@/domains/AgendamentoDeRelatorio/components/FormularioAgendamentoRelatorio.vue";
import { EquipamentoComPosicao } from "@/domains/Equipamentos/models/EquipamentoComPosicao";
import HorariosSelect from "@/domains/Horarios/components/HorariosSelect.vue";
import notificacaoService from "@/domains/Notificacao/services/notificacaoService";
import { ParametrosAgendamentoParaRelatorioOrigemEDestino } from "@/domains/OrigemEDestino/models/ParametrosAgendamentoParaRelatorioOrigemEDestino";
import origemEDestinoService from "@/domains/OrigemEDestino/services/OrigemEDestinoService";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { ISelectOption } from "@/interfaces/select/ISelectOption";
import { IRotaOrigemEDestino } from "@/views/OrigemEDestino/interfaces/IRotaOrigemEDestino";
import moment from "moment";
import { computed, ref, defineEmits, onMounted } from "vue";

const emit = defineEmits<{
    relatorioAgendado: []
}>();

const formulario = ref<ParametrosAgendamentoParaRelatorioOrigemEDestino>({
    diario: true,
    dia_da_semana: null,
    dia_do_mes: null,
    hora: moment().format("HH:mm:ss"),
    relatorio: "Relatório de Origem e Destino",
    operando: 0,
    atributo_de_calculo: "days",
    parametros: {
        origem_id: 0,
        destino_id: 0,
        data_inicial: "00:00:00",
        data_final: moment().format("HH:mm:ss")
    }
});

const origem = ref<EquipamentoComPosicao | IEquipamento | null>();
const destino = ref<EquipamentoComPosicao | IEquipamento | null>();

const rotasCadastradas = ref<IRotaOrigemEDestino[]>([]);
const opcoesRotasCadastradas = ref<ISelectOption[]>([]);
const rotaCadastradaSelecionada = ref<number | null>(null);

const horarioSelecionado = ref(0);

const getCoordenadasRota = computed(() => {
    if (origem.value && destino.value) {
        return {
            id: 1,
            inicio: {
                lat: origem.value.posicao.latitude,
                lng: origem.value.posicao.longitude,
            },
            fim: {
                lat: destino.value.posicao.latitude,
                lng: destino.value.posicao.longitude,
            },
        };
    }
    return {
        id: 1,
    };
});

function selecionarEquipamento(equipamento: EquipamentoComPosicao | IEquipamento) {
    if (equipamento.id === origem.value?.id) {
        notificacaoService.questionarAcao(
            `O equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}", já está selecionado como origem,
            deseja remover essa seleção?`,
        ).then((isConfirmado) => {
            if (isConfirmado) {
                origem.value = null;
                formulario.value.parametros.origem_id = 0;
                rotaCadastradaSelecionada.value = null;
            }
        });
        return;
    }
    if (equipamento.id === destino.value?.id) {
        notificacaoService.questionarAcao(
            `O equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}", já está selecionado como destino,
            deseja remover essa seleção?`,
        ).then((isConfirmado) => {
            if (isConfirmado) {
                destino.value = null;
                formulario.value.parametros.destino_id = 0;
                rotaCadastradaSelecionada.value = null;
            }
        });
        return;
    }
    notificacaoService.apresentarOpcoesDeEscolha(
        `Deseja selecionar o equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}",
        como origem ou como destino?`,
        "Origem",
        "Destino",
    ).then((opcaoSelecionada) => {
        if (opcaoSelecionada) {
            if (opcaoSelecionada === "Origem") {
                selecionarEquipamentoComoOrigem(equipamento);
            }
            if (opcaoSelecionada === "Destino") {
                selecionarEquipamentoComoDestino(equipamento);
            }
        }
    });
}

function selecionarEquipamentoComoOrigem(equipamento: EquipamentoComPosicao | IEquipamento) {
    origem.value = equipamento;
    formulario.value.parametros.origem_id = equipamento.id;
    rotaCadastradaSelecionada.value = null;
}

function selecionarEquipamentoComoDestino(equipamento: EquipamentoComPosicao | IEquipamento) {
    destino.value = equipamento;
    formulario.value.parametros.destino_id = equipamento.id;
    rotaCadastradaSelecionada.value = null;
}

function atualizarEquipamentosComRotaSelecionada(): void {
    const rota = rotasCadastradas.value.find((r) => r.id === rotaCadastradaSelecionada.value);
    if (rota) {
        origem.value = rota.primeiro_equipamento.equipamento;
        formulario.value.parametros.origem_id = origem.value.id;

        destino.value = rota.segundo_equipamento.equipamento;
        formulario.value.parametros.destino_id = destino.value.id;
    }
}

function atualizarValoresDeHorarioInicialEFinal(horarios: string[]) {
    formulario.value.parametros.data_inicial = horarios[0];
    formulario.value.parametros.data_final = horarios[1];
}

function removerSelecaoDoHorarioPredefinido(): void {
    horarioSelecionado.value = 0;
}

async function buscarRotasDeOrigemEDestino(): Promise<void> {
    const { data } = await origemEDestinoService.buscarRotas();
    rotasCadastradas.value = data;
    opcoesRotasCadastradas.value = data.map((rota) => ({
        text: rota.nome,
        value: rota.id
    }));
}

async function agendarRelatorioDeOrigemEDestino(): Promise<void> {
    notificacaoService.notificarCarregamento(
        "Estamos agendando a emissão do relatório, aguarde um momento por favor..."
    );
    await origemEDestinoService.agendarEmissaoDoPdfDoRelatorioDeFluxoVeicular(formulario.value);
    notificacaoService.notificarSucesso("Relatório agendado com sucesso!");
    emit("relatorioAgendado");
    reiniciarFormulario();
}

function reiniciarFormulario(): void {
    formulario.value = {
        diario: false,
        dia_da_semana: null,
        dia_do_mes: null,
        hora: moment().format("HH:mm:ss"),
        relatorio: "Relatório de Origem e Destino",
        operando: 0,
        atributo_de_calculo: "days",
        parametros: {
            origem_id: 0,
            destino_id: 0,
            data_inicial: "00:00:00",
            data_final: moment().format("HH:mm:ss")
        }
    };
}

onMounted(() => {
    buscarRotasDeOrigemEDestino();
});
</script>