<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-month-and-year
                        v-model="data_busca"
                        :max="moment().format('YYYY-MM-DDT00:00:00Z')"
                        label="Data da busca"
                    ></vias-input-month-and-year>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button type="submit" variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div id="relatorio-de-excesso-de-velocidade" v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div class="row">
                <div class="col col-12">
                    <div class="grafico-de-relatorio">
                        <div class="cabecalho">
                            <h3 class="text-capitalize">
                                {{ mesDaBuscaRealizada }}
                            </h3>
                        </div>
                        <vias-line-chart
                            :labels="labels"
                            :datasets="[equipamento.dataset]"
                            eixo_x="Dias"
                            eixo_y="Porcentagem (%)"
                            :apresentar-legenda="true"
                            unidade_datalabels="%"
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col col-12">
                    <div class="bloco-tabela overflow-x-auto pb-2">
                        <div class="divisao-tabela">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th
                                            v-for="coluna in colunas"
                                            :key="equipamento + coluna"
                                        >
                                            {{ coluna }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="linha in equipamento.tabela"
                                        :key="linha"
                                    >
                                        <td
                                            v-for="celula in linha"
                                            :key="`coluna-` + celula"
                                        >
                                            {{ celula }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script>
import ViasInputMonthAndYear from "@/components/shared/ViasInputMonthAndYear.vue";
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import moment from "moment";
import { close, open } from "@/services/notification.service";
import {
    buscarRelatorioDeImpacto,
    emitirPdfDeRelatorioDeImpacto,
} from "@/services/integrated-management.service";
import { cores } from "@/services/variables.service";

export default {
    components: {
        ViasInputMonthAndYear,
        ViasCard,
        ViasButton,
        ViasLineChart,
    },
    data: () => ({
        data_busca: moment().format("YYYY-MM-DDTHH:mm:ss"),
        data_busca_realizada: null,
        moment,
        relatorio: null,
        labels: [],
        colunas: [],
    }),
    computed: {
        mesDaBuscaRealizada() {
            if (this.data_busca_realizada) {
                return moment(this.data_busca_realizada).format("MMMM, YYYY");
            }
            return "";
        },
    },
    methods: {
        buscarRelatorio() {
            open("Buscando relatório, por favor aguarde um momento...");
            this.data_busca_realizada = this.data_busca;
            buscarRelatorioDeImpacto(this.data_busca)
                .then((res) => res.data)
                .then((dados) => {
                    this.relatorio = [];
                    this.colunas = [];
                    this.labels = [];
                    let setarLabelsEColunas = true;
                    for (const [sitio, informacoes] of Object.entries(dados)) {
                        const equipamento = {
                            sitio,
                            endereco: informacoes.endereco,
                            porcentagens: [],
                            tabela: [],
                        };

                        if (setarLabelsEColunas) {
                            setarLabelsEColunas = false;
                            this.colunas = this.labels = Object.keys(
                                informacoes.grafico,
                            ).map((dia) => moment(dia).format("DD"));
                        }
                        const valores = Object.values(informacoes.grafico);

                        equipamento.dataset = {
                            label: "Total de infrações (%)",
                            data: valores.map((valor) => valor.porcentagem),
                            backgroundColor: cores.primary,
                            borderColor: cores.primary,
                        };

                        equipamento.tabela = valores.reduce(
                            (temp, valor) => {
                                temp[0].push(
                                    this.$numero.formatar(valor.porcentagem) +
                                        "%",
                                );
                                temp[1].push(
                                    this.$numero.formatar(valor.fluxo),
                                );
                                temp[2].push(
                                    this.$numero.formatar(valor.infracao),
                                );
                                return temp;
                            },
                            [["Porcentagem"], ["Fluxo"], ["Infração"]],
                        );

                        this.relatorio.push(equipamento);
                    }
                })
                .then(() => {
                    close("Procedimento finalizado!", "success");
                });
        },
        emitirPdf() {
            open("Buscando relatório, por favor aguarde um momento...");
            emitirPdfDeRelatorioDeImpacto(this.data_busca_realizada)
                .then((res) => res.data.mensagem)
                .then((mensagem) => {
                    close(mensagem, "success");
                });
        },
    },
};
</script>
