<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template v-slot:header>
                    <div class="row row-between">
                        <h2 class="card-title">Editar grupo</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaApresentacaoDeGruposCadastrados()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>

                <ViasRow>
                    <ViasCol>
                        <FormularioEditarGrupo
                            :grupo-selecionado="grupoSelecionado"
                            @salvar-grupo="salvarGrupo($event)"
                        ></FormularioEditarGrupo>
                    </ViasCol>
                </ViasRow>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { useStore } from "@/storeTs";
import {
    BUSCAR_GRUPO_POR_ID,
    EDITAR_GRUPO,
} from "@/storeTs/gestao-integrada/grupos/actions-type";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { IGrupoParaEdicao } from "./interfaces/IGrupoParaEdicao";
import FormularioEditarGrupo from "./components/FormularioEditarGrupo.vue";
import { useNotificacao } from "@/hooks/notificacao.hook";

export default defineComponent({
    name: "EditarGrupoView",
    components: {
        FormularioEditarGrupo,
    },
    data: () => ({
        idGrupo: 0,
        grupoSelecionado: null as IGrupoParaEdicao | null,
    }),
    methods: {
        buscarGrupo() {
            this.store
                .dispatch(BUSCAR_GRUPO_POR_ID, this.idGrupo)
                .then((res) => {
                    this.grupoSelecionado = {
                        id: res.data.id,
                        nome: res.data.nome,
                        afericoes: res.data.afericoes.map(
                            (afericao) => afericao.id,
                        ),
                    };
                })
                .catch(() => {
                    this.voltarParaApresentacaoDeGruposCadastrados();
                });
        },
        voltarParaApresentacaoDeGruposCadastrados() {
            this.router.push("/gestao-integrada/grupos");
        },
        salvarGrupo(grupoEditado: IGrupoParaEdicao) {
            grupoEditado.id = this.idGrupo;

            this.notificarCarregamento(
                `Estamos enviando dados de atualização do grupo "${grupoEditado.nome}", aguarde um momento por favor...`,
            );
            this.store.dispatch(EDITAR_GRUPO, grupoEditado).then((res) => {
                if (res.status === 204) {
                    this.voltarParaApresentacaoDeGruposCadastrados();
                    this.notificar(
                        `O grupo "${grupoEditado.nome}" foi salvo com sucesso!`,
                        "success",
                    );
                }
            });
        },
    },
    created() {
        try {
            this.idGrupo = parseInt(
                this.router.currentRoute.value.params.id.toString(),
            );
            this.buscarGrupo();
        } catch {
            this.voltarParaApresentacaoDeGruposCadastrados();
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
