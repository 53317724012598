<template>
    <ViasSelect
        label="Selecionar horário"
        :options="horariosOptions"
        v-model="horarioSelecionado"
        @change="enviarHorario()"
    />
</template>

<script lang="ts" setup>
import horariosService from '../services/HorariosService';
import { onMounted, ref, defineEmits, defineModel } from 'vue';
import { Horario } from '../models/Horario';
import { ISelectOption } from '@/interfaces/select/ISelectOption';

const emit = defineEmits<{
    change: [rangeDeHorario: string[]]
}>();

let horariosCadastrados: Horario[] = [];
const horariosOptions = ref<ISelectOption[]>([]);
const horarioSelecionado = defineModel({ default: 0, required: false, type: Number });

async function buscarHorarios() {
    const response = await horariosService.buscarHorarios();
    horariosCadastrados = response.data;
    horariosOptions.value = horariosCadastrados.map((horario) => ({
        text: horario.nome,
        value: horario.id
    }));
}

function enviarHorario() {
    if (!horarioSelecionado.value) {
        return;
    }

    const horario = horariosCadastrados.find((horario) => horario.id === horarioSelecionado.value);

    if (!horario) {
        return;
    }

    emit("change", [horario.inicial, horario.final]);
}

onMounted(() => {
    buscarHorarios();
});

</script>