<template>
    <div class="col" :class="[isCol, isSM, isMD, isLG, isXL, isXXL]">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ViasColComponent",
    props: {
        cols: {
            type: Number,
            required: false,
        },
        sm: {
            type: Number,
            required: false,
        },
        md: {
            type: Number,
            required: false,
        },
        lg: {
            type: Number,
            required: false,
        },
        xl: {
            type: Number,
            required: false,
        },
        xxl: {
            type: Number,
            required: false,
        },
    },
    computed: {
        isCol(): string {
            if (this.cols) {
                return `col-${this.cols}`;
            }
            return "";
        },
        isSM(): string {
            if (this.sm) {
                return `col-sm-${this.sm}`;
            }
            return "";
        },
        isMD(): string {
            if (this.md) {
                return `col-md-${this.md}`;
            }
            return "";
        },
        isLG(): string {
            if (this.lg) {
                return `col-lg-${this.lg}`;
            }
            return "";
        },
        isXL(): string {
            if (this.xl) {
                return `col-xl-${this.xl}`;
            }
            return "";
        },
        isXXL(): string {
            if (this.xxl) {
                return `col-xxl-${this.xxl}`;
            }
            return "";
        },
    },
});
</script>
