import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 h-100 border border-radius p-4" }
const _hoisted_2 = { class: "cabecalho-equipamento" }
const _hoisted_3 = { class: "default-checkbox" }
const _hoisted_4 = { class: "input-checkbox" }
const _hoisted_5 = { class: "input-label" }
const _hoisted_6 = { class: "d-flex w-100" }
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "input-checkbox" }
const _hoisted_10 = { class: "input-label" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "input-checkbox" }
const _hoisted_14 = { class: "input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.afericaoSelecionada) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alterarSelecaoDeTodasAsFaixas()))
        }, null, 544), [
          [_vModelCheckbox, _ctx.afericaoSelecionada]
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            icon: "fas fa-check",
            class: "check"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.afericao.sitio.toUpperCase()), 1),
          _createElementVNode("small", null, _toDisplayString(_ctx.primeiroGrupo[0]?.endereco || "-"), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primeiroGrupo, (afericao) => {
          return (_openBlock(), _createElementBlock("label", {
            class: "default-checkbox mt-2",
            key: afericao.id
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.faixasSelecionadas) = $event)),
              value: afericao.id
            }, null, 8, _hoisted_8), [
              [_vModelCheckbox, _ctx.faixasSelecionadas]
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fas fa-check",
                class: "check"
              })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h3", null, "Faixa " + _toDisplayString(afericao.faixa), 1),
              _createElementVNode("small", null, "Sentido: " + _toDisplayString(afericao.sentido), 1)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segundoGrupo, (afericao) => {
          return (_openBlock(), _createElementBlock("label", {
            class: "default-checkbox mt-2",
            key: afericao.id
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.faixasSelecionadas) = $event)),
              value: afericao.id
            }, null, 8, _hoisted_12), [
              [_vModelCheckbox, _ctx.faixasSelecionadas]
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fas fa-check",
                class: "check"
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("h3", null, "Faixa " + _toDisplayString(afericao.faixa), 1),
              _createElementVNode("small", null, "Sentido: " + _toDisplayString(afericao.sentido), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}