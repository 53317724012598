<script setup>
import moment from "moment";
import { computed, defineProps } from "vue";

const props = defineProps(["equipamento"]);

const situacao = computed(() => {
    if (props.equipamento) {
        if (props.equipamento.status) {
            if (props.equipamento.ultima_passagem) {
                let now = moment();
                let ultimaPassagem = moment(props.equipamento.ultima_passagem);
                let diff = now.diff(ultimaPassagem, "minutes");
                if (diff > 15) {
                    return "Sem fluxo recente";
                }
                return "Online";
            }
            return "Nunca usado (sem fluxo registrado)";
        }
        return "Offline";
    }
    return "Não informado";
});

const corSituacao = computed(() => {
    if (props.equipamento) {
        if (props.equipamento.status) {
            if (props.equipamento.ultima_passagem) {
                let now = moment();
                let ultimaPassagem = moment(props.equipamento.ultima_passagem);
                let diff = now.diff(ultimaPassagem, "minutes");
                if (diff > 15) {
                    return "text-warning";
                }
                return "text-success";
            }
            return "text-danger";
        }
        return "text-secondary";
    }
    return "text-secondary";
});
</script>

<template>
    <div v-if="props.equipamento" class="pl-1 pr-1">
        <h1 class="mb-2">
            Equipamento {{ equipamento.sitio }} -
            <span :class="corSituacao">{{ situacao }}</span>
        </h1>
        <h2>{{ equipamento.endereco }}</h2>
        <table class="table table-bordered mt-4">
            <tbody>
                <tr>
                    <td>Tipo</td>
                    <td>{{ equipamento.tipo }}</td>
                </tr>
                <tr>
                    <td>Modelo</td>
                    <td>{{ equipamento.equipamento_fisico.modelo || "-" }}</td>
                </tr>
                <tr>
                    <td>Marca</td>
                    <td>{{ equipamento.equipamento_fisico.marca || "-" }}</td>
                </tr>
                <tr>
                    <td>Número de Série</td>
                    <td>
                        {{ equipamento.equipamento_fisico.numero_serie || "-" }}
                    </td>
                </tr>
                <tr>
                    <td>Portaria do Inmetro</td>
                    <td>
                        {{
                            equipamento.equipamento_fisico.portaria_inmetro ||
                            "-"
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Observação de Monitoramento</td>
                    <td>
                        {{
                            equipamento.equipamento_fisico
                                .observacao_monitoramento || "-"
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Último fluxo</td>
                    <td>
                        {{
                            moment(equipamento.ultima_passagem).format(
                                "DD/MM/YYYY HH:mm:ss",
                            )
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Latitude</td>
                    <td>{{ equipamento.posicao.latitude }}</td>
                </tr>
                <tr>
                    <td>Longitude</td>
                    <td>{{ equipamento.posicao.longitude }}</td>
                </tr>
            </tbody>
        </table>

        <h2 class="mb-2 mt-4">Faixas</h2>
        <table class="table table-bordered mt-4">
            <thead>
                <tr>
                    <th>Faixa</th>
                    <th>Endereço</th>
                    <th>Sentido</th>
                    <th>Velocidade limite</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="afericao in props.equipamento.afericoes"
                    :key="afericao.id"
                >
                    <td>{{ afericao.faixa }}</td>
                    <td>{{ afericao.endereco }}</td>
                    <td>{{ afericao.sentido }}</td>
                    <td>{{ afericao.velocidade_limite }} km/h</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
