import http from "@/http";
import { RelatorioInfracoesNasUltimas24Horas } from "../models/RelatorioInfracoesNasUltimas24Horas";
import { AxiosResponse } from "axios";

class InfracoesGeraisService {

    private rotaBase = "/vias/v1/graficos/infracao/gerais";

    buscarRelatorioDeInfracoesNasUltimas24Horas():
        Promise<AxiosResponse<RelatorioInfracoesNasUltimas24Horas>> {
        return http.get(`${this.rotaBase}/vinte-quatro`);
    }

}

const infracoesGeraisService = new InfracoesGeraisService();

export default infracoesGeraisService;