export const BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS =
    "BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS";
export const BUSCAR_ROTAS_TEMPO_DE_PERCURSO = "BUSCAR_ROTAS_TEMPO_DE_PERCURSO";
export const BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO =
    "BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO";
export const ADICIONAR_ROTA_TEMPO_DE_PERCURSO =
    "ADICIONAR_ROTA_TEMPO_DE_PERCURSO";
export const REMOVER_ROTA_TEMPO_DE_PERCURSO = "REMOVER_ROTA_TEMPO_DE_PERCURSO";
export const ATUALIZAR_ROTA_TEMPO_DE_PERCURSO =
    "ATUALIZAR_ROTA_TEMPO_DE_PERCURSO";
export const VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO =
    "VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO";
