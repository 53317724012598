<template>
    <div class="form-group datepicker">
        <label :for="id">Ano</label>
        <datepicker
            :id="id"
            v-model="ano"
            year-picker
            :dark="isDarkTheme"
            @update:model-value="atualizarData()"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            auto-apply
        />
    </div>
</template>

<script>
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";

export default {
    props: ["modelValue"],
    components: {
        Datepicker,
    },
    computed: {
        isDarkTheme() {
            return this.$store.state.theme === "dark-theme";
        },
    },
    data() {
        return {
            ano: moment(this.modelValue).format("YYYY"),
            id: `id-${Math.round(Math.random() * 100000)}-vias-input-year`,
        };
    },
    methods: {
        atualizarData() {
            this.$emit(
                "update:modelValue",
                moment(this.modelValue).set("year", this.ano).format("YYYY-MM-DDTHH:mm:ss"),
            );
        },
    },
    watch: {
        modelValue() {
            this.ano = moment(this.modelValue).format("YYYY");
        },
    },
};
</script>
