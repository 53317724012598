<template>

<ViasRow>
    <ViasCol cols="12" md="6" lg="4" xl="3">
        <BoxTodosOsEquipamentos :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
    <ViasCol cols="12" md="6" lg="4" xl="3">
        <BoxMediaDeVelocidade :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
    <ViasCol cols="12" md="6" lg="4" xl="3">
        <BoxTotalInfracoes24Horas :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
    <ViasCol cols="12" md="6" lg="4" xl="3">
        <BoxTotalFluxo24Horas :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
</ViasRow>

<ViasRow>
    <ViasCol cols="12">
        <BoxGraficoTotalInfracoes24Horas :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
</ViasRow>

<ViasRow>
    <ViasCol cols="12">
        <BoxGraficoTotalFluxo24Horas :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
</ViasRow>

<ViasRow>
    <ViasCol cols="12">
        <BoxGraficoMediaVelocidade24Horas :ref="adicionarCaixaDeApresentacao"/>
    </ViasCol>
</ViasRow>

</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue';
import BoxGraficoMediaVelocidade24Horas from '../components/BoxGraficoMediaVelocidade24Horas.vue';
import BoxGraficoTotalFluxo24Horas from '../components/BoxGraficoTotalFluxo24Horas.vue';
import BoxGraficoTotalInfracoes24Horas from '../components/BoxGraficoTotalInfracoes24Horas.vue';
import BoxMediaDeVelocidade from '../components/BoxMediaDeVelocidade.vue';
import BoxTodosOsEquipamentos from '../components/BoxTodosOsEquipamentos.vue';
import BoxTotalFluxo24Horas from '../components/BoxTotalFluxo24Horas.vue';
import BoxTotalInfracoes24Horas from '../components/BoxTotalInfracoes24Horas.vue';

const caixasDeApresentacaoDoContrato = ref<any[]>([]);

function adicionarCaixaDeApresentacao(caixa: any) {
    if (caixa) {
        caixasDeApresentacaoDoContrato.value.push(caixa);
    }
}

const contadorParaAtualizarInformacoesDoContrato = setInterval(() => {
    caixasDeApresentacaoDoContrato.value.forEach((caixa) => {
        caixa.atualizarInformacoes();
    });
}, 30 * 1000);

onBeforeUnmount(() => {
    clearInterval(contadorParaAtualizarInformacoesDoContrato);
});
</script>