<template>
    <div v-if="hasTextType" class="form-group" :class="hasDetail">
        <label
            v-if="hasLabel"
            :id="getLabelId"
            :for="getInputId"
            :class="theme"
        >
            {{ label }} <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <input
            :type="getType"
            :id="getInputId"
            class="form-control"
            :class="[theme, hasText, isValid]"
            :required="isRequired"
            :step="step"
            v-model="value"
            v-maska="mask"
            :maxlength="maxlength"
            :minlength="minlength"
            :placeholder="placeholder"
            @input="inputValue()"
            @change="changedValue()"
            @focusout="focusoutEvent()"
        />
        <button
            class="btn btn-sm btn-mostrar-senha"
            v-if="type === 'password'"
            type="button"
            @click="toggleMostrarSenha()"
        >
            <i class="fas fa-eye" v-if="this.mostrarSenha"></i>
            <i class="fas fa-eye-slash" v-else></i>
        </button>
        <small v-if="hasDetail" :id="getDetailId" class="detail">{{
            detail
        }}</small>
    </div>
    <div v-if="isCheckbox" class="form-group" :class="[theme, formGroupClass]">
        <label v-if="isSwitch" class="switch" :id="getLabelId">
            {{ beforeText }}
            <input
                v-model="value"
                type="checkbox"
                @change="changedValue"
                :id="getInputId"
            />
            <div class="slider"></div>
            {{ afterText }}
        </label>
    </div>
    <div v-if="hasDateOrDateTimeType" class="form-group">
        <label
            v-if="hasLabel"
            :for="getInputId"
            :id="getLabelId"
            :class="theme"
            >{{ label }}</label
        >
        <input
            :id="getInputId"
            class="form-control"
            :class="theme"
            :required="isRequired"
            v-model="value"
            :type="type"
            @input="updateValue"
        />
    </div>
    <div v-if="isSelect" class="form-group">
        <label
            v-if="hasLabel"
            :for="getInputId"
            :id="getLabelId"
            :class="theme"
            >{{ label }}</label
        >
        <select
            :id="getInputId"
            class="select-control"
            v-model="value"
            @change="updateValue"
            :required="isRequired"
            :class="theme"
        >
            <option
                v-for="option in options"
                :key="option"
                :value="option.value"
                :selected="option.selected"
            >
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: [
        "formGroupClass",
        "type",
        "id",
        "label",
        "required",
        "modelValue",
        "step",
        "mask",
        "switch",
        "beforeText",
        "afterText",
        "options",
        "maxlength",
        "minlength",
        "valid",
        "detail",
        "placeholder"
    ],
    emits: ["focusout", "input", "update:modelValue"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        getInputId() {
            if (this.id) {
                return `id-${this.id}-input`;
            }
            return "id-" + Math.floor(Math.random() * 1000000) + "-input";
        },
        getLabelId() {
            if (this.id) {
                return `id-${this.id}-label`;
            }
            return "id-" + Math.floor(Math.random() * 1000000) + "-label";
        },
        getDetailId() {
            if (this.id) {
                return `id-${this.id}-detail`;
            }
            return "id-" + Math.floor(Math.random() * 1000000) + "-detail";
        },
        getType() {
            if (this.type === "password") {
                return this.mostrarSenha ? "text" : "password";
            }
            return this.type;
        },
        hasLabel() {
            return !!this.label;
        },
        isRequired() {
            return this.required != undefined;
        },
        hasTextType() {
            const textType = ["text", "password", "email", "number"];
            return textType.includes(this.type);
        },
        hasDateOrDateTimeType() {
            return this.type == "date" || this.type == "datetime-local";
        },
        isCheckbox() {
            return this.type == "checkbox";
        },
        isSwitch() {
            return typeof this.switch == "string";
        },
        isSelect() {
            return this.type == "select";
        },
        hasText() {
            return this.value != undefined &&
                this.value != null &&
                this.value.toString().length > 0
                ? "has-text"
                : "";
        },
        isValid() {
            if (this.valid == true) {
                return "valid";
            }
            if (this.valid == false) {
                return "invalid";
            }
            return "";
        },
        hasDetail() {
            if (this.detail && this.detail.length > 0) {
                return "has-detail";
            }
            return "";
        },
    },
    data() {
        return {
            value: this.modelValue,
            mostrarSenha: false,
        };
    },
    watch: {
        modelValue() {
            this.value = this.modelValue;
        },
    },
    methods: {
        inputValue() {
            this.updateValue().then(() => this.$emit("input", this.value));
        },
        changedValue() {
            this.updateValue().then(() => this.$emit("change", this.value));
        },
        focusoutEvent() {
            this.$emit("focusout");
        },
        updateValue() {
            return new Promise((resolve) => {
                this.$emit("update:modelValue", this.value);
                resolve();
            });
        },
        toggleMostrarSenha() {
            this.mostrarSenha = !this.mostrarSenha;
        },
    },
};
</script>
