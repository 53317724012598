<template>

<ViasRow>
    <ViasCol>
        <ViasCard>
            <template #header>
                <div
                    class="d-flex justify-content-between align-items-center"
                >
                    <h2>{{ titulo }}</h2>
                    <router-link
                        :to="linkAdicionar"
                        class="btn btn-primary"
                    >
                        Adicionar
                    </router-link>
                </div>
            </template>
            <ViasTable
                :carregando="primeiraBusca"
                :linhas="linhas"
                :colunas="colunas"
                com-opcoes
            >
                <template #opcoes="{ linha }">
                    <div
                        class="d-flex align-items-center justify-content-end"
                    >
                        <router-link
                            :to="
                                linkEditar +
                                linha.id
                            "
                            class="btn btn-outline-primary btn-sm"
                        >
                            Editar
                        </router-link>
                        <ViasButton
                            variant="none-danger"
                            size="sm"
                            @click="clickRemover(linha)"
                            >Remover</ViasButton
                        >
                    </div>
                </template>
            </ViasTable>
        </ViasCard>
    </ViasCol>
</ViasRow>

</template>

<script lang="ts" setup>
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { defineProps, defineEmits, withDefaults } from "vue"

interface Props {
    titulo: string;
    linkAdicionar: string;
    linkEditar: string;
    primeiraBusca: boolean;
    linhas: any[];
    colunas: IColunaTabela[];
}

withDefaults(defineProps<Props>(), {
    linhas: () => [],
    colunas: () => []
});

const emits = defineEmits(["clickRemover"]);

function clickRemover(linha) {
    emits("clickRemover", linha);
}
</script>