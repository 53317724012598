<template>
    <ViasGoogleMapsEquipamento
        v-for="equipamento in equipamentosMapa"
        :key="equipamento.id"
        :id="equipamento.id"
        :equipamento="equipamento"
        @click="clickEquipamento(equipamento)"
    />
    <ViasGoogleMapsEquipamento
        v-for="equipamento in equipamentosEstaticosMapa"
        :key="equipamento.id"
        :id="equipamento.id"
        :equipamento="equipamento"
        @click="clickEquipamento(equipamento)"
    />
    <ViasGoogleMapsEquipamento
        v-for="equipamento in equipamentosPorPropriedadeMapa"
        :key="equipamento.id"
        :id="equipamento.id"
        :equipamento="equipamento"
        @click="clickEquipamento(equipamento)"
    />
</template>

<script lang="ts" setup>
import { converterEquipamentoParaMarcador } from "@/composable/equipamento/converterEquipamentoParaMarcador";
import { IEquipamentoMapa } from "@/interfaces/equipamento/IEquipamentoMapa";
import { useStore } from "@/storeTs";
import {
    BUSCAR_EQUIPAMENTOS,
    BUSCAR_EQUIPAMENTOS_ESTATICOS,
} from "@/storeTs/equipamentos/type-actions";
import {
    onMounted,
    ref,
    defineEmits,
    watch,
    computed,
    withDefaults,
    defineProps,
} from "vue";
import ViasGoogleMapsEquipamento from "../ViasGoogleMapsEquipamento.vue";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { EquipamentoComPosicao } from "@/domains/Equipamentos/models/EquipamentoComPosicao";
import { EquipamentoComFaixaEPosicao } from "@/domains/Equipamentos/models/EquipamentoComFaixaEPosicao";



/* -------------------------------------------------------------------------- */
/*                        Seção de definições iniciais                        */
/* -------------------------------------------------------------------------- */

interface Props {
    equipamentosPorPropriedade?: IEquipamento[] | EquipamentoComPosicao[] | EquipamentoComFaixaEPosicao[];
    habilitarTodosOsEquipamentos?: boolean;
    idsEquipamentosSelecionados?: number[];
    filtroDeEquipamentos?: string;
}

const props = withDefaults(defineProps<Props>(), {
    equipamentosPorPropriedade: () => [],
    habilitarTodosOsEquipamentos: true,
    idsEquipamentosSelecionados: () => [],
    filtroDeEquipamentos: "",
});

const emits = defineEmits<{
    click: [equipamento: IEquipamento];
}>();

const store = useStore();

const accessToken = computed(() => store.state.access_token);



/* -------------------------------------------------------------------------- */
/*                        Seção dos equipamentos fixos                        */
/* -------------------------------------------------------------------------- */

const equipamentos = ref<IEquipamento[]>([]);
const equipamentosMapa = ref<IEquipamentoMapa[]>([]);

const tiposDeEquipamentosSelecionados = computed(
    () => store.state.tipos_equipamentos_selecionados,
);

async function buscarTodosOsEquipamentos() {
    if (!props.habilitarTodosOsEquipamentos) {
        return;
    }

    const response = await store.dispatch(BUSCAR_EQUIPAMENTOS);
    equipamentos.value = response.data;
    converterEquipamentosParaMarcadorNoMapa();
    verificarEquipamentosSelecionados();
}

function converterEquipamentosParaMarcadorNoMapa() {
    equipamentosMapa.value = equipamentos.value.map((equipamento) => {
        return converterEquipamentoParaMarcador(equipamento)
    });
}

watch(
    () => tiposDeEquipamentosSelecionados.value,
    () => {
        if (accessToken.value) {
            buscarTodosOsEquipamentos();
        }
    },
);



/* -------------------------------------------------------------------------- */
/*                       Seção de equipamentos estáticos                      */
/* -------------------------------------------------------------------------- */

const equipamentosEstaticos = ref<IEquipamento[]>([]);
const equipamentosEstaticosMapa = ref<IEquipamentoMapa[]>([]);

const dataDaBuscaDeEquipamentosEstaticos = computed(
    () => store.state.dataBuscaEquipamentosEstaticos,
);
const apresentarEquipamentosEstaticos = computed(
    () => store.state.apresentarEquipamentosEstaticos,
);

async function buscarEquipamentosEstaticos() {
    if (!props.habilitarTodosOsEquipamentos) {
        return;
    }
    
    const response = await store.dispatch(BUSCAR_EQUIPAMENTOS_ESTATICOS);
    equipamentosEstaticos.value = response.data;
    converterEquipamentosEstaticosParaMarcadorNoMapa();
    verificarEquipamentosSelecionados();
}

function converterEquipamentosEstaticosParaMarcadorNoMapa() {
    equipamentosEstaticosMapa.value = equipamentosEstaticos.value.map((equipamento) => {
        return converterEquipamentoParaMarcador(equipamento);
    });
}

function removerEquipamentosEstaticosDoMapa() {
    equipamentosEstaticosMapa.value = [];
}

watch(
    () => dataDaBuscaDeEquipamentosEstaticos.value,
    () => {
        buscarEquipamentosEstaticos();
    },
);

watch(
    () => apresentarEquipamentosEstaticos.value,
    () => {
        if (apresentarEquipamentosEstaticos.value) {
            converterEquipamentosEstaticosParaMarcadorNoMapa();
        } else {
            removerEquipamentosEstaticosDoMapa();
        }
    },
);



/* -------------------------------------------------------------------------- */
/*        Seção dos equipamentos passados por propriedade ao componente       */
/* -------------------------------------------------------------------------- */

const equipamentosPorPropriedadeMapa = ref<IEquipamentoMapa[]>([]);

function converterEquipamentosPorPropriedadeParaMarcadorNoMapa() {
    equipamentosPorPropriedadeMapa.value = props.equipamentosPorPropriedade.map((equipamento) => {
        return converterEquipamentoParaMarcador(equipamento);
    });
}

watch(
    () => props.equipamentosPorPropriedade,
    () => {
        converterEquipamentosPorPropriedadeParaMarcadorNoMapa();
        verificarEquipamentosSelecionados();
    },
);



/* -------------------------------------------------------------------------- */
/*                  Seção de funções gerais dos equipamentos                  */
/* -------------------------------------------------------------------------- */

async function clickEquipamento(equipamento: IEquipamentoMapa) {
    try {
        const equipamentoBase = await buscarEquipamentoPeloId(equipamento.id);
        emits("click", equipamentoBase);
    } catch (error) {
        console.error(error);
    }
}

async function buscarEquipamentoPeloId(id: number): Promise<IEquipamento> {
    let equipamento = equipamentos.value.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }

    equipamento = equipamentosEstaticos.value.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }
    
    equipamento = props.equipamentosPorPropriedade.find((eq) => eq.id === id);
    if (equipamento) {
        return equipamento;
    }
    throw new Error(`Equipamento com o id ${id} não existe`);
}

async function verificarEquipamentosSelecionados() {
    equipamentosMapa.value = equipamentosMapa.value.map((equipamento) => {
        return isEquipamentoSelecionado(equipamento);
    });
    equipamentosEstaticosMapa.value = equipamentosEstaticosMapa.value.map((equipamento) => {
        return isEquipamentoSelecionado(equipamento);
    });
    equipamentosPorPropriedadeMapa.value = equipamentosPorPropriedadeMapa.value.map((equipamento) => {
        return isEquipamentoSelecionado(equipamento);
    });
}

function isEquipamentoSelecionado(equipamento: IEquipamentoMapa) {
    if (
        props.idsEquipamentosSelecionados.find(
            (id) => id === equipamento.id,
        )
    ) {
        equipamento.selecionado = true;
        return equipamento;
    }
    equipamento.selecionado = false;
    return equipamento;
}

watch(
    () => props.idsEquipamentosSelecionados,
    () => {
        verificarEquipamentosSelecionados();
    },
);



/* -------------------------------------------------------------------------- */
/*                   Seção de quando o componente é montado                   */
/* -------------------------------------------------------------------------- */

onMounted(() => {
    buscarTodosOsEquipamentos();
    buscarEquipamentosEstaticos();
    converterEquipamentosPorPropriedadeParaMarcadorNoMapa();
});
</script>
