<template>

<BoxInsightContrato variante="danger">
    <template #cabecalho>
        Infrações de trânsito
    </template>
    <template #valorPrincipal>
        {{ totalDeInfracoes }} infrações
    </template>
    <template #legenda>
        Nas últimas 24 horas
    </template>
    <template #rodape>
        <ModalDetalhamentoContrato
            titulo="Classificação de Infração"
            :itens="totalDeInfracoesSeparadoPorInfracao"
        />
    </template>
</BoxInsightContrato>

</template>

<script lang="ts" setup>
import BoxInsightContrato from './BoxInsightContrato.vue';
import ModalDetalhamentoContrato from './ModalDetalhamentoContrato.vue';
import numerosService from '@/domains/Numeros/services/NumerosService';
import contratoService from '../services/ContratoService';
import { onMounted, ref, defineExpose } from 'vue';
import { TotalDeInfracoesNasUltimas24horas } from '../models/TotalDeInfracoesNasUltimas24horas';

const totalDeInfracoesSeparadoPorInfracao = ref<TotalDeInfracoesNasUltimas24horas>({});
const totalDeInfracoes = ref("0");

async function buscarTotalDeInfracoesNasUltimas24Horas() {
    const { data } = await contratoService.buscarTotalDeInfracoes();
    totalDeInfracoesSeparadoPorInfracao.value = data;
    calcularTotalDeInfracoes();
}

function calcularTotalDeInfracoes() {
    const valores = Object.values(totalDeInfracoesSeparadoPorInfracao.value);
    const totalCalculado = valores.reduce((total, infracao) => {
        return total + infracao;
    }, 0);
    totalDeInfracoes.value = numerosService.formatarNumero(totalCalculado);
}

onMounted(() => {
    buscarTotalDeInfracoesNasUltimas24Horas();
});

function atualizarInformacoes() {
    buscarTotalDeInfracoesNasUltimas24Horas();
}

defineExpose({
    atualizarInformacoes
});
</script>