<template>
    <ViasCard>
        <template #header>
            <div class="flex aling-items-center justify-content-between">
                <h2 class="title">Nova whitelist</h2>
                <ViasButton
                    variant="outline-primary"
                    @click="retornarAApresentacaoDeTodasAsPlacasDeWhitelist()"
                >
                    Voltar
                </ViasButton>
            </div>
        </template>
        <FormularioAdicionarPlacaWhitelist
            @adicionar-nova-placa-na-whitelist="
                adicionarNovaPlacaNaWhitelist($event)
            "
        />
    </ViasCard>
</template>

<script lang="ts" setup>
import { useStore } from "@/storeTs";
import { useRouter } from "vue-router";
import FormularioAdicionarPlacaWhitelist from "./formulario/FormularioAdicionarPlacaWhitelist.vue";
import { INovaPlacaNaWhitelist } from "@/interfaces/whitelist/INovaPlacaNaWhitelist";
import { ADICIONAR_NOVA_PLACA_DE_WHITELIST } from "@/storeTs/configuracao/whitelist/type-actions";
import { useNotificacao } from "@/hooks/notificacao.hook";

const store = useStore();
const router = useRouter();
const { notificar, notificarCarregamento } = useNotificacao();

function adicionarNovaPlacaNaWhitelist(novaPlaca: INovaPlacaNaWhitelist) {
    notificarCarregamento(
        "Estamos enviando a nova placa para ser adicionada na whitelist, aguarde um momento por favor...",
    );
    store.dispatch(ADICIONAR_NOVA_PLACA_DE_WHITELIST, novaPlaca).then((res) => {
        if (res.status === 201) {
            notificar("Placa adicionada com sucesso a whitelist!", "success");
            retornarAApresentacaoDeTodasAsPlacasDeWhitelist();
        }
    });
}

function retornarAApresentacaoDeTodasAsPlacasDeWhitelist() {
    router.push("/configuracao-geral/whitelist");
}
</script>
