<template>
    <form
        id="vias-form-relatorio-passagens"
        @submit.prevent="buscarRelatorio()"
    >
        <div class="row">
            <div class="col">
                <vias-input-date
                    id="data"
                    v-model="formulario.data"
                    label="Data"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <vias-button variant="primary" class="mr-2"
                    >Buscar relatório</vias-button
                >
                <vias-button
                    type="button"
                    variant="outline-primary"
                    @click="alterarDataDoFormulario()"
                >
                    Selecionar data atual
                </vias-button>
            </div>
        </div>
    </form>
</template>

<script>
import ViasInputDateVue from "@/components/shared/ViasInputDate.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import moment from "moment";

export default {
    components: {
        "vias-input-date": ViasInputDateVue,
        "vias-button": ViasButtonVue,
    },
    data: () => ({
        formulario: {
            data: moment().format("YYYY-MM-DDTHH:mm:ss"),
        },
    }),
    created() {
        this.buscarRelatorio();
    },
    methods: {
        buscarRelatorio() {
            this.formulario.data = moment(this.formulario.data).format(
                "YYYY-MM-DDTHH:mm:ss",
            );
            this.$emit("buscarRelatorio", this.formulario);
        },
        alterarDataDoFormulario() {
            this.formulario.data = moment().format("YYYY-MM-DDTHH:mm:ss");
        },
    },
};
</script>
