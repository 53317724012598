<template>
    <div class="d-flex flex-column w-100">
        <h3 class="mb-2">Áreas</h3>
        <ul class="list">
            <li class="d-flex" v-for="area in areas" :key="area.id">
                <div class="quadrado" :style="{ background: area.cor }"></div>
                <h4>{{ area.nome }}</h4>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { IArea } from "@/interfaces/mapa/areas/IArea";
import { useStore } from "@/storeTs";
import { BUSCAR_AREAS_CADASTRADAS } from "@/storeTs/configuracao/areas/type-actions";
import { onMounted, ref } from "vue";

const store = useStore();

const areas = ref<IArea[]>([]);

async function buscarAreas() {
    const response = await store.dispatch(BUSCAR_AREAS_CADASTRADAS);
    areas.value = response.data;
}

onMounted(() => {
    buscarAreas();
});
</script>
