const TranslatePlugin = {
    install(app) {
        app.config.globalProperties.$traducao = {
            porte: (sigla) => {
                return {
                    G: "Caminhões leves",
                    L: "Carretas e trucks",
                    P: "Carros e veículos pequenos",
                    T: "Motos",
                    U: "Não definido",
                    B: "Ônibus",
                    M: "Veículos médio",
                }[sigla];
            },
        };
    },
};

export default TranslatePlugin;
