import http from "@/http";
import { AxiosResponse } from "axios";
import { MediaDeVelocidadeDaCidade } from "../models/MediaDeVelocidadeDaCidade";
import { TotalDeInfracoesNasUltimas24horas } from "../models/TotalDeInfracoesNasUltimas24horas";
import { TotalDeFluxoNasUltimas24Horas } from "../models/TotalDeFluxoNasUltimas24horas";

class ContratoService {

    private rotaBase = "/vias/v1/contrato";

    buscarTotalDeInfracoes(): Promise<AxiosResponse<TotalDeInfracoesNasUltimas24horas>> {
        return http.get(`${this.rotaBase}/infracoes`);
    }

    buscarMediaDeVelocidadeAtualDaCidade(): Promise<AxiosResponse<MediaDeVelocidadeDaCidade>> {
        return http.get(`${this.rotaBase}/media-de-velocidade`);
    }

    buscarTotalDeFluxo(): Promise<AxiosResponse<TotalDeFluxoNasUltimas24Horas>> {
        return http.get(`${this.rotaBase}/fluxo`);
    }

}

const contratoService = new ContratoService();

export default contratoService;