<template>
    <div :data-cy="dataCy">
        <vias-button
            v-if="hasButton"
            :variant="buttonTargetVariant"
            :class="buttonClass"
            data-cy="modal-button-open"
            @click="mostrarModal"
            >{{ buttonTargetText }}</vias-button
        >
        <div class="modal" :id="id" ref="modal" @click="modalClick($event)">
            <div class="modal-box">
                <div class="modal-header">
                    <h1 v-html="title" class="title" data-cy="modal-title"></h1>
                    <span
                        class="close-modal"
                        @click="fecharModal"
                        data-cy="modal-button-close"
                        >&times;</span
                    >
                </div>
                <div class="modal-body" :class="theme">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ViasButtonVue from "./ViasButton.vue";
import { mapState } from "vuex";
export default {
    props: [
        "buttonTargetText",
        "buttonTargetVariant",
        "title",
        "buttonClass",
        "showButton",
        "modelValue",
        "dataCy",
    ],
    emits: ["hidding", "hidden", "showing", "update:modelValue"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        hasButton() {
            return typeof this.showButton == "boolean" ? this.showButton : true;
        },
    },
    data() {
        return {
            mostrar_modal: !!this.modelValue,
            id: `id-modal-${Math.round(Math.random() * 100000)}`,
        };
    },
    components: {
        "vias-button": ViasButtonVue,
    },
    methods: {
        mostrarModal() {
            this.$refs.modal.style.display = "block";
            this.$emit("update:modelValue", true);
            this.$emit("showing");
        },
        fecharModal() {
            this.$emit("hidding");
            this.$refs.modal.style.display = "none";
            this.$emit("update:modelValue", false);
            this.$emit("hidden");
        },
        modalClick(e) {
            if (this.targetIsThisModal(e.target) && this.hasPathAndFirstPathIsModal(e.target.classList)) {
                this.fecharModal();
            }
        },
        targetIsThisModal(target) {
            return target.id === this.id;
        },
        hasPathAndFirstPathIsModal(classList) {
            return classList && classList[0] == "modal";
        },
    },
    watch: {
        modelValue() {
            this.mostrar_modal = !!this.modelValue;
            if (this.mostrar_modal) this.mostrarModal();
            else this.fecharModal();
        },
    },
};
</script>
