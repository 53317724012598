<template>

<BoxInsightContrato variante="primary">
    <template #cabecalho>
        Média de velocidade da cidade
        <ViasExplicacaoVelocidadeMediaGeral/>
    </template>
    <template #valorPrincipal>
        {{ mediaDeVelocidade }} km/h
    </template>
    <template #legenda>
        Agora
    </template>
</BoxInsightContrato>

</template>

<script lang="ts" setup>
import BoxInsightContrato from './BoxInsightContrato.vue';
import { onMounted, ref, defineExpose } from 'vue';
import contratoService from '../services/ContratoService';
import numerosService from '@/domains/Numeros/services/NumerosService';
import ViasExplicacaoVelocidadeMediaGeral from '@/components/shared/explicacoes/ViasExplicacaoVelocidadeMediaGeral.vue';

const mediaDeVelocidade = ref("0");

async function buscarMediaDeVelocidade() {
    const { data } = await contratoService.buscarMediaDeVelocidadeAtualDaCidade();
    mediaDeVelocidade.value = numerosService.formatarNumero(data.media_de_velocidade);
}

onMounted(() => {
    buscarMediaDeVelocidade();
});

function atualizarInformacoes() {
    buscarMediaDeVelocidade();
}

defineExpose({
    atualizarInformacoes
});
</script>