<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Editar rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDasRotas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <FormularioEditarRotaTempoDePercurso
                    :rota-selecionada="rotaSelecionada"
                    @salvar-rota="salvarRota($event)"
                ></FormularioEditarRotaTempoDePercurso>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { useNotificacao } from "@/hooks/notificacao.hook";
import { useStore } from "@/storeTs";
import {
    ATUALIZAR_ROTA_TEMPO_DE_PERCURSO,
    BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO,
} from "@/storeTs/tempo-de-percurso/configuracao/type-actions";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { INovaRotaTempoDePercurso } from "./interfaces/INovaRotaTempoDePercurso";
import FormularioEditarRotaTempoDePercurso from "./components/FormularioEditarRotaTempoDePercurso.vue";
import { IRotaTempoDePercurso } from "./interfaces/IRotaTempoDePercurso";

export default defineComponent({
    name: "NovaRotaTempoDePercursoView",
    components: {
        FormularioEditarRotaTempoDePercurso,
    },
    data: () => ({
        idRota: 0,
        rotaSelecionada: {} as IRotaTempoDePercurso,
    }),
    methods: {
        voltarParaAApresentacaoDasRotas() {
            this.router.push("/tempo-de-percurso/configuracao");
        },
        buscarRota() {
            this.store
                .dispatch(BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO, this.idRota)
                .then((res) => {
                    this.rotaSelecionada = res.data;
                })
                .catch((err) => {
                    this.notificar(err, "info");
                    this.voltarParaAApresentacaoDasRotas();
                });
        },
        salvarRota(novaRota: INovaRotaTempoDePercurso) {
            this.notificarCarregamento(
                "Estamos enviando os dados para atualizar a rota, aguarde um momento por favor...",
            );
            this.store
                .dispatch(ATUALIZAR_ROTA_TEMPO_DE_PERCURSO, novaRota)
                .then((res) => {
                    if (res.status === 204) {
                        this.notificar(
                            "Rota adicionada com sucesso!",
                            "success",
                        );
                        this.voltarParaAApresentacaoDasRotas();
                    }
                });
        },
    },
    created() {
        try {
            this.idRota = parseInt(
                this.router.currentRoute.value.params.id.toString(),
            );
            this.buscarRota();
        } catch {
            this.notificar(
                "É necessário que um id de rota existente seja informado.",
                "info",
            );
            this.voltarParaAApresentacaoDasRotas();
        }
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            store,
            router,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
