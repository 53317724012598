import { State } from "@/storeTs";
import { Module } from "vuex";
import { BUSCAR_AUDITORIA_DE_USUARIOS } from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const configuracaoAuditoriaUsuariosModule: Module<State, State> = {
    actions: {
        [BUSCAR_AUDITORIA_DE_USUARIOS](context, usuario_id: number) {
            return axios.get(`/vias/v1/usuarios/${usuario_id}/audit-logs`);
        },
    },
};
