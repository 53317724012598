<template>
    <div class="wrapper" data-cy="theme-container" :class="theme">
        <div class="container" :class="isMenuOpen">
            <vias-aside v-if="$route.name != 'Login'"></vias-aside>
            <main :class="{ 'position-relative': isLogged }">
                <vias-topbar v-if="$route.name != 'Login'"></vias-topbar>
                <vias-breadcrumb
                    v-if="$route.name != 'Login' && !isMapa"
                ></vias-breadcrumb>
                <router-view></router-view>
            </main>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ViasAsideVue from "./components/app/ViasAside.vue";
import ViasTopbarVue from "./components/app/ViasTopbar.vue";
import ViasBreadcrumb from "./components/app/ViasBreadcrumb.vue";
import { SET_MOSTRANDO_BOX } from "@/storeTs/type-mutations.ts";

export default {
    name: "app-wrapper",
    components: {
        "vias-topbar": ViasTopbarVue,
        "vias-aside": ViasAsideVue,
        ViasBreadcrumb,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
            menu_open: (state) => state.menu_open,
        }),
        isMenuOpen() {
            return this.menu_open ? "menu-open" : "";
        },
        isMapa() {
            return this.$router.currentRoute.value.path.endsWith("mapa");
        },
        isLogged() {
            return !!this.$store.state.access_token;
        },
    },
    beforeCreate() {
        this.$store.dispatch("onRefreshed");
        this.$store.commit(SET_MOSTRANDO_BOX, false);
        sessionStorage.setItem("google-maps", "nao carregado");
    },
};
</script>
