<template>
    <div id="login">
        <div class="d-flex flex-column">
            <div id="logo-fotosensores">
                <img src="@/assets/logo-foto.png" alt="" />
            </div>
            <form
                @submit.prevent="enviarCredenciais()"
                class="d-flex flex-column align-items-start p-8"
            >
                <vias-input
                    type="text"
                    v-model="credenciais.usuario"
                    label="Usuário"
                    data-cy-input="login-usuario"
                    required
                ></vias-input>

                <vias-input
                    type="password"
                    v-model="credenciais.senha"
                    label="Senha"
                    data-cy-input="login-senha"
                    required
                ></vias-input>

                <vias-button variant="primary" data-cy="login-enviar">
                    Entrar
                </vias-button>
            </form>
        </div>
    </div>
</template>

<script>
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasInputVue from "@/components/shared/ViasInput.vue";

export default {
    name: "login-view",
    components: {
        "vias-button": ViasButtonVue,
        "vias-input": ViasInputVue,
    },
    data: () => ({
        credenciais: {
            usuario: "",
            senha: "",
        },
    }),
    methods: {
        enviarCredenciais() {
            this.$notificacao.open("Enviando credenciais, aguarde...");

            this.$usuario
                .enviarCredenciais(this.credenciais)
                .then((res) => res.data)
                .then((dados) => {
                    this.$store.dispatch("inserirToken", dados);
                    this.$store.dispatch("inserirUsuario", dados.access_token);
                    return dados;
                })
                .then(() => this.buscarContratos());
        },
        buscarContratos() {
            this.$contrato
                .buscarContratos()
                .then((res) => res.data)
                .then((contratos) => this.tratarContratos([contratos]))
                .then((contratosTratados) => {
                    this.$store.dispatch("inserirContratos", contratosTratados);
                    this.$store.dispatch(
                        "selecionarContrato",
                        contratosTratados[0],
                    );
                    this.$router.push("/contrato");
                    this.$notificacao.close("Login efetuado com sucesso!");
                });
        },
        tratarContratos(contratos) {
            return contratos.map((contrato) => ({
                cidade: contrato.cidade,
                estado: contrato.estado,
                latitude: contrato.latitude,
                longitude: contrato.longitude,
            }));
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

#login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--intense-background-color);
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);

    & > div {
        width: 300px;
    }

    #logo-fotosensores {
        background: $color-intense-dark;
        border-top-right-radius: $border-radius-1;
        border-top-left-radius: $border-radius-1;
        width: 300px;
        border-bottom: 1px solid $color-info-dark;
    }

    form {
        width: 300px;
        height: 100%;
        row-gap: 1rem;
    }
}
</style>
