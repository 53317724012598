<template>
    <div class="form-group" :class="[theme, isSwitch ? 'switch' : '']">
        <label v-if="isSwitch" :id="getLabelId" class="switch">
            <span v-if="beforeText">{{ beforeText }}</span>
            <input
                v-model="valueInterno"
                :id="getInputId"
                type="checkbox"
                @change="updateValue"
                :value="value"
            />
            <div class="slider"></div>
            <span v-if="afterText">{{ afterText }}</span>
        </label>

        <div v-if="isNormalCheckbox" class="checkbox-control">
            <input
                type="checkbox"
                :id="getInputId"
                v-model="valueInterno"
                @change="updateValue"
            />
            <label for="checkbox" :id="getLabelId">{{ afterText }}</label>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: [
        "beforeText",
        "afterText",
        "switch",
        "pill",
        "modelValue",
        "id",
        "value",
    ],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        isSwitch() {
            return typeof this.switch == "string";
        },
        isNormalCheckbox() {
            return (
                typeof this.switch == "undefined" &&
                typeof this.pill == "undefined"
            );
        },
        getInputId() {
            if (this.id) {
                return `id-${this.id}-input`;
            }
            return `id-${Math.floor(Math.random() * 1000000)}-input`;
        },
        getLabelId() {
            if (this.id) {
                return `id-${this.id}-label`;
            }
            return `id-${Math.floor(Math.random() * 1000000)}-label`;
        },
    },
    data() {
        return {
            valueInterno: this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.valueInterno = this.modelValue;
        },
    },
    methods: {
        updateValue() {
            this.$emit("update:modelValue", this.valueInterno);
        },
    },
};
</script>
