<template>

<ViasRow class="max-h-300px overflow-y-auto pt-4 pb-4 border-top border-bottom">
    <ViasCol
        v-for="equipamento in equipamentos"
        :key="equipamento.id"
        :cols="12"
        :sm="6"
        :md="4"
    >   
        <BoxEquipamentoAfericaoParaFormulario
            :afericao="equipamento"
            v-model="faixasSelecionadas"
            @change="
                verificarSeTodasAsFaixasDoEquipamentoEstaoSelecionadas(
                    equipamento
                )
            "
            :id="'lista-de-equipamentos-com-faixas-equipamento-' + equipamento.id"
        />
    </ViasCol>
</ViasRow>

</template>

<script lang="ts" setup>
import { EquipamentoComFaixa } from '@/domains/Equipamentos/models/EquipamentoComFaixa';
import { watch, defineModel, defineProps } from 'vue';
import BoxEquipamentoAfericaoParaFormulario from '../formulario/BoxEquipamentoAfericaoParaFormulario.vue';

interface Props {
    equipamentos: EquipamentoComFaixa[];
}

const props = defineProps<Props>();

const faixasSelecionadas = defineModel<number[]>("faixasSelecionadas", {
    default: []
});
const equipamentosSelecionados = defineModel<number[]>("equipamentosSelecionados", {
    default: []
});

function buscarEquipamentoPeloId(idEquipamento: number): EquipamentoComFaixa | undefined {
    return props.equipamentos.find((equipamento) => equipamento.id === idEquipamento);
}

function verificarSeTodasAsFaixasDoEquipamentoEstaoSelecionadas(equipamento: EquipamentoComFaixa): void {
    let todasAsFaixasSelecionadas = true;

    for (const faixa of equipamento.afericoes) {
        if (!faixasSelecionadas.value.find((fx) => fx === faixa.id)) {
            todasAsFaixasSelecionadas = false;
            break;
        }
    }

    if (todasAsFaixasSelecionadas) {
        equipamentosSelecionados.value = [
            ...equipamentosSelecionados.value,
            equipamento.id
        ];
    } else {
        equipamentosSelecionados.value = equipamentosSelecionados.value.filter((eq) => {
            return eq !== equipamento.id;
        });
    }
}

function verificarSeAsFaixasDosEquipamentosSelecionadosEstaoSelecionadas(
    equipamento: EquipamentoComFaixa
): void {
    const faixasASeremSelecionadas: number[] = [];

    for (const faixa of equipamento.afericoes) {
        if (!faixasSelecionadas.value.find((fx) => fx === faixa.id)) {
            faixasASeremSelecionadas.push(faixa.id);
        }
    }
    if (faixasASeremSelecionadas.length > 0) {
        faixasSelecionadas.value = faixasSelecionadas.value.concat(faixasASeremSelecionadas);
    }
}

function removerAsFaixasDoEquipamento(equipamento: EquipamentoComFaixa): void {
    const faixasASeremRemovidas = equipamento.afericoes.map((faixa) => faixa.id);
    let todasAsFaixasEstaoSelecionadas = true;
    faixasASeremRemovidas.forEach((faixa) => {
        if (!faixasSelecionadas.value.includes(faixa)) {
            todasAsFaixasEstaoSelecionadas = false;
        }
    });
    if (todasAsFaixasEstaoSelecionadas) {
        faixasSelecionadas.value = faixasSelecionadas.value.filter((faixa) => {
            return !faixasASeremRemovidas.includes(faixa);
        });
    }
}

watch(() => equipamentosSelecionados.value, (actual, old) => {
    for (const idEquipamento of old) {
        if (!actual.includes(idEquipamento)) {
            const equipamento = buscarEquipamentoPeloId(idEquipamento);
            if (equipamento) {
                removerAsFaixasDoEquipamento(equipamento);
            }
        }
    }

    for (const idEquipamento of actual) {
        const equipamento = buscarEquipamentoPeloId(idEquipamento);
        if (equipamento) {
            verificarSeAsFaixasDosEquipamentosSelecionadosEstaoSelecionadas(equipamento);
        }
    }
});
</script>