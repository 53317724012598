<template>
    <form
        @submit.prevent="buscarRelatorio()"
        id="vias-formulario-relatorio-violacoes"
    >
        <div class="row">
            <div class="col">
                <vias-checkbox
                    beforeText="Equipamento"
                    afterText="Grupo"
                    v-model="formulario.buscar_por_grupo"
                    switch
                ></vias-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <vias-select
                    v-if="formulario.buscar_por_grupo"
                    :options="grupos"
                    label="Grupo"
                    v-model="formulario.grupo"
                ></vias-select>
                <vias-select
                    v-else
                    :options="equipamentos"
                    label="Equipamento"
                    v-model="formulario.equipamento"
                ></vias-select>
            </div>
        </div>
        <div class="row">
            <div class="col" :class="definirLarguraColunaFormatoVisualizacao">
                <vias-radio-button
                    v-model="formulario.formato_visualizacao"
                    :options="opcoes_formato_visualizacao"
                ></vias-radio-button>
            </div>
            <div class="col col-md-6">
                <vias-input-date-and-hour
                    v-if="formulario.formato_visualizacao == 'hora'"
                    v-model="formulario.data"
                ></vias-input-date-and-hour>

                <vias-input-date
                    v-if="formulario.formato_visualizacao == 'dia'"
                    v-model="formulario.data"
                    label="Dia"
                    limitMin
                    limitMax
                ></vias-input-date>

                <vias-input-week
                    v-if="formulario.formato_visualizacao == 'semana'"
                    label="Semana da busca"
                    v-model="formulario.data"
                ></vias-input-week>

                <vias-input-month-and-year
                    v-if="formulario.formato_visualizacao == 'mes'"
                    label="Mês e ano da busca"
                    v-model="formulario.data"
                ></vias-input-month-and-year>

                <vias-input-year
                    v-if="formulario.formato_visualizacao == 'ano'"
                    v-model="formulario.data"
                ></vias-input-year>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="button-block">
                    <vias-button variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import ViasCheckboxVue from "@/components/shared/ViasCheckbox.vue";
import ViasRadioButtonVue from "@/components/shared/ViasRadioButton.vue";
import ViasSelectVue from "@/components/shared/ViasSelect.vue";
import ViasInputDateAndHourVue from "@/components/shared/ViasInputDateAndHour.vue";
import ViasInputDateVue from "@/components/shared/ViasInputDate.vue";
import ViasInputMonthAndYearVue from "@/components/shared/ViasInputMonthAndYear.vue";
import ViasInputYearVue from "@/components/shared/ViasInputYear.vue";
import ViasInputWeek from "@/components/shared/ViasInputWeek.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import moment from "moment";

export default {
    components: {
        "vias-button": ViasButtonVue,
        "vias-checkbox": ViasCheckboxVue,
        "vias-select": ViasSelectVue,
        "vias-radio-button": ViasRadioButtonVue,
        "vias-input-date-and-hour": ViasInputDateAndHourVue,
        "vias-input-date": ViasInputDateVue,
        "vias-input-month-and-year": ViasInputMonthAndYearVue,
        "vias-input-year": ViasInputYearVue,
        "vias-input-week": ViasInputWeek,
    },
    data: () => ({
        formulario: {
            buscar_por_grupo: false,
            equipamento: "all",
            grupo: "all",
            formato_visualizacao: "completo",
            data: moment().format("YYYY-MM-DDTHH:mm:ss"),
        },
        equipamentos: [
            {
                text: "Todos os equipamentos",
                value: "all",
            },
        ],
        grupos: [
            {
                text: "Todos os grupos",
                value: "all",
            },
        ],
        opcoes_formato_visualizacao: [
            {
                text: "Hora",
                value: "hora",
            },
            {
                text: "Dia",
                value: "dia",
            },
            {
                text: "Semana",
                value: "semana",
            },
            {
                text: "Mês",
                value: "mes",
            },
            {
                text: "Ano",
                value: "ano",
            },
            {
                text: "Contrato inteiro",
                value: "completo",
            },
        ],
    }),
    computed: {
        definirLarguraColunaFormatoVisualizacao() {
            return this.formulario.formato_visualizacao === "completo"
                ? "col-12"
                : "col-md-6";
        },
    },
    created() {
        this.buscarGrupos();
        this.buscarEquipamentos();
    },
    methods: {
        buscarRelatorio() {
            this.$emit("buscarRelatorio", this.formulario);
        },
        buscarEquipamentos() {
            this.$equipamento
                .buscarEquipamentos()
                .then((res) => res.data)
                .then((equipamentos) => {
                    this.equipamentos = this.equipamentos.concat(
                        equipamentos.map((equipamento) => ({
                            value: equipamento.id,
                            text: equipamento.sitio.toUpperCase(),
                        })),
                    );
                });
        },
        buscarGrupos() {
            this.$gestao_integrada
                .buscarGrupos()
                .then((res) => res.data)
                .then((grupos) => this.tratarGrupos(grupos));
        },
        tratarGrupos(grupos) {
            this.grupos = this.grupos.concat(
                grupos.map((grupo) => ({
                    value: grupo.id,
                    text: grupo.nome,
                })),
            );
        },
    },
};
</script>
