<template>
    <div id="vias-relatorio-ocorrencias">
        <vias-card column>
            <template #header>
                <h2 class="card-title">
                    Infrações - {{ busca.rota.text }}
                </h2>
            </template>
            <div class="row">
                <div class="col">
                    <div class="grafico-de-relatorio" :class="theme">
                        <div class="cabecalho">
                            <vias-button variant="link">
                                {{ getRangeDate }}
                            </vias-button>
                        </div>
                        <vias-line-chart
                            :datasets="datasets_ocorrencias"
                            :labels="labels"
                            :eixo_x="eixoX"
                            eixo_y="Total de Infrações"
                            @alterarFormatoDeVisualizacao="
                                alterarFormatoDeVisualizacaoParaBaixo($event)
                            "
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="grafico-de-relatorio" :class="theme">
                        <div class="cabecalho">
                            <vias-button variant="link">
                                {{ getRangeDate }}
                            </vias-button>
                        </div>
                        <vias-histograma
                            :datasets="datasets_ocorrencias"
                            :labels="labels"
                            :eixo_x="eixoX"
                            eixo_y="Total de Infrações"
                            @alterarFormatoDeVisualizacao="
                                alterarFormatoDeVisualizacaoParaBaixo($event)
                            "
                        ></vias-histograma>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-table
                        class="mt-4 overflow-x-auto"
                        :colunas="colunas_ocorrencias"
                        :linhas="linhas_ocorrencias"
                    ></vias-table>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasLineChartVue from "@/components/shared/ViasLineChart.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasHistograma from "@/components/shared/ViasHistograma.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
    name: "vias-relatorio-ocorrencias",
    props: [
        "identificadores",
        "graficoDeOcorrencias",
        "tabelaDeOcorrencias",
        "busca",
    ],
    components: {
        "vias-card": ViasCardVue,
        "vias-line-chart": ViasLineChartVue,
        "vias-table": ViasTableVue,
        "vias-button": ViasButtonVue,
        "vias-histograma": ViasHistograma,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        colunas_ocorrencias() {
            return [
                {
                    titulo: "#",
                    tipo: "numero",
                    referencia: "numero_linha",
                },
                {
                    titulo: this.titulos_da_coluna_identificador[
                        this.busca.formato_visualizacao
                    ],
                    tipo: "texto",
                    referencia: "identificador",
                },
                {
                    titulo: "Infrações",
                    tipo: "numero",
                    referencia: "ocorrencias",
                    unidade: "infrações",
                },
            ];
        },
        getRangeDate() {
            return `De ${moment(this.busca.data[0]).format("DD/MM/YYYY HH:mm:ss")} até ${moment(this.busca.data[1]).format("DD/MM/YYYY HH:mm:ss")}`;
        },
        eixoX() {
            return this.legendas_graficos[this.busca.formato_visualizacao];
        },
    },
    data: () => ({
        labels: [],
        datasets_ocorrencias: [],
        linhas_ocorrencias: [],
        titulos_da_coluna_identificador: {
            completo: "Ano",
            ano: "Mês",
            mes: "Dia",
            semana: "Dia",
            dia: "Hora",
            hora: "Horário",
        },
        legendas_graficos: {
            completo: "Anos do contrato",
            ano: "Meses do ano",
            mes: "Dias do mês",
            semana: "Dias da semana",
            dia: "Horas do dia",
            hora: "Horários",
        },
    }),
    created() {
        this.gerarLabels();
        this.gerarDatasetDeOcorrencias();
        this.gerarTabelaDeOcorrencias();
    },
    watch: {
        identificadores() {
            this.gerarLabels();
        },
        graficoDeOcorrencias() {
            this.gerarDatasetDeOcorrencias();
        },
        tabelaDeOcorrencias() {
            this.gerarTabelaDeOcorrencias();
        },
    },
    methods: {
        gerarLabels() {
            this.labels = this.identificadores;
        },
        gerarDatasetDeOcorrencias() {
            this.datasets_ocorrencias = [
                {
                    label: "Infrações",
                    data: this.graficoDeOcorrencias,
                    backgroundColor: this.$variaveis.cores.primary,
                    borderColor: this.$variaveis.cores.primary,
                },
            ];
        },
        gerarTabelaDeOcorrencias() {
            this.linhas_ocorrencias = this.tabelaDeOcorrencias;
        },
        alterarFormatoDeVisualizacao(formato) {
            this.$emit("alterarFormatoDeVisualizacao", formato);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            this.$emit("alterarFormatoDeVisualizacaoParaBaixo", index);
        },
    },
};
</script>
