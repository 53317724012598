import http from "@/http";
import moment from "moment";
import { AxiosResponse } from "axios";
import { TrocaDeSenha } from "../models/TrocaDeSenha";
import { UsuarioParaCadastro } from "../models/UsuarioParaCadastro";
import { UsuarioParaEdicao } from "../models/UsuarioParaEdicao";
import { Usuario } from "../models/Usuario";
import { UsuarioParaApresentacaoEmTabela } from "../models/UsuarioParaApresentacaoEmTabela";
import { AuditoriaUsuario } from "../models/AuditoriaUsuario";

class UsuarioService {

    private rotaBase: string = "/vias/v1/usuarios";

    public buscarUsuarios(): Promise<AxiosResponse<Usuario[]>> {
        return http.get(this.rotaBase);
    }
    
    public async buscarUsuarioPorId(idUsuario: number): Promise<Usuario> {
        const response = await this.buscarUsuarios();
        const usuario = response.data.find((usuario) => usuario.id == idUsuario);

        if (!usuario) {
            throw new Error("Usuário não encontrado");
        }

        return usuario;
    }
    
    public adicionarUsuario(usuario: UsuarioParaCadastro): Promise<AxiosResponse<Usuario>> {
        return http.post(this.rotaBase, usuario);
    }
    
    public editarUsuario(idUsuario: number, usuario: UsuarioParaEdicao): Promise<AxiosResponse<Usuario>> {
        return http.put(`${this.rotaBase}/${idUsuario}`, usuario);
    }
    
    public alterarStatusDoUsuario(idUsuario: number): Promise<AxiosResponse<null>> {
        return http.put(`${this.rotaBase}/${idUsuario}/status`);
    }
    
    public alterarSenhaDoUsuario(idUsuario: number, trocaDeSenha: TrocaDeSenha): Promise<AxiosResponse> {
        return http.put(`${this.rotaBase}/${idUsuario}/senha`, trocaDeSenha);
    }

    public buscarAuditoriaDoUsuario(idUsuario: number): Promise<AxiosResponse<AuditoriaUsuario[]>> {
        return http.get(`${this.rotaBase}/${idUsuario}/audit-logs`);
    }

    public tratarUltimoLoginParaApresentacaoEmTabela(ultimoLogin: string | null): string {
        if (ultimoLogin) {
            return moment(ultimoLogin).format("DD/MM/YYYY HH:mm:ss");
        }
        return "Nunca acessou o sistema";
    }

    public tratarSuperUsuarioParaApresentacaoEmTabela(superUsuario: boolean): string {
        return superUsuario ? "Sim" : "Não";
    }

    public tratarUsuarioParaApresentacaoEmTabela(usuario: Usuario): UsuarioParaApresentacaoEmTabela {
        return {
            id: usuario.id,
            nome: usuario.nome,
            usuario: usuario.username,
            email: usuario.email,
            superUsuario: this.tratarSuperUsuarioParaApresentacaoEmTabela(usuario.super_usuario),
            ultimoLogin: this.tratarUltimoLoginParaApresentacaoEmTabela(usuario.ultimo_login),
            ativo: usuario.ativo
        };
    }

    public async buscarUsuariosParaApresentacaoEmTabela(): Promise<UsuarioParaApresentacaoEmTabela[]> {
        const { data } = await this.buscarUsuarios();
        return data.map((usuario) => this.tratarUsuarioParaApresentacaoEmTabela(usuario));
    }

}

const usuarioService = new UsuarioService();

export default usuarioService;