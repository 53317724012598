import { AxiosResponse } from "axios";
import { PossiveisRelatoriosParaAgendamento } from "../models/TiposDeRelatoriosParaAgendamento";
import { Agendamento } from "../models/Agendamento";
import http from "@/http";
import { store } from "@/storeTs";

class AgendamentoService {

    private rotaBase = "/vias/v1/agendador-de-relatorios";

    public buscarAgendamentos(
        relatorio: PossiveisRelatoriosParaAgendamento
    ): Promise<AxiosResponse<Agendamento[]>> {
        return http.get(`${this.rotaBase}?relatorio=${relatorio}`);
    }

    public removerAgendamento(
        idRelatorio: number
    ): Promise<AxiosResponse> {
        return http.delete(
            `${this.rotaBase}/${store.state.usuario?.context.id}?relatorio_id=${idRelatorio}`
        );
    }

}

const agendamentoService = new AgendamentoService();

export default agendamentoService;