import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { IEquipamentoMapa } from "@/interfaces/equipamento/IEquipamentoMapa";

export function converterEquipamentoParaMarcador(
    equipamento: IEquipamento,
): IEquipamentoMapa {
    return {
        id: equipamento.id,
        posicao: {
            lat: equipamento.posicao.latitude,
            lng: equipamento.posicao.longitude,
        },
        endereco: equipamento.posicao.endereco,
        sitio: equipamento.sitio,
        status: equipamento.status,
        ultima_passagem: equipamento.ultima_passagem,
        tipo: equipamento.tipo,
        selecionado: false,
    };
}
