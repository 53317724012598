<template>

<form @submit.prevent="agendarRelatorioVolumeMedioDiario()">

    <FormularioAgendamentoRelatorio v-model="formulario"/>

    <hr class="mb-4">

    <ViasRow class="mb-4">
        <ViasCol :cols="12">
            <h2>Informações do relatório</h2>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                before-text="Equipamentos"
                after-text="Grupos"
                v-model="isRelatorioPorGrupo"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow v-if="isRelatorioPorGrupo">
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Selecione um grupo"
                :options="opcoesGrupos"
                v-model="formulario.parametros.grupo_id"
            />
        </ViasCol>
    </ViasRow>
    
    <ViasRow v-else>
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Selecione um equipamento"
                :options="opcoesEquipamentos"
                v-model="formulario.parametros.equipamento_id"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                after-text="Buscar apenas equipamentos com falhas"
                v-model="formulario.parametros.apenas_falhas"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasCheckbox
                after-text="Ordenar os equipamentos pelo volume médio diário (VMD)"
                v-model="formulario.parametros.ordernar_por_vmd"
                switch
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasButton variant="primary">
                Agendar
            </ViasButton>
        </ViasCol>
    </ViasRow>

</form>

</template>

<script lang="ts" setup>
import { ISelectOption } from "@/interfaces/select/ISelectOption";
import { ref, defineEmits, onMounted } from "vue";
import { ParametrosAgendamentoParaRelatorioVolumeMedioDiario } from "../models/ParametrosAgendamentoParaRelatorioVolumeMedioDiario";
import moment from "moment";
import ViasVueSelect from "@/components/shared/ViasVueSelect.vue";
import notificacaoService from "@/domains/Notificacao/services/notificacaoService";
import volumeMedioDiarioService from "../services/VolumeMedioDiarioService";
import gruposService from "@/domains/Grupos/services/GruposService";
import equipamentoService from "@/domains/Equipamentos/services/EquipamentoService";
import FormularioAgendamentoRelatorio from "@/domains/AgendamentoDeRelatorio/components/FormularioAgendamentoRelatorio.vue";

const emit = defineEmits<{
    relatorioAgendado: []
}>();

const isRelatorioPorGrupo = ref(false);
const opcoesGrupos = ref<ISelectOption[]>([]);
const opcoesEquipamentos = ref<ISelectOption[]>([]);

const formulario = ref<ParametrosAgendamentoParaRelatorioVolumeMedioDiario>({
    diario: true,
    dia_da_semana: null,
    dia_do_mes: null,
    hora: moment().format("HH:mm:ss"),
    relatorio: "Volume Médio Diário",
    operando: 0,
    atributo_de_calculo: "days",
    parametros: {
        grupo_id: null,
        equipamento_id: null,
        apenas_falhas: false,
        ordernar_por_vmd: false
    }
});

async function buscarGrupos(): Promise<void> {
    const { data } = await gruposService.buscarGrupos();
    opcoesGrupos.value = data.map((grupo) => ({
        text: grupo.nome,
        value: grupo.id
    }));
}

async function buscarEquipamentos(): Promise<void> {
    const { data } = await equipamentoService.buscarEquipamentos();
    opcoesEquipamentos.value = data.map((equipamento) => ({
        text: `${equipamento.sitio}, ${equipamento.posicao.endereco}`,
        value: equipamento.id
    }));
}

async function agendarRelatorioVolumeMedioDiario() {
    if (isRelatorioPorGrupo.value) {
        formulario.value.parametros.equipamento_id = null;
    } else {
        formulario.value.parametros.grupo_id = null;
    }

    notificacaoService.notificarCarregamento(
        "Estamos agendando a emissão do relatório, aguarde um momento por favor..."
    );
    await volumeMedioDiarioService.agendarEmissaoPdfRelatorioVolumeMedioDiario(formulario.value);
    notificacaoService.notificarSucesso("Relatório agendado com sucesso!");
    emit("relatorioAgendado");
    reiniciarFormulario();
}

function reiniciarFormulario(): void {
    formulario.value = {
        diario: true,
        dia_da_semana: null,
        dia_do_mes: null,
        hora: moment().format("HH:mm:ss"),
        relatorio: "Volume Médio Diário",
        operando: 0,
        atributo_de_calculo: "days",
        parametros: {
            grupo_id: null,
            equipamento_id: null,
            apenas_falhas: false,
            ordernar_por_vmd: false
        }
    };
}

onMounted(() => {
    buscarGrupos();
    buscarEquipamentos();
});

</script>