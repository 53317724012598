<template>

<ViasCard v-for="[sitio, vplumeMedioDiarioDoEquipamento] in itensDoRelatorio" :key="sitio" class="mt-4">
    <template #header>
        <div class="d-flex flex-column align-items-center mb-4">
            <h2 class="card-title p-0">
                {{ sitio }} 
                <ViasExplicacaoVMD/>
            </h2>
            <h4 class="text-center">{{ vplumeMedioDiarioDoEquipamento.endereco }}</h4>
        </div>
    </template>
    <TabelaApresentacaoRelatorioVolumeMedioDiario
        :volume-medio-diario-do-equipamento="vplumeMedioDiarioDoEquipamento"
    />
</ViasCard>

</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { RelatorioVolumeMedioDiario } from '../models/RelatorioVolumeMedioDiario';
import ViasExplicacaoVMD from '@/components/shared/explicacoes/ViasExplicacaoVMD.vue';
import TabelaApresentacaoRelatorioVolumeMedioDiario from './TabelaApresentacaoRelatorioVolumeMedioDiario.vue';

interface Props {
    relatorio: RelatorioVolumeMedioDiario;
}

const props = defineProps<Props>();

const itensDoRelatorio = computed(() => Object.entries(props.relatorio));
</script>