import http from "@/http";
import { AxiosResponse } from "axios";
import { Grupo } from "../models/Grupo";
import { GrupoParaCadastro } from "../models/GrupoParaCadastro";

class GruposService {

    private rotaBase = "/vias/v1/equipamentos/grupos";

    buscarGrupos(): Promise<AxiosResponse<Grupo[]>> {
        return http.get(this.rotaBase);
    }

    adicionarGrupo(novoGrupo: GrupoParaCadastro): Promise<AxiosResponse<Grupo>> {
        return http.post(this.rotaBase, novoGrupo);
    }

}

const gruposService = new GruposService();

export default gruposService;