<script setup>
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasCard from "@/components/shared/ViasCard.vue";
import router from "@/router";
import { useStore } from "@/storeTs";
import moment from "moment";
import {
    BUSCAR_OCORRENCIA_ESPECIFICA,
    GERAR_LINKS_IMAGENS,
} from "@/store/tipo-acoes";
import { formatar } from "@/services/numbers.service";
import { ref, onBeforeUnmount } from "vue";
import { emitirPdf } from "@/services/pdf.service";

const store = useStore();

const ocorrencia = ref(null);

const links = ref(null);

const passagens = ref(null);

const relatorio = ref(null);

const imagemRota = ref("");

let runnerGerarImagem = null;

const gerarImagem = () => {
    const {
        value: {
            rota: { primeiro_equipamento, segundo_equipamento },
        },
    } = ocorrencia;

    const apiKey = "AIzaSyDHGqzvNFxYwebumsfQGE7udVPhoZcaXgA",
        primeiroMarcador = `color:blue|label:A|${primeiro_equipamento.posicao.latitude}|${primeiro_equipamento.posicao.longitude}`,
        segundoMarcador = `color:blue|label:B|${segundo_equipamento.posicao.latitude}|${segundo_equipamento.posicao.longitude}`,
        h = (passagens.value.offsetHeight - 2) | 0,
        w = passagens.value.offsetWidth | 0;
    return `https://maps.googleapis.com/maps/api/staticmap?size=${w}x${h}&key=${apiKey}&markers=${primeiroMarcador}&markers=${segundoMarcador}`;
};

store
    .dispatch(BUSCAR_OCORRENCIA_ESPECIFICA, router.currentRoute.value.params.id)
    .then((res) => res.data)
    .then((dadosOcorrencia) => {
        ocorrencia.value = dadosOcorrencia;
    })
    .then(async () => {
        links.value = await store.dispatch(
            GERAR_LINKS_IMAGENS,
            ocorrencia.value.id,
        );
    })
    .then(() => {
        imagemRota.value = gerarImagem();
        runnerGerarImagem = setInterval(() => {
            imagemRota.value = gerarImagem();
        }, 5 * 1000);
    });

const emitirRelatorio = () => {
    if (relatorio.value) {
        emitirPdf(
            relatorio.value,
            "autuacao_de_velocidade_media_educativa_" + ocorrencia.value.id,
            relatorio.value.offsetWidth,
        );
    }
};

onBeforeUnmount(() => {
    if (runnerGerarImagem) clearInterval(runnerGerarImagem);
});
</script>

<template>
    <div id="relatorio-ocorrencia-especifica-view">
        <vias-button @click="emitirRelatorio" variant="primary">
            Emitir relatório em PDF
        </vias-button>

        <vias-card>
            <div v-if="!ocorrencia" class="spinner-carregando"></div>
            <div v-else class="relatorio" ref="relatorio">
                <div id="relatorio-ocorrencia-especifica">
                    <h1 id="titulo" class="text-center">
                        Autuação de Velocidade Média Educativa
                    </h1>

                    <div id="imagens">
                        <div class="imagem">
                            <div v-if="!links" class="spinner-carregando"></div>
                            <div v-else>
                                <img
                                    :src="links.primeiro"
                                    alt="Primeiro fluxo do veículo"
                                />
                                <h2 class="text-center">Primeiro fluxo</h2>
                            </div>
                        </div>
                        <div class="imagem">
                            <div v-if="!links" class="spinner-carregando"></div>
                            <div v-else>
                                <img
                                    :src="links.segundo"
                                    alt="Segundo fluxo do veículo"
                                />
                                <h2 class="text-center">Segundo fluxo</h2>
                            </div>
                        </div>
                    </div>

                    <div id="placa">
                        <h1>Placa: {{ ocorrencia.placa }}</h1>
                    </div>

                    <div id="rota">
                        <h1>Rota: {{ ocorrencia.rota.nome }}</h1>
                        <div>
                            <p>
                                <strong>Tempo entre os equipamentos:</strong>
                                {{
                                    formatar(
                                        ocorrencia.rota
                                            .tempo_entre_equipamentos,
                                    )
                                }}
                                segundos
                            </p>
                            <p>
                                <strong
                                    >Limite de velocidade média para o
                                    percurso:</strong
                                >
                                {{
                                    formatar(
                                        ocorrencia.rota.limite_de_velocidade,
                                    )
                                }}
                                km/h
                            </p>
                            <p>
                                <strong
                                    >Distância entre os equipamentos:</strong
                                >
                                {{
                                    formatar(
                                        ocorrencia.rota
                                            .distancia_entre_equipamentos,
                                    )
                                }}
                                metros
                            </p>
                        </div>
                    </div>

                    <div id="passagens-e-mapa">
                        <div id="passagens" ref="passagens">
                            <div>
                                <h1>Primeiro fluxo - A</h1>
                                <div>
                                    <p>
                                        <strong>Local:</strong>
                                        {{
                                            ocorrencia.rota.primeiro_equipamento
                                                .posicao.endereco
                                        }}
                                    </p>
                                    <p>
                                        <strong>Data/Hora:</strong>
                                        {{
                                            moment(
                                                ocorrencia.primeira_passagem_data,
                                            ).format("DD/MM/YYYY HH:mm:ss")
                                        }}
                                    </p>
                                    <p>
                                        <strong>Velocidade pontual:</strong>
                                        {{
                                            ocorrencia.primeira_passagem_velocidade
                                                ? ocorrencia.primeira_passagem_velocidade +
                                                  " km/h"
                                                : "Não consta"
                                        }}
                                    </p>
                                    <p>
                                        <strong>Velocidade permitida:</strong>
                                        {{
                                            ocorrencia.primeira_passagem_velocidade_permitida
                                                ? ocorrencia.primeira_passagem_velocidade_permitida +
                                                  " km/h"
                                                : "Não consta"
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <h1>Segundo fluxo - B</h1>
                                <div>
                                    <p>
                                        <strong>Local:</strong>
                                        {{
                                            ocorrencia.rota.segundo_equipamento
                                                .posicao.endereco
                                        }}
                                    </p>
                                    <p>
                                        <strong>Data/Hora:</strong>
                                        {{
                                            moment(
                                                ocorrencia.segunda_passagem_data,
                                            ).format("DD/MM/YYYY HH:mm:ss")
                                        }}
                                    </p>
                                    <p>
                                        <strong>Velocidade pontual:</strong>
                                        {{
                                            ocorrencia.segunda_passagem_velocidade
                                                ? ocorrencia.segunda_passagem_velocidade +
                                                  " km/h"
                                                : "Não consta"
                                        }}
                                    </p>
                                    <p>
                                        <strong>Velocidade permitida:</strong>
                                        {{
                                            ocorrencia.segunda_passagem_velocidade_permitida
                                                ? ocorrencia.segunda_passagem_velocidade_permitida +
                                                  " km/h"
                                                : "Não consta"
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="mapa">
                            <img
                                :src="imagemRota"
                                alt="Imagem da rota percorrida pelo veículo"
                            />
                        </div>
                    </div>

                    <div id="constatacao">
                        <h1>Constatação da Infração de Velocidade Média</h1>
                        <div>
                            <p>
                                <strong
                                    >Distância entre os equipamentos:</strong
                                >
                                {{
                                    formatar(
                                        ocorrencia.rota
                                            .distancia_entre_equipamentos,
                                    )
                                }}
                                metros
                            </p>
                            <p>
                                <strong
                                    >Tempo legal entre os equipamentos:</strong
                                >
                                {{
                                    formatar(
                                        ocorrencia.rota
                                            .tempo_entre_equipamentos,
                                    )
                                }}
                                segundos
                            </p>
                            <p>
                                <strong
                                    >Limite de velocidade média para o
                                    percurso:</strong
                                >
                                {{
                                    formatar(
                                        ocorrencia.rota.limite_de_velocidade,
                                    )
                                }}
                                km/h
                            </p>

                            <hr />

                            <p>
                                <strong>Tempo de travessia:</strong>
                                {{ formatar(ocorrencia.tempo_de_travessia) }}
                                segundos
                            </p>
                            <p>
                                <strong>Velocidade média pontual:</strong>
                                {{ Math.floor(ocorrencia.velocidade_medida) }}
                                km/h ({{
                                    Math.floor(
                                        (Math.floor(
                                            ocorrencia.velocidade_medida,
                                        ) /
                                            ocorrencia.rota
                                                .limite_de_velocidade) *
                                            100 -
                                            100,
                                    )
                                }}% acima do tempo permitido no trecho)
                            </p>
                            <p>
                                <strong>Velocidade média considerada:</strong>
                                {{
                                    Math.floor(
                                        ocorrencia.velocidade_considerada,
                                    )
                                }}
                                km/h ({{
                                    Math.floor(
                                        (Math.floor(
                                            ocorrencia.velocidade_considerada,
                                        ) /
                                            ocorrencia.rota
                                                .limite_de_velocidade) *
                                            100 -
                                            100,
                                    )
                                }}% acima do tempo permitido no trecho)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </vias-card>
    </div>
</template>
