<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-date
                        v-model="data_hora"
                        label="Data da busca"
                    ></vias-input-date>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button variant="primary" type="submit"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <div class="overflow-x-auto">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th rowspan="2">Equipamento</th>
                            <th rowspan="2">Data</th>
                            <th colspan="24" class="text-center">
                                Faixa de Horário
                            </th>
                        </tr>
                        <tr>
                            <th
                                v-for="i in 24"
                                :key="i"
                                class="border-radius-0"
                            >
                                {{ ajustarNumeroParaDuasCasas(i - 1) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(mes, i) in equipamento.meses"
                            :key="mes.data + equipamento.sitio"
                        >
                            <td
                                v-if="i === 0"
                                class="table-border-bottom border-right"
                                :rowspan="mes.mapa.length"
                            >
                                {{ equipamento.sitio }}
                                <br />
                                {{ equipamento.endereco }}
                            </td>
                            <td>{{ mes.data }}</td>
                            <td
                                v-for="valor in mes.mapa"
                                :key="valor + mes.data + equipamento.sitio"
                                :style="{
                                    'background-color': definirCorDaCelula(
                                        valor,
                                        mes.valor_maximo,
                                    ),
                                    color: definirCorDoTexto(valor),
                                }"
                            >
                                {{ valor }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasInputDate from "@/components/shared/ViasInputDate.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import {
    buscarRelatorioDeCalorDeExcessosDeVelocidade,
    emitirPdfDeCalorDeExcessosDeVelocidade,
} from "@/services/integrated-management.service";
import { close, open } from "@/services/notification.service";
import moment from "moment";
import { ref } from "vue";
import { ordenarArrayCrescente } from "@/services/array.service";

const data_hora = ref(moment().format("YYYY-MM-DDTHH:mm:ssZ"));

const relatorio = ref(null);

const buscarRelatorio = () => {
    open("Buscando relatório, por favor aguarte um momento...");
    buscarRelatorioDeCalorDeExcessosDeVelocidade(data_hora.value)
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = [];
            for (const [sitio, informacoes] of ordenarArrayCrescente(
                Object.entries(dados),
                "0",
            )) {
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    meses: [],
                };

                for (const [data, mapa] of ordenarArrayCrescente(
                    Object.entries(informacoes.grafico),
                    "0",
                )) {
                    const valoresMapa = Object.values(mapa);

                    const mes = {
                        data: moment(data).format("DD/MM/YYYY"),
                        mapa: [],
                        valor_maximo: valoresMapa.pop(),
                    };

                    for (let i = 0; i < 24; i++) {
                        mes.mapa.push(valoresMapa[i] || "Nulo");
                    }

                    equipamento.meses.push(mes);
                }

                relatorio.value.push(equipamento);
            }
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const ajustarNumeroParaDuasCasas = (n) => {
    if (typeof n !== "number") {
        return n;
    }
    return n < 10 ? `0${n}` : n;
};

const definirCorDaCelula = (valor, maximo) => {
    if (typeof valor !== "number") {
        return null;
    }

    const cores = [
        "#205CAA",
        "#276DAC",
        "#2E7DAE",
        "#358EB0",
        "#3B9FB2",
        "#56C798",
        "#6ED377",
        "#FFC973",
        "#FCC484",
        "#FFB493",
        "#FFA48C",
        "#FF9485",
        "#FA7068",
    ];

    if (valor === maximo) {
        return cores[cores.length - 1];
    }

    const percentual = valor / maximo;
    const indice = Math.floor(percentual * (cores.length - 1));
    return cores[percentual === 0 ? indice : indice + 1];
};

const definirCorDoTexto = (valor) => {
    if (typeof valor !== "number") {
        return null;
    }

    return "#16182E";
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDeCalorDeExcessosDeVelocidade(data_hora.value)
        .then((res) => res.data)
        .then(({ mensagem }) => {
            close(mensagem, "success");
        });
};
</script>
