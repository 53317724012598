<template>
    <div
        class="mapa__menu-lateral"
        :class="{ 'mapa__menu-lateral--open': isSecaoSelecionada }"
    >
        <ul class="mapa__menu-lateral-secoes">
            <li
                v-for="secao in secoes"
                :key="secao.nome"
                class="mapa__menu-lateral-secao"
                :class="{
                    'mapa__menu-lateral-secao--active':
                        secaoSelecionada?.nome === secao.nome,
                }"
                :title="secao.nome"
                @click="abrirSecao(secao)"
            >
                <i :title="secao.nome" :class="secao.icone"></i>
            </li>
        </ul>
        <div class="mapa__menu-lateral-conteudo p-4">
            <ViasLegendaMapa
                :class="{ 'd-none': secaoSelecionada?.alvo !== 'legenda' }"
                :habilitar-legenda-de-equipamentos="
                    habilitarLegendaDeEquipamentos
                "
            />
            <ViasLegendaAreas
                :class="{
                    'd-none': secaoSelecionada?.alvo !== 'legenda-areas',
                }"
            />
            <ViasMapaFiltroEquipamentos
                :habilitar-filtro-de-data-dos-equipamentos-estaticos="
                    habilitarFiltroDeDataDosEquipamentosEstaticos
                "
                :class="{
                    'd-none': secaoSelecionada?.alvo !== 'filtro-equipamentos',
                }"
            />
            <div
                v-for="secao in secoesAdicionais"
                :key="secao.alvo"
                :class="{ 'd-none': secaoSelecionada?.alvo !== secao.alvo }"
            >
                <slot :name="secao.alvo">
                    <h1>{{ secao.alvo }} 02</h1>
                </slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ISecaoMapaMenuLateral } from "@/interfaces/mapa/menu-lateral/ISecaoMapaMenuLateral";
import { computed, ref, withDefaults, defineProps, onMounted } from "vue";
import ViasLegendaMapa from "./ViasLegendaMapa.vue";
import ViasMapaFiltroEquipamentos from "./ViasMapaFiltroEquipamentos.vue";
import ViasLegendaAreas from "./ViasLegendaAreas.vue";

interface Props {
    habilitarLegendas?: boolean;
    habilitarLegendaDeEquipamentos?: boolean;
    habilitarLegendaDeAreas?: boolean;
    habilitarFiltroDeEquipamentos?: boolean;
    habilitarFiltroDeDataDosEquipamentosEstaticos?: boolean;
    secoesAdicionais?: ISecaoMapaMenuLateral[];
}

const props = withDefaults(defineProps<Props>(), {
    habilitarLegendas: true,
    habilitarLegendaDeEquipamentos: true,
    habilitarLegendaDeAreas: true,
    habilitarFiltroDeEquipamentos: true,
    habilitarFiltroDeDataDosEquipamentosEstaticos: true,
    secoesAdicionais: () => [],
});

const secoes = ref<ISecaoMapaMenuLateral[]>([]);
const secaoSelecionada = ref<ISecaoMapaMenuLateral | null>(null);

const isSecaoSelecionada = computed(() => secaoSelecionada.value !== null);

function abrirSecao(secao: ISecaoMapaMenuLateral) {
    if (secaoSelecionada.value?.nome === secao.nome) {
        secaoSelecionada.value = null;
        return;
    }
    secaoSelecionada.value = secao;
}

function gerarSecoes() {
    let secoesHabilitadas: ISecaoMapaMenuLateral[] = [];

    if (props.habilitarLegendas) {
        secoesHabilitadas.push({
            icone: "fas fa-scroll",
            alvo: "legenda",
            nome: "Legenda",
        });
    }

    if (props.habilitarLegendaDeAreas) {
        secoesHabilitadas.push({
            icone: "fas fa-layer-group",
            alvo: "legenda-areas",
            nome: "Legenda das áreas do mapa",
        });
    }

    if (props.habilitarFiltroDeEquipamentos) {
        secoesHabilitadas.push({
            icone: "fas fa-filter",
            alvo: "filtro-equipamentos",
            nome: "Filtro de equipamentos",
        });
    }

    if (props.secoesAdicionais.length > 0) {
        secoesHabilitadas = props.secoesAdicionais.concat(secoesHabilitadas);
    }

    secoes.value = secoesHabilitadas;
}

onMounted(() => {
    gerarSecoes();
});
</script>
