import { formatar } from "./numbers.service";
import { cores, padding } from "./variables.service";

export const tratarEquipamento = (equipamento) => ({
    id: equipamento.id,
    posicao: {
        lat: equipamento.posicao.latitude,
        lng: equipamento.posicao.longitude,
    },
    endereco: equipamento.posicao.endereco,
    sitio: equipamento.sitio.toUpperCase(),
    status: equipamento.status,
    ultima_passagem: equipamento.ultima_passagem,
    tipo: equipamento.tipo,
    selecionado: false,
});

export const tratarEquipamentoSemCamera = (equipamento) => ({
    id: equipamento.id,
    posicao: {
        lat: equipamento.posicao.latitude,
        lng: equipamento.posicao.longitude,
    },
    endereco: equipamento.posicao.endereco,
    sitio: equipamento.sitio.toUpperCase(),
    status: equipamento.status,
    ultima_passagem: equipamento.ultima_passagem,
    tipo: equipamento.tipo,
    selecionado: false,
});

export const tratarRotaDeVelocidadeMedia = (rota) => ({
    id: rota.id,
    nome: rota.nome,
    distancia_entre_equipamentos: rota.distancia_entre_equipamentos,
    tempo_entre_equipamentos: rota.tempo_entre_equipamentos,
    limite_de_velocidade: rota.limite_de_velocidade,
    inicio: {
        lat: rota.primeiro_equipamento.posicao.latitude,
        lng: rota.primeiro_equipamento.posicao.longitude,
    },
    fim: {
        lat: rota.segundo_equipamento.posicao.latitude,
        lng: rota.segundo_equipamento.posicao.longitude,
    },
    box: criarInfoVelocidadeMedia(rota),
});

const criarInfoVelocidadeMedia = (rota) => {
    return `
        <h3 class="text-background">${rota.nome}</h3>
        <h4 class="text-background">Origem: ${rota.primeiro_equipamento.sitio} - ${rota.primeiro_equipamento.posicao.endereco}</h4>
        <h4 class="text-background">Destino: ${rota.segundo_equipamento.sitio} - ${rota.segundo_equipamento.posicao.endereco}</h4>
        <hr>
        <h4 class="text-background">Tempo de percurso: ${formatar(rota.tempo_entre_equipamentos)} segundos</h4>
        <h4 class="text-background">Distância entre os equipamentos: ${formatar(rota.distancia_entre_equipamentos)} metros</h4>
        <h4 class="text-background">Velocidade limite: ${formatar(rota.limite_de_velocidade)} km/h</h4>
    `;
};

export const criarBox = (event) => {
    const div = document.createElement("div");

    div.style.border = "2px solid " + cores.dark;
    div.style.backgroundColor = "rgba(0, 0, 0, .5)";
    div.style.color = cores.background;
    div.style.padding = padding.padding_1;
    div.style.position = "absolute";
    div.style.top = event.originalEvent.clientY + 5 + "px";
    div.style.left = event.originalEvent.clientX + 5 + "px";
    div.style.zIndex = 901;

    return div;
};

export const infoEquipamento = (event, equipamento) => {
    const box = criarBox(event);
    box.innerHTML = equipamento.texto_box
        ? equipamento.texto_box
        : `${equipamento.sitio} - ${equipamento.endereco}`;
    return box;
};
