<template>

<ViasButton variant="link" @click="abrirModal()">
    {{ titulo }}
</ViasButton>

<ViasModal :show-button="false" :title="titulo" v-model="mostrarModal">
    <ul class="list">
        <li v-for="quantidade, chave in itens" :key="chave" class="justify-content-between">
            <slot :chave="chave" :quantidade="quantidade">
                <h3>{{ chave }}</h3>
                <div class="badge badge-primary">
                    {{ numerosService.formatarNumero(quantidade) }}
                </div>
            </slot>
        </li>
    </ul>
</ViasModal>

</template>

<script lang="ts" setup>
import numerosService from "@/domains/Numeros/services/NumerosService";
import { defineProps, ref } from "vue";

interface Props {
    titulo: string;
    itens: any;
}

defineProps<Props>();

const mostrarModal = ref(false);

function abrirModal() {
    mostrarModal.value = true;
}
</script>