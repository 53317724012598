<template>
    <button
        class="btn"
        :id="getButtonId"
        :type="type"
        :class="[hasVariant, isBlock, isActive, theme, hasSize]"
        @click="clickEvent"
    >
        <slot></slot>
    </button>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["type", "variant", "block", "active", "size", "id"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        hasVariant() {
            return this.variant ? `btn-${this.variant}` : "btn-default";
        },
        isBlock() {
            return typeof this.block == "string" ? "btn-block" : "";
        },
        isActive() {
            return typeof this.active == "boolean" && this.active == true
                ? "active"
                : "";
        },
        hasSize() {
            return typeof this.size == "string" ? `btn-${this.size}` : "";
        },
        getButtonId() {
            if (this.id) {
                return `id-${this.id}-button`;
            }
            return `id-${Math.floor(Math.random() * 1000000)}-button`;
        },
    },
    methods: {
        clickEvent() {
            this.$emit("clickEvent");
        },
    },
};
</script>
