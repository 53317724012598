<template>
    <vias-card>
        <h2 class="mb-4">Auditoria</h2>
        <form @submit.prevent="buscarAuditoria()">
            <div class="row">
                <div class="col col-12 p-0">
                    <vias-select
                        label="Selecionar usuário"
                        :options="usuarios_cadastrados"
                        v-model="usuario_selecionado"
                    ></vias-select>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 p-0">
                    <vias-button variant="primary" type="submit"
                        >Buscar auditoria</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <vias-card class="mt-4" v-if="usuario_buscado">
        <h2 class="mb-4">Auditoria do usuário: {{ usuario_buscado.text }}</h2>
        <vias-tabela-auditoria
            :linhas="linhas_auditoria"
        ></vias-tabela-auditoria>
    </vias-card>
</template>

<script>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasSelect from "@/components/shared/ViasSelect.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasTabelaAuditoria from "@/components/shared/ViasTabelaAuditoria.vue";
import {
    BUSCAR_USUARIOS,
    BUSCAR_AUDITORIA_DE_USUARIOS,
} from "@/store/tipo-acoes";
import moment from "moment";
import { getDiff } from "json-difference";

export default {
    name: "AuditoriaUsuarios",
    components: {
        ViasCard,
        ViasSelect,
        ViasButton,
        ViasTabelaAuditoria,
    },
    data: () => ({
        usuarios_cadastrados: [],
        usuario_selecionado: null,
        usuario_buscado: null,
        linhas_auditoria: [],
    }),
    methods: {
        buscarUsuariosCadastrados() {
            this.$store
                .dispatch(BUSCAR_USUARIOS)
                .then((res) => res.data)
                .then((usuarios) =>
                    usuarios.map((usuario) => ({
                        text: usuario.nome,
                        value: usuario.id,
                    })),
                )
                .then((usuarios) => {
                    this.usuarios_cadastrados = usuarios;
                });
        },
        buscarAuditoria() {
            if (!this.usuario_selecionado) {
                return;
            }

            this.$store
                .dispatch(
                    BUSCAR_AUDITORIA_DE_USUARIOS,
                    this.usuario_selecionado,
                )
                .then((res) => res.data)
                .then((auditoria) =>
                    auditoria.map((linha, i) => ({
                        numero_linha: i + 1,
                        data_hora: moment(linha.audit_log.data_hora).format(
                            "DD/MM/YYYY HH:mm:ss",
                        ),
                        autor: linha.audit_log.username,
                        tipo_de_acao: linha.entidade.tipo_de_alteracao,
                        acoes: this.tratarDiferencas(
                            this.gerarDiferencas(linha, auditoria[i - 1]),
                        ),
                    })),
                )
                .then((auditoria_formatada) => {
                    this.linhas_auditoria = auditoria_formatada;
                })
                .then(() => {
                    this.usuario_buscado = this.usuarios_cadastrados.find(
                        (usuario) => usuario.value == this.usuario_selecionado,
                    );
                });
        },
        gerarDiferencas(linha, linhaAnterior) {
            if (linha.entidade.tipo_de_alteracao == "Atualização") {
                let diff = null;
                let retorno = [];

                if (linhaAnterior) {
                    diff = getDiff(linhaAnterior.entidade, linha.entidade, {
                        isLodashLike: true,
                    });
                } else {
                    diff = getDiff({}, linha.entidade, { isLodashLike: true });
                }

                if (diff.edited.length > 0) {
                    retorno = retorno.concat(diff.edited);
                }
                if (diff.added.length > 0) {
                    retorno = retorno.concat(diff.added);
                }
                if (diff.removed.length > 0) {
                    retorno = retorno.concat(diff.removed);
                }

                return retorno;
            }
            return [["Usuário criado"]];
        },
        tratarDiferencas(diferencas) {
            return diferencas
                .filter((acao) => !acao[0].includes("tipo_de_alteracao"))
                .reduce((condensado, valores) => {
                    let resposta = [];
                    if (valores[0] == "Usuário criado") {
                        resposta.push({
                            textos: [
                                {
                                    variante: "primary",
                                    valor: "Usuário criado",
                                },
                            ],
                        });
                    } else if (valores[0].includes("desativada_em")) {
                        if (valores[0].includes(".")) {
                            resposta.push({
                                titulo: valores[0].split(".")[0],
                                textos: [
                                    {
                                        variante:
                                            valores[1] == null
                                                ? "primary"
                                                : "danger",
                                        valor:
                                            valores[1] == null
                                                ? "Usuário reativado"
                                                : "Usuário desativado",
                                    },
                                ],
                            });
                        } else {
                            resposta.push({
                                textos: [
                                    {
                                        variante:
                                            valores[1] == null
                                                ? "primary"
                                                : "danger",
                                        valor:
                                            valores[1] == null
                                                ? "Usuário reativado"
                                                : "Usuário desativado",
                                    },
                                ],
                            });
                        }
                    } else if (valores[0].includes("ativo")) {
                        resposta.push({
                            textos: [
                                {
                                    variante: valores[1] ? "primary" : "danger",
                                    valor: valores[1]
                                        ? "Usuário reativado"
                                        : "Usuário desativado",
                                },
                            ],
                        });
                    } else if (valores[0].includes("super_usuario")) {
                        resposta.push({
                            textos: [
                                {
                                    valor: valores[1]
                                        ? "Se tornou super usuário"
                                        : "Deixou de ser super usuário",
                                    variante: valores[1] ? "primary" : "danger",
                                },
                            ],
                        });
                    } else {
                        resposta.push({
                            titulo: valores[0].replaceAll("_", " "),
                            textos: [
                                {
                                    valor: valores[2],
                                    variante: "danger",
                                },
                                {
                                    valor: valores[1],
                                    variante: "primary",
                                },
                            ],
                        });
                    }
                    return [...condensado, ...resposta];
                }, []);
        },
    },
    created() {
        this.buscarUsuariosCadastrados();
    },
};
</script>
