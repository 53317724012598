<template>
    <div ref="mapHtml" class="w-100 h-100"></div>
    <slot v-if="ready"></slot>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from "vue";
import { Loader, LoaderOptions } from "google-maps";
import { useStore } from "@/storeTs";

export default defineComponent({
    name: "ViasGoogleMaps",
    expose: ["googleApi", "map"],
    data: () => ({
        ready: false,
        googleMaps: "nao carregado",
    }),
    computed: {
        contratoSelecionado(): any {
            return this.store.state.contrato_selecionado;
        },
    },
    methods: {
        carregarMapa() {
            if (this.$refs.mapHtml && this.googleApi) {
                this.map = new this.googleApi.maps.Map(
                    this.$refs.mapHtml as HTMLElement,
                    {
                        center: new this.googleApi.maps.LatLng(
                            this.contratoSelecionado.latitude,
                            this.contratoSelecionado.longitude,
                        ),
                        zoom: 10,
                        disableDefaultUI: true,
                    },
                );
                this.ready = true;
                this.map.addListener("mousemove", (e) => {
                    this.posicaoMouse.left = e.pixel.x;
                    this.posicaoMouse.top = e.pixel.y;
                });
            }
        },
    },
    async created() {
        this.googleMaps =
            sessionStorage.getItem("google-maps") || "nao carregado";

        if (this.googleMaps === "nao carregado") {
            this.googleMaps = "carregando";
            sessionStorage.setItem("google-maps", this.googleMaps);
            const loaderOptions: LoaderOptions = {
                libraries: ["visualization", "places"],
            };
            const loader = new Loader(
                "AIzaSyDHGqzvNFxYwebumsfQGE7udVPhoZcaXgA",
                loaderOptions,
            );
            this.googleApi = await loader.load();
            this.googleMaps = "carregado";
            sessionStorage.setItem("google-maps", this.googleMaps);
            this.carregarMapa();
        } else if (
            this.googleMaps === "carregando" ||
            this.googleMaps === "carregado"
        ) {
            const verificadorDeCarregamentoDoGoogleMaps = setInterval(() => {
                if (window.google) {
                    this.googleApi = window.google;
                    clearInterval(verificadorDeCarregamentoDoGoogleMaps);
                    this.carregarMapa();
                }
            }, 10);
        }
    },
    setup() {
        const store = useStore();
        const googleApi = ref<any>();
        const map = ref<any>();
        const posicaoMouse = ref<any>({
            left: 0,
            top: 0,
        });

        provide("googleApi", googleApi);
        provide("map", map);
        provide("posicaoMouse", posicaoMouse);

        return {
            store,
            googleApi,
            map,
            posicaoMouse,
        };
    },
});
</script>
