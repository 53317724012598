import { State } from "@/storeTs";
import { BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO } from "./type-actions";
import axios from "@/plugins/axios.plugin";
import { Module } from "vuex";

export const origemEDestinoAuditoriaModule: Module<State, State> = {
    actions: {
        [BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO](context, rota_id: number) {
            return axios.get(
                `/vias/v1/origem-destino/rotas/${rota_id}/audit-logs`,
            );
        },
    },
};
