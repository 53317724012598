class CoresService {

    listarCoresDisponiveis() {
        return {
            primary: "#7380EC",
            danger: "#EC7380",
            success: "#52BE80",
            warning: "#ECDF73",
            dark_warning: "#EC8D05",
            light: "rgba(132, 139, 200, 0.18)",
            background: "#ebecf2",
            intense_background: "#fcfcfc",
            dark: "#1D203B",
            intense_dark: "#16182e",
            dark_light: "rgba(#0E101D, 0.18)",
        }
    }

}

const coresService = new CoresService();

export default coresService;