import validadorDeTipo from "@/core/composables/ValidadorDeTipo";
import { FormularioParaBuscarRelatorioDeFluxoVeicular } from "@/domains/Fluxo/models/FormularioParaBuscarRelatorioDeFluxoVeicular";
import http from "@/http";

class VelocidadeService {

    private rotaBase = "/vias/v1/graficos/velocidade/por-classe";

    buscarFluxoPorVelocidadeSeparadoPorClasse(formulario: FormularioParaBuscarRelatorioDeFluxoVeicular) {
        const params = new URLSearchParams();
        Object.entries(formulario).forEach((entrada) => {
            if (this.isEntradaValidaParaRelatorioDeVelocidadesPorClasse(entrada[1])) {
                this.adicionarEntradaNosParametrosParaRelatorioDeVelocidadesPorClasse(params, entrada);
            }
        });
        return http.get(`${this.rotaBase}/fluxo`, {
            params: params
        });
    }
    
    isEntradaValidaParaRelatorioDeVelocidadesPorClasse(entrada) {
        if (validadorDeTipo.isNull(entrada)) {
            return false;
        }
        if (validadorDeTipo.isBoolean(entrada)) {
            return entrada;
        }
        if (validadorDeTipo.isNumber(entrada)) {
            return entrada > 0;
        }
        if (validadorDeTipo.isString(entrada) || validadorDeTipo.isArray(entrada)) {
            return entrada.length > 0;
        }
        return false;
    }

    adicionarEntradaNosParametrosParaRelatorioDeVelocidadesPorClasse(parametros: URLSearchParams, entrada: any[]): void {
        if (validadorDeTipo.isArray(entrada[1])) {
            entrada[1].forEach((valorDeEntrada) => {
                parametros.append(entrada[0], valorDeEntrada);
            });
            return;
        }
        parametros.append(entrada[0], entrada[1]);
    }


}

const velocidadeService = new VelocidadeService();

export default velocidadeService;