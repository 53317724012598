<template>
<button
    class="btn btn-helper"
    type="button"
    :title="'Clique para ver as explicações sobre: ' + titulo"
    @click="abrirPopupExplicacoes()"
>
    ?
</button>
<vias-modal
    :show-button="false"
    v-model="mostrarPopupExplicacoes"
    :title="'Explicação: ' + titulo"
>
    <div class="explicacoes">
        <slot name="explicacoes"></slot>
    </div>
</vias-modal>

</template>

<script lang="ts" setup>
import { ref, defineProps } from "vue";

interface Props {
    titulo: string;
}

const mostrarPopupExplicacoes = ref(false);
defineProps<Props>();

function abrirPopupExplicacoes() {
    mostrarPopupExplicacoes.value = true;
}

</script>