import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative mapa-formulario-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViasMapaGeral = _resolveComponent("ViasMapaGeral")!
  const _component_vias_col = _resolveComponent("vias-col")!
  const _component_vias_row = _resolveComponent("vias-row")!
  const _component_vias_vue_select = _resolveComponent("vias-vue-select")!
  const _component_HorariosSelect = _resolveComponent("HorariosSelect")!
  const _component_vias_input_date_and_time_range = _resolveComponent("vias-input-date-and-time-range")!
  const _component_vias_button = _resolveComponent("vias-button")!
  const _component_ViasButton = _resolveComponent("ViasButton")!
  const _component_FormularioAgendamentoRelatorioOrigemEDestino = _resolveComponent("FormularioAgendamentoRelatorioOrigemEDestino")!
  const _component_ViasModal = _resolveComponent("ViasModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.buscarRelatorio()), ["prevent"])),
      novalidate: ""
    }, [
      _createVNode(_component_vias_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_vias_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ViasMapaGeral, {
                  rotas: [_ctx.getCoordenadasRota],
                  idsEquipamentosSelecionados: 
                        _ctx.idsEquipamentosSelecionados
                    ,
                  onClickEquipamento: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selecionarEquipamento($event)))
                }, null, 8, ["rotas", "idsEquipamentosSelecionados"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_vias_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_vias_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_vias_vue_select, {
                label: "Selecionar uma rota já cadastrada",
                options: _ctx.rotasCadastradasOptions,
                modelValue: _ctx.rotaCadastradaSelecionada,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rotaCadastradaSelecionada) = $event)),
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mudarRotaApresentadaParaARotaSelecionada()))
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_vias_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_vias_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_HorariosSelect, {
                modelValue: _ctx.horarioSelecionado,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.horarioSelecionado) = $event)),
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.alterarDataSelecionadaComHorarioJaCriado($event)))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_vias_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_vias_col, { class: "p-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_vias_input_date_and_time_range, {
                label: "Período do relatório",
                modelValue: _ctx.data,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data) = $event)),
                valid: _ctx.isDataValida,
                detail: _ctx.getDataMessage,
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.desselecionarHorario())),
                required: ""
              }, null, 8, ["modelValue", "valid", "detail"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_vias_row, { class: "mt-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_vias_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_vias_button, {
                variant: "primary",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Buscar ")
                ]),
                _: 1
              }),
              _createVNode(_component_ViasButton, {
                class: "ml-2",
                variant: "outline-primary",
                type: "button",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.abrirModalDeAgendamento()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Agendar ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 32),
    _createVNode(_component_ViasModal, {
      title: "Agendamento de relatório de origem e destino",
      "show-button": false,
      modelValue: _ctx.modalDeAgendamentoAberto,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalDeAgendamentoAberto) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormularioAgendamentoRelatorioOrigemEDestino, {
          onRelatorioAgendado: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fecharModalDeAgendamento()))
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}