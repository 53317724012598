<template>

<form @submit.prevent="buscarRelatorio()" novalidate>
    <vias-row>
        <vias-col>
            <div class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    :rotas="[getCoordenadasRota]"
                    :idsEquipamentosSelecionados="
                        idsEquipamentosSelecionados
                    "
                    @click-equipamento="selecionarEquipamento($event)"
                />
            </div>
        </vias-col>
    </vias-row>
    <vias-row>
        <vias-col>
            <vias-vue-select
                label="Selecionar uma rota já cadastrada"
                :options="rotasCadastradasOptions"
                v-model="rotaCadastradaSelecionada"
                @change="mudarRotaApresentadaParaARotaSelecionada()"
            />
        </vias-col>
    </vias-row>
    <vias-row>
        <vias-col>
            <HorariosSelect
                v-model="horarioSelecionado"
                @change="alterarDataSelecionadaComHorarioJaCriado($event)"
            />
        </vias-col>
    </vias-row>
    <vias-row>
        <vias-col class="p-0">
            <vias-input-date-and-time-range
                label="Período do relatório"
                v-model="data"
                :valid="isDataValida"
                :detail="getDataMessage"
                @change="desselecionarHorario()"
                required
            ></vias-input-date-and-time-range>
        </vias-col>
    </vias-row>
    <vias-row class="mt-3">
        <vias-col>
            <vias-button variant="primary" type="submit">
                Buscar
            </vias-button>
            <ViasButton
                class="ml-2"
                variant="outline-primary"
                type="button"
                @click="abrirModalDeAgendamento()"
            >
                Agendar
            </ViasButton>
        </vias-col>
    </vias-row>
</form>

<ViasModal
    title="Agendamento de relatório de origem e destino"
    :show-button="false"
    v-model="modalDeAgendamentoAberto"
>
    <FormularioAgendamentoRelatorioOrigemEDestino
        @relatorio-agendado="fecharModalDeAgendamento()"
    />
</ViasModal>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/storeTs";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { useNotificacao } from "@/hooks/notificacao.hook";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import { IEquipamentoCompleto } from "@/interfaces/equipamento/IEquipamentoCompleto";
import { BUSCAR_EQUIPAMENTO_POR_ID } from "@/storeTs/equipamentos/type-actions";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import ViasVueSelect from "@/components/shared/ViasVueSelect.vue";
import { ISelectOption } from "@/interfaces/select/ISelectOption";
import { IRotaOrigemEDestino } from "@/views/OrigemEDestino/interfaces/IRotaOrigemEDestino";
import { BUSCAR_ROTAS_ORIGEM_E_DESTINO } from "@/store/tipo-acoes";
import datetimeService from "@/core/composables/DatetimeService";
import HorariosSelect from "@/domains/Horarios/components/HorariosSelect.vue";
import FormularioAgendamentoRelatorioOrigemEDestino from "./FormularioAgendamentoRelatorioOrigemEDestino.vue";

export default defineComponent({
    name: "FormularioRelatorioDadosApurados",
    emits: ["buscarRelatorio"],
    components: {
        ViasMapaGeral,
        ViasVueSelect,
        HorariosSelect,
        FormularioAgendamentoRelatorioOrigemEDestino,
    },
    data: () => ({
        origem: null as IEquipamentoCompleto | null,
        destino: null as IEquipamentoCompleto | null,
        idsEquipamentosSelecionados: [] as number[],
        rotaMapa: null as IRotaMapa | null,
        rotasCadastradasOptions: [] as ISelectOption[],
        rotasCadastradas: [] as IRotaOrigemEDestino[],
        rotaCadastradaSelecionada: null as null | number,
        horarioSelecionado: 0,
        data: [] as string[],
        modalDeAgendamentoAberto: false,
    }),
    computed: {
        getCoordenadasRota(): IRotaMapa {
            if (this.origem && this.destino) {
                return {
                    id: 1,
                    inicio: {
                        lat: this.origem.posicao.latitude,
                        lng: this.origem.posicao.longitude,
                    },
                    fim: {
                        lat: this.destino.posicao.latitude,
                        lng: this.destino.posicao.longitude,
                    },
                };
            }
            return {
                id: 1,
            };
        },
        isOrigemValida(): boolean | null {
            if (this.v$.origem.$dirty) {
                return !this.v$.origem.$invalid;
            }
            return null;
        },
        getOrigemMessage(): string | null {
            return this.v$.origem.$errors[0]?.$message;
        },
        isDestinoValida(): boolean | null {
            if (this.v$.destino.$dirty) {
                return !this.v$.destino.$invalid;
            }
            return null;
        },
        getDestinoMessage(): string | null {
            return this.v$.destino.$errors[0]?.$message;
        },
        isDataValida(): boolean | null {
            if (this.v$.data.$dirty) {
                return !this.v$.data.$invalid;
            }
            return null;
        },
        getDataMessage(): string | null {
            return this.v$.data.$errors[0]?.$message;
        },
    },
    methods: {
        async buscarRotasCadastradasDeOrigemEDestino() {
            const response = await this.store.dispatch(
                BUSCAR_ROTAS_ORIGEM_E_DESTINO,
            );
            this.rotasCadastradas = response.data;
            this.rotasCadastradasOptions = response.data.map(
                (rota: IRotaOrigemEDestino): ISelectOption => ({
                    text: rota.nome,
                    value: rota.id,
                }),
            );
        },
        mudarRotaApresentadaParaARotaSelecionada() {
            const rota = this.rotasCadastradas.find(
                (rota) => rota.id === this.rotaCadastradaSelecionada,
            );
            if (rota) {
                this.selecionarEquipamentoComoOrigem(
                    rota.primeiro_equipamento.equipamento,
                );
                this.selecionarEquipamentoComoDestino(
                    rota.segundo_equipamento.equipamento,
                );
            }
        },
        verificarEquipamentosSelecionados() {
            this.idsEquipamentosSelecionados = [
                this.origem?.id || 0,
                this.destino?.id || 0,
            ];
            this.atualizarRota();
        },
        atualizarRota() {
            if (!this.origem) {
                return;
            }
            if (!this.destino) {
                return;
            }
            this.rotaMapa = {
                id: 1,
                inicio: {
                    lat: this.origem.posicao.latitude,
                    lng: this.origem.posicao.longitude,
                },
                fim: {
                    lat: this.destino.posicao.latitude,
                    lng: this.destino.posicao.longitude,
                },
            };
        },
        selecionarEquipamento(equipamento: IEquipamento) {
            if (equipamento.id === this.origem?.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}", já está selecionado como origem,
                    deseja remover essa seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.origem = null;
                        this.rotaCadastradaSelecionada = null;
                        this.verificarEquipamentosSelecionados();
                    }
                });
                return;
            }
            if (equipamento.id === this.destino?.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}", já está selecionado como destino,
                    deseja remover essa seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.destino = null;
                        this.rotaCadastradaSelecionada = null;
                        this.verificarEquipamentosSelecionados();
                    }
                });
                return;
            }
            this.apresentarOpcoesDeEscolha(
                `Deseja selecionar o equipamento "${equipamento.sitio}", do endereço "${equipamento.posicao.endereco}",
                como origem ou como destino?`,
                "Origem",
                "Destino",
            ).then((opcaoSelecionada: string) => {
                if (opcaoSelecionada) {
                    if (opcaoSelecionada === "Origem") {
                        this.selecionarEquipamentoComoOrigem(equipamento);
                    }
                    if (opcaoSelecionada === "Destino") {
                        this.selecionarEquipamentoComoDestino(equipamento);
                    }
                    this.rotaCadastradaSelecionada = null;
                    this.verificarEquipamentosSelecionados();
                }
            });
        },
        selecionarEquipamentoComoOrigem(equipamento: IEquipamento) {
            this.store
                .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                .then((res) => {
                    this.origem = res.data;
                });
        },
        selecionarEquipamentoComoDestino(equipamento: IEquipamento) {
            this.store
                .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                .then((res) => {
                    this.destino = res.data;
                });
        },
        alterarDataSelecionadaComHorarioJaCriado(horario: string[]) {
            this.data[0] = datetimeService.setTimeOnDatetime(this.data[0], horario[0]);
            this.data[1] = datetimeService.setTimeOnDatetime(this.data[1], horario[1]);
        },
        desselecionarHorario() {
            this.horarioSelecionado = 0;
        },
        async buscarRelatorio() {
            const isFormularioValido = await this.v$.$validate();
            if (isFormularioValido) {
                this.$emit("buscarRelatorio", {
                    origem: this.origem,
                    destino: this.destino,
                    data_inicial: this.data[0],
                    data_final: this.data[1],
                });
            }
        },
        abrirModalDeAgendamento() {
            this.modalDeAgendamentoAberto = true;
        },
        fecharModalDeAgendamento() {
            this.modalDeAgendamentoAberto = false;
        }
    },
    watch: {
        origem() {
            this.verificarEquipamentosSelecionados();
        },
        destino() {
            this.verificarEquipamentosSelecionados();
        },
    },
    validations() {
        return {
            origem: {
                required: helpers.withMessage(
                    "É necessário selecionar um equipamento de origem",
                    required,
                ),
            },
            destino: {
                required: helpers.withMessage(
                    "É necessário selecionar um equipamento de destino",
                    required,
                ),
            },
            data: {
                required: helpers.withMessage(
                    "É necessário selecionar um período de tempo",
                    required,
                ),
                minLength: helpers.withMessage(
                    "É necessário selecionar um período de tempo",
                    minLength(2),
                ),
            },
        };
    },
    created() {
        this.buscarRotasCadastradasDeOrigemEDestino();
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();
        const { apresentarOpcoesDeEscolha, questionarAcao } = useNotificacao();

        return {
            store,
            v$,
            apresentarOpcoesDeEscolha,
            questionarAcao,
        };
    },
});
</script>
