<template>

<ViasCard>
    <FormularioRelatorioVolumeMedioDiario
        @apresentar-relatorio="apresentarRelatorio($event)"
        v-model:ultima-busca="ultimaBusca"
    />
</ViasCard>

<ViasCard v-if="relatorioGerado" class="mt-4">
    <ViasRow>
        <ViasCol :cols="12" :md="6">
            <ViasButton variant="outline-primary" @click="emitirPdfDoRelatorioGerado()" block>
                Emitir PDF
            </ViasButton>
        </ViasCol>
        <ViasCol :cols="12" :md="6">
            <ViasButton variant="outline-primary" @click="emitirExcelDoRelatorioGerado()" block>
                Emitir Excel
            </ViasButton>
        </ViasCol>
    </ViasRow>
</ViasCard>

<ApresentacaoRelatorioVolumeMedioDiario
    v-if="relatorioGerado"
    :relatorio="relatorioGerado"
/>

</template>

<script lang="ts" setup>
import { ref } from 'vue';
import FormularioRelatorioVolumeMedioDiario from '../components/FormularioRelatorioVolumeMedioDiario.vue';
import { RelatorioVolumeMedioDiario } from '../models/RelatorioVolumeMedioDiario';
import ApresentacaoRelatorioVolumeMedioDiario from '../components/ApresentacaoRelatorioVolumeMedioDiario.vue';
import { FormularioBuscaRelatorioVolumeMedioDiario } from '../models/FormularioBuscaRelatorioVolumeMedioDiario';
import volumeMedioDiarioService from '../services/VolumeMedioDiarioService';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';

const relatorioGerado = ref<RelatorioVolumeMedioDiario>();
const ultimaBusca = ref<FormularioBuscaRelatorioVolumeMedioDiario>();

function apresentarRelatorio(relatorio: RelatorioVolumeMedioDiario) {
    relatorioGerado.value = relatorio;
}

async function emitirPdfDoRelatorioGerado() {
    if (ultimaBusca.value) {
        notificacaoService.notificarCarregamento("Emitindo PDF do relatório apresentado, aguarde um momento por favor...");
        const { data } = await volumeMedioDiarioService.emitirPdfRelatorioVolumeMedioDiario(ultimaBusca.value);
        notificacaoService.notificarSucesso(data.mensagem);
    }
}

async function emitirExcelDoRelatorioGerado() {
    if (ultimaBusca.value) {
        notificacaoService.notificarCarregamento("Emitindo excel do relatório apresentado, aguarde um momento por favor...");
        const { data } = await volumeMedioDiarioService.emitirExcelRelatorioVolumeMedioDiario(ultimaBusca.value);
        console.log(data);
        notificacaoService.notificarSucesso(data.mensagem);
    }
}
</script>