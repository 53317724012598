<template>

<div class="d-flex gap-4">
    <ViasRow>
        <ViasCol :cols="12">
            <div class="border border-radius-1 grafico-de-relatorio d-flex flex-column g-3">
                <div class="cabecalho">
                    <CabecalhoRelatorioDeFluxoVeicular
                        :periodo-de-busca="periodoDeBusca"
                        :data-hora-da-busca="dataHoraDaBusca"
                        @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaCima($event)"
                    />
                </div>
                <ViasRow>
                    <ViasCol :cols="12" class="p-4">
                        <ViasBarChart
                            v-if="mostrarGraficoEmColuna"
                            eixo_x="Velocidades"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                        />
                        <ViasLineChart
                            v-else
                            eixo_x="Velocidades"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                        />
                    </ViasCol>
                </ViasRow>
            </div>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <div class="border border-radius-1 grafico-de-relatorio d-flex flex-column g-3">
                <div class="cabecalho">
                    <CabecalhoRelatorioDeFluxoVeicular
                        :periodo-de-busca="periodoDeBusca"
                        :data-hora-da-busca="dataHoraDaBusca"
                        @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaCima($event)"
                    />
                </div>
                <ViasRow>
                    <ViasCol :cols="12" class="p-4">
                        <ViasHistograma
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                            eixo_x="Velocidades"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                        />
                    </ViasCol>
                </ViasRow>
            </div>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasTable
                :colunas="colunas"
                :linhas="linhas"
            />
        </ViasCol>
    </ViasRow>
</div>

</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits } from 'vue';
import ViasLineChart from '@/components/shared/ViasLineChart.vue';
import coresService from '@/core/composables/CoresService';
import { IColunaTabela } from '@/interfaces/IColunaTabela';
import ViasTable from '@/components/shared/ViasTable.vue';
import numerosService from '@/domains/Numeros/services/NumerosService';
import CabecalhoRelatorioDeFluxoVeicular from './CabecalhoRelatorioDeFluxoVeicular.vue';
import ViasHistograma from '@/components/shared/ViasHistograma.vue';
import ViasBarChart from '@/components/shared/ViasBarChart.vue';
import { PeriodoDeRelatorio } from '../models/PeriodoDoRelatorioDeFluxoVeicular';

interface Props {
    periodoDeBusca: PeriodoDeRelatorio;
    dataHoraDaBusca: string;
    mostrarGraficoEmColuna: boolean;
    mostrarValoresNoGrafico: boolean;
    relatorio: any;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    alterarFormatoDeVisualizacaoParaCima: [periodo: PeriodoDeRelatorio]
}>();

const labels = computed(() => {
    return Object.keys(props.relatorio).filter((chave) => chave !== "total");
});

const datasets = computed(() => {
    return gerarDatasetsDeFluxo();
});

function gerarDatasetsDeFluxo() {
    return [{
        label: "Fluxo",
        backgroundColor: coresService.listarCoresDisponiveis().success,
        borderColor: coresService.listarCoresDisponiveis().success,
        data: labels.value.map((chave) => props.relatorio[chave])
    }];
}

const colunas = computed((): IColunaTabela[] => {
    return [
        {
            titulo: '#',
            referencia: 'numero_da_linha'
        },
        {
            titulo: 'Velocidade',
            referencia: 'identificador'
        },
        {
            titulo: 'Fluxo',
            referencia: 'fluxo'
        },
        {
            titulo: 'Fluxo (%)',
            referencia: 'fluxo_porcentagem'
        }
    ];
});

const linhas = computed(() => {
    const totalDeFluxo = props.relatorio.total;
    return Object.entries(props.relatorio).map(([identificador, fluxo]: any, i) => {
        return {
            numero_da_linha: i + 1,
            identificador: identificador,
            fluxo: numerosService.formatarNumero(fluxo),
            fluxo_porcentagem: numerosService.formatarNumero((fluxo / Math.max(totalDeFluxo, 1)) * 100) + "%"
        };
    });
});

function alterarFormatoDeVisualizacaoParaCima(novoPeriodo: PeriodoDeRelatorio) {
    emit("alterarFormatoDeVisualizacaoParaCima", novoPeriodo);
}
</script>