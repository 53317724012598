<template>
    <vias-card>
        <h2 class="mb-4">Auditoria</h2>
        <form @submit.prevent="buscarAuditoria()">
            <div class="row">
                <div class="col col-12 p-0">
                    <vias-select
                        label="Selecionar rota"
                        :options="rotas_cadastradas"
                        v-model="rota_selecionada"
                    ></vias-select>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 p-0">
                    <vias-button variant="primary" type="submit"
                        >Buscar auditoria</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <vias-card class="mt-4" v-if="rota_buscada">
        <h2 class="mb-4">Auditoria da rota: {{ rota_buscada.text }}</h2>
        <vias-tabela-auditoria
            :linhas="linhas_auditoria"
        ></vias-tabela-auditoria>
    </vias-card>
</template>

<script>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasSelect from "@/components/shared/ViasSelect.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasTabelaAuditoria from "@/components/shared/ViasTabelaAuditoria.vue";
import {
    BUSCAR_ROTAS_ORIGEM_E_DESTINO,
    BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO,
} from "@/store/tipo-acoes";
import moment from "moment";
import { getDiff } from "json-difference";

export default {
    name: "AuditoriaOrigemEDestino",
    components: {
        ViasCard,
        ViasSelect,
        ViasButton,
        ViasTabelaAuditoria,
    },
    data: () => ({
        rotas_cadastradas: [],
        rota_selecionada: null,
        rota_buscada: null,
        linhas_auditoria: [],
    }),
    methods: {
        buscarRotasCadastradas() {
            this.$store
                .dispatch(BUSCAR_ROTAS_ORIGEM_E_DESTINO)
                .then((res) => res.data)
                .then((rotas) =>
                    rotas.map((rota) => ({
                        text: rota.nome,
                        value: rota.id,
                    })),
                )
                .then((rotas) => {
                    this.rotas_cadastradas = rotas;
                });
        },
        buscarAuditoria() {
            if (!this.rota_selecionada) {
                return;
            }

            this.$store
                .dispatch(
                    BUSCAR_AUDITORIA_DE_ORIGEM_E_DESTINO,
                    this.rota_selecionada,
                )
                .then((res) => res.data)
                .then((auditoria) =>
                    auditoria.map((linha, i) => ({
                        numero_linha: i + 1,
                        data_hora: moment(linha.audit_log.data_hora).format(
                            "DD/MM/YYYY HH:mm:ss",
                        ),
                        autor: linha.audit_log.username,
                        tipo_de_acao: linha.entidade.tipo_de_alteracao,
                        acoes: this.tratarDiferencas(
                            this.gerarDiferencas(linha, auditoria[i - 1]),
                        ),
                    })),
                )
                .then((auditoria_formatada) => {
                    this.linhas_auditoria = auditoria_formatada;
                })
                .then(() => {
                    this.rota_buscada = this.rotas_cadastradas.find(
                        (rota) => rota.value == this.rota_selecionada,
                    );
                });
        },
        gerarDiferencas(linha, linhaAnterior) {
            if (linha.entidade.tipo_de_alteracao == "Atualização") {
                if (linhaAnterior) {
                    return getDiff(linha.entidade, linhaAnterior.entidade, {
                        isLodashLike: true,
                    }).edited;
                }
                return getDiff(linha.entidade, {}, { isLodashLike: true });
            }
            return [["Rota criada"]];
        },
        tratarDiferencas(diferencas) {
            return diferencas
                .filter((acao) => !acao[0].includes("tipo_de_alteracao"))
                .reduce((condensado, valores) => {
                    let resposta = [];
                    if (valores[0] == "Rota criada") {
                        resposta.push({
                            textos: [
                                {
                                    variante: "primary",
                                    valor: "Rota criada",
                                },
                            ],
                        });
                    } else if (valores[0].includes("desativada_em")) {
                        if (valores[0].includes(".")) {
                            resposta.push({
                                titulo: valores[0].split(".")[0],
                                textos: [
                                    {
                                        variante:
                                            valores[1] == null
                                                ? "primary"
                                                : "danger",
                                        valor:
                                            valores[1] == null
                                                ? "Rota reativada"
                                                : "Rota desativada",
                                    },
                                ],
                            });
                        } else {
                            resposta.push({
                                textos: [
                                    {
                                        variante:
                                            valores[1] == null
                                                ? "primary"
                                                : "danger",
                                        valor:
                                            valores[1] == null
                                                ? "Rota reativada"
                                                : "Rota desativada",
                                    },
                                ],
                            });
                        }
                    } else {
                        resposta.push({
                            titulo: valores[0].replaceAll("_", " "),
                            textos: [
                                {
                                    valor: valores[2],
                                    variante: "danger",
                                },
                                {
                                    valor: valores[1],
                                    variante: "primary",
                                },
                            ],
                        });
                    }
                    return [...condensado, ...resposta];
                }, []);
        },
    },
    created() {
        this.buscarRotasCadastradas();
    },
};
</script>
