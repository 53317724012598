import * as IntegratedManagementService from "@/services/integrated-management.service";

const IntegratedManagementPlugin = {
    install(app) {
        app.config.globalProperties.$gestao_integrada =
            IntegratedManagementService;
    },
};

export default IntegratedManagementPlugin;
