<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Novo usuário</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="
                                voltarParaApresentacaoDeUsuariosCadastrados()
                            "
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <ViasRow>
                    <ViasCol>
                        <FormularioCadastroDeUsuario
                            @usuario-adicionado="voltarParaApresentacaoDeUsuariosCadastrados()"
                        />
                    </ViasCol>
                </ViasRow>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts" setup>
import FormularioCadastroDeUsuario from "../components/FormularioCadastroDeUsuario.vue";
import { useRouter } from "vue-router";

const router = useRouter();

function voltarParaApresentacaoDeUsuariosCadastrados() {
    router.push("/configuracao/usuarios");
}
</script>
