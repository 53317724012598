<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Nova rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDeRotasCadastradas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <ViasRow>
                    <ViasCol>
                        <FormularioNovaRotaVelocidadeMedia
                            @adicionar-rota="adicionarRota($event)"
                        ></FormularioNovaRotaVelocidadeMedia>
                    </ViasCol>
                </ViasRow>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { INovaRotaVelocidadeMedia } from "./interfaces/INovaRotaVelocidadeMedia";
import FormularioNovaRotaVelocidadeMedia from "./components/FormularioNovaRotaVelocidadeMedia.vue";
import { useStore } from "@/storeTs";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { ADICIONAR_ROTA_VELOCIDADE_MEDIA } from "@/storeTs/velocidade-media/configuracao/type-actions";

export default defineComponent({
    name: "NovaRotaVelocidadeMediaView",
    components: {
        FormularioNovaRotaVelocidadeMedia,
    },
    methods: {
        voltarParaAApresentacaoDeRotasCadastradas() {
            this.router.push("/velocidade-media/configuracao");
        },
        adicionarRota(novaRota: INovaRotaVelocidadeMedia) {
            this.notificarCarregamento(
                "Estamos enviando a nova rota, aguarde um momento por favor...",
            );
            this.store
                .dispatch(ADICIONAR_ROTA_VELOCIDADE_MEDIA, novaRota)
                .then((res) => {
                    if (res.status === 201) {
                        this.notificar(
                            "Rota adicionada com sucesso!",
                            "success",
                        );
                        this.voltarParaAApresentacaoDeRotasCadastradas();
                    }
                });
        },
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
