import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-bold" }
const _hoisted_2 = { class: "text-background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViasGoogleMapsMarcador = _resolveComponent("ViasGoogleMapsMarcador")!

  return (_openBlock(), _createBlock(_component_ViasGoogleMapsMarcador, {
    position: _ctx.equipamento.posicao,
    icon: _ctx.icone,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickEquipamento()))
  }, {
    box: _withCtx(() => [
      _renderSlot(_ctx.$slots, "personalizar-box", {}, () => [
        _createElementVNode("strong", _hoisted_1, _toDisplayString(_ctx.equipamento.sitio), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.equipamento.endereco), 1),
        _renderSlot(_ctx.$slots, "informacoes-adicionais")
      ])
    ]),
    _: 3
  }, 8, ["position", "icon"]))
}