<template>
    <div class="form-group datepicker">
        <label :for="id">{{ label }}</label>
        <datepicker
            :id="id"
            class="w-100"
            v-model="value"
            :dark="theme"
            @update:model-value="updateValue($event)"
            format="MM/yyyy"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            month-name-format="long"
            auto-apply
            month-picker
            week-start="0"
            week-numbers="iso"
            week-num-name="sem"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";

export default {
    props: ["modelValue", "label", "id"],
    components: {
        datepicker: Datepicker,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme === "dark-theme",
        }),
    },
    data() {
        return {
            value: {
                month: moment(this.modelValue).format("MM") - 1,
                year: moment(this.modelValue).format("YYYY"),
            },
            primeira_data: null,
            moment,
        };
    },
    watch: {
        modelValue() {
            this.value = {
                month: moment(this.modelValue).format("MM") - 1,
                year: moment(this.modelValue).format("YYYY"),
            };
        },
    },
    created() {
        this.buscarPrimeiraData();
        this.value = {
            month: moment(this.modelValue).format("MM") - 1,
            year: moment(this.modelValue).format("YYYY"),
        };
    },
    methods: {
        buscarPrimeiraData() {
            this.$gestao_integrada
                .buscarPrimeiraData()
                .then((res) => res.data.data_hora)
                .then((dataHora) => (this.primeira_data = dataHora));
        },
        updateValue(update) {
            console.log(
                moment()
                    .set("month", update.month)
                    .set("year", update.year)
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
            );
            this.$emit(
                "update:modelValue",
                moment()
                    .set("month", update.month)
                    .set("year", update.year)
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
            );
        },
    },
};
</script>
