import http from "@/http";
import { AxiosResponse } from "axios";
import { TotalDeEquipamentosEFaixasSeparadasPorTipo } from "../models/TotalDeEquipamentosEFaixasSeparadasPorTipo";
import moment from "moment";
import { EquipamentoComFaixa } from "../models/EquipamentoComFaixa";
import { Equipamento } from "../models/Equipamento";
import { EquipamentoComFaixaEPosicao } from "../models/EquipamentoComFaixaEPosicao";
import { EquipamentoComPosicao } from "../models/EquipamentoComPosicao";

class EquipamentoService {

    private rotaBase = "/vias/v1/equipamentos";

    buscarTotalDeEquipamentosSeparadosPorTipo(): 
        Promise<AxiosResponse<TotalDeEquipamentosEFaixasSeparadasPorTipo>> {
        return http.get(`${this.rotaBase}/tipos/quantidade`);
    }

    buscarEquipamentos(): Promise<AxiosResponse<EquipamentoComPosicao[]>> {
        return http.get(this.rotaBase);
    }

    buscarEquipamentosComAsFaixas(): Promise<AxiosResponse<EquipamentoComFaixa[]>> {
        return http.get(`${this.rotaBase}/afericoes`);
    }

    buscarEquipamentosEstaticos(data: string = moment().format("YYYY-MM-DD")) {
        return http.get(`${this.rotaBase}/estaticos`, {
            params: {
                data: data
            }
        });
    }

    buscarEquipamentosFixos(tipos: string[] = []) {
        return http.get(`${this.rotaBase}/fixo`, {
            params: {
                tipos: tipos
            }
        })
    }

    buscarEquipamentosFixosEEstaticosComFaixasEPosicao(
        tipos: string[] = []
    ) {
        const params = this.gerarParametrosParaABuscaDeEquipamentosFixosEEstaticosComFaixasEPosicao(
            tipos
        );

        return http.get(`${this.rotaBase}/posicao/afericoes`, {
            params: params
        });
    }

    filtrarEquipamentosComFaixasEPosicao(
        equipamentos: EquipamentoComFaixaEPosicao[],
        filtro: string
    ): EquipamentoComFaixaEPosicao[] {
        const regex = new RegExp(filtro, "i");
        return equipamentos.filter((equipamento) => {
            if (regex.exec(equipamento.sitio)) {
                return true;
            }
            return equipamento.afericoes.find((faixa) => {
                return regex.exec(faixa.endereco) || regex.exec(faixa.sentido);
            });
        });
    }

    filtrarEquipamentosComFaixas(
        equipamentos: EquipamentoComFaixa[],
        filtro: string
    ): EquipamentoComFaixa[] {
        const regex = new RegExp(filtro, "i");
        return equipamentos.filter((equipamento) => {
            if (regex.exec(equipamento.sitio)) {
                return true;
            }
            return equipamento.afericoes.find((faixa) => {
                return regex.exec(faixa.endereco) || regex.exec(faixa.sentido);
            });
        });
    }

    private gerarParametrosParaABuscaDeEquipamentosFixosEEstaticosComFaixasEPosicao(
        tiposDeEquipamento: string[]
    ): URLSearchParams {
        const params = new URLSearchParams();

        tiposDeEquipamento.forEach((tipo) => {
            params.append("tipos", tipo);
        });

        return params;
    }

}

const equipamentoService = new EquipamentoService();

export default equipamentoService;