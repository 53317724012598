import { Module } from "vuex";
import { State } from "../..";
import {
    ADICIONAR_AREA,
    BUSCAR_AREAS_CADASTRADAS,
    REMOVER_AREA,
} from "./type-actions";
import { INovaArea } from "@/interfaces/mapa/areas/INovaArea";
import http from "@/http";

export const configuracaoAreasModule: Module<State, State> = {
    actions: {
        [BUSCAR_AREAS_CADASTRADAS]() {
            return http.get("/vias/v1/areas");
        },
        [ADICIONAR_AREA](context, novaArea: INovaArea) {
            return http.post("/vias/v1/areas", novaArea);
        },
        [REMOVER_AREA](context, idArea: number) {
            return http.get(`/vias/v1/areas/${idArea}`);
        },
    },
};
