import moment from "moment";

class ValidadorDeTipo {

    isBoolean(valor: any): boolean {
        return typeof valor === "boolean";
    }

    isString(valor: any): boolean {
        return typeof valor === "string";
    }

    isNumber(valor: any): boolean {
        return typeof valor === "number";
    }

    isObjectOrArray(valor: any): boolean {
        return typeof valor === "object"
    }

    isFunction(valor: any): boolean {
        return typeof valor === "function";
    }

    isNull(valor: any): boolean {
        return valor === null;
    }

    isObject(valor: any): boolean {
        return this.isObjectOrArray(valor) && valor.length === undefined;
    }

    isArray(valor: any): boolean {
        return this.isObjectOrArray(valor) && valor.length !== undefined;
    }

    isDatetime(valor: any): boolean {
        return moment(valor).isValid();
    }

}

const validadorDeTipo = new ValidadorDeTipo();

export default validadorDeTipo;