<template>

<div class="p-relative mapa-para-formulario">
    <ViasMapaGeral
        :ids-equipamentos-selecionados="equipamentosSelecionados"
        @click-equipamento="alternarASelecaoDoEquipamento($event)"
        :habilitar-todos-os-equipamentos="false"
        :habilitar-filtro-de-data-dos-equipamentos-estaticos="false"
        :equipamentos-por-propriedade="equipamentosComFaixasEPosicaoApresentados"
    />
</div>

<div class="mt-4">
    <ViasInput
        type="text"
        label="Buscar equipamento"
        placeholder="Ex: Av. Andrômeda ou FV551SJC"
        v-model="filtroDeEquipamentos"
        @input="filtrarEquipamentos()"
    />
</div>

<div class="mt-4">
    <ListaDeEquipamentosComFaixas
        :equipamentos="equipamentosComFaixasEPosicaoApresentados"
        v-model:faixas-selecionadas="faixasSelecionadas"
        v-model:equipamentos-selecionados="equipamentosSelecionados"
    />
</div>

</template>

<script lang="ts" setup>
import ViasMapaGeral from './ViasMapaGeral.vue';
import equipamentoService from '@/domains/Equipamentos/services/EquipamentoService';
import ListaDeEquipamentosComFaixas from '../Equipamentos/ListaDeEquipamentosComFaixas.vue';
import { computed, defineModel, onMounted, ref, watch } from 'vue';
import { IEquipamento } from '@/interfaces/equipamento/IEquipamento';
import { EquipamentoComFaixaEPosicao } from '@/domains/Equipamentos/models/EquipamentoComFaixaEPosicao';
import { useStore } from '@/storeTs';
import ViasInput from '../ViasInput.vue';

const store = useStore();

const faixasSelecionadas = defineModel<number[]>("faixasSelecionadas", {
    default: []
});
const equipamentosSelecionados = defineModel<number[]>("equipamentosSelecionados", {
    default: []
});

const equipamentosComFaixasEPosicao = ref<EquipamentoComFaixaEPosicao[]>([]);
const equipamentosComFaixasEPosicaoApresentados = ref<EquipamentoComFaixaEPosicao[]>([]);
const filtroDeEquipamentos = ref("");

const apresentarEquipamentosEstaticos = computed(() => {
    return store.state.apresentarEquipamentosEstaticos
});
const tiposDeEquipamentosSelecionados = computed(() => {
    if (apresentarEquipamentosEstaticos.value) {
        return [...store.state.tipos_equipamentos_selecionados, "RADAR ESTATICO"];
    }
    return store.state.tipos_equipamentos_selecionados;
});

async function buscarEquipamentosFixosEEstaticosComFaixasEPosicao(): Promise<void> {
    const { data } = await equipamentoService.buscarEquipamentosFixosEEstaticosComFaixasEPosicao(
        tiposDeEquipamentosSelecionados.value
    );
    equipamentosComFaixasEPosicao.value = data;
    filtrarEquipamentos();
}

function filtrarEquipamentos(): void {
    equipamentosComFaixasEPosicaoApresentados.value = equipamentoService.filtrarEquipamentosComFaixasEPosicao(
        equipamentosComFaixasEPosicao.value,
        filtroDeEquipamentos.value
    );
}

function alternarASelecaoDoEquipamento(equipamento: IEquipamento): void {
    if (isEquipamentoSelecionado(equipamento)) {
        equipamentosSelecionados.value = equipamentosSelecionados.value.filter((id) => {
            return id !== equipamento.id;
        });
    } else {
        equipamentosSelecionados.value = [
            ...equipamentosSelecionados.value,
            equipamento.id
        ];
    }
}

function isEquipamentoSelecionado(equipamento: IEquipamento) {
    return equipamentosSelecionados.value.find((id) => id === equipamento.id);
}

watch(() => tiposDeEquipamentosSelecionados.value, () => {
    buscarEquipamentosFixosEEstaticosComFaixasEPosicao();
});

watch(() => apresentarEquipamentosEstaticos.value, () => {
    buscarEquipamentosFixosEEstaticosComFaixasEPosicao();
});

onMounted(() => {
    buscarEquipamentosFixosEEstaticosComFaixasEPosicao();
});
</script>

<style lang="scss" scoped>
.mapa-para-formulario {
    height: 300px;
}
</style>