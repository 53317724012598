import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mostrarBox)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "box-marcador",
        style: _normalizeStyle({ bottom: _ctx.posicaoBox.bottom, left: _ctx.posicaoBox.left })
      }, [
        _renderSlot(_ctx.$slots, "box")
      ], 4))
    : _createCommentVNode("", true)
}