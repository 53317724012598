<template>
    <div class="form-group datepicker">
        <label :for="id">{{ label }}</label>
        <datepicker
            :id="id"
            class="w-100"
            v-model="value"
            :dark="theme"
            @update:model-value="atualizarData"
            format="dd/MM/yyyy"
            :disabled="disabled"
            locale="pt-BR"
            select-text="OK"
            cancel-text="Cancelar"
            month-name-format="long"
            :enable-time-picker="false"
            week-start="0"
            week-numbers="local"
            week-num-name="sem"
            week-picker
            auto-apply
        />
    </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
    name: "FotoInputDate",
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: `id-${Math.floor(Math.random() * 10000)}`,
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Datepicker,
    },
    data: () => ({
        value: [],
    }),
    computed: {
        theme() {
            return this.$store.state.theme === "dark-theme";
        },
    },
    methods: {
        atualizarData() {
            this.$emit(
                "update:modelValue",
                this.value
                    ? moment(this.value[0]).format("YYYY-MM-DDTHH:mm:ssZ")
                    : null,
            );
        },
    },
    created() {
        const momentDay = moment(this.modelValue);
        this.value = [
            momentDay.startOf("week").format("YYYY-MM-DDTHH:mm:ssZ"),
            momentDay.endOf("week").format("YYYY-MM-DDTHH:mm:ssZ"),
        ];
    },
    watch: {
        modelValue() {
            const momentDay = moment(this.modelValue);
            this.value = [
                momentDay.startOf("week").format("YYYY-MM-DDTHH:mm:ssZ"),
                momentDay.endOf("week").format("YYYY-MM-DDTHH:mm:ssZ"),
            ];
        },
    },
};
</script>
