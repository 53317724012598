<template>
    <ul class="breadcrumb">
        <li v-for="(item, i) in itens" :key="'item-' + i">
            {{ item }}
        </li>
    </ul>
</template>

<script lang="ts">
import { RouteRecordName, useRouter } from "vue-router";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ViasBreadcrumb",
    computed: {
        itens(): RouteRecordName[] {
            const rotas: RouteRecordName[] = [];

            for (const rota of this.router.currentRoute.value.matched) {
                if (
                    rota.name &&
                    typeof rota.name === "string" &&
                    rota.name.length > 0
                ) {
                    rotas.push(rota.name);
                }
            }

            return rotas;
        },
    },
    setup() {
        const router = useRouter();
        return {
            router,
        };
    },
});
</script>
