<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Editar rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDeRotas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <FormularioEditarRotaOrigemEDestino
                    :rota-selecionada="rotaSelecionada"
                    @salvar-rota="salvarRota($event)"
                ></FormularioEditarRotaOrigemEDestino>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import FormularioEditarRotaOrigemEDestino from "./components/FormularioEditarRotaOrigemEDestino.vue";
import { IRotaOrigemEDestinoEditada } from "./interfaces/IRotaOrigemEDestinoEditada";
import { useStore } from "@/storeTs";
import { useNotificacao } from "@/hooks/notificacao.hook";
import {
    ALTERAR_ROTA_ORIGEM_E_DESTINO,
    BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO,
} from "@/storeTs/origem-e-destino/configuracao/type-actions";
import { IRotaOrigemEDestino } from "./interfaces/IRotaOrigemEDestino";

export default defineComponent({
    name: "EditarRotaOrigemEDestinoView",
    components: {
        FormularioEditarRotaOrigemEDestino,
    },
    data: () => ({
        idRota: 0,
        rotaSelecionada: {} as IRotaOrigemEDestino,
    }),
    methods: {
        voltarParaAApresentacaoDeRotas() {
            this.router.push("/origem-e-destino/configuracao");
        },
        buscarRota() {
            this.store
                .dispatch(BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO, this.idRota)
                .then((res) => {
                    this.rotaSelecionada = res.data;
                });
        },
        salvarRota(rotaEditada: IRotaOrigemEDestinoEditada) {
            this.notificarCarregamento(
                "Adicionando rotas, aguarde um momento por favor...",
            );
            rotaEditada.id = this.idRota;
            this.store
                .dispatch(ALTERAR_ROTA_ORIGEM_E_DESTINO, rotaEditada)
                .then((res) => {
                    if (res.status === 204) {
                        this.notificar("Rota alterada com sucesso!", "success");
                        this.voltarParaAApresentacaoDeRotas();
                    }
                });
        },
    },
    created() {
        try {
            this.idRota = parseInt(
                this.router.currentRoute.value.params.id.toString(),
            );
            this.buscarRota();
        } catch {
            this.voltarParaAApresentacaoDeRotas();
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificarCarregamento, notificar } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
