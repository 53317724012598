<template>
    <h3 class="mb-4">
        Como a velocidade média é calculada?
        <ViasExplicacaoVelocidadeMedia/>
    </h3>
    <h3 class="mb-4">
        Como a velocidade considerada é calculada?
        <ViasExplicacaoVelocidadeConsiderada/>
    </h3>
    <div class="overflow-x-auto pb-4">
        <vias-table
            :colunas="tabela.colunas"
            :linhas="tabela.linhas"
            size="sm"
            com-opcoes
        >
            <template v-slot:opcoes="{ linha }">
                <div class="options-table">
                    <a
                        :href="
                            '/velocidade-media/relatorios/infracao-especifica/' +
                            linha.id
                        "
                        target="_blank"
                        class="btn btn-primary btn-sm"
                    >
                        <font-awesome-icon
                            icon="fas fa-info-circle"
                        ></font-awesome-icon>
                    </a>
                </div>
            </template>
        </vias-table>
    </div>
</template>

<script>
import ViasExplicacaoVelocidadeConsiderada from "@/components/shared/explicacoes/ViasExplicacaoVelocidadeConsiderada.vue";
import ViasExplicacaoVelocidadeMedia from "@/components/shared/explicacoes/ViasExplicacaoVelocidadeMedia.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";

export default {
    name: "vias-ultimas-ocorrencias",
    props: ["rota"],
    components: {
        "vias-table": ViasTableVue,
        ViasExplicacaoVelocidadeConsiderada,
        ViasExplicacaoVelocidadeMedia,
    },
    data: () => ({
        tabela: {
            colunas: [
                {
                    titulo: "#",
                    referencia: "numero_linha",
                    tipo: "numero",
                },
                {
                    titulo: "Placa",
                    tipo: "texto",
                    referencia: "placa",
                },
                {
                    titulo: "Data do Primeiro Fluxo",
                    tipo: "data_hora",
                    referencia: "primeira_passagem_data",
                },
                {
                    titulo: "Velocidade nno Primeiro Fluxo",
                    tipo: "numero",
                    referencia: "primeira_passagem_velocidade",
                    unidade: "km/h",
                },
                {
                    titulo: "Data do Segundo Fluxo",
                    tipo: "data_hora",
                    referencia: "segunda_passagem_data",
                },
                {
                    titulo: "Velocidade no Segundo Fluxo",
                    tipo: "numero",
                    referencia: "segunda_passagem_velocidade",
                    unidade: "km/h",
                },
                {
                    titulo: "Tempo Entre os Equipamentos",
                    tipo: "numero",
                    referencia: "tempo_de_travessia",
                    unidade: "segundos",
                },
                {
                    titulo: "Velocidade Média Medida",
                    tipo: "numero",
                    referencia: "velocidade_medida",
                    unidade: "km/h",
                },
                {
                    titulo: "Velocidade Média Considerada",
                    tipo: "numero",
                    referencia: "velocidade_considerada",
                    unidade: "km/h",
                },
            ],
            linhas: [],
        },
    }),
    created() {
        this.buscarUltimasOcorrencias();
    },
    watch: {
        rota() {
            this.buscarUltimasOcorrencias();
        },
    },
    methods: {
        buscarUltimasOcorrencias() {
            this.$velocidade_media
                .buscarUltimasOcorrencias(this.rota.id)
                .then((res) => res.data)
                .then((ultimasOcorrencias) => {
                    this.tabela.linhas = ultimasOcorrencias.map(
                        (ocorrencia, i) => ({
                            numero_linha: i + 1,
                            id: ocorrencia.id,
                            placa: ocorrencia.placa,
                            primeira_passagem_data:
                                ocorrencia.primeira_passagem_data,
                            primeira_passagem_velocidade:
                                ocorrencia.primeira_passagem_velocidade,
                            segunda_passagem_data:
                                ocorrencia.segunda_passagem_data,
                            segunda_passagem_velocidade:
                                ocorrencia.segunda_passagem_velocidade,
                            tempo_de_travessia: ocorrencia.tempo_de_travessia,
                            velocidade_medida: ocorrencia.velocidade_medida,
                            velocidade_considerada:
                                ocorrencia.velocidade_considerada,
                        }),
                    );
                });
        },
    },
};
</script>
