<template>

<ViasCard>
    <template #header>
        <h2 class="card-title">
            Media de velocidade nas últimas 24 horas
            <ViasExplicacaoVelocidadeMediaGeral/>
        </h2>
    </template>
    <ViasLineChart
        :labels="horarios"
        :datasets="[dataset]"
        eixo_x="Horário"
        eixo_y="Velocidade (km/h)"
    />
</ViasCard>

</template>

<script lang="ts" setup>
import ViasExplicacaoVelocidadeMediaGeral from '@/components/shared/explicacoes/ViasExplicacaoVelocidadeMediaGeral.vue';
import { DatasetGraficoDeLinha } from '@/components/shared/Graficos/GraficoDeLinha/models/DatasetGraficoDeLinha';
import ViasLineChart from '@/components/shared/ViasLineChart.vue';
import coresService from '@/core/composables/CoresService';
import datetimeService from '@/core/composables/DatetimeService';
import { RelatorioDeFluxoDasUltimas24Horas } from '@/domains/Fluxo/models/RelatorioDeFluxoDasUltimas24Horas';
import fluxoService from '@/domains/Fluxo/services/FluxoService';
import { onMounted, ref, defineExpose } from 'vue';

const horarios = ref<string[]>([]);
const dataset = ref<DatasetGraficoDeLinha>({
    label: "Média de Velocidade",
    data: [],
    borderColor: coresService.listarCoresDisponiveis().success,
    backgroundColor: coresService.listarCoresDisponiveis().success
});
const relatorioDeFluxoDasUltimas24Horas = ref<RelatorioDeFluxoDasUltimas24Horas>();

async function buscarFluxoDasUltimas24Horas() {
    const { data } = await fluxoService.buscarRelatorioDeFluxoDasUltimas24Horas();
    relatorioDeFluxoDasUltimas24Horas.value = data;
    gerarOsHorariosDoGrafico();
    gerarOsValoresDoGrafico();
}

function gerarOsHorariosDoGrafico() {
    if (relatorioDeFluxoDasUltimas24Horas.value) {
        const datasDoRelatorio = Object.keys(relatorioDeFluxoDasUltimas24Horas.value.grafico);
        horarios.value = datasDoRelatorio.map((data) => datetimeService.extrairHorarioDaData(data));
    }
}

function gerarOsValoresDoGrafico() {
    if (relatorioDeFluxoDasUltimas24Horas.value) {
        const valoresDoRelatorio = Object.values(relatorioDeFluxoDasUltimas24Horas.value.grafico);
        dataset.value.data = valoresDoRelatorio.map((valor) => valor.media_de_velocidade_total);
    }
}

onMounted(() => {
    buscarFluxoDasUltimas24Horas();
});

function atualizarInformacoes() {
    buscarFluxoDasUltimas24Horas();
}

defineExpose({
    atualizarInformacoes
});
</script>