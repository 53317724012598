import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "border border-radius-1 p-4" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "text-muted" }
const _hoisted_4 = { class: "mt-3" }
const _hoisted_5 = { class: "alert alert-danger" }
const _hoisted_6 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViasInput = _resolveComponent("ViasInput")!
  const _component_ViasCol = _resolveComponent("ViasCol")!
  const _component_ViasRow = _resolveComponent("ViasRow")!
  const _component_ViasMapaGeral = _resolveComponent("ViasMapaGeral")!
  const _component_ViasButton = _resolveComponent("ViasButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.editarRota()), ["prevent"]))
  }, [
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              label: "Nome",
              modelValue: _ctx.nome,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nome) = $event)),
              type: "text",
              id: "editar-rota-velocidade-media-nome",
              valid: _ctx.isNomeValido,
              detail: _ctx.getNomeMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, { class: "position-relative mapa-formulario-height" }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasMapaGeral, {
              rotas: [_ctx.getCoordenadasRota],
              "ids-equipamentos-selecionados": _ctx.idsEquipamentosSelecionados,
              onClickEquipamento: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alternarSelecaoDeEquipamento($event)))
            }, null, 8, ["rotas", "ids-equipamentos-selecionados"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, { class: "mt-4 max-h-300px overflow-y-auto" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipamentos, (equipamento) => {
          return (_openBlock(), _createBlock(_component_ViasCol, {
            key: equipamento.id,
            col: "12",
            sm: "6",
            md: "4",
            lg: "3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", null, _toDisplayString(equipamento.sitio), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(equipamento.posicao.endereco), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ViasButton, {
                    type: "button",
                    variant: _ctx.definirVarianteOrigem(equipamento.id),
                    class: "mr-2",
                    onClick: ($event: any) => (_ctx.definirEquipamentoComoOrigem(equipamento)),
                    id: 
                                'editar-rota-velocidade-media-equipamento-' +
                                equipamento.sitio +
                                '-botao-origem'
                            ,
                    size: "sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Origem ")
                    ]),
                    _: 2
                  }, 1032, ["variant", "onClick", "id"]),
                  _createVNode(_component_ViasButton, {
                    type: "button",
                    variant: _ctx.definirVarianteDestino(equipamento.id),
                    onClick: ($event: any) => (_ctx.definirEquipamentoComoDestino(equipamento)),
                    id: 
                                'editar-rota-velocidade-media-equipamento-' +
                                equipamento.sitio +
                                '-botao-destino'
                            ,
                    size: "sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Destino ")
                    ]),
                    _: 2
                  }, 1032, ["variant", "onClick", "id"])
                ])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.isOrigemValida !== null && !_ctx.isOrigemValida)
      ? (_openBlock(), _createBlock(_component_ViasRow, {
          key: 0,
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasCol, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getOrigemMessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isDestinoValida !== null && !_ctx.isDestinoValida)
      ? (_openBlock(), _createBlock(_component_ViasRow, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasCol, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getDestinoMessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ViasRow, { class: "my-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, {
          cols: "12",
          md: "4",
          class: "d-flex align-items-end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              type: "number",
              label: "Distância (em metros)",
              modelValue: _ctx.distancia,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.distancia) = $event)),
              valid: _ctx.isDistanciaValida,
              detail: _ctx.getDistanciaMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        }),
        _createVNode(_component_ViasCol, {
          cols: "12",
          md: "4",
          class: "d-flex align-items-end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              type: "number",
              label: "Tempo de percurso esperado (em segundos)",
              modelValue: _ctx.tempoDePercurso,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tempoDePercurso) = $event)),
              valid: _ctx.isTempoDePercursoValida,
              detail: _ctx.getTempoDePercursoMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        }),
        _createVNode(_component_ViasCol, {
          cols: "12",
          md: "4",
          class: "d-flex align-items-end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              type: "number",
              label: "Limite de velocidade média (em km/h)",
              modelValue: _ctx.limiteDeVelocidade,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.limiteDeVelocidade) = $event)),
              valid: _ctx.isLimiteDeVelocidadeValida,
              detail: _ctx.getLimiteDeVelocidadeMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasButton, { variant: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode("Salvar")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}