<template>
    <form @submit.prevent="adicionarNovaPlacaNaWhitelist()">
        <ViasRow class="mb-4">
            <ViasCol :cols="12" :md="6">
                <ViasInput
                    label="Placa"
                    v-model="placa"
                    type="text"
                    id="nova-placa-whitelist-placa"
                    mask="AAA#X##"
                    required
                ></ViasInput>
            </ViasCol>
            <ViasCol :cols="12" :md="6">
                <ViasInput
                    label="Veículo"
                    v-model="veiculo"
                    type="text"
                    id="nova-placa-whitelist-veiculo"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mb-4">
            <ViasCol :cols="12">
                <ViasInputTextarea
                    label="Descrição"
                    v-model="descricao"
                    id="nova-placa-whitelist-descricao"
                    required
                ></ViasInputTextarea>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol :cols="12">
                <ViasButton variant="primary" type="submit"
                    >Adicionar placa</ViasButton
                >
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts" setup>
import { INovaPlacaNaWhitelist } from "@/interfaces/whitelist/INovaPlacaNaWhitelist";
import { ref, defineEmits } from "vue";
import ViasRow from "@/components/shared/ViasRow.vue";
import ViasCol from "@/components/shared/ViasCol.vue";
import ViasInput from "@/components/shared/ViasInput.vue";
import ViasInputTextarea from "@/components/shared/ViasInputTextarea.vue";

const emits = defineEmits<{
    adicionarNovaPlacaNaWhitelist: [form: INovaPlacaNaWhitelist];
}>();

const placa = ref("");
const veiculo = ref("");
const descricao = ref("");

function adicionarNovaPlacaNaWhitelist() {
    emits("adicionarNovaPlacaNaWhitelist", {
        placa: placa.value,
        veiculo: veiculo.value,
        descricao: descricao.value,
    });
}
</script>
