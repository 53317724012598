import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_ROTAS_ORIGEM_E_DESTINO,
    ADICIONAR_ROTAS_ORIGEM_E_DESTINO,
    REMOVER_ROTA_ORIGEM_E_DESTINO,
    ALTERAR_ROTA_ORIGEM_E_DESTINO,
    CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO,
    VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO,
    BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const origemEDestinoConfiguracaoModule: Module<State, State> = {
    actions: {
        [BUSCAR_ROTAS_ORIGEM_E_DESTINO]() {
            return axios.get("/vias/v1/origem-destino/rotas");
        },
        [BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO](context, idRota: number) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/vias/v1/origem-destino/rotas")
                    .then((res) => {
                        for (const rota of res.data) {
                            if (rota.id === idRota) {
                                res.data = rota;
                                resolve(res);
                            }
                        }
                        reject("Rota não encontrada.");
                    })
                    .catch(() => {
                        reject("Ocorreu algum erro durante a busca da rota.");
                    });
            });
        },
        [ADICIONAR_ROTAS_ORIGEM_E_DESTINO](context, rotas: any[]) {
            return axios.post("/vias/v1/origem-destino/rotas", rotas);
        },
        [REMOVER_ROTA_ORIGEM_E_DESTINO](context, id: number) {
            return axios.put(`/vias/v1/origem-destino/rotas/${id}/desativar`);
        },
        [ALTERAR_ROTA_ORIGEM_E_DESTINO](context, rota: any) {
            return axios.put(`/vias/v1/origem-destino/rotas/${rota.id}`, rota);
        },
        [CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO](context, id: any) {
            return axios.get(
                `/vias/v1/origem-destino/rotas/calcular-origem/${id}`,
            );
        },
        [VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO](context, dados: any) {
            return axios.post(
                "/vias/v1/origem-destino/rotas/validar/fluxo",
                dados,
            );
        },
    },
};
