import http from "@/http";
import { ParametrosAgendamentoParaRelatorioOrigemEDestino } from "../models/ParametrosAgendamentoParaRelatorioOrigemEDestino";
import { IRotaOrigemEDestino } from "@/views/OrigemEDestino/interfaces/IRotaOrigemEDestino";
import { AxiosResponse } from "axios";

class OrigemEDestinoService {

    private rotaBase = "/vias/v1/origem-destino";

    public buscarRotas(): Promise<AxiosResponse<IRotaOrigemEDestino[]>> {
        return http.get(`${this.rotaBase}/rotas`);
    }

    public agendarEmissaoDoPdfDoRelatorioDeFluxoVeicular(
        formulario: ParametrosAgendamentoParaRelatorioOrigemEDestino
    ) {
        return http.post(`${this.rotaBase}/relatorios/pdf/agendar`, formulario);
    }

}

const origemEDestinoService = new OrigemEDestinoService();

export default origemEDestinoService;