<template>
    <doughnut-chart
        ref="doughnutChart"
        :chart-data="data"
        :options="options"
        class="doughnut-chart"
    ></doughnut-chart>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { DoughnutChart } from "vue-chart-3";
import { mapState } from "vuex";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables, ChartDataLabels);
export default {
    name: "vias-doughnut-chart",
    props: {
        labels: Array,
        datasets: Array,
        apresentarDatalabels: Boolean,
        unidade_datalabels: String,
        habilitarApresentacaoTotal: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        "doughnut-chart": DoughnutChart,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
    },
    data: () => ({
        data: {
            labels: [],
            datasets: [],
        },
        options: {},
    }),
    created() {
        this.verificarEAtualizarGrafico();
    },
    methods: {
        verificarEAtualizarGrafico() {
            this.data = {
                labels: this.verificarLabels(),
                datasets: this.verificarDatasets(),
            };

            this.options = {
                color:
                    this.theme == "dark-theme"
                        ? this.$variaveis.cores.background
                        : this.$variaveis.cores.dark,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: `Total: ${this.calcularTotal()}`,
                        },
                    },
                },
            };

            if (this.$refs.doughnutChart) {
                this.$refs.doughnutChart.chartInstance.data = this.data;
                this.$refs.doughnutChart.update();
            }
        },
        verificarLabels() {
            return this.labels != null ? this.labels : [];
        },
        verificarDatasets() {
            return this.datasets != null
                ? this.tratarDatalabelDosDatasets()
                : [];
        },
        tratarDatalabelDosDatasets() {
            const newDatasets = [];
            this.datasets.forEach((dataset) => {
                newDatasets.push({
                    ...dataset,
                    datalabels: {
                        display:
                            typeof this.$props.apresentarDatalabels ===
                            "boolean"
                                ? this.$props.apresentarDatalabels
                                : true,
                        backgroundColor:
                            dataset.backgroundColor ||
                            this.elements.point.backgroundColor,
                        color: this.$variaveis.cores.intense_dark,
                        borderRadius: 25,
                        borderWidth: 2,
                        borderColor: this.$variaveis.cores.intense_background,
                        formatter: (value) => {
                            return this.$numero.formatar(value);
                        },
                        align: "start",
                        anchor: "start",
                    },
                });
            });

            return newDatasets;
        },
        calcularTotal() {
            let total = 0;
            this.data.datasets.forEach((dataset) => {
                dataset.data.forEach((quantidade) => {
                    total += quantidade;
                });
            });
            return this.$numero.formatar(total);
        },
    },
    watch: {
        labels() {
            this.verificarEAtualizarGrafico();
        },
        datasets() {
            this.verificarEAtualizarGrafico();
        },
        theme() {
            this.verificarEAtualizarGrafico();
        },
        apresentarDatalabels() {
            this.verificarEAtualizarGrafico();
        },
    },
};
</script>
