export const BUSCAR_ROTAS_ORIGEM_E_DESTINO = "BUSCAR_ROTAS_ORIGEM_E_DESTINO";
export const BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO =
    "BUSCAR_ROTA_POR_ID_ORIGEM_E_DESTINO";
export const ADICIONAR_ROTAS_ORIGEM_E_DESTINO =
    "ADICIONAR_ROTAS_ORIGEM_E_DESTINO";
export const REMOVER_ROTA_ORIGEM_E_DESTINO = "REMOVER_ROTA_ORIGEM_E_DESTINO";
export const ALTERAR_ROTA_ORIGEM_E_DESTINO = "ALTERAR_ROTA_ORIGEM_E_DESTINO";
export const CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO =
    "CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO";
export const VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO =
    "VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO";
