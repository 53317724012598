<template>

<ViasCard title="Relatórios agendados">

    <ViasRow>
        <ViasCol :cols="12">
            <ViasVueSelect
                label="Selecione um relatório"
                v-model="relatorioSelecionado"
                :options="opcoesDeRelatoriosComAgendamento"
                @change="buscarAgendamentos()"
            />
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasTable
                :colunas="colunasAgendamento"
                :linhas="listaDeAgendamentos"
                com-opcoes
            >
                <template #opcoes="{ linha }">
                    <ViasButton variant="none-danger" size="sm" @click="removerAgendamento(linha)">
                        Remover
                    </ViasButton>
                </template>
            </ViasTable>
        </ViasCol>
    </ViasRow>

</ViasCard>

</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { ParametrosAgendamentoDeRelatorio } from '../models/ParametrosAgendamentoDeRelatorio';
import { PossiveisRelatoriosParaAgendamento } from '../models/TiposDeRelatoriosParaAgendamento';
import { ISelectOption } from '@/interfaces/select/ISelectOption';
import agendamentoService from '../services/AgendamentoService';
import ViasVueSelect from '@/components/shared/ViasVueSelect.vue';
import { IColunaTabela } from '@/interfaces/IColunaTabela';
import { AgendamentoParaApresentacao } from '../models/AgendamentoParaApresentacao';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';

const opcoesDeRelatoriosComAgendamento = ref<ISelectOption[]>([
    {
        text: "Fluxo Veicular",
        value: "Gráfico de Fluxo"
    },
    {
        text: "Origem e Destino",
        value: "Relatório de Origem e Destino"
    },
    {
        text: "Volume Médio Diário",
        value: "Volume Médio Diário"
    }
]);
const relatorioSelecionado = ref<PossiveisRelatoriosParaAgendamento>(
    "Gráfico de Fluxo"
);

const colunasAgendamento = ref<IColunaTabela[]>([
    {
        titulo: "#",
        referencia: "numeroDaLinha"
    },
    {
        titulo: "Período em que o relatório será extraído",
        referencia: "periodoDeExtracaoDoRelatorio"
    },
    {
        titulo: "Horário que o relatório será enviado",
        referencia: "horarioDeEnvio"
    },
    {
        titulo: "O relatório será do mesmo dia ou de algum período anterior?",
        referencia: "relatorioGeradoSeraDeQuantosDiasAntes"
    }
]);
const listaDeAgendamentos = ref<AgendamentoParaApresentacao[]>([]);

async function buscarAgendamentos(): Promise<void> {
    const { data } = await agendamentoService.buscarAgendamentos(relatorioSelecionado.value);
    listaDeAgendamentos.value = data.map((agendamento, i) => {
        return {
            id: agendamento.id,
            numeroDaLinha: i + 1,
            periodoDeExtracaoDoRelatorio: definirPeriodoDeExtracao(agendamento),
            horarioDeEnvio: agendamento.hora,
            relatorioGeradoSeraDeQuantosDiasAntes: definirDeQuantosDiasAtrasORelatorioSeraGerado(
                agendamento
            )
        };
    });
}

function definirPeriodoDeExtracao(agendamento: ParametrosAgendamentoDeRelatorio) {
    if (agendamento.diario) {
        return "Diário";
    } else {
        if (typeof agendamento.dia_da_semana === "number") {
            return "Semanal";
        } else {
            return "Mensal";
        }
    }
}

function definirDeQuantosDiasAtrasORelatorioSeraGerado(agendamento: ParametrosAgendamentoDeRelatorio) {
    if (agendamento.operando === 0) {
        return "Será gerado o relatório do mesmo dia";
    }
    if (agendamento.atributo_de_calculo === "days") {
        return `Será gerado o relatório de ${agendamento.operando} dia(s) antes`;
    }
    return `Será gerado o relatório de ${agendamento.operando} mes(es) antes`;
}

async function removerAgendamento(agendamento: AgendamentoParaApresentacao): Promise<void> {
    const isConfirmado = await notificacaoService.questionarAcao(
        "Tem certeza que deseja remover este agendamento? Após fazê-lo, não será possível recuperar."
    );
    if (isConfirmado) {
        notificacaoService.notificarCarregamento(
            "Removendo agendamento, aguarde um momento por favor..."
        );
        await agendamentoService.removerAgendamento(agendamento.id);
        notificacaoService.notificarSucesso("Agendamento removido com sucesso!");
        buscarAgendamentos();
    }
}

onMounted(() => {
    buscarAgendamentos();
});
</script>