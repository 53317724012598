export const cores = {
    primary: "#7380EC",
    danger: "#EC7380",
    success: "#52BE80",
    warning: "#ECDF73",
    dark_warning: "#EC8D05",
    light: "rgba(132, 139, 200, 0.18)",
    background: "#ebecf2",
    intense_background: "#fcfcfc",
    dark: "#1D203B",
    intense_dark: "#16182e",
    dark_light: "rgba(#0E101D, 0.18)",
};

export const border = {
    radius_1: 5,
    radius_2: 10,
    radius_3: 15,
};

export const padding = {
    padding_1: "1.2rem",
};
