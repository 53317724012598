<template>
    <div class="w-100 h-100 border border-radius p-4">
        <div class="cabecalho-equipamento">
            <label class="default-checkbox">
                <input
                    type="checkbox"
                    v-model="afericaoSelecionada"
                    @change="alterarSelecaoDeTodasAsFaixas()"
                />
                <div class="input-checkbox">
                    <font-awesome-icon
                        icon="fas fa-check"
                        class="check"
                    ></font-awesome-icon>
                </div>
                <div class="input-label">
                    <h3>{{ afericao.sitio.toUpperCase() }}</h3>
                    <small>{{ primeiroGrupo[0]?.endereco || "-" }}</small>
                </div>
            </label>
        </div>
        <div class="d-flex w-100">
            <div class="d-flex flex-column">
                <label
                    class="default-checkbox mt-2"
                    v-for="afericao in primeiroGrupo"
                    :key="afericao.id"
                >
                    <input
                        type="checkbox"
                        v-model="faixasSelecionadas"
                        :value="afericao.id"
                    />
                    <div class="input-checkbox">
                        <font-awesome-icon
                            icon="fas fa-check"
                            class="check"
                        ></font-awesome-icon>
                    </div>
                    <div class="input-label">
                        <h3>Faixa {{ afericao.faixa }}</h3>
                        <small>Sentido: {{ afericao.sentido }}</small>
                    </div>
                </label>
            </div>
            <div class="d-flex flex-column">
                <label
                    class="default-checkbox mt-2"
                    v-for="afericao in segundoGrupo"
                    :key="afericao.id"
                >
                    <input
                        type="checkbox"
                        v-model="faixasSelecionadas"
                        :value="afericao.id"
                    />
                    <div class="input-checkbox">
                        <font-awesome-icon
                            icon="fas fa-check"
                            class="check"
                        ></font-awesome-icon>
                    </div>
                    <div class="input-label">
                        <h3>Faixa {{ afericao.faixa }}</h3>
                        <small>Sentido: {{ afericao.sentido }}</small>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { IEquipamentoAfericao } from "@/interfaces/equipamento/IEquipamentoAfericao";
import { IAfericao } from "@/interfaces/equipamento/IAfericao";

export default defineComponent({
    name: "BoxEquipamentoAfericaoParaFormulario",
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: Object as PropType<number[]>,
            required: false,
        },
        afericao: {
            type: Object as PropType<IEquipamentoAfericao>,
            required: true,
        },
    },
    data: () => ({
        afericaoSelecionada: false,
        faixasSelecionadas: [] as number[],
    }),
    computed: {
        faixasEmGrupos(): Array<IAfericao[]> {
            const afericoesOrganizadas = this.organizarAfericoes();
            const totalDeAfericoes = afericoesOrganizadas.length;
            const isPar = totalDeAfericoes % 2 === 0;

            const grupo1 = isPar
                ? afericoesOrganizadas.slice(0, totalDeAfericoes / 2)
                : afericoesOrganizadas.slice(0, totalDeAfericoes / 2 + 1);

            const grupo2 = isPar
                ? afericoesOrganizadas.slice(
                      totalDeAfericoes / 2,
                      totalDeAfericoes,
                  )
                : afericoesOrganizadas.slice(
                      totalDeAfericoes / 2 + 1,
                      totalDeAfericoes,
                  );

            return [grupo1, grupo2];
        },
        primeiroGrupo(): IAfericao[] {
            return this.faixasEmGrupos[0];
        },
        segundoGrupo(): IAfericao[] {
            return this.faixasEmGrupos[1];
        },
    },
    methods: {
        organizarAfericoes() {
            const afericoes = this.afericao.afericoes;
            return afericoes.sort((a, b) => {
                if (a.faixa > b.faixa) {
                    return 1;
                } else if (a.faixa === b.faixa) {
                    return 0;
                }
                return -1;
            });
        },
        alterarSelecaoDeTodasAsFaixas() {
            if (this.afericaoSelecionada) {
                this.faixasSelecionadas = this.afericao.afericoes.map(
                    (faixa) => faixa.id,
                );
            } else {
                this.faixasSelecionadas = [];
            }
        },
        atualizarModelValue() {
            let faixasParaAtualizar = this.modelValue || [];

            for (const faixa of this.afericao.afericoes) {
                if (
                    this.faixasSelecionadas.find(
                        (idFaixa) => faixa.id === idFaixa,
                    )
                ) {
                    if (
                        !faixasParaAtualizar.find(
                            (idFaixa) => faixa.id === idFaixa,
                        )
                    ) {
                        faixasParaAtualizar = [
                            ...faixasParaAtualizar,
                            faixa.id,
                        ];
                    }
                } else {
                    if (
                        faixasParaAtualizar.find(
                            (idFaixa) => faixa.id === idFaixa,
                        )
                    ) {
                        faixasParaAtualizar = faixasParaAtualizar.filter(
                            (idFaixa) => idFaixa !== faixa.id,
                        );
                    }
                }
            }

            this.$emit("update:modelValue", faixasParaAtualizar);
        },
        verificarSelecoesDoModelValue() {
            const faixasSelecionadasPeloModelValue: number[] = [];
            for (const faixa of this.afericao.afericoes) {
                if (this.modelValue?.find((f) => f === faixa.id)) {
                    faixasSelecionadasPeloModelValue.push(faixa.id);
                }
            }
            this.faixasSelecionadas = faixasSelecionadasPeloModelValue;
            this.verificarSeAAfericaoEstaTodaSelecionada();
        },
        verificarSeAAfericaoEstaTodaSelecionada() {
            if (
                this.faixasSelecionadas.length ===
                this.afericao.afericoes.length
            ) {
                this.afericaoSelecionada = true;
            } else {
                this.afericaoSelecionada = false;
            }
        },
    },
    created() {
        this.verificarSelecoesDoModelValue();
    },
    watch: {
        modelValue() {
            this.verificarSelecoesDoModelValue();
        },
        faixasSelecionadas() {
            this.verificarSeAAfericaoEstaTodaSelecionada();
            this.atualizarModelValue();
        },
    },
});
</script>
