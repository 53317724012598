<template>
    <form id="vias-formulario-ocorrencias" @submit="buscarRelatorio">
        <div class="row">
            <div class="col">
                <vias-select
                    v-model="formulario.rota"
                    :options="opcoes_rotas"
                    label="Rota"
                ></vias-select>
            </div>
        </div>

        <div class="row">
            <div class="col col-md-6">
                <vias-radio-button
                    v-model="formulario.formato_visualizacao"
                    :options="opcoes_formato_visualizacao"
                ></vias-radio-button>
            </div>
            <div class="col col-md-6">
                <div class="row">
                    <div class="col col-12">
                        <HorariosSelect
                            v-model="horarioSelecionado"
                            @change="mudarHorarioSelecionadoParaUmJaExistente($event)"
                        />
                    </div>
                    <div class="col col-12">
                        <ViasInputPeriodDateAndTime
                            v-model="formulario.data"
                            label="Período da busca"
                            @change="desselecionarHorarioExistente()"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="button-block">
                    <vias-button variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import ViasSelectVue from "@/components/shared/ViasSelect.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasRadioButtonVue from "@/components/shared/ViasRadioButton.vue";
import moment from "moment";
import ViasInputPeriodDateAndTime from "@/components/shared/ViasInputPeriodDateAndTime.vue";
import HorariosSelect from "@/domains/Horarios/components/HorariosSelect.vue";
import datetimeService from "@/core/composables/DatetimeService";

export default {
    components: {
        "vias-select": ViasSelectVue,
        "vias-radio-button": ViasRadioButtonVue,
        "vias-button": ViasButtonVue,
        ViasInputPeriodDateAndTime,
        HorariosSelect,
    },
    data: () => ({
        horarioSelecionado: 0,
        formulario: {
            rota: "all",
            formato_visualizacao: "ano",
            data: [
                moment().subtract("month", 3).format("YYYY-MM-DDT00:00:00"),
                moment().format("YYYY-MM-DDTHH:mm:ss"),
            ],
        },
        opcoes_rotas: [
            {
                text: "Todas as rotas",
                value: "all",
            },
        ],
        opcoes_formato_visualizacao: [
            {
                text: "Hora",
                value: "hora",
            },
            {
                text: "Dia",
                value: "dia",
            },
            {
                text: "Semana",
                value: "semana",
            },
            {
                text: "Mês",
                value: "mes",
            },
            {
                text: "Ano",
                value: "ano",
            },
        ],
    }),
    created() {
        this.buscarRotas();
    },
    methods: {
        buscarRotas() {
            this.$velocidade_media
                .buscarRotas()
                .then((res) => res.data)
                .then((rotas) => {
                    this.opcoes_rotas = this.opcoes_rotas.concat(
                        rotas.map((rota) => ({
                            text: rota.nome,
                            value: rota.id,
                        })),
                    );
                });
        },
        mudarHorarioSelecionadoParaUmJaExistente(horario) {
            this.formulario.data[0] = datetimeService.setTimeOnDatetime(this.formulario.data[0], horario[0]);
            this.formulario.data[1] = datetimeService.setTimeOnDatetime(this.formulario.data[1], horario[1]);
        },
        desselecionarHorarioExistente() {
            this.horarioSelecionado = 0;
        },
        buscarRelatorio(e) {
            e.preventDefault();
            this.$emit("buscarRelatorio", {
                ...this.formulario,
                rota: this.opcoes_rotas.find((opcao) => opcao.value === this.formulario.rota),
            });
        },
    },
};
</script>
