<template>

<BoxInsightContrato variante="success">
    <template #cabecalho>
        Total de equipamentos
    </template>
    <template #valorPrincipal>
        {{ totalDeEquipamentos }} equipamentos
    </template>
    <template #valorSecundario>
        {{ totalDeFaixas }} faixas
    </template>
    <template #legenda>
        Presentes na cidade
    </template>
    <template #rodape>
        <ModalDetalhamentoContrato
            titulo="Classificação de Equipamento"
            :itens="totalDeEquipamentosEFaixasSeparadosPorTipo"
        >
            <template #default="{ chave, quantidade }">
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center w-100 mb-2">
                        <h3>{{ chave }}</h3>
                        <div class="badge badge-primary">
                            {{ quantidade.equipamentos }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <h4>Total de faixas</h4>
                        <div class="badge badge-success">
                            {{ quantidade.faixas }}
                        </div>
                    </div>
                </div>
            </template>
        </ModalDetalhamentoContrato>
    </template>
</BoxInsightContrato>

</template>

<script lang="ts" setup>
import BoxInsightContrato from './BoxInsightContrato.vue';
import ModalDetalhamentoContrato from "./ModalDetalhamentoContrato.vue";
import equipamentoService from '@/domains/Equipamentos/services/EquipamentoService';
import numerosService from '@/domains/Numeros/services/NumerosService';
import { TotalDeEquipamentosEFaixasSeparadasPorTipo } from '@/domains/Equipamentos/models/TotalDeEquipamentosEFaixasSeparadasPorTipo';
import { onMounted, ref, defineExpose } from 'vue';

const totalDeEquipamentosEFaixasSeparadosPorTipo = ref<TotalDeEquipamentosEFaixasSeparadasPorTipo>({});
const totalDeEquipamentos = ref("0");
const totalDeFaixas = ref("0");

async function buscarTotalDeEquipamentos() {
    const { data } = await equipamentoService.buscarTotalDeEquipamentosSeparadosPorTipo();
    totalDeEquipamentosEFaixasSeparadosPorTipo.value = data;
    calcularTotalDeEquipamentos();
    calcularTotalDeFaixas();
}

function calcularTotalDeEquipamentos() {
    const valores = Object.values(totalDeEquipamentosEFaixasSeparadosPorTipo.value);
    const totalCalculado = valores.reduce((total, quantidade) => {
        return total + quantidade.equipamentos;
    }, 0);
    totalDeEquipamentos.value = numerosService.formatarNumero(totalCalculado);
}

function calcularTotalDeFaixas() {
    const valores = Object.values(totalDeEquipamentosEFaixasSeparadosPorTipo.value);
    const totalCalculado = valores.reduce((total, quantidade) => {
        return total + quantidade.faixas;
    }, 0);
    totalDeFaixas.value = numerosService.formatarNumero(totalCalculado);
}

onMounted(() => {
    buscarTotalDeEquipamentos();
});

function atualizarInformacoes() {
    buscarTotalDeEquipamentos();
}

defineExpose({
    atualizarInformacoes
});
</script>