import axios from "../plugins/axios.plugin";

export const buscarContratos = () => axios.get("/vias/v1/contrato");

export const buscarViolacoes = () => axios.get("/vias/v1/contrato/infracoes");

export const buscarFluxo = () => axios.get("/vias/v1/contrato/fluxo");

export const buscarMediaDeVelocidade = () =>
    axios.get("/vias/v1/contrato/media-de-velocidade");

export const buscarUltimasViolacoes = () =>
    axios.get("/vias/v1/graficos/infracao/gerais/vinte-quatro");

export const buscarUltimosFluxosVeicularesEUltimasMediasDeVelocidade = () =>
    axios.get("/vias/v1/graficos/fluxo/vinte-quatro");
