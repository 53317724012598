<template>
    <form @submit.prevent="salvarGrupo()">
        <ViasRow>
            <ViasCol>
                <ViasInput
                    label="Nome"
                    id="editar-grupo-nome"
                    v-model="nome"
                    type="text"
                    :valid="isNomeValido"
                    :detail="getNomeMessage"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    @click-equipamento="alternarSelecaoDeEquipamento($event)"
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4 max-h-300px overflow-y-auto">
            <ViasCol
                v-for="equipamento in equipamentos"
                :key="equipamento.id"
                :cols="12"
                :sm="6"
                :md="4"
                :lg="3"
            >
                <BoxEquipamentoAfericaoParaFormulario
                    :afericao="equipamento"
                    v-model="afericoesSelecionadas"
                    @change="
                        verificarSeTodasAsAfericoesEstaoSelecionadas(
                            equipamento,
                        )
                    "
                    :id="'editar-grupo-equipamento-' + equipamento.id"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isAfericoesSelecionadasValido === false">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getAfericoesSelecionadasMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4">
            <ViasCol>
                <ViasButton variant="primary" type="submit">Salvar</ViasButton>
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts">
import { BUSCAR_AFERICOES } from "@/storeTs/equipamentos/type-actions";
import { useStore } from "@/storeTs";
import { PropType, defineComponent } from "vue";
import { IEquipamentoAfericao } from "@/interfaces/equipamento/IEquipamentoAfericao";
import { AxiosResponse } from "axios";
import BoxEquipamentoAfericaoParaFormulario from "@/components/shared/formulario/BoxEquipamentoAfericaoParaFormulario.vue";
import { IGrupoParaEdicao } from "../interfaces/IGrupoParaEdicao";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minLength } from "@vuelidate/validators";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";

export default defineComponent({
    name: "FormularioEditarGrupo",
    emits: ["salvarGrupo"],
    props: {
        grupoSelecionado: {
            type: Object as PropType<IGrupoParaEdicao | null>,
            default: () => null,
        },
    },
    components: {
        BoxEquipamentoAfericaoParaFormulario,
        ViasMapaGeral,
    },
    data: () => ({
        nome: "",
        afericoesSelecionadas: [] as number[],
        equipamentos: [] as IEquipamentoAfericao[],
        idsEquipamentosSelecionados: [] as number[],
        tiposEquipamentosSelecionados: [] as string[],
    }),
    computed: {
        isNomeValido(): boolean | null {
            if (this.v$.nome.$dirty) {
                return !this.v$.nome.$invalid;
            }
            return null;
        },
        getNomeMessage(): string {
            if (this.v$.nome.$dirty) {
                return this.v$.nome.$errors[0]?.$message;
            }
            return "";
        },
        isAfericoesSelecionadasValido(): boolean | null {
            if (this.v$.afericoesSelecionadas.$dirty) {
                return !this.v$.afericoesSelecionadas.$invalid;
            }
            return null;
        },
        getAfericoesSelecionadasMessage(): string {
            if (this.v$.afericoesSelecionadas.$dirty) {
                return this.v$.afericoesSelecionadas.$errors[0]?.$message;
            }
            return "";
        },
    },
    methods: {
        buscarAfericoes() {
            this.store
                .dispatch(BUSCAR_AFERICOES)
                .then((res: AxiosResponse<IEquipamentoAfericao[]>) => {
                    this.equipamentos = res.data;
                    this.verificarFaixasSelecionadas();
                });
        },
        buscarEquipamentoPorId(
            idEquipamento: number,
        ): IEquipamentoAfericao | null {
            for (const equipamento of this.equipamentos) {
                if (equipamento.id === idEquipamento) {
                    return equipamento;
                }
            }
            return null;
        },
        isEquipamentoSelecionado(idEquipamento: number): boolean {
            return !!this.idsEquipamentosSelecionados.find(
                (id) => id === idEquipamento,
            );
        },
        alternarSelecaoDeEquipamento({ id }: IEquipamento) {
            const equipamento = this.buscarEquipamentoPorId(id);

            if (equipamento) {
                for (const faixa of equipamento.afericoes) {
                    if (this.isEquipamentoSelecionado(equipamento.id)) {
                        this.afericoesSelecionadas =
                            this.afericoesSelecionadas.filter((f) => {
                                return f !== faixa.id;
                            });
                    } else {
                        if (
                            !this.afericoesSelecionadas.find(
                                (f) => f === faixa.id,
                            )
                        ) {
                            this.afericoesSelecionadas = [
                                ...this.afericoesSelecionadas,
                                faixa.id,
                            ];
                        }
                    }
                }
                this.verificarSeTodasAsAfericoesEstaoSelecionadas(equipamento);
            }
        },
        verificarFaixasSelecionadas() {
            for (const equipamento of this.equipamentos) {
                this.verificarSeTodasAsAfericoesEstaoSelecionadas(equipamento);
            }
        },
        verificarSeTodasAsAfericoesEstaoSelecionadas(
            equipamento: IEquipamentoAfericao,
        ) {
            let todasSelecionadas = true;

            for (const faixa of equipamento.afericoes) {
                if (!this.afericoesSelecionadas.find((f) => f === faixa.id)) {
                    todasSelecionadas = false;
                    break;
                }
            }

            if (todasSelecionadas) {
                this.idsEquipamentosSelecionados = [
                    ...this.idsEquipamentosSelecionados,
                    equipamento.id,
                ];
            } else {
                this.idsEquipamentosSelecionados =
                    this.idsEquipamentosSelecionados.filter(
                        (id) => id !== equipamento.id,
                    );
            }
        },
        async salvarGrupo() {
            const isFormularioValido = await this.v$.$validate();

            if (isFormularioValido) {
                this.$emit("salvarGrupo", {
                    nome: this.nome,
                    afericoes: this.afericoesSelecionadas,
                });
            }
        },
        atualizarTiposDeEquipamentosSelecionados() {
            this.buscarAfericoes();
        },
    },
    created() {
        if (this.grupoSelecionado) {
            this.nome = this.grupoSelecionado.nome;
            this.afericoesSelecionadas = this.grupoSelecionado.afericoes;
            this.v$.$validate();
        }
        this.buscarAfericoes();
    },
    watch: {
        grupoSelecionado() {
            if (this.grupoSelecionado) {
                this.nome = this.grupoSelecionado.nome;
                this.afericoesSelecionadas = this.grupoSelecionado.afericoes;
                this.v$.$validate();
            }
        },
    },
    validations() {
        return {
            nome: {
                required: helpers.withMessage(
                    "É necessário que o grupo possua um nome",
                    required,
                ),
                minLength: helpers.withMessage(
                    "O nome do grupo precisa ter, pelo menos, 5 caracteres",
                    minLength(5),
                ),
            },
            afericoesSelecionadas: {
                required: helpers.withMessage(
                    "É necessário que, ao menos, 1 faixa seja selecionada",
                    required,
                ),
                minLength: helpers.withMessage(
                    "É necessário que, ao menos, 1 faixa seja selecionada",
                    minLength(1),
                ),
            },
        };
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();

        return {
            store,
            v$,
        };
    },
});
</script>
