<template>
    <form @submit.prevent="editarRota()">
        <ViasRow>
            <ViasCol>
                <ViasInput
                    label="Nome"
                    v-model="nome"
                    type="text"
                    id="editar-rota-velocidade-media-nome"
                    :valid="isNomeValido"
                    :detail="getNomeMessage"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    :rotas="[getCoordenadasRota]"
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                    @click-equipamento="alternarSelecaoDeEquipamento($event)"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4 max-h-300px overflow-y-auto">
            <ViasCol
                v-for="equipamento in equipamentos"
                :key="equipamento.id"
                col="12"
                sm="6"
                md="4"
                lg="3"
            >
                <div class="border border-radius-1 p-4">
                    <h3>{{ equipamento.sitio }}</h3>
                    <div class="mt-3">
                        <p class="text-muted">
                            {{ equipamento.posicao.endereco }}
                        </p>
                    </div>
                    <div class="mt-3">
                        <ViasButton
                            type="button"
                            :variant="definirVarianteOrigem(equipamento.id)"
                            class="mr-2"
                            @click="definirEquipamentoComoOrigem(equipamento)"
                            :id="
                                'editar-rota-velocidade-media-equipamento-' +
                                equipamento.sitio +
                                '-botao-origem'
                            "
                            size="sm"
                        >
                            Origem
                        </ViasButton>
                        <ViasButton
                            type="button"
                            :variant="definirVarianteDestino(equipamento.id)"
                            @click="definirEquipamentoComoDestino(equipamento)"
                            :id="
                                'editar-rota-velocidade-media-equipamento-' +
                                equipamento.sitio +
                                '-botao-destino'
                            "
                            size="sm"
                        >
                            Destino
                        </ViasButton>
                    </div>
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isOrigemValida !== null && !isOrigemValida" class="mt-4">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getOrigemMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isDestinoValida !== null && !isDestinoValida">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getDestinoMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow class="my-4">
            <ViasCol cols="12" md="4" class="d-flex align-items-end">
                <ViasInput
                    type="number"
                    label="Distância (em metros)"
                    v-model="distancia"
                    :valid="isDistanciaValida"
                    :detail="getDistanciaMessage"
                    required
                ></ViasInput>
            </ViasCol>
            <ViasCol cols="12" md="4" class="d-flex align-items-end">
                <ViasInput
                    type="number"
                    label="Tempo de percurso esperado (em segundos)"
                    v-model="tempoDePercurso"
                    :valid="isTempoDePercursoValida"
                    :detail="getTempoDePercursoMessage"
                    required
                ></ViasInput>
            </ViasCol>
            <ViasCol cols="12" md="4" class="d-flex align-items-end">
                <ViasInput
                    type="number"
                    label="Limite de velocidade média (em km/h)"
                    v-model="limiteDeVelocidade"
                    :valid="isLimiteDeVelocidadeValida"
                    :detail="getLimiteDeVelocidadeMessage"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol>
                <ViasButton variant="primary">Salvar</ViasButton>
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { IRotaVelocidadeMedia } from "../interfaces/IRotaVelocidadeMedia";
import { useStore } from "@/storeTs";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { BUSCAR_EQUIPAMENTOS } from "@/store/tipo-acoes";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minLength } from "@vuelidate/validators";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { BUSCAR_INFORMACOES_DA_ROTA } from "@/storeTs/mapa/type-actions";

export default defineComponent({
    name: "FormularioEditarRotaVelocidadeMedia",
    emits: ["editarRota"],
    props: {
        rotaSelecionada: {
            type: Object as PropType<IRotaVelocidadeMedia>,
            default: () => ({}),
        },
    },
    components: {
        ViasMapaGeral,
    },
    data: () => ({
        equipamentos: [] as IEquipamento[],
        idsEquipamentosSelecionados: [] as number[],
        nome: "",
        origem: null as IEquipamento | null,
        destino: null as IEquipamento | null,
        tempoDePercurso: null as number | null,
        distancia: null as number | null,
        limiteDeVelocidade: null as number | null,
    }),
    computed: {
        getCoordenadasRota(): IRotaMapa {
            if (this.origem && this.destino) {
                return {
                    id: 1,
                    inicio: {
                        lat: this.origem.posicao.latitude,
                        lng: this.origem.posicao.longitude,
                    },
                    fim: {
                        lat: this.destino.posicao.latitude,
                        lng: this.destino.posicao.longitude,
                    },
                };
            }
            return {
                id: 1,
            };
        },
        isNomeValido(): boolean | null {
            if (this.v$.nome.$dirty) {
                return !this.v$.nome.$invalid;
            }
            return null;
        },
        getNomeMessage(): string | null {
            return this.v$.nome.$errors[0]?.$message;
        },
        isOrigemValida(): boolean | null {
            if (this.v$.origem.$dirty) {
                return !this.v$.origem.$invalid;
            }
            return null;
        },
        getOrigemMessage(): string | null {
            return this.v$.origem.$errors[0]?.$message;
        },
        isDestinoValida(): boolean | null {
            if (this.v$.destino.$dirty) {
                return !this.v$.destino.$invalid;
            }
            return null;
        },
        getDestinoMessage(): string | null {
            return this.v$.destino.$errors[0]?.$message;
        },
        isTempoDePercursoValida(): boolean | null {
            if (this.v$.tempoDePercurso.$dirty) {
                return !this.v$.tempoDePercurso.$invalid;
            }
            return null;
        },
        getTempoDePercursoMessage(): string | null {
            return this.v$.tempoDePercurso.$errors[0]?.$message;
        },
        isDistanciaValida(): boolean | null {
            if (this.v$.distancia.$dirty) {
                return !this.v$.distancia.$invalid;
            }
            return null;
        },
        getDistanciaMessage(): string | null {
            return this.v$.distancia.$errors[0]?.$message;
        },
        isLimiteDeVelocidadeValida(): boolean | null {
            if (this.v$.limiteDeVelocidade.$dirty) {
                return !this.v$.limiteDeVelocidade.$invalid;
            }
            return null;
        },
        getLimiteDeVelocidadeMessage(): string | null {
            return this.v$.limiteDeVelocidade.$errors[0]?.$message;
        },
    },
    methods: {
        async buscarEquipamentos() {
            const response = await this.store.dispatch(BUSCAR_EQUIPAMENTOS);
            this.equipamentos = response.data;
            this.atualizarSelecoesDeEquipamentos();
        },
        definirVarianteOrigem(id: number) {
            if (this.origem?.id === id) {
                return "success";
            }
            return "outline-secondary";
        },
        definirVarianteDestino(id: number) {
            if (this.destino?.id === id) {
                return "success";
            }
            return "outline-secondary";
        },
        alternarSelecaoDeEquipamento(equipamento: IEquipamento) {
            this.apresentarOpcoesDeEscolha(
                `Deseja selecionar o equipamento "${equipamento.sitio}", 
                com o endereço ${equipamento.posicao.endereco}, como origem ou destino?`,
                "Origem",
                "Destino",
            ).then((resultado) => {
                if (resultado === "Origem") {
                    this.definirEquipamentoComoOrigem(equipamento);
                    return;
                }

                if (resultado === "Destino") {
                    this.definirEquipamentoComoDestino(equipamento);
                    return;
                }
            });
        },
        definirEquipamentoComoOrigem(equipamento: IEquipamento) {
            if (this.destino?.id === equipamento.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" já está selecionado como destino, deseja alterá-lo para origem?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.destino = null;
                        this.origem = equipamento;
                    }
                    this.atualizarSelecoesDeEquipamentosEBuscarSugestoes();
                });
            } else {
                if (this.origem?.id === equipamento.id) {
                    this.origem = null;
                } else {
                    this.origem = equipamento;
                }
                this.atualizarSelecoesDeEquipamentosEBuscarSugestoes();
            }
        },
        definirEquipamentoComoDestino(equipamento: IEquipamento) {
            if (this.origem?.id === equipamento.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" já está selecionado como origem, deseja alterá-lo para destino?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.origem = null;
                        this.destino = equipamento;
                    }
                    this.atualizarSelecoesDeEquipamentosEBuscarSugestoes();
                });
            } else {
                if (this.destino?.id === equipamento.id) {
                    this.destino = null;
                } else {
                    this.destino = equipamento;
                }
                this.atualizarSelecoesDeEquipamentosEBuscarSugestoes();
            }
        },
        atualizarSelecoesDeEquipamentos() {
            this.idsEquipamentosSelecionados = [
                this.origem?.id || 0,
                this.destino?.id || 0,
            ];
        },
        atualizarSelecoesDeEquipamentosEBuscarSugestoes() {
            this.atualizarSelecoesDeEquipamentos();
            this.buscarSugestoesDeInformacoesDaRota();
        },
        async buscarSugestoesDeInformacoesDaRota() {
            if (!this.hasOrigemSelecionado()) {
                return;
            }

            if (!this.hasDestinoSelecionado()) {
                return;
            }

            const { distancia, tempoDePercurso } = await this.store.dispatch(
                BUSCAR_INFORMACOES_DA_ROTA,
                {
                    origem: this.getCoordenadasRota.inicio,
                    destino: this.getCoordenadasRota.fim,
                },
            );

            this.distancia = distancia;
            this.tempoDePercurso = tempoDePercurso;
            this.limiteDeVelocidade = Math.round(
                (distancia / tempoDePercurso) * 3.6,
            );
        },
        hasOrigemSelecionado() {
            return !!this.getCoordenadasRota.inicio;
        },
        hasDestinoSelecionado() {
            return !!this.getCoordenadasRota.fim;
        },
        async editarRota() {
            const isFormularioValido = await this.v$.$validate();
            if (isFormularioValido) {
                this.$emit("editarRota", {
                    id: this.rotaSelecionada.id,
                    primeiro_equipamento_id: this.origem?.id,
                    segundo_equipamento_id: this.destino?.id,
                    nome: this.nome,
                    distancia_entre_equipamentos: this.distancia,
                    tempo_entre_equipamentos: this.tempoDePercurso,
                    limite_de_velocidade: this.limiteDeVelocidade,
                });
            }
        },
    },
    created() {
        this.buscarEquipamentos();
    },
    watch: {
        rotaSelecionada() {
            if (this.rotaSelecionada) {
                this.nome = this.rotaSelecionada.nome;
                this.origem = this.rotaSelecionada.primeiro_equipamento;
                this.destino = this.rotaSelecionada.segundo_equipamento;
                this.tempoDePercurso =
                    this.rotaSelecionada.tempo_entre_equipamentos;
                this.distancia =
                    this.rotaSelecionada.distancia_entre_equipamentos;
                this.limiteDeVelocidade =
                    this.rotaSelecionada.limite_de_velocidade;
                this.v$.$validate();
            } else {
                this.nome = "";
                this.origem = null;
                this.destino = null;
                this.tempoDePercurso = null;
                this.distancia = null;
            }
            this.atualizarSelecoesDeEquipamentos();
        },
    },
    validations() {
        return {
            nome: {
                required: helpers.withMessage(
                    "É obrigatório que a rota possua um nome",
                    required,
                ),
                minLength: helpers.withMessage(
                    "O nome da rota precisa ter, pelo menos, 5 caracteres",
                    minLength(5),
                ),
            },
            origem: {
                required: helpers.withMessage(
                    "É obrigatório que uma origem seja selecionada para a rota",
                    required,
                ),
            },
            destino: {
                required: helpers.withMessage(
                    "É obrigatório que um destino seja selecionado para a rota",
                    required,
                ),
            },
            tempoDePercurso: {
                required: helpers.withMessage(
                    "É obrigatório informar o tempo de percurso da rota",
                    required,
                ),
            },
            distancia: {
                required: helpers.withMessage(
                    "É obrigatório informar a distância da rota",
                    required,
                ),
            },
            limiteDeVelocidade: {
                required: helpers.withMessage(
                    "É obrigatório informar o limite de velocidade da rota",
                    required,
                ),
            },
        };
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();
        const { notificar, questionarAcao, apresentarOpcoesDeEscolha } =
            useNotificacao();

        return {
            store,
            v$,
            questionarAcao,
            apresentarOpcoesDeEscolha,
            notificar,
        };
    },
});
</script>
