import { createRouter, createWebHistory } from "vue-router";

import FathersView from "@/views/Fathers.vue";
import LoginView from "@/views/Login.vue";
import ContratoAtual from "@/domains/Contrato/pages/ContratoAtual.vue";
import MapaGestaoIntegradaView from "@/views/MapaGestaoIntegrada.vue";
import RelatorioMapaDeCalorVolumeHorarioTrafego from "@/views/RelatorioMapaDeCalorVolumeHorarioTrafego.vue";
import RelatorioMapaDeCalorExcessosDeVelocidade from "@/views/RelatorioMapaDeCalorExcessosDeVelocidade.vue";
import RelatorioMapaDeCalorIndiceLeituraPlaca from "@/views/RelatorioMapaDeCalorIndiceLeituraPlaca.vue";
import RelatorioDeFluxoVeicular from "@/domains/Fluxo/pages/RelatorioDeFluxoVeicular.vue";
import RelatorioVolumeDiarioTrafego from "@/views/RelatorioVolumeDiarioTrafego.vue";
import RelatorioExcessoVelocidade from "@/views/RelatorioExcessoVelocidade.vue";
import RelatorioExcessoVelocidadePorClasse from "@/views/RelatorioExcessoVelocidadePorClasse.vue";
import RelatorioTotalDeOcorrenciasPorEnquadramento from "@/views/RelatorioTotalDeOcorrenciasPorEnquadramento.vue";
import RelatorioTotalDeOcorrenciasPorEquipamento from "@/views/RelatorioTotalDeOcorrenciasPorEquipamento.vue";
import RelatorioTotalDeAutuacoesLavradas from "@/views/RelatorioTotalDeAutuacoesLavradas.vue";
import RelatorioVolumeMedioDiario from "@/domains/VolumeMedioDiario/pages/RelatorioVolumeMedioDiario.vue";
import RelatorioViolacoes from "@/views/RelatorioViolacoes.vue";
import RelatorioPassagens from "@/views/RelatorioPassagens.vue";
import RelatorioDeImpacto from "@/views/RelatorioDeImpacto.vue";
import GruposView from "@/views/GestaoIntegrada/Grupos/Grupos.vue";
import NovoGrupoView from "@/views/GestaoIntegrada/Grupos/NovoGrupo.vue";
import EditarGrupoView from "@/views/GestaoIntegrada/Grupos/EditarGrupo.vue";
import RelatorioUltimosCalculos from "@/views/RelatorioUltimosCalculos.vue";
import MapaVelocidadeMedia from "@/views/MapaVelocidadeMedia.vue";
import RelatorioOcorrencias from "@/views/RelatorioOcorrencias.vue";
import HistoricoDeOcorrenciasDeVelocidadeMedia from "@/views/HistoricoDeOcorrenciasDeVelocidadeMedia.vue";
import RelatorioVelocidadeMedia from "@/views/RelatorioVelocidadeMedia.vue";
import RelatorioOcorrenciaEspecificaVue from "@/views/RelatorioOcorrenciaEspecifica.vue";
import ListarUsuarios from "@/domains/Usuarios/pages/ListarUsuarios.vue";
import CadastrarUsuario from "@/domains/Usuarios/pages/CadastrarUsuario.vue";
import EditarUsuario from "@/domains/Usuarios/pages/EditarUsuario.vue";
import MapaTempoDePercurso from "@/views/MapaTempoDePercurso.vue";
import MapaOrigemEDestino from "@/views/MapaOrigemEDestino.vue";
import AuditoriaVelocidadeMedia from "@/views/AuditoriaVelocidadeMedia.vue";
import AuditoriaTempoDePercurso from "@/views/AuditoriaTempoDePercurso.vue";
import AuditoriaOrigemEDestinoVue from "@/views/AuditoriaOrigemEDestino.vue";
import AuditoriaUsuarios from "@/views/AuditoriaUsuarios.vue";
import RelatorioDeOrigemEDestino from "@/views/Relatorios/RelatorioDeOrigemEDestino/RelatorioDeOrigemEDestino.vue";

import ConfiguracaoVelocidadeMediaView from "@/views/VelocidadeMedia/Configuracao/ConfiguracaoVelocidadeMedia.vue";
import NovaRotaVelocidadeMediaView from "@/views/VelocidadeMedia/Configuracao/NovaRotaVelocidadeMedia.vue";
import EditarRotaVelocidadeMediaView from "@/views/VelocidadeMedia/Configuracao/EditarRotaVelocidadeMedia.vue";
import ConfiguracaoTempoDePercursoView from "@/views/TempoDePercurso/Configuracao/ConfiguracaoTempoDePercurso.vue";
import NovaRotaTempoDePercursoView from "@/views/TempoDePercurso/Configuracao/NovaRotaTempoDePercurso.vue";
import EditarRotaTempoDePercursoView from "@/views/TempoDePercurso/Configuracao/EditarRotaTempoDePercurso.vue";
import ConfiguracaoOrigemEDestinoView from "@/views/OrigemEDestino/ConfiguracaoOrigemEDestino.vue";
import NovaRotaOrigemEDestinoView from "@/views/OrigemEDestino/NovaRotaOrigemEDestino.vue";
import EditarRotaOrigemEDestinoView from "@/views/OrigemEDestino/EditarRotaOrigemEDestino.vue";

import WhitelistView from "@/views/Configuracao/Whitelist/WhitelistView.vue";
import AdicionarPlacaNaWhitelistView from "@/views/Configuracao/Whitelist/AdicionarPlacaNaWhitelistView.vue";
import EditarPlacaDaWhitelistView from "../views/Configuracao/Whitelist/EditarPlacaDaWhitelistView.vue";

import AreasView from "@/views/Configuracao/Areas/AreasView.vue";
import HorariosListar from "../domains/Horarios/pages/HorariosListar.vue";
import HorariosAdicionar from "../domains/Horarios/pages/HorariosAdicionar.vue";
import HorariosEditar from "../domains/Horarios/pages/HorariosEditar.vue";

import ListarAgendamentos from "../domains/AgendamentoDeRelatorio/pages/ListarAgendamentos.vue";


import { store } from "@/storeTs";

const routes = [
    {
        path: "/",
        component: FathersView,
        children: [
            {
                path: "",
                redirect: "/contrato",
            },
            {
                name: "Login",
                path: "login",
                component: LoginView,
            },
            {
                name: "Contrato",
                path: "contrato",
                component: ContratoAtual,
            },
            {
                name: "Gestão Integrada",
                path: "gestao-integrada",
                component: FathersView,
                children: [
                    {
                        name: "Mapa Gestão Integrada",
                        path: "mapa",
                        component: MapaGestaoIntegradaView,
                    },
                    {
                        name: "Grupos",
                        path: "grupos",
                        component: FathersView,
                        children: [
                            {
                                name: "Grupos de gestão integrada cadastrados",
                                path: "",
                                component: GruposView,
                            },
                            {
                                name: "Novo grupo de gestão integrada",
                                path: "novo-grupo",
                                component: NovoGrupoView,
                            },
                            {
                                name: "Editar grupo de gestão integrada",
                                path: "editar-grupo/:id",
                                component: EditarGrupoView,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Velocidade Média",
                path: "velocidade-media",
                component: FathersView,
                children: [
                    {
                        name: "Mapa Velocidade Média",
                        path: "mapa",
                        component: MapaVelocidadeMedia,
                    },
                    {
                        name: "Relatórios Velocidade Média",
                        path: "relatorios",
                        component: FathersView,
                        children: [
                            {
                                name: "Infrações",
                                path: "infracoes",
                                component: RelatorioOcorrencias,
                            },
                            {
                                name: "Histórico de Infrações de Velocidade Média",
                                path: "historico-de-infracoes-de-velocidade-media",
                                component:
                                    HistoricoDeOcorrenciasDeVelocidadeMedia,
                            },
                            {
                                name: "Relatório de Velocidade Média",
                                path: "velocidade-media",
                                component: RelatorioVelocidadeMedia,
                            },
                            {
                                name: "Infração Específica",
                                path: "infracao-especifica/:id",
                                component: RelatorioOcorrenciaEspecificaVue,
                            },
                        ],
                    },
                    {
                        name: "Configuração Velocidade Média",
                        path: "configuracao",
                        component: FathersView,
                        children: [
                            {
                                name: "Rotas de velocidade média cadastradas",
                                path: "",
                                component: ConfiguracaoVelocidadeMediaView,
                            },
                            {
                                name: "Nova rota de velocidade média",
                                path: "nova-rota",
                                component: NovaRotaVelocidadeMediaView,
                            },
                            {
                                name: "Editar rota de velocidade média",
                                path: "editar-rota/:id",
                                component: EditarRotaVelocidadeMediaView,
                            },
                        ],
                    },
                    {
                        name: "Auditoria Velocidade Média",
                        path: "auditoria",
                        component: AuditoriaVelocidadeMedia,
                    },
                ],
            },
            {
                name: "Tempo de Percurso",
                path: "tempo-de-percurso",
                component: FathersView,
                children: [
                    {
                        name: "Mapa Tempo de Percurso",
                        path: "mapa",
                        component: MapaTempoDePercurso,
                    },
                    {
                        name: "Relatórios Tempo de Percurso",
                        path: "relatorios",
                        component: FathersView,
                        children: [
                            {
                                name: "Fluxo",
                                path: "fluxo",
                                component: RelatorioPassagens,
                            },
                            {
                                name: "Últimos Cálculos",
                                path: "ultimos-calculos",
                                component: RelatorioUltimosCalculos,
                            },
                        ],
                    },
                    {
                        name: "Configuração Tempo de Percurso",
                        path: "configuracao",
                        component: FathersView,
                        children: [
                            {
                                name: "Rotas de tempo de percurso cadastradas",
                                path: "",
                                component: ConfiguracaoTempoDePercursoView,
                            },
                            {
                                name: "Nova rota de tempo de percurso",
                                path: "nova-rota",
                                component: NovaRotaTempoDePercursoView,
                            },
                            {
                                name: "Edição de rota de tempo de percurso",
                                path: "editar-rota/:id",
                                component: EditarRotaTempoDePercursoView,
                            },
                        ],
                    },
                    {
                        name: "Auditoria Tempo de Percurso",
                        path: "auditoria",
                        component: AuditoriaTempoDePercurso,
                    },
                ],
            },
            {
                name: "Origem e Destino",
                path: "origem-e-destino",
                component: FathersView,
                children: [
                    {
                        name: "Mapa Origem e Destino",
                        path: "mapa",
                        component: MapaOrigemEDestino,
                    },
                    {
                        name: "Configuração Origem e Destino",
                        path: "configuracao",
                        component: FathersView,
                        children: [
                            {
                                name: "Rotas de origem e destino cadastradas",
                                path: "",
                                component: ConfiguracaoOrigemEDestinoView,
                            },
                            {
                                name: "Nova rota de origem e destino",
                                path: "nova-rota",
                                component: NovaRotaOrigemEDestinoView,
                            },
                            {
                                name: "Editar rota de origem e destino",
                                path: "editar-rota/:id",
                                component: EditarRotaOrigemEDestinoView,
                            },
                        ],
                    },
                    {
                        name: "Auditoria Origem e Destino",
                        path: "auditoria",
                        component: AuditoriaOrigemEDestinoVue,
                    },
                ],
            },
            {
                name: "Relatórios",
                path: "relatorios",
                component: FathersView,
                children: [
                    {
                        name: "Mapa de Calor de Volume Horário de Tráfego",
                        path: "volume-horario-de-trafego",
                        component: RelatorioMapaDeCalorVolumeHorarioTrafego,
                    },
                    {
                        name: "Mapa de Calor de Excessos de Velocidade",
                        path: "excessos-de-velocidade",
                        component: RelatorioMapaDeCalorExcessosDeVelocidade,
                    },
                    {
                        name: "Mapa de Calor de Índice de Leitura de Placa",
                        path: "indice-de-leitura-de-placa",
                        component: RelatorioMapaDeCalorIndiceLeituraPlaca,
                    },
                    {
                        name: "Fluxo Veicular",
                        path: "fluxo-veicular",
                        component: RelatorioDeFluxoVeicular,
                    },
                    {
                        name: "Infrações Gerais",
                        path: "infracoes-gerais",
                        component: RelatorioViolacoes,
                    },
                    {
                        name: "Volume Médio Diário por Mês",
                        path: "volume-medio-diario-por-mes",
                        component: RelatorioVolumeMedioDiario,
                    },
                    {
                        name: "Volume Diário de Tráfego",
                        path: "volume-diario-trafego",
                        component: RelatorioVolumeDiarioTrafego,
                    },
                    {
                        name: "Excesso de Velocidade",
                        path: "excesso-de-velocidade",
                        component: RelatorioExcessoVelocidade,
                    },
                    {
                        name: "Excesso de Velocidade por Classe",
                        path: "excesso-de-velocidade-por-classe",
                        component: RelatorioExcessoVelocidadePorClasse,
                    },
                    {
                        name: "Total de Infrações por Enquadramento",
                        path: "total-de-infracoes-por-enquadramento",
                        component: RelatorioTotalDeOcorrenciasPorEnquadramento,
                    },
                    {
                        name: "Total de Infrações por Equipamento",
                        path: "total-de-infracoes-por-equipamento",
                        component: RelatorioTotalDeOcorrenciasPorEquipamento,
                    },
                    {
                        name: "Total de Autuações Lavradas",
                        path: "total-de-autuacoes-lavradas",
                        component: RelatorioTotalDeAutuacoesLavradas,
                    },
                    {
                        name: "Relatório de Impacto",
                        path: "impacto",
                        component: RelatorioDeImpacto,
                    },
                    {
                        name: "Relatório de Origem e Destino",
                        path: "origem-e-destino",
                        component: RelatorioDeOrigemEDestino,
                    },
                ],
            },
            {
                name: "Configuração Geral",
                path: "configuracao-geral",
                component: FathersView,
                children: [
                    {
                        name: "Agendamentos",
                        path: "agendamentos",
                        component: ListarAgendamentos
                    },
                    {
                        name: "Áreas",
                        path: "areas/mapa",
                        component: AreasView,
                    },
                    {
                        name: "Horários",
                        path: "horarios",
                        component: FathersView,
                        children: [
                            {
                                name: "Horários cadastrados",
                                path: "",
                                component: HorariosListar,
                            },
                            {
                                name: "Adicionar horário",
                                path: "adicionar-horario",
                                component: HorariosAdicionar,
                            },
                            {
                                name: "Editar horário",
                                path: "editar-horario/:id",
                                component: HorariosEditar,
                            },
                        ],
                    },
                    {
                        name: "Whitelist",
                        path: "whitelist",
                        component: FathersView,
                        children: [
                            {
                                name: "Placas cadastradas",
                                path: "",
                                component: WhitelistView,
                            },
                            {
                                name: "Adicionar placa",
                                path: "adicionar-placa",
                                component: AdicionarPlacaNaWhitelistView,
                            },
                            {
                                name: "Editar placa",
                                path: "editar-placa/:id",
                                component: EditarPlacaDaWhitelistView,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Configuração",
                path: "configuracao",
                component: FathersView,
                children: [
                    {
                        name: "Usuários",
                        path: "usuarios",
                        component: FathersView,
                        children: [
                            {
                                name: "Usuários cadastrados",
                                path: "",
                                component: ListarUsuarios,
                            },
                            {
                                name: "Cadastrar usuário",
                                path: "cadastrar-usuario",
                                component: CadastrarUsuario,
                            },
                            {
                                name: "Editar usuário",
                                path: "editar-usuario/:id",
                                component: EditarUsuario,
                            },
                        ],
                    },
                    {
                        name: "Auditoria Usuários",
                        path: "auditoria-usuarios",
                        component: AuditoriaUsuarios,
                    },
                ],
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

const validarSeDeixaMenuAberto = () => {
    const larguraDaTela = window.innerWidth;
    return larguraDaTela > 1200;
};

router.beforeEach((to, from) => {
    if (store.getters.hasCredentials) {
        if (to.path == "/login") {
            router.push("/contrato");
        }

        if (validarSeDeixaMenuAberto(from)) {
            store.dispatch("openMenu");
        } else {
            store.dispatch("closeMenu");
        }

        return true;
    } else {
        if (to.path != "/login") router.push("/login");
    }
});

export default router;
