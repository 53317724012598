import coresService from "@/core/composables/CoresService";
import Swal, { SweetAlertOptions } from "sweetalert2";

class NotificacaoService {

    private opcoesPadraoSwal: SweetAlertOptions = {
        toast: true,
        position: "bottom-right"
    };
    
    notificarCarregamento(titulo: string, texto?: string) {
        Swal.fire({
            ...this.opcoesPadraoSwal,
            title: titulo,
            text: texto,
            showConfirmButton: false
        });
        Swal.showLoading();
    }

    notificarSucesso(titulo: string, texto?: string) {
        Swal.fire({
            ...this.opcoesPadraoSwal,
            title: titulo,
            text: texto,
            icon: "success",
            timer: 3000,
            showConfirmButton: false
        });
    }

    notificarErro(titulo: string, texto?: string) {
        Swal.fire({
            ...this.opcoesPadraoSwal,
            title: titulo,
            text: texto,
            icon: "error",
            timer: 3000,
            showConfirmButton: false
        });
    }

    apresentarOpcoesDeEscolha(texto: string, primeiraEscolha: string, segundaEscolha: string) {
        return Swal.fire({
            text: texto,
            icon: "question",
            confirmButtonText: primeiraEscolha,
            showDenyButton: true,
            denyButtonText: segundaEscolha,
            denyButtonColor: coresService.listarCoresDisponiveis().primary
        }).then((res) => {
            if (res.isConfirmed) {
                return primeiraEscolha;
            }
            if (res.isDenied) {
                return segundaEscolha;
            }
            return null;
        });
    }

    questionarAcao(titulo: string, texto?: string) {
        return Swal.fire({
            title: titulo,
            text: texto,
            icon: "question",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não"
        }).then((res) => {
            return res.isConfirmed;
        });
    }

}

const notificacaoService = new NotificacaoService();

export default notificacaoService;