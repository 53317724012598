<template>
<LayoutFormularioRegistro titulo="Novo horário" texto-botao-enviar="Adicionar" @submit="enviarHorarioParaSerAdicionado()">
    <ViasRow>
        <ViasCol cols="12">
            <ViasInput type="text" label="Nome" v-model="nome" required/>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInputTime type="text" label="Data inicial" v-model="dataInicial" required/>
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInputTime type="text" label="Data final" v-model="dataFinal" required/>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12">
            <ViasCheckbox v-model="publica" after-text="Pública" switch/>
        </ViasCol>
    </ViasRow>
</LayoutFormularioRegistro>
</template>

<script lang="ts" setup>
import ViasInputTime from "@/components/shared/ViasInputTime.vue";
import LayoutFormularioRegistro from "@/layouts/LayoutFormularioRegistro.vue"
import moment from "moment";
import horariosService from "../services/HorariosService";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { ref } from "vue"
import { useRouter } from "vue-router";

const router = useRouter();
const { notificar, notificarCarregamento } = useNotificacao();

const nome = ref("");
const dataInicial = ref(moment().format("HH:mm:ss"));
const dataFinal = ref(moment().add("h", 1).format("HH:mm:ss"));
const publica = ref(true);

async function enviarHorarioParaSerAdicionado() {
    notificarCarregamento("Adicionando novo horário, aguarde um momento por favor...");
    const response = await horariosService.adicionarHorario({
        nome: nome.value,
        inicial: dataInicial.value,
        final: dataFinal.value,
        publica: publica.value
    });

    if (response.status === 201) {
        notificar("Novo horário adicionado com sucesso!", "success");
        router.push("/configuracao-geral/horarios");
    }
}
</script>