<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Rotas cadastradas</h2>
                        <router-link
                            to="/origem-e-destino/configuracao/nova-rota"
                            class="btn btn-primary"
                        >
                            Adicionar
                        </router-link>
                    </div>
                </template>
                <ViasTable
                    class="mt-4"
                    :carregando="primeiraBusca"
                    :linhas="rotas"
                    :colunas="colunas"
                    com-opcoes
                >
                    <template #opcoes="{ linha }">
                        <div
                            class="d-flex align-items-center justify-content-end"
                        >
                            <router-link
                                class="btn btn-outline-primary btn-sm m-2"
                                :to="
                                    '/origem-e-destino/configuracao/editar-rota/' +
                                    linha.id
                                "
                            >
                                Editar
                            </router-link>
                            <ViasButton
                                variant="none-danger"
                                size="sm"
                                @click="removerRota(linha)"
                            >
                                Remover
                            </ViasButton>
                        </div>
                    </template>
                </ViasTable>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import { IRotaOrigemEDestino } from "./interfaces/IRotaOrigemEDestino";
import { IRotaOrigemEDestinoApresentacao } from "./interfaces/IRotaOrigemEDestinoApresentacao";
import { defineComponent } from "vue";
import { useStore } from "@/storeTs";
import { BUSCAR_ROTAS_ORIGEM_E_DESTINO } from "@/store/tipo-acoes";
import moment from "moment";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { REMOVER_ROTA_ORIGEM_E_DESTINO } from "@/storeTs/origem-e-destino/configuracao/type-actions";

export default defineComponent({
    name: "ConfiguracaoOrigemEDestinoView",
    data: () => ({
        primeiraBusca: true,
        rotas: [] as IRotaOrigemEDestinoApresentacao[],
        colunas: [
            {
                titulo: "#",
                referencia: "numeroDaLinha",
            },
            {
                titulo: "Nome",
                referencia: "nome",
            },
            {
                titulo: "Origem",
                referencia: "origem",
            },
            {
                titulo: "Destino",
                referencia: "destino",
            },
            {
                titulo: "Tempo de percurso",
                referencia: "tempoEntreEquipamentos",
            },
            {
                titulo: "Criada em",
                referencia: "criadaEm",
            },
        ] as IColunaTabela[],
    }),
    methods: {
        buscarRotas() {
            this.store.dispatch(BUSCAR_ROTAS_ORIGEM_E_DESTINO).then((res) => {
                this.rotas = res.data.map(
                    (
                        rota: IRotaOrigemEDestino,
                        i: number,
                    ): IRotaOrigemEDestinoApresentacao => ({
                        numeroDaLinha: i + 1,
                        id: rota.id,
                        nome: rota.nome,
                        tempoEntreEquipamentos: `${rota.tempo_entre_equipamentos} segundos`,
                        criadaEm: moment(rota.criada_em).format(
                            "DD/MM/YYYY HH:mm:ss",
                        ),
                        origem: `${rota.primeiro_equipamento.equipamento.sitio}, ${rota.primeiro_equipamento.equipamento.posicao.endereco}`,
                        destino: `${rota.segundo_equipamento.equipamento.sitio}, ${rota.segundo_equipamento.equipamento.posicao.endereco}`,
                    }),
                );
                this.primeiraBusca = false;
            });
        },
        removerRota(rota: IRotaOrigemEDestinoApresentacao) {
            this.questionarAcao(
                `Tem certeza que deseja remover a rota "${rota.nome}"? Após removê-la, não será possível recuperá-la.`,
            ).then((isConfirmado) => {
                if (isConfirmado) {
                    this.notificarCarregamento(
                        `Estamos removendo a rota "${rota.nome}", aguarde um momento por favor...`,
                    );
                    this.store
                        .dispatch(REMOVER_ROTA_ORIGEM_E_DESTINO, rota.id)
                        .then((res) => {
                            if (res.status === 204) {
                                this.notificar(
                                    `A rota "${rota.nome}" foi removida com sucesso!`,
                                    "success",
                                );
                                this.buscarRotas();
                            }
                        });
                }
            });
        },
    },
    created() {
        this.buscarRotas();
    },
    setup() {
        const store = useStore();
        const { questionarAcao, notificarCarregamento, notificar } =
            useNotificacao();

        return {
            store,
            questionarAcao,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
