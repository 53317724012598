import { InjectionKey } from "vue";
import { createStore, useStore as vuexUseStore, type Store } from "vuex";
import VuexPersistence from "vuex-persist";
import { IUsuarioState } from "./IUsuarioState";
import moment from "moment";
import router from "@/router";
import axios from "@/plugins/axios.plugin";
import { configuracaoAuditoriaUsuariosModule } from "./configuracao/auditoria-usuarios";
import { configuracaoUsuariosModule } from "./configuracao/usuarios";
import { equipamentosModule } from "./equipamentos";
import { gestaoIntegradaGruposModule } from "./gestao-integrada/grupos";
import { gestaoIntegradaMapaModule } from "./gestao-integrada/mapa";
import { origemEDestinoAuditoriaModule } from "./origem-e-destino/auditoria";
import { origemEDestinoConfiguracaoModule } from "./origem-e-destino/configuracao";
import { tempoDePercursoConfiguracaoModule } from "./tempo-de-percurso/configuracao";
import { velocidadeMediaConfiguracaoModule } from "./velocidade-media/configuracao";
import { velocidadeMediaOcorrenciaEspecificaModule } from "./velocidade-media/relatorios/ocorrencia-especifica";
import { camerasModule } from "./cameras";
import { mapaModule } from "./mapa";
import { tempoDePercursoMapaModule } from "./tempo-de-percurso/mapa";
import { origemEDestinoMapaModule } from "./origem-e-destino/mapa";
import { relatoriosModule } from "./relatorios";
import {
    SET_APRESENTAR_EQUIPAMENTOS_ESTATICOS,
    SET_DATA_BUSCA_EQUIPAMENTOS_ESTATICOS,
    SET_MOSTRANDO_BOX,
    SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS,
    SET_TIPOS_EQUIPAMENTOS,
} from "./type-mutations";
import { configuracaoWhitelistModule } from "./configuracao/whitelist";
import { configuracaoAreasModule } from "./configuracao/areas";

export interface State {
    theme: string;
    menu_open: boolean;
    usuario: IUsuarioState | null;
    access_token: string | null;
    expiration: string | null;
    refresh_token: string | null;
    token_type: string | null;
    contratos: Array<any>;
    contrato_selecionado: any;
    refreshing: boolean;
    subscribersTokenRefresh: Array<Promise<any>>;
    tipos_equipamentos_selecionados: Array<string>;
    primeiraBuscaDeTiposDeEquipamentos: boolean;
    dataBuscaEquipamentosEstaticos: string;
    apresentarEquipamentosEstaticos: boolean;
    cameras: {
        credenciais: null | string;
        ultima_busca_das_credenciais: null | string;
        monitores: Array<any>;
        ultima_busca_dos_monitores: null | string;
    };
    mostrandoBox: boolean;
}

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: "vias-inteligentes",
});

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    state: {
        theme: "dark-theme",
        menu_open: true,
        usuario: null,
        access_token: null,
        expiration: null,
        refresh_token: null,
        token_type: null,
        contratos: [],
        contrato_selecionado: null,
        refreshing: false,
        subscribersTokenRefresh: [],
        tipos_equipamentos_selecionados: [],
        primeiraBuscaDeTiposDeEquipamentos: true,
        dataBuscaEquipamentosEstaticos: moment().format("YYYY-MM-DD"),
        apresentarEquipamentosEstaticos: true,
        cameras: {
            credenciais: null,
            ultima_busca_das_credenciais: null,
            monitores: [],
            ultima_busca_dos_monitores: null,
        },
        mostrandoBox: false,
    },
    getters: {
        doneToken(state): string {
            return `${state.token_type} ${state.access_token}`;
        },
        isLogged(state): boolean {
            return (
                state.access_token !== null &&
                moment().unix() < moment(state.expiration).unix()
            );
        },
        hasCredentials(state): boolean {
            return !!state.usuario;
        },
        doneRefreshToken(state): string {
            return `${state.token_type} ${state.refresh_token}`;
        },
        doneFirstName(state): string {
            if (state.usuario) {
                return state.usuario.context.nome.split(" ")[0];
            }
            return "";
        },
        doneFirstLetter(state): string {
            if (state.usuario) {
                return state.usuario.context.nome.charAt(0);
            }
            return "";
        },
        isSuperUsuario(state): boolean {
            if (state.usuario) {
                return state.usuario.context.super_usuario;
            }
            return false;
        },
        doneSuperUsuario(state): string {
            if (state.usuario && state.usuario.context.super_usuario) {
                return "Super usuário";
            }
            return "Usuário";
        },
        doneContracts(state): any[] {
            return state.contratos.map((contrato) => ({
                text: contrato.nome,
                value: contrato.id,
            }));
        },
        getTiposEquipamentosSelecionados(state): string[] {
            return state.tipos_equipamentos_selecionados;
        },
    },
    mutations: {
        alterarTema(state, newTheme) {
            state.theme = newTheme;
        },
        inserirToken(state, dados) {
            state.access_token = dados.access_token;
            state.expiration = dados.expiration;
            state.refresh_token = dados.refresh_token;
            state.token_type = dados.token_type;
        },
        updateMenuStatus(state, status) {
            state.menu_open = status;
        },
        toggleMenuStatus(state) {
            state.menu_open = !state.menu_open;
        },
        inserirContratos(state, contratos) {
            state.contratos = contratos;
        },
        inserirUsuario(state, token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map(
                        (c) =>
                            "%" +
                            ("00" + c.charCodeAt(0).toString(16)).slice(-2),
                    )
                    .join(""),
            );

            state.usuario = JSON.parse(jsonPayload);
        },
        removerToken(state) {
            state.usuario = null;
            state.access_token = null;
            state.expiration = null;
            state.refresh_token = null;
            state.token_type = null;
            state.contratos = [];
            state.contrato_selecionado = null;
            state.refreshing = false;
            state.tipos_equipamentos_selecionados = [];
            state.primeiraBuscaDeTiposDeEquipamentos = true;
            state.dataBuscaEquipamentosEstaticos =
                moment().format("YYYY-MM-DD");
            state.apresentarEquipamentosEstaticos = true;
        },
        selecionarContrato(state, contrato) {
            state.contrato_selecionado = contrato;
        },
        atualizarRefreshing(state, estado) {
            state.refreshing = estado;
        },
        subscriberTokenRefresh(state, funcao) {
            state.subscribersTokenRefresh.push(funcao);
        },
        [SET_TIPOS_EQUIPAMENTOS](state, tiposEquipamentos) {
            state.tipos_equipamentos_selecionados = tiposEquipamentos;
        },
        [SET_DATA_BUSCA_EQUIPAMENTOS_ESTATICOS](state, data: string) {
            state.dataBuscaEquipamentosEstaticos =
                moment(data).format("YYYY-MM-DD");
        },
        [SET_APRESENTAR_EQUIPAMENTOS_ESTATICOS](state, apresentar: boolean) {
            state.apresentarEquipamentosEstaticos = apresentar;
        },
        [SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS](state, primeiraBusca: boolean) {
            state.primeiraBuscaDeTiposDeEquipamentos = primeiraBusca;
        },
        [SET_MOSTRANDO_BOX](state, novoValor: boolean) {
            state.mostrandoBox = novoValor;
        },
    },
    actions: {
        alterarTema({ commit }, newTheme: string) {
            commit("alterarTema", newTheme);
        },
        closeMenu({ commit }) {
            commit("updateMenuStatus", false);
        },
        openMenu({ commit }) {
            commit("updateMenuStatus", true);
        },
        toggleMenu({ commit }) {
            commit("toggleMenuStatus");
        },
        inserirToken({ commit }, dados: any) {
            commit("inserirToken", dados);
        },
        inserirContratos({ commit }, contratos: any[]) {
            commit("inserirContratos", contratos);
        },
        inserirUsuario({ commit }, token: any) {
            commit("inserirUsuario", token);
        },
        removerToken({ commit }) {
            commit("removerToken");
        },
        selecionarContrato({ commit }, contrato) {
            commit("selecionarContrato", contrato);
        },
        atualizarTokenDeAcesso({ commit }) {
            if (!this.state.refreshing) {
                commit("atualizarRefreshing", true);

                return new Promise((resolve) => {
                    const axiosRefresh = axios.create({
                        baseURL:
                            process.env.NODE_ENV === "production"
                                ? process.env.VUE_APP_URL_BACKEND
                                : "http://10.76.77.91/api/",
                        headers: {
                            Authorization: this.getters.doneRefreshToken,
                        },
                    });

                    axiosRefresh
                        .post("/vias/v1/autenticacao/refresh")
                        .then((res) => {
                            const dados = res.data;

                            commit("inserirToken", dados);
                            commit("inserirUsuario", dados.access_token);

                            resolve(res);
                        })
                        .catch(({ response: { status } }) => {
                            if (status == 401) {
                                commit("removerToken");
                                router.push("/login");
                            }
                        });
                });
            } else {
                return new Promise((resolve, reject) => reject());
            }
        },
        onRefreshed({ commit }) {
            commit("atualizarRefreshing", false);
        },
        selecionarContratoDoUsuario({ commit, state }) {
            if (state.usuario) {
                commit("selecionarContrato", "");
            }
        },
    },
    modules: {
        configuracaoAreasModule,
        configuracaoAuditoriaUsuariosModule,
        configuracaoUsuariosModule,
        configuracaoWhitelistModule,
        equipamentosModule,
        gestaoIntegradaGruposModule,
        gestaoIntegradaMapaModule,
        origemEDestinoAuditoriaModule,
        origemEDestinoConfiguracaoModule,
        origemEDestinoMapaModule,
        tempoDePercursoConfiguracaoModule,
        tempoDePercursoMapaModule,
        velocidadeMediaConfiguracaoModule,
        velocidadeMediaOcorrenciaEspecificaModule,
        camerasModule,
        mapaModule,
        relatoriosModule,
    },
    plugins: [vuexLocal.plugin],
});

export const useStore = (): Store<State> => {
    return vuexUseStore(key);
};
