<template>
    <vias-card>
        <FormularioRelatorioDeOrigemEDestino
            @buscar-relatorio="buscarRelatorio($event)"
        ></FormularioRelatorioDeOrigemEDestino>
    </vias-card>
    <vias-card class="mt-4" v-if="relatorio">
        <vias-row>
            <vias-col class="p-0">
                <vias-button
                    class="w-100 m-0"
                    variant="outline-primary"
                    @click="emitirPdf()"
                    >Emitir PDF</vias-button
                >
            </vias-col>
        </vias-row>
    </vias-card>
    <vias-card class="mt-4" v-if="relatorio" ref="cardRelatorio">
        <vias-row>
            <vias-col cols="12" md="6">
                <div class="p-8 border border-radius-1">
                    <h2>Origem: {{ relatorio.origem.equipamento.sitio }}</h2>
                    <p class="my-2">
                        {{ relatorio.origem.equipamento.endereco }}
                    </p>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Total de fluxo:</strong
                                >
                                {{ relatorio.origem.passagens }} de fluxo
                            </h4>
                        </vias-col>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Total de placas lidas:</strong
                                >
                                {{ relatorio.origem.placasLidas }} placas
                            </h4>
                        </vias-col>
                    </vias-row>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Porcentagem de placas lidas:</strong
                                >
                                {{ relatorio.origem.porcentagemPlacasLidas }}
                            </h4>
                        </vias-col>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold">
                                    Margem de erro:
                                </strong>
                                {{ relatorio.origem.margemDeErro }}
                                <ViasExplicacaoMargemDeErro/>
                            </h4>
                        </vias-col>
                    </vias-row>
                </div>
            </vias-col>
            <vias-col cols="12" md="6">
                <div class="p-8 border border-radius-1">
                    <h2>Destino: {{ relatorio.destino.equipamento.sitio }}</h2>
                    <p class="my-2">
                        {{ relatorio.destino.equipamento.endereco }}
                    </p>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Total de fluxo:</strong
                                >
                                {{ relatorio.destino.passagens }} de fluxo
                            </h4>
                        </vias-col>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Total de placas lidas:</strong
                                >
                                {{ relatorio.destino.placasLidas }} placas
                            </h4>
                        </vias-col>
                    </vias-row>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Porcentagem de placas lidas:</strong
                                >
                                {{ relatorio.destino.porcentagemPlacasLidas }}
                            </h4>
                        </vias-col>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Margem de erro:</strong
                                >
                                {{ relatorio.destino.margemDeErro }}
                                <ViasExplicacaoMargemDeErro/>
                            </h4>
                        </vias-col>
                    </vias-row>
                </div>
            </vias-col>
        </vias-row>
        <vias-row>
            <vias-col cols="12">
                <div class="p-8 border border-radius-1">
                    <h2>Em ambos</h2>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Total de fluxo:</strong
                                >
                                {{ relatorio.ambos.totalDePassagens }} de fluxo
                            </h4>
                        </vias-col>
                        <vias-col class="p-0 mt-2" cols="12" md="6">
                            <h4>
                                <strong class="text-bold"
                                    >Margem de erro:</strong
                                >
                                {{ relatorio.ambos.margemDeErro }}
                                <ViasExplicacaoMargemDeErro/>
                            </h4>
                        </vias-col>
                    </vias-row>
                    <vias-row>
                        <vias-col class="p-0 mt-2" cols="12">
                            <h4>
                                <strong class="text-bold">Porcentagem:</strong>
                                {{ relatorio.ambos.porcentagem }}
                            </h4>
                            <small class="text-primary">
                                Essa porcentagem é o cálculo feito com o total
                                de veículos que passaram em ambos os
                                equipamentos em relação ao total de veículos que
                                passaram na origem.
                            </small>
                        </vias-col>
                    </vias-row>
                </div>
            </vias-col>
        </vias-row>
        <vias-row class="mt-4">
            <vias-col>
                <vias-table
                    :colunas="colunasPlacas"
                    :linhas="relatorio.ambos.placas"
                ></vias-table>
            </vias-col>
        </vias-row>
    </vias-card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "@/storeTs";
import FormularioRelatorioDeOrigemEDestino from "./components/FormularioRelatorioDeOrigemEDestino.vue";
import {
    BUSCAR_RELATORIO_DE_DADOS_APURADOS,
    EMITIR_PDF_RELATORIO_DE_DADOS_APURADOS,
} from "@/storeTs/relatorios/type-actions";
import { useNotificacao } from "@/hooks/notificacao.hook";
import moment from "moment";
import ViasExplicacaoMargemDeErro from "@/components/shared/explicacoes/ViasExplicacaoMargemDeErro.vue";

export default defineComponent({
    name: "RelatorioDeOrigemEDestino",
    components: {
        FormularioRelatorioDeOrigemEDestino,
        ViasExplicacaoMargemDeErro,
    },
    data: () => ({
        ultimaBusca: null as any,
        relatorio: null as any,
        colunasPlacas: [
            {
                titulo: "#",
                referencia: "numeroDaLinha",
            },
            {
                titulo: "Placa",
                referencia: "placa",
            },
            {
                titulo: "Data e horário na origem",
                referencia: "dataHoraOrigem",
            },
            {
                titulo: "Data e horário no destino",
                referencia: "dataHoraDestino",
            },
        ],
        mostrarPopupExplicacaoMargemDeErro: false,
    }),
    methods: {
        buscarRelatorio(formulario: any) {
            this.ultimaBusca = formulario;
            this.notificarCarregamento(
                "Buscando relatório, aguarde um momento por favor...",
            );
            this.store
                .dispatch(BUSCAR_RELATORIO_DE_DADOS_APURADOS, {
                    origem_id: formulario.origem.id,
                    destino_id: formulario.destino.id,
                    data_inicial: formulario.data_inicial,
                    data_final: formulario.data_final,
                })
                .then((res) => {
                    this.relatorio = {
                        origem: {
                            passagens:
                                res.data.origem.passagens.toLocaleString(
                                    "pt-br",
                                ),
                            placasLidas:
                                res.data.origem.placas_lidas.toLocaleString(
                                    "pt-br",
                                ),
                            porcentagemPlacasLidas:
                                res.data.origem.porcentagem.toFixed(2) + "%",
                            margemDeErro:
                                res.data.origem.margem_de_erro.toFixed(2) + "%",
                            equipamento: formulario.origem,
                        },
                        destino: {
                            passagens:
                                res.data.destino.passagens.toLocaleString(
                                    "pt-br",
                                ),
                            placasLidas:
                                res.data.destino.placas_lidas.toLocaleString(
                                    "pt-br",
                                ),
                            porcentagemPlacasLidas:
                                res.data.destino.porcentagem.toFixed(2) + "%",
                            margemDeErro:
                                res.data.destino.margem_de_erro.toFixed(2) +
                                "%",
                            equipamento: formulario.destino,
                        },
                        ambos: {
                            ...res.data.ambos,
                            totalDePassagens:
                                res.data.ambos.total_de_passagens.toLocaleString(
                                    "pt-br",
                                ),
                            margemDeErro:
                                res.data.ambos.margem_de_erro.toFixed(2) + "%",
                            porcentagem:
                                res.data.ambos.porcentagem.toFixed(2) + "%",
                            placas: res.data.ambos.placas.map(
                                (passagem, i) => ({
                                    numeroDaLinha: i + 1,
                                    placa: passagem.placa,
                                    dataHoraOrigem: moment(
                                        passagem.data_hora_origem,
                                    )
                                        .add("hours", "3")
                                        .format("DD/MM/YYYY HH:mm:ss"),
                                    dataHoraDestino: moment(
                                        passagem.data_hora_destino,
                                    )
                                        .add("hours", "3")
                                        .format("DD/MM/YYYY HH:mm:ss"),
                                }),
                            ),
                        },
                    };
                    this.fecharPopup();
                    setTimeout(() => {
                        const posicaoY = this.cardRelatorio?.$el.offsetTop;
                        document.querySelector("main")?.scroll({
                            left: 0,
                            top: posicaoY,
                            behavior: "smooth",
                        });
                    });
                });
        },
        emitirPdf() {
            this.notificarCarregamento(
                "Emitindo PDF, aguarde um momento por favor...",
            );
            this.store
                .dispatch(EMITIR_PDF_RELATORIO_DE_DADOS_APURADOS, {
                    origem_id: this.ultimaBusca.origem.id,
                    destino_id: this.ultimaBusca.destino.id,
                    data_inicial: this.ultimaBusca.data_inicial,
                    data_final: this.ultimaBusca.data_final,
                })
                .then((res) => {
                    this.notificarComTexto(
                        "Sucesso!",
                        res.data.mensagem,
                        "success",
                    );
                });
        },
        mostrarPopupMargemDeErro() {
            this.mostrarPopupExplicacaoMargemDeErro = true;
        },
    },
    setup() {
        const store = useStore();
        const { notificarCarregamento, fecharPopup, notificarComTexto } =
            useNotificacao();
        const cardRelatorio = ref<any>();

        return {
            store,
            notificarCarregamento,
            fecharPopup,
            cardRelatorio,
            notificarComTexto,
        };
    },
});
</script>
