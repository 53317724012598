<template>
    <div
        class="mapa-aplicacao"
        :class="{ 'mapa-100': hasTotal }"
        ref="hereMap"
    ></div>
</template>

<script>
import { infoEquipamento, criarBox } from "@/services/map.service";
import moment from "moment";
import { mapState } from "vuex";

export default {
    name: "vias-map",
    props: {
        equipments: {
            type: Array,
            default: () => [],
        },
        routes: {
            type: Array,
            default: () => [],
        },
        total: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["equipmentClick", "routeClick"],
    computed: {
        ...mapState({
            contrato: (state) => state.contrato_selecionado,
            menu_open: (state) => state.menu_open,
        }),
        hasTotal() {
            return this.total;
        },
    },
    data: () => ({
        platform: null,
        router: null,
        map: null,
        apikey: "oScfuXmMRO5pAR92al55nKZaNowqIcgUeglmWAHO0ag",
        markers: [],
        polylines: [],
        info_atual: null,
    }),
    mounted() {
        this.platform = new window.H.service.Platform({ apikey: this.apikey });
        this.router = this.platform.getRoutingService(null, 8);
        this.initializeHereMap();
    },
    watch: {
        menu_open() {
            let contador = 0;
            const runner = setInterval(() => {
                this.map.getViewPort().resize();

                if (contador == 300) clearInterval(runner);
                else contador++;
            }, 10);
        },
        equipments() {
            this.setEquipments();
        },
        routes() {
            this.setRoutes();
        },
    },
    methods: {
        initializeHereMap() {
            const mapContainer = this.$refs.hereMap;
            const H = window.H;

            let mapTypes = this.platform.createDefaultLayers();

            this.map = new H.Map(mapContainer, mapTypes.raster.normal.map, {
                zoom: 10,
                center: {
                    lat: this.contrato.latitude,
                    lng: this.contrato.longitude,
                },
                engineType: H.map.render.RenderEngine.EngineType.P2D,
            });

            addEventListener("resize", () => this.map.getViewPort().resize());

            new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

            H.ui.UI.createDefault(this.map, mapTypes);

            this.setEquipments();

            this.setRoutes();
        },
        setEquipments() {
            for (let i = 0; i < this.equipments.length; i++) {
                let equipment = this.equipments[i];

                let marker = this.markers.find(
                    (m) => m.equipment.id == equipment.id,
                );

                if (marker) {
                    let differences = this.$json.diff(
                        marker.equipment,
                        equipment,
                    );

                    if (this.hasDiference(differences)) {
                        marker.equipment = Object.assign({}, equipment);
                        marker.marker.setIcon(
                            this.generateIcon(equipment).icon,
                        );
                    }

                    if (differences.result.texto_box !== undefined) {
                        marker.equipment = Object.assign({}, equipment);
                        marker.marker.addEventListener("pointermove", (e) =>
                            this.infoEquipment(e, equipment),
                        );
                    }
                } else {
                    this.addMarker(equipment);
                }
            }

            let marcadoresMantidos = [];

            for (let i = 0; i < this.markers.length; i++) {
                const marker = this.markers[i];

                const equipment = this.equipments.find(
                    (e) => e.id == marker.equipment.id,
                );

                if (!equipment) {
                    this.map.removeObject(marker.marker);
                } else {
                    marcadoresMantidos.push(marker);
                }
            }

            this.markers = marcadoresMantidos;
        },
        hasDiference(differences) {
            return (
                differences.result.status !== undefined ||
                differences.result.ultima_passagem !== undefined ||
                differences.result.selecionado !== undefined
            );
        },
        addMarker(equipment) {
            this.markers.push({
                equipment: Object.assign({}, equipment),
                marker: this.generateMarker(equipment),
            });
        },
        generateMarker(equipment) {
            const marker = new window.H.map.Marker(
                equipment.posicao,
                this.generateIcon(equipment),
            );
            marker.addEventListener("tap", () =>
                this.$emit("equipmentClick", equipment),
            );
            marker.addEventListener("pointermove", (e) =>
                this.infoEquipment(e, equipment),
            );
            marker.addEventListener("pointerleave", () => {
                if (this.info_atual) {
                    document.querySelector("body").removeChild(this.info_atual);
                    this.info_atual = null;
                }
            });
            this.map.addObject(marker);
            return marker;
        },
        generateIcon(equipment) {
            let initials =
                    this.contrato.cidade.toLowerCase() == "fortaleza"
                        ? "FV"
                        : equipment.sitio.slice(0, 2),
                equipmentStatus = this.verifyEquipmentStatus(equipment),
                equipmentSelected = equipment.selecionado;
            let imagemIcone = null;

            if (!isNaN(+initials)) {
                initials = "ES";
            }

            if (equipmentSelected) {
                if (this.$marker.ponteiros_selecionados[initials]) {
                    imagemIcone =
                        this.$marker.ponteiros_selecionados[initials][
                            equipmentStatus
                        ];
                } else {
                    imagemIcone =
                        this.$marker.ponteiros_selecionados["DF"][
                            equipmentStatus
                        ];
                }
            } else {
                if (this.$marker.ponteiros[initials]) {
                    imagemIcone =
                        this.$marker.ponteiros[initials][equipmentStatus];
                } else {
                    imagemIcone = this.$marker.ponteiros["DF"][equipmentStatus];
                }
            }

            return {
                icon: new window.H.map.Icon(imagemIcone),
            };
        },
        verifyEquipmentStatus(equipment) {
            if (equipment.ultima_passagem) {
                if (equipment.status) {
                    let now = moment();
                    let ultimaPassagem = moment(equipment.ultima_passagem);
                    let diff = now.diff(ultimaPassagem, "minutes");

                    if (diff > 15) return "sem_deteccao_recente";
                    else return "online";
                } else {
                    return "offline";
                }
            } else {
                return "nunca_usado";
            }
        },
        infoEquipment(event, equipment) {
            const body = document.querySelector("body");

            if (this.info_atual) body.removeChild(this.info_atual);

            this.info_atual = infoEquipamento(event, equipment);
            body.appendChild(this.info_atual);
        },
        setRoutes() {
            if (this.routes && this.routes.length > 0) {
                for (let i = 0; i < this.routes.length; i++) {
                    const route = this.routes[i];

                    if (!route) {
                        continue;
                    }

                    this.polylines = this.polylines.filter((poly) => {
                        if (poly.route.id == route.id) {
                            this.removePolyline(poly);
                            return false;
                        }
                        return true;
                    });

                    if (route.inicio && route.fim)
                        this.generateRoute(this.routes[i]).then((polyline) => {
                            this.polylines.push(polyline);
                        });
                }

                this.polylines = this.polylines.filter((poly) => {
                    if (
                        !this.routes.find((route) => route.id == poly.route.id)
                    ) {
                        this.removePolyline(poly);
                        return false;
                    }
                    return true;
                });
            } else {
                this.polylines = this.polylines.filter((poly) => {
                    this.removePolyline(poly);
                    return false;
                });
            }
        },
        generateRoute(route) {
            return new Promise((resolve) => {
                const routingParams = {
                    routingMode: "fast",
                    transportMode: "car",
                    origin: `${route.inicio.lat},${route.inicio.lng}`,
                    destination: `${route.fim.lat},${route.fim.lng}`,
                    return: "polyline",
                };

                this.router.calculateRoute(
                    routingParams,
                    (result) => {
                        this.generatePolylines(result, route).then(
                            (polyline) => {
                                resolve({
                                    route,
                                    polyline,
                                });
                            },
                        );
                    },
                    () => {
                        resolve(this.generateRoute(route));
                    },
                );
            });
        },
        generatePolylines(result, route) {
            return new Promise((resolve) => {
                const H = window.H;
                if (result.routes.length) {
                    result.routes[0].sections.forEach((section) => {
                        let linestring = H.geo.LineString.fromFlexiblePolyline(
                            section.polyline,
                        );

                        let borderLine = new H.map.Polyline(linestring, {
                            style: {
                                strokeColor: this.$variaveis.cores.dark,
                                lineWidth: 10,
                            },
                        });

                        let routeLine = new H.map.Polyline(linestring, {
                            style: {
                                strokeColor:
                                    this.$variaveis.cores[
                                        route.cor || "primary"
                                    ],
                                lineWidth: 8,
                            },
                        });

                        routeLine.addEventListener("tap", () =>
                            this.$emit("routeClick", route),
                        );
                        if (route.info) {
                            routeLine.addEventListener("pointermove", (e) =>
                                this.infoRota(e, route),
                            );
                            routeLine.addEventListener("pointerleave", () => {
                                if (this.info_atual) {
                                    document
                                        .querySelector("body")
                                        .removeChild(this.info_atual);
                                    this.info_atual = null;
                                }
                            });
                        }

                        this.map.addObjects([borderLine, routeLine]);

                        resolve([borderLine, routeLine]);
                    });
                }
            });
        },
        infoRota(event, route) {
            const body = document.querySelector("body");

            if (this.info_atual) body.removeChild(this.info_atual);

            this.info_atual = criarBox(event);
            this.info_atual.innerHTML = route.info;
            body.appendChild(this.info_atual);
        },
        removePolyline(polyline) {
            this.map.removeObjects(polyline.polyline);
        },
    },
};
</script>

<style></style>
