import axios from "../plugins/axios.plugin";

export const enviarCredenciais = (credenciais) => {
    const fd = new FormData();
    fd.append("username", credenciais.usuario);
    fd.append("password", credenciais.senha);

    return axios.post("/vias/v1/autenticacao/login", fd, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
};

export const adicionarUsuario = (dadosUsuario) =>
    axios.post("/vias/v1/usuarios", dadosUsuario);

export const ativarUsuario = (idUsuario) =>
    axios.put(`/vias/v1/usuarios/${idUsuario}/status`);
