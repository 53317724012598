<script setup>
import { store } from "@/storeTs";
import { ref, computed, onBeforeUnmount } from "vue";
import {
    BUSCAR_ROTAS_ORIGEM_E_DESTINO,
    BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO,
    BUSCAR_RESULTADOS_ORIGEM_E_DESTINO,
} from "@/store/tipo-acoes";
import moment from "moment";
import { useCores } from "@/hooks/cores.hook.ts";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasInputDateAndTime from "@/components/shared/ViasInputDateAndTime.vue";
import ViasProgressBar from "@/components/shared/ViasProgressBar.vue";

const equipamentos = ref([]);

const rotasMapa = ref([]);

const cores = useCores().listaDeCores();

const buscaAutomatica = ref(true);

const progressao = ref(100);

const tempoParaBuscaAutomatica = 30;

const decrescimoDeProgressao = 100 / tempoParaBuscaAutomatica;

let buscandoResultado = false;

const varianteAtualizacaoAutomatica = computed(() =>
    buscaAutomatica.value ? "primary" : "danger",
);

const ultimoMinuto = ref("0000-00-00T00:00:00");

const tempoSelecionado = ref("0000-00-00T00:00:00");

const ultimoResultadoApresentado = ref("00/00/0000 00:00:00");

const runner = ref(null);

const secoesAdicionaisMenuLateralDoMapa = ref([
    {
        icone: "fas fa-book",
        alvo: "formulario",
        nome: "Formulário de busca de origem e destino",
    },
    {
        icone: "fas fa-route",
        alvo: "legenda-rotas",
        nome: "Legenda das rotas",
    },
]);

const isAtualizacaoAutomaticaEmTexto = computed(() =>
    buscaAutomatica.value ? "ligada" : "desligada",
);

store
    .dispatch(BUSCAR_ROTAS_ORIGEM_E_DESTINO)
    .then((res) => res.data)
    .then((rotas) => {
        for (const rota of rotas) {
            adicionarEquipamento(rota.primeiro_equipamento.equipamento);
            adicionarEquipamento(rota.segundo_equipamento.equipamento);

            const rotaMapa = {
                id: rota.id,
                nome: rota.nome,
                inicio: {
                    lat: rota.primeiro_equipamento.equipamento.posicao.latitude,
                    lng: rota.primeiro_equipamento.equipamento.posicao
                        .longitude,
                },
                fim: {
                    lat: rota.segundo_equipamento.equipamento.posicao.latitude,
                    lng: rota.segundo_equipamento.equipamento.posicao.longitude,
                },
                cor: "background",
                tempo_entre_equipamentos: rota.tempo_entre_equipamentos,
                passagens_na_origem: null,
                passagens_no_destino: null,
                porcentagem: null,
                primeiro_equipamento: rota.primeiro_equipamento,
                segundo_equipamento: rota.segundo_equipamento,
                box: `
          <h3 class="text-background">${rota.nome}</h3>
          <h4 class="text-background">Origem: ${rota.primeiro_equipamento.equipamento.sitio} - ${rota.primeiro_equipamento.equipamento.posicao.endereco}</h4>
          <h4 class="text-background">Destino: ${rota.segundo_equipamento.equipamento.sitio} - ${rota.segundo_equipamento.equipamento.posicao.endereco}</h4>
          <hr>
          <h4 class="text-background">Fluxo na origem: -- de fluxo</h4>
          <h4 class="text-background">Fluxo no destino: -- de fluxo</h4>
          <h4 class="text-background">Porcentagem: --%</h4>
        `,
            };

            rotasMapa.value = [...rotasMapa.value, rotaMapa];
        }
    });

store
    .dispatch(BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO)
    .then((res) => res.data.data_hora)
    .then((data_hora) => {
        ultimoMinuto.value = data_hora;
        tempoSelecionado.value = data_hora;
        buscarResultados(true);
    });

const adicionarEquipamento = (equipamento) => {
    if (!equipamentos.value.find((eq) => eq.id == equipamento.id))
        equipamentos.value = [...equipamentos.value, equipamento];
};

const alterarAtualizacaoAutomatica = () => {
    buscaAutomatica.value = !buscaAutomatica.value;

    if (buscaAutomatica.value) {
        iniciarRunner();
        progressao.value = 100;
    } else {
        clearInterval(runner.value);
        runner.value = null;
    }
};

const iniciarRunner = () => {
    if (runner.value) clearInterval(runner.value);

    runner.value = setInterval(() => {
        if (progressao.value <= 0 && !buscandoResultado) buscarResultados();
        else if (progressao.value > 0)
            progressao.value -= decrescimoDeProgressao;
    }, 1000);
};

const buscarResultados = (primeiraBusca = false) => {
    buscandoResultado = true;
    store
        .dispatch(
            BUSCAR_RESULTADOS_ORIGEM_E_DESTINO,
            buscaAutomatica.value ? null : tempoSelecionado.value,
        )
        .then((res) => res.data)
        .then((resultados) => {
            ultimoMinuto.value = resultados.reduce(
                (tempo, resultado) =>
                    moment.max(moment(tempo), moment(resultado.data_hora)),
                ultimoMinuto.value,
            );
            aplicarResultados(resultados);

            if (primeiraBusca) iniciarRunner();

            if (buscaAutomatica.value)
                tempoSelecionado.value = ultimoMinuto.value;

            progressao.value = 100;
            buscandoResultado = false;
            ultimoResultadoApresentado.value = moment(
                tempoSelecionado.value,
            ).format("DD/MM/YYYY HH:mm:ss");
        });
};

const aplicarResultados = (resultados) => {
    rotasMapa.value = rotasMapa.value.map((rota) => {
        const resultado = resultados.find((res) => res.rota_id == rota.id);
        if (resultado) return aplicarResultadoNaRota(resultado, rota);
        else rota.cor = cores.background;
        return rota;
    });
};

const aplicarResultadoNaRota = (resultado, rota) => {
    if (resultado.porcentagem == null) rota.cor = cores.background;
    else if (resultado.porcentagem < 25) rota.cor = cores.success;
    else if (resultado.porcentagem < 50) rota.cor = cores.primary;
    else if (resultado.porcentagem < 75) rota.cor = cores.warning;
    else rota.cor = cores.danger;

    rota.passagens_na_origem = resultado.passagens_na_origem;
    rota.passagens_no_destino = resultado.passagens_no_destino;
    rota.porcentagem = resultado.porcentagem;
    rota.box = `
    <h3 class="text-background">${rota.nome}</h3>
    <h4 class="text-background">Origem: ${rota.primeiro_equipamento.equipamento.sitio} - ${rota.primeiro_equipamento.equipamento.posicao.endereco}</h4>
    <h4 class="text-background">Destino: ${rota.segundo_equipamento.equipamento.sitio} - ${rota.segundo_equipamento.equipamento.posicao.endereco}</h4>
    <hr>
    <h4 class="text-background">Fluxo na origem: ${resultado.passagens_na_origem || "--"} de fluxo</h4>
    <h4 class="text-background">Fluxo no destino: ${resultado.passagens_no_destino || "--"} de fluxo</h4>
    <h4 class="text-background">Porcentagem: ${resultado.porcentagem || "--"}%</h4>
  `;

    return rota;
};

onBeforeUnmount(() => {
    if (runner.value) clearInterval(runner.value);
});
</script>

<template>
    <ViasMapaGeral
        :equipamentos-por-propriedade="equipamentos"
        :habilitar-filtro-de-equipamentos="false"
        :habilitar-todos-os-equipamentos="false"
        :rotas="rotasMapa"
        :secoes-adicionais-menu-lateral="secoesAdicionaisMenuLateralDoMapa"
    >
        <template #formulario>
            <ViasButton
                :variant="varianteAtualizacaoAutomatica"
                @click="alterarAtualizacaoAutomatica()"
                size="sm"
                block
            >
                Atualização automática {{ isAtualizacaoAutomaticaEmTexto }}
            </ViasButton>
            <ViasProgressBar
                v-if="buscaAutomatica"
                color="primary"
                class="mt-2"
                :value="progressao"
            />
            <ViasInputDateAndTime
                label="Data da última busca feita"
                class="mt-4"
                @change="buscarResultados()"
                :limit-max="ultimoMinuto"
                :disabled="buscaAutomatica"
                v-model="tempoSelecionado"
            />
            <h4 class="mt-4 text-center">
                Data do último resultado apresentado <br />
                {{ ultimoResultadoApresentado }}
            </h4>
        </template>
        <template #legenda-rotas>
            <div class="d-flex flex-column w-100">
                <h3 class="mb-2">Cores das rotas</h3>
                <ul class="list">
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--background"></div>
                        <h4>Não foi possível calcular</h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--success"></div>
                        <h4>De 0% à 25% dos veículos fazem o trajeto</h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--primary"></div>
                        <h4>De 25% à 50% dos veículos fazem o trajeto</h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--warning"></div>
                        <h4>De 50% à 75% dos veículos fazem o trajeto</h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--danger"></div>
                        <h4>De 75% à 100% dos veículos fazem o trajeto</h4>
                    </li>
                </ul>
            </div>
        </template>
    </ViasMapaGeral>
</template>
