<template>
    <ViasMapaGeral :habilitarTodosOsEquipamentos="false" />
</template>

<script lang="ts" setup>
import { IArea } from "@/interfaces/mapa/areas/IArea";
import { useStore } from "@/storeTs";
import { BUSCAR_AREAS_CADASTRADAS } from "@/storeTs/configuracao/areas/type-actions";
import { onMounted, ref } from "vue";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";

const store = useStore();

const areasCadastradas = ref<IArea[]>([]);

function buscarAreas() {
    store.dispatch(BUSCAR_AREAS_CADASTRADAS).then((res) => {
        areasCadastradas.value = res.data;
    });
}

onMounted(() => {
    buscarAreas();
});
</script>
