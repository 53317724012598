<template>
    <form @submit.prevent="adicionarRota()" class="mt-4" novalidate>
        <ViasRow>
            <ViasCol class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    :rotas="rotas"
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                    @clickEquipamento="alternarSelecaoDeEquipamento($event)"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow class="my-4 max-h-300px overflow-y-auto">
            <ViasCol
                v-for="equipamento in equipamentos"
                :key="equipamento.id"
                col="12"
                sm="6"
                md="4"
                lg="3"
            >
                <div
                    class="border border-radius-1 p-4"
                    :class="{
                        'bg-success': isEquipamentoJaSelecionado(equipamento),
                    }"
                >
                    <h3
                        :class="{
                            'text-dark':
                                isEquipamentoJaSelecionado(equipamento),
                        }"
                    >
                        {{ equipamento.sitio }}
                    </h3>
                    <div class="mt-3">
                        <p class="text-muted">
                            {{ equipamento.posicao.endereco }}
                        </p>
                    </div>
                    <div class="mt-3">
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            class="mr-2"
                            @click="adicionarEquipamentoComoOrigem(equipamento)"
                            size="sm"
                        >
                            Origem
                        </ViasButton>
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            @click="
                                adicionarEquipamentoComoDestino(equipamento)
                            "
                            size="sm"
                        >
                            Destino
                        </ViasButton>
                    </div>
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow
            v-for="(novaRota, i) in novasRotas"
            :key="novaRota.origem.id + novaRota.destino.id"
            class="border-top border-bottom pt-4 pb-4"
        >
            <ViasCol>
                <h3 class="mb-4">
                    Rota do equipamento "{{ novaRota.origem.sitio }}", cujo
                    endereço é {{ novaRota.origem.posicao.endereco }}, ao
                    equipamento "{{ novaRota.destino.sitio }}", com o endereço
                    {{ novaRota.destino.posicao.endereco }}
                </h3>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasInput
                            class="mt-4"
                            type="text"
                            :id="
                                'rota-' +
                                novaRota.origem.sitio +
                                '-' +
                                novaRota.destino.sitio
                            "
                            label="Nome"
                            v-model="novaRota.nome"
                            :valid="isNovasRotasIsValidas[i]"
                            :detail="getNovasRotasMessages[i]"
                            required
                        ></ViasInput>
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasInput
                            type="number"
                            label="Tempo de percurso esperado (em segundos)"
                            v-model="novaRota.tempoDePercurso"
                            required
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasCheckbox
                            v-model="novaRota.publica"
                            after-text="Pública"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow v-if="!novaRota.publica">
                    <ViasCol
                        v-for="usuario in usuarios"
                        :key="usuario.id"
                        cols="12"
                        md="4"
                        class="p-0"
                    >
                        <ViasCheckbox
                            v-model="novaRota.ids_usuarios_com_acesso"
                            :value="usuario.id"
                            :after-text="usuario.nome"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
                <ViasRow>
                    <ViasCol class="p-0" cols="12" md="6">
                        <h3 class="pl-3 pr-3">Origem</h3>
                        <h4 class="pl-3 pr-3 mb-3">
                            {{ novaRota.origem.sitio }} -
                            {{ novaRota.origem.posicao.endereco }}
                        </h4>
                        <ViasCheckbox
                            v-for="faixa in novaRota.origem.afericoes"
                            v-model="novaRota.ids_faixas_origem"
                            :key="faixa.id"
                            :value="faixa.id"
                            :after-text="'Faixa ' + faixa.faixa"
                            switch
                        />
                    </ViasCol>
                    <ViasCol class="p-0" cols="12" md="6">
                        <h3 class="pl-3 pr-3">Destino</h3>
                        <h4 class="pl-3 pr-3 mb-3">
                            {{ novaRota.destino.sitio }} -
                            {{ novaRota.destino.posicao.endereco }}
                        </h4>
                        <ViasCheckbox
                            v-for="faixa in novaRota.destino.afericoes"
                            v-model="novaRota.ids_faixas_destino"
                            :key="faixa.id"
                            :value="faixa.id"
                            :after-text="'Faixa ' + faixa.faixa"
                            switch
                        />
                    </ViasCol>
                </ViasRow>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isOrigemValida === false">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getOrigemMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="isDestinosValida === false">
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getDestinosMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="validado" class="mt-4">
            <ViasCol>
                <ViasTable
                    :colunas="colunasValidacao"
                    :linhas="validacoes"
                    no-filter
                ></ViasTable>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4">
            <ViasCol>
                <ViasButton variant="primary" class="mr-4"
                    >Adicionar</ViasButton
                >
                <ViasButton
                    variant="outline-primary"
                    type="button"
                    @click="validarRota()"
                >
                    Validar
                </ViasButton>
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts">
import { useNotificacao } from "@/hooks/notificacao.hook";
import { IRotaMapa } from "@/interfaces/IRotaMapa";
import { useStore } from "@/storeTs";
import { defineComponent } from "vue";
import { INovaRotaOrigemEDestinoApresentacao } from "../interfaces/INovaRotaOrigemEDestinoApresentacao";
import { INovaRotaOrigemEDestino } from "../interfaces/INovaRotaOrigemEDestino";
import { IValidacaoOrigemEDestino } from "../interfaces/IValidacaoOrigemEDestino";
import { BUSCAR_INFORMACOES_DA_ROTA } from "@/storeTs/mapa/type-actions";
import {
    CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO,
    VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO,
} from "@/storeTs/origem-e-destino/configuracao/type-actions";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { BUSCAR_USUARIOS } from "@/storeTs/configuracao/usuarios/type-actions";
import { BUSCAR_EQUIPAMENTO_POR_ID } from "@/storeTs/equipamentos/type-actions";
import { IEquipamentoCompleto } from "@/interfaces/equipamento/IEquipamentoCompleto";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { BUSCAR_EQUIPAMENTOS } from "@/store/tipo-acoes";
import { Usuario } from "@/domains/Usuarios/models/Usuario";

export default defineComponent({
    name: "FormularioNovaRotaOrigemEDestino",
    emits: ["adicionarRota"],
    components: {
        ViasMapaGeral,
    },
    data: () => ({
        validacoes: [] as IValidacaoOrigemEDestino[],
        colunasValidacao: [
            {
                titulo: "Nome da rota",
                referencia: "rota",
            },
            {
                titulo: "Fluxo na origem",
                referencia: "passagensNaOrigem",
            },
            {
                titulo: "Fluxo no destino",
                referencia: "passagensNoDestino",
            },
            {
                titulo: "Porcentagem de fluxo em ambos",
                referencia: "porcentagemDePassagemEmAmbos",
            },
        ] as IColunaTabela[],
        validado: false,
        origem: null as null | IEquipamentoCompleto,
        destinos: [] as IEquipamentoCompleto[],
        equipamentos: [] as IEquipamento[],
        idsEquipamentosSelecionados: [] as number[],
        rotas: [] as IRotaMapa[],
        novasRotas: [] as INovaRotaOrigemEDestinoApresentacao[],
        usuarios: [] as Usuario[],
    }),
    computed: {
        isNovasRotasIsValidas(): boolean[] {
            if (this.v$.novasRotas.$dirty) {
                if (this.v$.novasRotas.$errors.length > 0) {
                    return this.v$.novasRotas.$errors[0]?.$message.map(
                        (message) => message.length === 0,
                    );
                }
                return new Array(this.novasRotas.length).fill(
                    !this.v$.novasRotas.$invalid,
                );
            }
            return [];
        },
        getNovasRotasMessages(): string[] {
            if (this.v$.novasRotas.$dirty) {
                if (this.v$.novasRotas.$errors.length > 0) {
                    return this.v$.novasRotas.$errors[0]?.$message.map(
                        (message) => message[0],
                    );
                }
                return new Array(this.novasRotas.length).fill("");
            }
            return [];
        },
        isOrigemValida(): boolean | null {
            if (this.v$.origem.$dirty) {
                return !this.v$.origem.$invalid;
            }
            return null;
        },
        getOrigemMessage(): string | null {
            if (this.v$.origem.$dirty) {
                return this.v$.origem.$errors[0]?.$message;
            }
            return "";
        },
        isDestinosValida(): boolean | null {
            if (this.v$.destinos.$dirty) {
                return !this.v$.destinos.$invalid;
            }
            return null;
        },
        getDestinosMessage(): string {
            if (this.v$.destinos.$dirty) {
                return this.v$.destinos.$errors[0]?.$message;
            }
            return "";
        },
    },
    methods: {
        buscarEquipamentos() {
            this.store.dispatch(BUSCAR_EQUIPAMENTOS).then((res) => {
                this.equipamentos = res.data;
                this.atualizarSelecaoDeEquipamentos();
            });
        },
        buscarUsuarios() {
            this.store.dispatch(BUSCAR_USUARIOS).then((res) => {
                this.usuarios = res.data;
            });
        },
        isEquipamentoJaSelecionado(equipamento: IEquipamento) {
            if (this.origem?.id === equipamento.id) {
                return true;
            }

            if (this.destinos.find((eq) => eq.id === equipamento.id)) {
                return true;
            }

            return false;
        },
        adicionarEquipamentoComoOrigem(equipamento: IEquipamento) {
            new Promise((resolve) => {
                if (this.origem?.id === equipamento.id) {
                    this.origem = null;
                    resolve(true);
                } else if (
                    this.destinos.find((eq) => eq.id === equipamento.id)
                ) {
                    this.questionarAcao(
                        `O equipamento "${equipamento.sitio}" está selecionado como destino, deseja alterar para origem?`,
                    ).then((isConfirmado) => {
                        if (isConfirmado) {
                            this.destinos = this.destinos.filter(
                                (eq) => eq.id !== equipamento.id,
                            );
                            this.store
                                .dispatch(
                                    BUSCAR_EQUIPAMENTO_POR_ID,
                                    equipamento.id,
                                )
                                .then((res) => {
                                    this.origem = res.data;
                                    this.novasRotas = this.novasRotas.map(
                                        (rota) => {
                                            rota.ids_faixas_origem = [];
                                            return rota;
                                        },
                                    );
                                    resolve(true);
                                });
                            resolve(true);
                        }
                    });
                } else {
                    this.store
                        .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                        .then((res) => {
                            this.origem = res.data;
                            this.novasRotas = this.novasRotas.map((rota) => {
                                rota.ids_faixas_origem = [];
                                return rota;
                            });
                            resolve(true);
                        });
                }
            }).finally(() => {
                this.atualizarSelecaoDeEquipamentos();
            });
        },
        adicionarEquipamentoComoDestino(equipamento: IEquipamento) {
            new Promise((resolve) => {
                if (this.destinos.find((eq) => eq.id === equipamento.id)) {
                    this.destinos = this.destinos.filter(
                        (eq) => eq.id !== equipamento.id,
                    );
                    resolve(true);
                } else if (this.origem?.id === equipamento.id) {
                    this.questionarAcao(
                        `O equipamento "${equipamento.sitio}" está selecionado como origem, deseja alterar para destino?`,
                    ).then((isConfirmado) => {
                        if (isConfirmado) {
                            this.origem = null;
                            this.store
                                .dispatch(
                                    BUSCAR_EQUIPAMENTO_POR_ID,
                                    equipamento.id,
                                )
                                .then((res) => {
                                    this.destinos.push(res.data);
                                    resolve(true);
                                });
                            resolve(true);
                        }
                    });
                } else {
                    this.store
                        .dispatch(BUSCAR_EQUIPAMENTO_POR_ID, equipamento.id)
                        .then((res) => {
                            this.destinos.push(res.data);
                            resolve(true);
                        });
                }
            }).finally(() => {
                this.atualizarSelecaoDeEquipamentos();
            });
        },
        atualizarSelecaoDeEquipamentos() {
            const novosIdsEquipamentosSelecionados = [] as number[];

            if (this.origem) {
                novosIdsEquipamentosSelecionados.push(this.origem.id);
            }

            for (const destino of this.destinos) {
                novosIdsEquipamentosSelecionados.push(destino.id);
            }

            this.idsEquipamentosSelecionados = novosIdsEquipamentosSelecionados;

            this.atualizarInformacoesSobrePossiveisDestinos();
            this.atualizarRotas();
        },
        atualizarInformacoesSobrePossiveisDestinos() {
            if (this.origem) {
                this.store
                    .dispatch(
                        CHECAR_EQUIPAMENTO_DE_ORIGEM_DE_ORIGEM_E_DESTINO,
                        this.origem.id,
                    )
                    .then((res) => {
                        const entradas = Object.entries(
                            res.data.destinos.porcentagens,
                        );
                        this.equipamentos = this.equipamentos.map((eq) => {
                            if (eq.id === this.origem?.id) {
                                return {
                                    ...eq,
                                    texto_box: `${res.data.origem.quantidade} de fluxo`,
                                };
                            } else {
                                const entrada: any = entradas.find(
                                    (entrada) => entrada[0] == eq.id.toString(),
                                );

                                if (!entrada) {
                                    return eq;
                                }

                                return {
                                    ...eq,
                                    texto_box: `${parseInt(entrada[1].toString()).toFixed(2)}%`,
                                };
                            }
                        });
                    });
                return;
            }
            this.equipamentos = this.equipamentos.map((eq) => ({
                ...eq,
            }));
        },
        atualizarRotas() {
            const novasRotasMapa: IRotaMapa[] = [];
            const novasRotas: INovaRotaOrigemEDestinoApresentacao[] = [];

            if (this.origem) {
                for (const destino of this.destinos) {
                    novasRotasMapa.push({
                        id: this.origem.id + destino.id,
                        inicio: {
                            lat: this.origem.posicao.latitude,
                            lng: this.origem.posicao.longitude,
                        },
                        fim: {
                            lat: destino.posicao.latitude,
                            lng: destino.posicao.longitude,
                        },
                    });

                    const novaRota = this.novasRotas.find(
                        (rota) => rota.destino.id === destino.id,
                    );
                    if (novaRota) {
                        novasRotas.push(novaRota);
                    } else {
                        novasRotas.push({
                            nome: "",
                            origem: this.origem,
                            destino: destino,
                            publica: true,
                            ids_faixas_origem: [],
                            ids_faixas_destino: [],
                            ids_usuarios_com_acesso: [],
                            tempoDePercurso: null,
                        });
                    }
                }
            }

            this.rotas = novasRotasMapa;
            this.novasRotas = novasRotas;
            this.buscarSugestoesDasInformacoesDasRotas();
        },
        buscarSugestoesDasInformacoesDasRotas() {
            const promises: Promise<any>[] = [];
            for (let i = 0; i < this.novasRotas.length; i++) {
                const promise = this.store.dispatch(
                    BUSCAR_INFORMACOES_DA_ROTA,
                    {
                        origem: this.rotas[i].inicio,
                        destino: this.rotas[i].fim,
                    },
                );
                promises.push(promise);
            }
            Promise.all(promises).then((responses) => {
                responses.forEach((response, i) => {
                    this.novasRotas[i].tempoDePercurso =
                        response.tempoDePercurso;
                });
            });
        },
        alternarSelecaoDeEquipamento(equipamento: IEquipamento) {
            if (this.origem?.id === equipamento.id) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" está selecionado como origem, deseja remover a seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.origem = null;
                        this.atualizarSelecaoDeEquipamentos();
                    }
                });
            } else if (this.destinos.find((eq) => eq.id === equipamento.id)) {
                this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" está selecionado como destino, deseja remover a seleção?`,
                ).then((isConfirmado) => {
                    if (isConfirmado) {
                        this.destinos = this.destinos.filter((destino) => {
                            return destino.id !== equipamento.id;
                        });
                        this.atualizarSelecaoDeEquipamentos();
                    }
                });
            } else {
                this.apresentarOpcoesDeEscolha(
                    `Deseja adicionar o equipamento "${equipamento.sitio}" como origem ou como destino?`,
                    "Origem",
                    "Destino",
                ).then((opcaoEscolhida) => {
                    if (opcaoEscolhida === "Origem") {
                        this.adicionarEquipamentoComoOrigem(equipamento);
                        this.atualizarSelecaoDeEquipamentos();
                        return;
                    }

                    if (opcaoEscolhida === "Destino") {
                        this.adicionarEquipamentoComoDestino(equipamento);
                        this.atualizarSelecaoDeEquipamentos();
                        return;
                    }
                });
            }
        },
        async validarRota() {
            const isFormularioValido = await this.v$.$validate();
            if (isFormularioValido) {
                this.notificarCarregamento(
                    "Validando as rotas selecionadas, aguarde um momento por favor...",
                );
                const rotas: INovaRotaOrigemEDestino[] = [];
                for (const rota of this.novasRotas) {
                    rotas.push({
                        nome: rota.nome,
                        tempo_entre_equipamentos: rota.tempoDePercurso || 0,
                        primeiro_equipamento: {
                            equipamento_id: rota.origem.id,
                            faixas: rota.ids_faixas_origem,
                        },
                        segundo_equipamento: {
                            equipamento_id: rota.destino.id,
                            faixas: rota.ids_faixas_destino,
                        },
                        publica: rota.publica,
                        usuarios_ids: rota.publica
                            ? []
                            : rota.ids_usuarios_com_acesso,
                    });
                }
                this.store
                    .dispatch(VALIDAR_ROTAS_DE_ORIGEM_E_DESTINO, rotas)
                    .then((res) => {
                        this.validacoes = Object.keys(res.data).map(
                            (rota): IValidacaoOrigemEDestino => ({
                                rota: rota,
                                passagensNaOrigem:
                                    res.data[rota].origem + " de fluxo",
                                passagensNoDestino:
                                    res.data[rota].destino + " de fluxo",
                                porcentagemDePassagemEmAmbos:
                                    (
                                        (res.data[rota].destino /
                                            res.data[rota].origem) *
                                        100
                                    ).toFixed(2) + "%",
                            }),
                        );
                        this.validado = true;
                        this.fecharPopup();
                    });
            }
        },
        async adicionarRota() {
            const isFormularioValido = await this.v$.$validate();
            if (isFormularioValido) {
                const rotas: INovaRotaOrigemEDestino[] = [];
                for (const rota of this.novasRotas) {
                    rotas.push({
                        nome: rota.nome,
                        tempo_entre_equipamentos: rota.tempoDePercurso || 0,
                        primeiro_equipamento: {
                            equipamento_id: rota.origem.id,
                            faixas: rota.ids_faixas_origem,
                        },
                        segundo_equipamento: {
                            equipamento_id: rota.destino.id,
                            faixas: rota.ids_faixas_destino,
                        },
                        publica: rota.publica,
                        usuarios_ids: rota.publica
                            ? []
                            : rota.ids_usuarios_com_acesso,
                    });
                }
                this.$emit("adicionarRota", rotas);
            }
        },
    },
    created() {
        this.buscarEquipamentos();
        this.buscarUsuarios();
    },
    validations() {
        return {
            origem: {
                required: helpers.withMessage(
                    "É necessário que seja selecionado uma origem para as rotas",
                    required,
                ),
            },
            destinos: {
                required: helpers.withMessage(
                    "É necessário que seja selecionado, ao menos, 1 destino",
                    required,
                ),
                minLength: helpers.withMessage(
                    "É necessário que seja selecionado, ao menos, 1 destino",
                    minLength(1),
                ),
            },
            novasRotas: {
                $each: helpers.forEach({
                    nome: {
                        required: helpers.withMessage(
                            "É necessário que a rota possua um nome",
                            required,
                        ),
                        minLength: helpers.withMessage(
                            "O nome da rota precisa ter, pelo menos, 5 caracteres",
                            minLength(5),
                        ),
                    },
                }),
            },
        };
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();
        const {
            notificar,
            apresentarOpcoesDeEscolha,
            questionarAcao,
            notificarCarregamento,
            fecharPopup,
        } = useNotificacao();

        return {
            store,
            v$,
            notificar,
            apresentarOpcoesDeEscolha,
            questionarAcao,
            notificarCarregamento,
            fecharPopup,
        };
    },
});
</script>
