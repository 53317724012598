<template>
    <ViasGoogleMapsMarcador
        :position="equipamento.posicao"
        :icon="icone"
        @click="clickEquipamento()"
    >
        <template #box>
            <slot name="personalizar-box">
                <strong class="text-bold">{{ equipamento.sitio }}</strong>
                <p class="text-background">{{ equipamento.endereco }}</p>
                <slot name="informacoes-adicionais"></slot>
            </slot>
        </template>
    </ViasGoogleMapsMarcador>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ViasGoogleMapsMarcador from "./ViasGoogleMapsMarcador.vue";
import { useStore } from "@/storeTs";
import moment from "moment";

export default defineComponent({
    name: "ViasGoogleMapsEquipamento",
    emits: ["click"],
    props: {
        equipamento: {
            type: Object,
            required: true,
        },
    },
    components: {
        ViasGoogleMapsMarcador,
    },
    computed: {
        contrato(): any {
            return this.store.state.contrato_selecionado;
        },
        icone(): string {
            const estadoDoEquipamento = this.verificarEstadoDoEquipamento();
            const equipamentoSelecionado = this.equipamento.selecionado;
            let iniciais = "";

            if (this.contrato?.cidade.toLowerCase() === "fortaleza") {
                iniciais = "FV";
            } else {
                iniciais = this.equipamento.sitio.slice(0, 2);
            }

            if (!isNaN(+iniciais)) {
                iniciais = "ES";
            }

            const marcadores = (this as any).$marker;

            if (equipamentoSelecionado) {
                if (marcadores.ponteiros_selecionados[iniciais]) {
                    return marcadores.ponteiros_selecionados[iniciais][
                        estadoDoEquipamento
                    ];
                }
                return marcadores.ponteiros_selecionados["DF"][
                    estadoDoEquipamento
                ];
            }

            if (marcadores.ponteiros[iniciais]) {
                return marcadores.ponteiros[iniciais][estadoDoEquipamento];
            }
            return marcadores.ponteiros["DF"][estadoDoEquipamento];
        },
    },
    methods: {
        verificarEstadoDoEquipamento() {
            if (this.equipamento.ultima_passagem) {
                if (this.equipamento.status) {
                    let agora = moment();
                    let ultimaPassagem = moment(
                        this.equipamento.ultima_passagem,
                    );
                    let diff = agora.diff(ultimaPassagem, "minutes");

                    if (diff <= 15) {
                        return "online";
                    }
                    return "sem_deteccao_recente";
                }
                return "offline";
            }
            return "nunca_usado";
        },
        clickEquipamento() {
            this.$emit("click");
        },
    },
    setup() {
        const store = useStore();

        return {
            store,
        };
    },
});
</script>
