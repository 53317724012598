<template>
    <header :class="[theme, { 'bg-transparent box-shadow-none': isMapa }]">
        <div class="right">
            <button
                :class="theme"
                id="menu-btn"
                type="button"
                @click="toggleMenu"
                data-cy="menu-toggle"
            >
                <font-awesome-icon icon="fas fa-angle-up"></font-awesome-icon>
            </button>
            <div
                v-if="!isMapa"
                class="theme-toggler"
                data-cy="theme-toggle"
                @click="themeChange"
            >
                <div
                    class="light"
                    :class="[theme, { active: theme == 'light-theme' }]"
                >
                    <font-awesome-icon
                        class="icone"
                        icon="fas fa-sun"
                    ></font-awesome-icon>
                </div>
                <div
                    class="dark"
                    :class="[theme, { active: theme == 'dark-theme' }]"
                >
                    <font-awesome-icon
                        class="icone"
                        icon="fas fa-moon"
                    ></font-awesome-icon>
                </div>
            </div>
            <div
                class="profile"
                v-if="usuario && !isMapa"
                @click="alterarSenha"
                data-cy="open-modal-update-password-topbar"
            >
                <div class="info d-flex flex-column">
                    <p>
                        Olá, <b>{{ primeiroNome }}</b>
                    </p>
                    <small class="text-muted">{{ isSuperUsuario }}</small>
                    <small class="primary">Alterar senha</small>
                </div>
                <div class="profile-photo">
                    <div class="inicial-do-usuario">
                        {{ primeiraLetra }}
                    </div>
                </div>
            </div>
        </div>
    </header>

    <vias-modal-alterar-senha
        v-model="mostrar_modal"
    ></vias-modal-alterar-senha>
</template>

<script>
import ViasModalAlterarSenha from "./ViasModalAlterarSenha.vue";
import { mapState } from "vuex";
export default {
    props: ["titulo"],
    components: {
        "vias-modal-alterar-senha": ViasModalAlterarSenha,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
            usuario: (state) => state.usuario,
        }),
        primeiroNome() {
            return this.$store.getters.doneFirstName;
        },
        primeiraLetra() {
            return this.$store.getters.doneFirstLetter;
        },
        isSuperUsuario() {
            return this.$store.getters.doneSuperUsuario;
        },
        isMapa() {
            return this.$router.currentRoute.value.path.endsWith("mapa");
        },
    },
    data: () => ({
        mostrar_modal: false,
    }),
    methods: {
        themeChange() {
            const newTheme =
                this.theme == "dark-theme" ? "light-theme" : "dark-theme";
            this.$store.dispatch("alterarTema", newTheme);
        },
        toggleMenu() {
            this.$store.dispatch("toggleMenu");
        },
        alterarSenha() {
            this.mostrar_modal = true;
        },
    },
};
</script>
