<template>
    <ul class="paginacao" :class="theme">
        <li @click="primeiraPagina" :class="firstPage">
            <font-awesome-icon icon="fas fa-angles-left"></font-awesome-icon>
        </li>
        <li @click="paginaAnterior">
            <font-awesome-icon icon="fas fa-angle-left"></font-awesome-icon>
        </li>

        <li
            @click="pularParaPaginaEspecifica(atual - 4)"
            v-if="atual == quantidade - 1 && quantidade > 4"
        >
            {{ atual - 3 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual - 3)"
            v-if="atual >= quantidade - 2 && quantidade > 3"
        >
            {{ atual - 2 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual - 2)"
            v-if="atual > 1 && quantidade > 2"
        >
            {{ atual - 1 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual - 1)"
            v-if="atual > 0 && quantidade > 1"
        >
            {{ atual }}
        </li>
        <li :class="hasVariant">{{ atual + 1 }}</li>
        <li
            @click="pularParaPaginaEspecifica(atual + 1)"
            v-if="atual < quantidade - 1 && quantidade > 1"
        >
            {{ atual + 2 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual + 2)"
            v-if="atual < quantidade - 2 && quantidade > 2"
        >
            {{ atual + 3 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual + 3)"
            v-if="atual <= 1 && quantidade > 3"
        >
            {{ atual + 4 }}
        </li>
        <li
            @click="pularParaPaginaEspecifica(atual + 4)"
            v-if="atual == 0 && quantidade > 4"
        >
            {{ atual + 5 }}
        </li>

        <li @click="proximaPagina">
            <font-awesome-icon icon="fas fa-angle-right"></font-awesome-icon>
        </li>
        <li @click="ultimaPagina" :class="lastPage">
            <font-awesome-icon icon="fas fa-angles-right"></font-awesome-icon>
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["atual", "quantidade", "variant"],
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        firstPage() {
            return this.atual == 0 ? `${this.variant || "primary"}-active` : "";
        },
        lastPage() {
            return this.atual == this.quantidade - 1
                ? `${this.variant || "primary"}-active`
                : "";
        },
        hasVariant() {
            return `${this.variant || "primary"}-active`;
        },
    },
    methods: {
        primeiraPagina() {
            this.$emit("mudarDePagina", 0);
        },
        paginaAnterior() {
            if (this.atual > 0) this.$emit("mudarDePagina", this.atual - 1);
        },
        proximaPagina() {
            if (this.atual < this.quantidade - 1)
                this.$emit("mudarDePagina", this.atual + 1);
        },
        ultimaPagina() {
            this.$emit(
                "mudarDePagina",
                this.quantidade < 1 ? 0 : this.quantidade - 1,
            );
        },
        pularParaPaginaEspecifica(pagina) {
            this.$emit("mudarDePagina", pagina);
        },
    },
};
</script>

<style></style>
