// @ts-ignore
import "vue-select/dist/vue-select.css";
import "./assets/scss/style.scss";
import "./plugins/font-awesome.plugin";
import { createApp } from "vue";
import AppWrapper from "./AppWrapper.vue";
import router from "./router/index";
import { store as storeTs, key } from "./storeTs";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import maska from "maska";
import UserPlugin from "./plugins/user.plugin";
import NotificationPlugin from "./plugins/notification.plugin";
import ContractPlugin from "./plugins/contract.plugin";
import EquipmentPlugin from "./plugins/equipment.plugin";
import VariablesPlugin from "./plugins/variables.plugin";
import NumbersPlugin from "./plugins/numbers.plugin";
import TravelTimePlugin from "./plugins/travel-time.plugin";
import IntegratedManagementPlugin from "./plugins/integrated-management.plugin";
import AverageSpeedPlugin from "./plugins/average-speed.plugin";
import MarkerPlugin from "./plugins/marker.plugin";
import MapPlugin from "./plugins/map.plugin";
import JsonPlugin from "./plugins/json.plugin";
import moment from "moment";
import TranslatePlugin from "./plugins/translate.plugin";
import PdfPlugin from "./plugins/pdf.plugin";
import ArrayPlugin from "./plugins/array.plugin";
import ViasRow from "./components/shared/ViasRow.vue";
import ViasCol from "./components/shared/ViasCol.vue";
import ViasCard from "./components/shared/ViasCard.vue";
import ViasButton from "./components/shared/ViasButton.vue";
import ViasInput from "./components/shared/ViasInput.vue";
import ViasTable from "./components/shared/ViasTable.vue";
import ViasMap from "./components/shared/ViasMap.vue";
import ViasBlocoRetratil from "./components/shared/ViasBlocoRetratil.vue";
import ViasFiltroDeEquipamentos from "./components/shared/ViasFiltroDeEquipamentos.vue";
import ViasCheckbox from "./components/shared/ViasCheckbox.vue";
import ViasSelect from "./components/shared/ViasSelect.vue";
import ViasInputDateAndTimeRange from "./components/shared/ViasInputDateAndTimeRange.vue";
import ViasModal from "./components/shared/ViasModal.vue";

const app = createApp(AppWrapper);

app.use(router);
app.use(maska);
app.use(UserPlugin);
app.use(NotificationPlugin);
app.use(ContractPlugin);
app.use(EquipmentPlugin);
app.use(VariablesPlugin);
app.use(NumbersPlugin);
app.use(TravelTimePlugin);
app.use(IntegratedManagementPlugin);
app.use(AverageSpeedPlugin);
app.use(MarkerPlugin);
app.use(MapPlugin);
app.use(JsonPlugin);
app.use(TranslatePlugin);
app.use(PdfPlugin);
app.use(ArrayPlugin);
app.use(storeTs, key);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("ViasRow", ViasRow);
app.component("ViasCol", ViasCol);
app.component("ViasCard", ViasCard);
app.component("ViasButton", ViasButton);
app.component("ViasInput", ViasInput);
app.component("ViasTable", ViasTable);
app.component("ViasMap", ViasMap);
app.component("ViasBlocoRetratil", ViasBlocoRetratil);
app.component("ViasFiltroDeEquipamentos", ViasFiltroDeEquipamentos);
app.component("ViasCheckbox", ViasCheckbox);
app.component("ViasSelect", ViasSelect);
app.component("ViasInputDateAndTimeRange", ViasInputDateAndTimeRange);
app.component("ViasModal", ViasModal);

moment.locale("pt-br");

app.mount("#app");
