import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO,
    BUSCAR_RESULTADOS,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const tempoDePercursoMapaModule: Module<State, State> = {
    actions: {
        [BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO]() {
            return axios.get(
                "/vias/v1/tempo-de-percurso/resultados/ultimo-minuto",
            );
        },
        [BUSCAR_RESULTADOS](context, data_hora: string) {
            let url = "/vias/v1/tempo-de-percurso/resultados";

            if (data_hora) {
                url += `?data_hora=${data_hora}`;
            }

            return axios.get(url);
        },
    },
};
