import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_USUARIOS,
    EDITAR_USUARIO,
    ALTERAR_SENHA,
    ALTERAR_SENHA_DO_USUARIO_ATUAL,
    ADICIONAR_NOVO_USUARIO,
    BUSCAR_USUARIO_POR_ID,
    ALTERAR_STATUS_DO_USUARIO,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";
import { Usuario } from "@/domains/Usuarios/models/Usuario";

export const configuracaoUsuariosModule: Module<State, State> = {
    actions: {
        [BUSCAR_USUARIOS]() {
            return axios.get("/vias/v1/usuarios");
        },
        [BUSCAR_USUARIO_POR_ID](context, id: number) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/vias/v1/usuarios")
                    .then((res) => {
                        const usuario = res.data.find(
                            (usuario) => usuario.id == id,
                        );
                        if (usuario) {
                            res.data = usuario;
                            resolve(res);
                        } else {
                            reject("Usuário não encontrado.");
                        }
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        [ADICIONAR_NOVO_USUARIO](context, novoUsuario: Usuario) {
            return axios.post(`/vias/v1/usuarios`, novoUsuario);
        },
        [EDITAR_USUARIO](context, dados: any) {
            return axios.put(`/vias/v1/usuarios/${dados.id}`, dados);
        },
        [ALTERAR_STATUS_DO_USUARIO](context, id: number) {
            return axios.put(`/vias/v1/usuarios/${id}/status`);
        },
        [ALTERAR_SENHA](context, dadosTrocaDeSenha: any) {
            return axios.put(
                `/vias/v1/usuarios/${dadosTrocaDeSenha.id}/senha`,
                dadosTrocaDeSenha,
            );
        },
        [ALTERAR_SENHA_DO_USUARIO_ATUAL]({ rootState }, dados: any) {
            if (rootState.usuario) {
                return axios.put(
                    `/vias/v1/usuarios/${rootState.usuario.context.id}/senha`,
                    dados,
                );
            }
        },
    },
};
