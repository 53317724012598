<template>

<form @submit.prevent="trocarSenha()">
    <ViasRow v-if="isUsuarioLogadoEOMesmoDaTrocaDeSenha">
        <ViasCol>
            <ViasInput
                label="Senha atual"
                type="password"
                v-model="trocaDeSenha.senha_atual"
                id="troca-de-senha-senha-atual"
                required
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Nova senha"
                type="password"
                v-model="trocaDeSenha.nova_senha"
                id="troca-de-senha-nova-senha"
                required
            />
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Confirmar senha"
                type="password"
                v-model="trocaDeSenha.confirmar_senha"
                id="troca-de-senha-confirmar-senha"
                required
            />
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol>
            <ViasButton variant="primary" id="troca-de-senha-botao-salvar">
                Salvar
            </ViasButton>
        </ViasCol>
    </ViasRow>
</form>

</template>

<script lang="ts" setup>
import { useStore } from '@/storeTs';
import { computed, defineProps, ref, withDefaults } from 'vue';
import { TrocaDeSenha } from '../models/TrocaDeSenha';
import notificacaoService from '@/domains/Notificacao/services/notificacaoService';
import usuarioService from '../services/UsuarioService';

interface Props {
    idUsuario: number;
}

const props = withDefaults(defineProps<Props>(), {
    idUsuario: 0
});

const store = useStore();

const trocaDeSenha = ref<TrocaDeSenha>({
    senha_atual: "",
    nova_senha: "",
    confirmar_senha: ""
});

const isUsuarioLogadoEOMesmoDaTrocaDeSenha = computed(() => {
    if (props.idUsuario) {
        return store.state.usuario?.context.id === props.idUsuario;
    }
    return true;
});

async function trocarSenha() {
    const idDoUsuarioASerAlteradoASenha = definirIdASerUtilizadoParaATrocaDeSenha();
    const formularioAdequadoParaATrocaDeSenha = gerarFormularioAdequadoParaATrocaDeSenha();

    notificacaoService.notificarCarregamento(
        "Estamos enviando a nova senha",
        "Aguarde um momento..."
    );

    await usuarioService.alterarSenhaDoUsuario(
        idDoUsuarioASerAlteradoASenha,
        formularioAdequadoParaATrocaDeSenha
    );

    notificacaoService.notificarSucesso("Senha alterada com sucesso!");
    
    limparFormulario();
}

function gerarFormularioAdequadoParaATrocaDeSenha(): TrocaDeSenha {
    if (isUsuarioLogadoEOMesmoDaTrocaDeSenha.value) {
        return trocaDeSenha.value;
    }
    return {
        nova_senha: trocaDeSenha.value.nova_senha,
        confirmar_senha: trocaDeSenha.value.confirmar_senha
    };
}

function definirIdASerUtilizadoParaATrocaDeSenha() {
    if (!store.state.usuario) {
        throw new Error("Você não está logado no sistema!");
    }
    if (isUsuarioLogadoEOMesmoDaTrocaDeSenha.value) {
        return store.state.usuario.context.id;
    } else {
        return props.idUsuario;
    }
}

function limparFormulario() {
    trocaDeSenha.value = {
        senha_atual: "",
        nova_senha: "",
        confirmar_senha: ""
    }
}
</script>