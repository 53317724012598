import axios from "@/plugins/axios.plugin";
import moment from "moment/moment";

export const buscarRelatorioDePassagens = (dadosDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/leitura?data=${moment(dadosDaBusca.data).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfRelatorioDePassagens = (dadosDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/leitura/pdf?data_hora=${moment(dadosDaBusca.data).format("YYYY-MM-DD")}`,
    );
};

export const buscarUltimosCalculos = (quantidade) => {
    return axios.get(
        `/vias/v1/tempo-de-percurso/resultados/ultimos?quantidade=${quantidade}`,
    );
};

export const buscarAuditoriaDeTempoDePercurso = (rota_id) => {
    return axios.get(`/vias/v1/tempo-de-percurso/rotas/${rota_id}/audit-logs`);
};
