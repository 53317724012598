import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    ADICIONAR_ROTA_TEMPO_DE_PERCURSO,
    ATUALIZAR_ROTA_TEMPO_DE_PERCURSO,
    BUSCAR_ROTAS_TEMPO_DE_PERCURSO,
    BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO,
    BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS,
    REMOVER_ROTA_TEMPO_DE_PERCURSO,
    VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const tempoDePercursoConfiguracaoModule: Module<State, State> = {
    actions: {
        [BUSCAR_TEMPO_DE_PERCURSO_E_DISTANCIA_ENTRE_EQUIPAMENTOS](
            context,
            { inicio, fim }: any,
        ) {
            return new Promise((resolve) => {
                const resposta = {
                    tempo_de_percurso: 0,
                    distancia: 0,
                };

                const platform = new (<any>window).H.service.Platform({
                    apikey: "oScfuXmMRO5pAR92al55nKZaNowqIcgUeglmWAHO0ag",
                });

                platform.getRoutingService(null, 8).calculateRoute(
                    {
                        routingMode: "fast",
                        transportMode: "car",
                        origin: `${inicio.lat},${inicio.lng}`,
                        destination: `${fim.lat},${fim.lng}`,
                        return: "summary",
                    },
                    (result) => {
                        if (result.routes.length) {
                            result.routes[0].sections.forEach((section) => {
                                resposta.tempo_de_percurso =
                                    section.summary.duration;
                                resposta.distancia = section.summary.length;
                            });
                        }

                        resolve(resposta);
                    },
                );
            });
        },
        [BUSCAR_ROTAS_TEMPO_DE_PERCURSO]() {
            return axios.get("/vias/v1/tempo-de-percurso/rotas");
        },
        [BUSCAR_ROTA_POR_ID_TEMPO_DE_PERCURSO](context, idRota: number) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/vias/v1/tempo-de-percurso/rotas")
                    .then((res) => {
                        for (const rota of res.data) {
                            if (rota.id === idRota) {
                                res.data = rota;
                                resolve(res);
                            }
                        }
                        reject("Rota não encontrada.");
                    })
                    .catch(() => {
                        reject("Erro na busca da rota.");
                    });
            });
        },
        [ADICIONAR_ROTA_TEMPO_DE_PERCURSO](context, rota: any) {
            return axios.post("/vias/v1/tempo-de-percurso/rotas", rota);
        },
        [REMOVER_ROTA_TEMPO_DE_PERCURSO](context, id: number) {
            return axios.put(
                `/vias/v1/tempo-de-percurso/rotas/${id}/desativar`,
            );
        },
        [ATUALIZAR_ROTA_TEMPO_DE_PERCURSO](context, rota: any) {
            return axios.put(
                `/vias/v1/tempo-de-percurso/rotas/${rota.id}`,
                rota,
            );
        },
        [VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO](context, dados: any) {
            return axios.post(
                "/vias/v1/tempo-de-percurso/rotas/validar",
                dados,
            );
        },
    },
};
