interface IListaCores {
    primary: string;
    danger: string;
    success: string;
    warning: string;
    white: string;
    info_dark: string;
    info_white: string;
    dark: string;
    light: string;
    dark_light: string;
    primary_variant: string;
    dark_variant: string;
    background: string;
}
type Cores = {
    listaDeCores: () => IListaCores;
};

export function useCores(): Cores {
    const listaDeCores = (): IListaCores => ({
        primary: "#7380EC",
        danger: "#EC7380",
        success: "#52BE80",
        warning: "#ECDF73",
        white: "#FFF",
        info_dark: "#7D8DA1",
        info_white: "#DCE1EB",
        dark: "#1D203B",
        light: "rgba(132, 139, 200, 0.18)",
        dark_light: "rgba(#000, 0.18)",
        primary_variant: "#111E88",
        dark_variant: "#677483",
        background: "#ebecf2",
    });

    return {
        listaDeCores,
    };
}
