<template>

<ViasCard>
    <ViasRow class="justify-content-evenly">
        <ViasCol :cols="tamanhoDaColuna" v-for="faixa in possiveisFaixas" :key="faixa">
            <ViasButton
                :variant="isFaixaSelecionada(faixa)"
                @click="selecionarFaixa(faixa)"
                block
            >
                {{ faixa }}
            </ViasButton>
        </ViasCol>
    </ViasRow>
</ViasCard>

</template>

<script lang="ts" setup>
import { defineProps, defineModel, computed } from "vue";

interface Props {
    possiveisFaixas: string[];
}

const props = defineProps<Props>();
const faixaSelecionada = defineModel<string>("faixaSelecionada");
const tamanhoMinimo = 2;

const tamanhoDaColuna = computed(() => {
    const maximoDeColunasPorLinha = 12;
    const tamanhoCalculado = Math.round(maximoDeColunasPorLinha / props.possiveisFaixas.length);
    return Math.max(tamanhoCalculado, tamanhoMinimo);
});

function selecionarFaixa(faixa: string) {
    faixaSelecionada.value = faixa;
}

function isFaixaSelecionada(faixa: string) {
    return faixaSelecionada.value === faixa ? "primary" : "outline-primary";
}
</script>