<template>
    <div class="form-group natural-label v-select">
        <label for="v-select" v-if="label">{{ label }}</label>
        <v-select
            id="v-select"
            :options="options"
            label="text"
            :multiple="multiple"
            v-model="selectedOptions"
            :reduce="(option) => option.value"
            :loading="loading"
        >
            <template #no-options>Nenhuma opção encontrada.</template>
        </v-select>
    </div>
</template>

<script lang="ts" setup>
import vSelect from "vue-select";
import {
    defineProps,
    withDefaults,
    defineModel,
    defineEmits,
    watch,
} from "vue";
import { ISelectOption } from "@/interfaces/select/ISelectOption";

interface Props {
    label?: string;
    options: ISelectOption[];
    multiple?: boolean;
    loading?: boolean;
}

withDefaults(defineProps<Props>(), {
    options: () => [],
    multiple: false,
    loading: false,
});

const emits = defineEmits<{
    change: [event: any];
}>();

const selectedOptions = defineModel();

watch(
    () => selectedOptions.value,
    () => {
        emits("change", selectedOptions.value);
    },
);
</script>
