<template>
    <div class="d-flex flex-column w-100">
        <h3 class="mb-2">Situações</h3>
        <ul class="list">
            <li class="d-flex">
                <img src="@/assets/ponteiros/FV/online.svg" alt="" />
                <h4>Equipamento online</h4>
            </li>
            <li class="d-flex">
                <img
                    src="@/assets/ponteiros/FV/sem-deteccao-recente.svg"
                    alt=""
                />
                <h4>Equipamento sem fluxo recente</h4>
            </li>
            <li class="d-flex">
                <img src="@/assets/ponteiros/FV/offline.svg" alt="" />
                <h4>Equipamento offline</h4>
            </li>
            <li class="d-flex">
                <img src="@/assets/ponteiros/FV/nunca-usado.svg" alt="" />
                <h4>Equipamento nunca usado</h4>
            </li>
        </ul>
    </div>
</template>
