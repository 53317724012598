<script lang="ts" setup>
import { type IVertice } from "@/interfaces/mapa/areas/IVertice";
import { google } from "google-maps";
import {
    type Ref,
    defineProps,
    inject,
    onBeforeUnmount,
    onMounted,
    watch,
    withDefaults,
} from "vue";

interface Props {
    vertices: IVertice[];
    cor?: string;
}

const props = withDefaults(defineProps<Props>(), {
    cor: "#000",
});

const googleApi: Ref<google> | undefined = inject("googleApi");
const map: any = inject("map");

let poligono: google.maps.Polygon | null = null;

function gerarPoligono() {
    removerPoligonoCasoExista();
    if (googleApi) {
        poligono = new googleApi.value.maps.Polygon({
            paths: props.vertices.map(
                (vertice) =>
                    new googleApi.value.maps.LatLng(
                        vertice.latitude,
                        vertice.longitude,
                    ),
            ),
            strokeColor: props.cor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: props.cor,
            fillOpacity: 0.35,
        });
        poligono.setMap(map.value);
    }
}

function removerPoligonoCasoExista() {
    if (poligono) {
        poligono.setMap(null);
    }
}

watch(
    () => props,
    () => {
        gerarPoligono();
    },
);

onMounted(() => {
    gerarPoligono();
});

onBeforeUnmount(() => {
    removerPoligonoCasoExista();
});
</script>
