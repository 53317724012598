import axios from "../plugins/axios.plugin";

export const buscarEquipamentos = (tipos) => {
    if (tipos && tipos.length > 0) {
        const query = tipos.reduce((query, tipo) => {
            if (query === "") {
                return `tipos=${tipo}`;
            }
            return `${query}&tipos=${tipo}`;
        }, "");
        return axios.get("/vias/v1/equipamentos?" + query);
    }
    return axios.get("/vias/v1/equipamentos");
};

export const buscarEquipamentosAferidos = () =>
    axios.get("/vias/v1/equipamentos/afericoes");

export const buscarTiposDeEquipamentos = () =>
    axios.get("/vias/v1/equipamentos/tipos");

export const buscarQuantidadeDeEquipamentosPorTipo = () =>
    axios.get("/vias/v1/equipamentos/tipos/quantidade");
