<template>
    <vias-table :colunas="colunas_auditoria" :linhas="linhas" com-opcoes>
        <template v-slot:opcoes="{ linha }">
            <div class="acao" v-for="(acao, i) in linha.acoes" :key="i">
                <h4 class="text-capitalize" v-if="acao.titulo">
                    {{ acao.titulo }}
                </h4>
                <ul class="lista-de-acoes">
                    <li
                        class="d-flex align-items-center"
                        v-for="texto in acao.textos"
                        :key="texto"
                    >
                        <i
                            :class="[
                                'text-' + texto.variante,
                                'fas',
                                'fa-' +
                                    (texto.variante === 'danger'
                                        ? 'minus-circle'
                                        : 'plus-circle'),
                            ]"
                        >
                        </i>
                        {{ texto.valor }}
                    </li>
                </ul>
            </div>
        </template>
    </vias-table>
</template>

<script>
import ViasTable from "./ViasTable.vue";

export default {
    name: "ViasTabelaAuditoria",
    props: ["linhas"],
    components: {
        ViasTable,
    },
    data: () => ({
        colunas_auditoria: [
            {
                titulo: "#",
                referencia: "numero_linha",
                tipo: "numero",
            },
            {
                titulo: "Data/Hora",
                referencia: "data_hora",
                tipo: "texto",
            },
            {
                titulo: "Autor",
                referencia: "autor",
                tipo: "texto",
            },
            {
                titulo: "Tipo da ação",
                referencia: "tipo_de_acao",
                tipo: "texto",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.lista-de-acoes {
    li i {
        margin-right: 1rem;
    }

    * {
        font-size: 1.25rem;
    }
}

.acao {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    & + & {
        border-top: 1px solid $color-light;
    }
}
</style>
