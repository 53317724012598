<template>
    <div id="relatorio-ocorrencias">
        <vias-card>
            <vias-formulario-ocorrencias
                @buscarRelatorio="buscarRelatorio"
            ></vias-formulario-ocorrencias>
        </vias-card>

        <vias-card class="mt-4" column v-if="mostrar_relatorio">
            <vias-button
                variant="outline-primary"
                type="button"
                @click="emitirPdf()"
            >
                Emitir PDF
            </vias-button>
        </vias-card>

        <vias-card
            class="mb-4 mt-4"
            v-if="
                mostrar_relatorio &&
                relatorio &&
                relatorio.busca &&
                relatorio.busca.formato_visualizacao === 'hora'
            "
        >
            <div class="d-flex">
                <vias-checkbox
                    class="w-auto h-auto p-0 m-0"
                    afterText="Apresentar informações de 15 em 15 minutos"
                    v-model="informacoes_em_quinze_minutos"
                    switch
                ></vias-checkbox>
            </div>
        </vias-card>

        <vias-relatorio-ocorrencias
            v-if="mostrar_relatorio"
            class="mt-4"
            :identificadores="relatorio.identificadores"
            :graficoDeOcorrencias="relatorio.graficos.ocorrencias"
            :tabelaDeOcorrencias="relatorio.tabelas.ocorrencias"
            :busca="relatorio.busca"
            @alterarFormatoDeVisualizacao="alterarFormatoDeVisualizacao"
            @alterarFormatoDeVisualizacaoParaBaixo="
                alterarFormatoDeVisualizacaoParaBaixo
            "
        ></vias-relatorio-ocorrencias>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasCheckbox from "@/components/shared/ViasCheckbox.vue";
import ViasRelatorioOcorrenciasVue from "@/components/velocidade-media/relatorios/ocorrencias/ViasRelatorioOcorrencias.vue";
import ViasFormularioOcorrenciasVue from "@/components/velocidade-media/relatorios/ocorrencias/ViasFormularioOcorrencias.vue";
import moment from "moment";
export default {
    name: "relatorio-ocorrencias",
    components: {
        "vias-card": ViasCardVue,
        "vias-relatorio-ocorrencias": ViasRelatorioOcorrenciasVue,
        "vias-formulario-ocorrencias": ViasFormularioOcorrenciasVue,
        "vias-button": ViasButton,
        "vias-checkbox": ViasCheckbox,
    },
    data: () => ({
        formulario: null,
        relatorio: {
            busca: null,
            identificadores_simplificados: null,
            identificadores: [],
            graficos: {
                ocorrencias: [],
            },
            tabelas: {
                ocorrencias: [],
            },
        },
        mostrar_relatorio: false,
        informacoes_em_quinze_minutos: false,
        formatos_moment: {
            completo: "YYYY",
            ano: "MMMM, YYYY",
            mes: "DD, dddd",
            semana: "DD, dddd",
            dia: "HH",
            hora: "HH:mm",
        },
        formatos_moment_simplificados: {
            ano: "MMMM, YYYY",
            mes: "DD",
            semana: "DD",
            dia: "HH",
            hora: "mm",
        },
    }),
    methods: {
        buscarRelatorio(formulario) {
            this.$notificacao.open("Buscando relatório, aguarde...");
            console.log((formulario))

            this.relatorio.busca = Object.assign({}, formulario);
            this.formulario = {
                ...formulario,
                rota: formulario.rota.value
            };

            this.$velocidade_media
                .buscarRelatorioDeOcorrencias(this.formulario)
                .then((res) => res.data)
                .then((relatorio) => {
                    this.dados_brutos = relatorio;
                    this.tratarRelatorio();
                })
                .then(() => this.$notificacao.close("Procedimento finalizado"));
        },
        tratarRelatorio() {
            let chavesBrutas = Object.keys(this.dados_brutos.grafico);
            let valoresBrutos = Object.values(this.dados_brutos.grafico);

            if (
                this.relatorio.busca.formato_visualizacao === "hora" &&
                this.informacoes_em_quinze_minutos
            ) {
                const resultado = this.tratarRelatorioParaQuinzeMinutos();
                chavesBrutas = resultado.chaves;
                valoresBrutos = resultado.valores;
            }

            this.relatorio.identificadores = chavesBrutas.map((identificador) =>
                moment(identificador).format(
                    this.formatos_moment[this.formulario.formato_visualizacao],
                ),
            );
            this.relatorio.identificadores_simplificados = chavesBrutas.map(
                (identificador) =>
                    moment(identificador).format(
                        this.formatos_moment_simplificados[
                            this.formulario.formato_visualizacao
                        ],
                    ),
            );
            this.relatorio.graficos.ocorrencias = valoresBrutos;
            this.relatorio.tabelas.ocorrencias = chavesBrutas.map(
                (identificador, i) => ({
                    numero_linha: i + 1,
                    identificador: moment(identificador).format(
                        this.formatos_moment[
                            this.formulario.formato_visualizacao
                        ],
                    ),
                    ocorrencias: valoresBrutos[i],
                }),
            );

            this.mostrar_relatorio = true;
        },
        emitirPdf() {
            this.$notificacao.open(
                "Estamos emitindo o PDF solicitado, aguarde um momento por favor...",
            );

            this.$velocidade_media
                .emitirPdfRelatorioDeOcorrencias(this.relatorio.busca)
                .then((res) => res.data)
                .then(({ mensagem }) => {
                    this.$notificacao.close(mensagem, "success");
                });
        },
        alterarFormatoDeVisualizacao(formato) {
            this.formulario.formato_visualizacao = formato;
            this.buscarRelatorio(this.formulario);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            if (this.relatorio.busca.formato_visualizacao !== "hora") {
                this.formulario.formato_visualizacao =
                    this.definirNovoFormatoDeVisualizacao();
                this.formulario.data = moment(this.relatorio.busca.data)
                    .set(
                        this.definirAtributoASerAlteradoDoMoment(),
                        this.relatorio.identificadores_simplificados[index],
                    )
                    .format("YYYY-MM-DDTHH:mm:ss");

                this.buscarRelatorio(this.formulario);
            }
        },
        tratarRelatorioParaQuinzeMinutos() {
            let i = 0;
            const chaves = Object.keys(this.dados_brutos.grafico).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.push(valor);
                    }

                    i++;

                    if (i == 3) {
                        i = 0;
                    }
                    return temp;
                },
                [],
            );

            i = 0;
            const valores = Object.values(this.dados_brutos.grafico).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.somatoria = valor;
                    }

                    if (i > 0) {
                        temp.somatoria += valor;
                    }

                    if (i == 2) {
                        temp.valores.push(temp.somatoria);
                        temp.somatoria = null;
                        i = 0;
                        return temp;
                    }
                    i++;
                    return temp;
                },
                {
                    valores: [],
                    somatoria: null,
                },
            ).valores;

            return {
                chaves,
                valores,
            };
        },
        definirNovoFormatoDeVisualizacao() {
            const formatosDeVisualizacao = {
                ano: "mes",
                mes: "dia",
                semana: "dia",
                dia: "hora",
            };
            return formatosDeVisualizacao[
                this.relatorio.busca.formato_visualizacao
            ];
        },
        definirAtributoASerAlteradoDoMoment() {
            const atributosMoment = {
                ano: "month",
                mes: "date",
                semana: "date",
                dia: "hour",
            };
            return atributosMoment[this.relatorio.busca.formato_visualizacao];
        },
    },
    watch: {
        informacoes_em_quinze_minutos() {
            this.tratarRelatorio();
        },
    },
};
</script>
