<template>

<div class="d-flex gap-4 aling-items-center">
    <ViasCheckbox
        class="w-auto m-0"
        after-text="Mostrar os gráficos em colunas"
        v-model="mostrarOsGraficosEmColunas"
        switch
    />
    <ViasCheckbox
        class="w-auto m-0"
        after-text="Mostrar os valores nos gráficos"
        v-model="mostrarOsValoresNosGraficos"
        switch
    />
    <ViasCheckbox
        class="w-auto m-0"
        after-text="Mostrar os gráficos por categoria de veículo"
        v-model="mostrarOsValoresDosGraficosPorCategoriaDeVeiculo"
        switch
    />
    <ViasCheckbox
        class="w-auto m-0"
        after-text="Mostrar gráficos de pizza"
        v-model="mostrarGraficosDePizza"
        switch
    />
    <ViasCheckbox
        class="w-auto m-0"
        after-text="Mostrar valores acumulados na vertical"
        v-model="mostrarValoresAcumuladosNaVertical"
        switch
    />
</div>

</template>

<script lang="ts" setup>
import ViasCheckbox from '@/components/shared/ViasCheckbox.vue';
import { defineModel } from 'vue';

const mostrarOsGraficosEmColunas = defineModel("mostrarOsGraficosEmColunas");
const mostrarOsValoresNosGraficos = defineModel("mostrarOsValoresNosGraficos");
const mostrarOsValoresDosGraficosPorCategoriaDeVeiculo = defineModel(
    "mostrarOsValoresDosGraficosPorCategoriaDeVeiculo"
);
const mostrarGraficosDePizza = defineModel("mostrarGraficosDePizza");
const mostrarValoresAcumuladosNaVertical = defineModel("mostrarValoresAcumuladosNaVertical");
</script>