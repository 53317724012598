import { Module } from "vuex";
import { State } from "..";
import {
    BUSCAR_RELATORIO_DE_DADOS_APURADOS,
    EMITIR_PDF_RELATORIO_DE_DADOS_APURADOS,
} from "./type-actions";
import http from "@/http";

export const relatoriosModule: Module<State, State> = {
    actions: {
        [BUSCAR_RELATORIO_DE_DADOS_APURADOS](
            context,
            formulario: any,
        ): Promise<any> {
            return http.get(
                `/vias/v1/origem-destino/relatorios?origem_id=${formulario.origem_id}&destino_id=${formulario.destino_id}&data_inicial=${formulario.data_inicial}&data_final=${formulario.data_final}`,
            );
        },
        [EMITIR_PDF_RELATORIO_DE_DADOS_APURADOS](
            context,
            formulario: any,
        ): Promise<any> {
            return http.get(
                `/vias/v1/origem-destino/relatorios/pdf?origem_id=${formulario.origem_id}&destino_id=${formulario.destino_id}&data_inicial=${formulario.data_inicial}&data_final=${formulario.data_final}`,
            );
        },
    },
};
