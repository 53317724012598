import { State } from "@/storeTs";
import { Module } from "vuex";
import {
    BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO,
    BUSCAR_RESULTADOS_ORIGEM_E_DESTINO,
} from "./type-actions";
import axios from "@/plugins/axios.plugin";

export const origemEDestinoMapaModule: Module<State, State> = {
    actions: {
        [BUSCAR_ULTIMO_MINUTO_ORIGEM_E_DESTINO]() {
            return axios.get(
                "/vias/v1/origem-destino/resultados/ultimo-minuto",
            );
        },
        [BUSCAR_RESULTADOS_ORIGEM_E_DESTINO](context, data_hora: string) {
            let url = "/vias/v1/origem-destino/resultados";

            if (data_hora) {
                url += `?data_hora=${data_hora}`;
            }

            return axios.get(url);
        },
    },
};
