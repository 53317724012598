<template>
    <vias-card column>
        <form
            id="vias-formulario-relatorio-velocidade-media"
            @submit="enviarDados"
        >
            <div class="row">
                <div class="col">
                    <vias-select
                        v-model="formulario.rota"
                        :options="rotas"
                        label="Rota"
                    ></vias-select>
                </div>
            </div>

            <div class="row">
                <div class="col col-md-6">
                    <vias-radio-button
                        :options="opcoes_formato_visualizacao"
                        v-model="formulario.formato_visualizacao"
                    ></vias-radio-button>
                </div>
                <div class="col col-md-6">
                    <div class="row">
                        <div class="col col-12">
                            <HorariosSelect
                                v-model="horarioSelecionado"
                                @change="atualizarHorarioDaBusca($event)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <vias-input-period-date-and-time
                                v-model="formulario.data"
                                label="Período da busca"
                                @change="desselecionarHorario()"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="button-block">
                        <vias-button variant="primary" type="submit"
                            >Buscar relatório</vias-button
                        >
                    </div>
                </div>
            </div>
        </form>
    </vias-card>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasRadioButtonVue from "@/components/shared/ViasRadioButton.vue";
import ViasInputPeriodDateAndTime from "@/components/shared/ViasInputPeriodDateAndTime.vue";
import ViasSelect from "@/components/shared/ViasSelect.vue";
import moment from "moment";
import HorariosSelect from "@/domains/Horarios/components/HorariosSelect.vue";
import datetimeService from "@/core/composables/DatetimeService";

export default {
    name: "vias-formulario-relatorio-velocidade-media",
    components: {
        "vias-card": ViasCardVue,
        "vias-button": ViasButtonVue,
        "vias-radio-button": ViasRadioButtonVue,
        "vias-input-period-date-and-time": ViasInputPeriodDateAndTime,
        "vias-select": ViasSelect,
        HorariosSelect,
    },
    data: () => ({
        formulario: {
            rota: "all",
            data: [
                moment().subtract("month", 3).format("YYYY-MM-DDT00:00:00"),
                moment().format("YYYY-MM-DDTHH:mm:ss"),
            ],
            formato_visualizacao: "ano",
        },
        horarioSelecionado: 0,
        rotas: [
            {
                text: "Todas as rotas",
                value: "all",
            },
        ],
        opcoes_formato_visualizacao: [
            {
                text: "Hora",
                value: "hora",
            },
            {
                text: "Dia",
                value: "dia",
            },
            {
                text: "Mês",
                value: "mes",
            },
            {
                text: "Ano",
                value: "ano",
            },
        ],
    }),
    created() {
        this.buscarRotas();
    },
    methods: {
        buscarRotas() {
            this.$velocidade_media
                .buscarRotas()
                .then((res) => res.data)
                .then((rotas) => this.tratarRotas(rotas));
        },
        tratarRotas(rotas) {
            this.rotas = this.rotas.concat(
                rotas.map((rota) => ({
                    text: rota.nome,
                    value: rota.id,
                })),
            );
        },
        atualizarHorarioDaBusca(horario) {
            this.formulario.data[0] = datetimeService.setTimeOnDatetime(
                this.formulario.data[0],
                horario[0],
            );
            this.formulario.data[1] = datetimeService.setTimeOnDatetime(
                this.formulario.data[1],
                horario[1],
            );
        },
        desselecionarHorario() {
            this.horarioSelecionado = 0;
        },
        enviarDados(e) {
            e.preventDefault();

            this.$emit("buscarRelatorio", this.formulario);
        },
    },
};
</script>
