<template>
    <div id="vias-relatorio-velocidade-media">
        <vias-card titulo="Fluxo" column>
            <div class="row">
                <div class="col">
                    <div class="grafico-de-relatorio" :class="theme">
                        <div class="cabecalho">
                            <vias-button variant="link">{{
                                searchPeriod
                            }}</vias-button>
                        </div>
                        <vias-line-chart
                            :labels="labels"
                            :datasets="datasets_registros"
                            :eixo_x="eixoX"
                            eixo_y="Total de fluxo"
                            @alterarFormatoDeVisualizacao="
                                alterarFormatoDeVisualizacaoParaBaixo($event)
                            "
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="grafico-de-relatorio" :class="theme">
                        <div class="cabecalho">
                            <vias-button variant="link">{{
                                searchPeriod
                            }}</vias-button>
                        </div>
                        <vias-histograma
                            :labels="labels"
                            :datasets="datasets_registros"
                            :eixo_x="eixoX"
                            eixo_y="Total de fluxo"
                            @alterarFormatoDeVisualizacao="
                                alterarFormatoDeVisualizacaoParaBaixo($event)
                            "
                        ></vias-histograma>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-table
                        class="mt-4 overflow-x-auto"
                        :colunas="colunas_registros"
                        :linhas="linhas_registros"
                    ></vias-table>
                </div>
            </div>
        </vias-card>

        <vias-card column class="mt-4">
            <template #header>
                <h2 class="card-title">
                    Velocidade Média <ViasExplicacaoVelocidadeMediaGeral/>
                </h2>
            </template>
            <div class="row">
                <div class="col">
                    <div class="grafico-de-relatorio" :class="theme">
                        <div class="cabecalho">
                            <vias-button variant="link">{{
                                searchPeriod
                            }}</vias-button>
                        </div>
                        <vias-line-chart
                            :labels="labels"
                            :datasets="datasets_velocidade_media"
                            :eixo_x="eixoX"
                            eixo_y="Velocidade média"
                            :habilitar-apresentacao-total="false"
                            @alterarFormatoDeVisualizacao="
                                alterarFormatoDeVisualizacaoParaBaixo($event)
                            "
                        ></vias-line-chart>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-table
                        class="mt-4 overflow-x-auto"
                        :colunas="colunas_velocidade_media"
                        :linhas="linhas_velocidade_media"
                    ></vias-table>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasLineChartVue from "@/components/shared/ViasLineChart.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasHistograma from "@/components/shared/ViasHistograma.vue";
import moment from "moment";
import { mapState } from "vuex";
import ViasExplicacaoVelocidadeMediaGeral from "@/components/shared/explicacoes/ViasExplicacaoVelocidadeMediaGeral.vue";

export default {
    name: "vias-relatorio-velocidade-media",
    props: [
        "identificadores",
        "graficoRegistros",
        "tabelaRegistros",
        "graficoVelocidadeMedia",
        "tabelaVelocidadeMedia",
        "busca",
    ],
    components: {
        "vias-card": ViasCardVue,
        "vias-line-chart": ViasLineChartVue,
        "vias-table": ViasTableVue,
        "vias-button": ViasButtonVue,
        "vias-histograma": ViasHistograma,
        ViasExplicacaoVelocidadeMediaGeral,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        colunas_registros() {
            return [
                {
                    titulo: "#",
                    tipo: "numero",
                    referencia: "numero_linha",
                },
                {
                    titulo: this.titulos_da_coluna_identificador[
                        this.busca.formato_visualizacao
                    ],
                    tipo: "texto",
                    referencia: "identificador",
                },
                {
                    titulo: "Fluxo",
                    tipo: "numero",
                    referencia: "registros",
                    unidade: "de fluxo",
                },
            ];
        },
        colunas_velocidade_media() {
            return [
                {
                    titulo: "#",
                    tipo: "numero",
                    referencia: "numero_linha",
                },
                {
                    titulo: this.titulos_da_coluna_identificador[
                        this.busca.formato_visualizacao
                    ],
                    tipo: "texto",
                    referencia: "identificador",
                },
                {
                    titulo: "Velocidade Média",
                    tipo: "numero",
                    referencia: "velocidade_media",
                    unidade: "km/h",
                },
            ];
        },
        eixoX() {
            return this.legendas_eixo_x[this.busca.formato_visualizacao];
        },
        searchPeriod() {
            return `${moment(this.busca.data[0]).format("DD/MM/YYYY HH:mm")} até ${moment(this.busca.data[1]).format("DD/MM/YYYY HH:mm")}`;
        },
    },
    data: () => ({
        labels: [],
        datasets_registros: [],
        datasets_velocidade_media: [],
        titulos_da_coluna_identificador: {
            ano: "Mês",
            mes: "Dia",
            semana: "Dia",
            dia: "Hora",
            hora: "Horário",
        },
        legendas_eixo_x: {
            completo: "Anos do contrato",
            ano: "Meses do ano",
            mes: "Dias do mês",
            semana: "Dias da semana",
            dia: "Horas do dia",
            hora: "Horários",
        },
        linhas_registros: [],
        linhas_velocidade_media: [],
    }),
    created() {
        this.gerarLabels();
        this.gerarDatasetRegistros();
        this.gerarTabelaRegistros();
        this.gerarDatasetVelocidadeMedia();
        this.gerarTabelaVelocidadeMedia();
    },
    watch: {
        identificadores() {
            this.gerarLabels();
        },
        graficoRegistros() {
            this.gerarDatasetRegistros();
        },
        tabelaRegistros() {
            this.gerarTabelaRegistros();
        },
        graficoVelocidadeMedia() {
            this.gerarDatasetVelocidadeMedia();
        },
        tabelaVelocidadeMedia() {
            this.gerarTabelaVelocidadeMedia();
        },
    },
    methods: {
        gerarLabels() {
            this.labels = this.identificadores;
        },
        gerarDatasetRegistros() {
            this.datasets_registros = [
                {
                    label: "Fluxo",
                    data: this.graficoRegistros,
                    backgroundColor: this.$variaveis.cores.primary,
                    borderColor: this.$variaveis.cores.primary,
                },
            ];
        },
        gerarTabelaRegistros() {
            this.linhas_registros = this.tabelaRegistros;
        },
        gerarDatasetVelocidadeMedia() {
            this.datasets_velocidade_media = [
                {
                    label: "Velocidade Média",
                    data: this.graficoVelocidadeMedia,
                    backgroundColor: this.$variaveis.cores.danger,
                    borderColor: this.$variaveis.cores.danger,
                },
            ];
        },
        gerarTabelaVelocidadeMedia() {
            this.linhas_velocidade_media = this.tabelaVelocidadeMedia;
        },
        alterarFormatoDeVisualizacao(formato) {
            this.$emit("alterarFormatoDeVisualizacao", formato);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            this.$emit("alterarFormatoDeVisualizacaoParaBaixo", index);
        },
    },
};
</script>
