import axios from "@/plugins/axios.plugin";
import moment from "moment";

export const buscarUsuarios = () => axios.get("/vias/v1/usuarios");

export const buscarGrupos = () => axios.get("/vias/v1/equipamentos/grupos");

export const adicionarGrupo = (grupo) =>
    axios.post("/vias/v1/equipamentos/grupos", grupo);

export const alterarGrupo = (grupo, id) =>
    axios.put(`/vias/v1/equipamentos/grupos/${id}`, grupo);

export const removerGrupo = ({ id }) =>
    axios.delete(`/vias/v1/equipamentos/grupos/${id}`);

export const buscarPrimeiraData = () => axios.get("/vias/v1/contrato/inicio");

export const buscarRelatorioFluxoVeicular = (formulario) => {
    const params = new URLSearchParams();
    params.append("data_hora", formulario.data);
    params.append("periodo", formulario.periodo_visualizacao);
    params.append("formato", formulario.formato_pesquisa);

    if (formulario.faixas.length > 0) {
        for (const faixa of formulario.faixas) {
            params.append("faixas", faixa);
        }
    }

    if (formulario.grupo_id) {
        params.append("grupo_id", formulario.grupo_id);
    }

    return axios.get(`/vias/v1/graficos/fluxo?${params.toString()}`);
};

export const emitirPdfRelatorioFluxoVeicular = (formulario) => {
    const params = new URLSearchParams();
    params.append("data_hora", formulario.data);
    params.append("periodo", formulario.periodo_visualizacao);
    params.append("formato", formulario.formato_pesquisa);

    if (formulario.faixas.length > 0) {
        for (const faixa of formulario.faixas) {
            params.append("faixas", faixa);
        }
    }

    if (formulario.grupo_id) {
        params.append("grupo_id", formulario.grupo_id);
    }

    return axios.get(`/vias/v1/graficos/fluxo/pdf?${params.toString()}`);
};

export const buscarRelatorioVelocidade = (formulario) => {
    const params = new URLSearchParams();
    params.append("data_hora", formulario.data);
    params.append("periodo", formulario.periodo_visualizacao);
    params.append("formato", formulario.formato_pesquisa);

    if (formulario.faixas.length > 0) {
        for (const faixa of formulario.faixas) {
            params.append("faixas", faixa);
        }
    }

    if (formulario.grupo_id) {
        params.append("grupo_id", formulario.grupo_id);
    }

    return axios.get(
        `/vias/v1/graficos/velocidade/por-classe/todos?${params.toString()}`,
    );
};

export const buscarRelatorioVolumeMedioDiarioPorMes = (formulario) => {
    formulario.data = moment(formulario.data).format("YYYY-MM-DD");
    let url = `/vias/v1/relatorios/volume-medio-diario?data=${formulario.data}&apenas_falhas=${formulario.apenas_falhas.toString()}`;

    if (!formulario.buscar_por_grupo && formulario.equipamento !== "all")
        url += `&equipamento_id=${formulario.equipamento}`;
    if (formulario.buscar_por_grupo && formulario.grupo !== "all")
        url += `&grupo_id=${formulario.grupo}`;

    return axios.get(url);
};

export const emitirPdfRelatorioVolumeMedioDiarioPorMes = (formulario) => {
    let url = `/vias/v1/relatorios/volume-medio-diario/pdf?data=${formulario.data}&apenas_falhas=${formulario.apenas_falhas.toString()}`;

    if (!formulario.buscar_por_grupo && formulario.equipamento !== "all")
        url += `&equipamento_id=${formulario.equipamento}`;
    if (formulario.buscar_por_grupo && formulario.grupo !== "all")
        url += `&grupo_id=${formulario.grupo}`;

    return axios.get(url);
};

export const buscarRelatorioViolacoes = (formulario) => {
    let url = `/vias/v1/graficos/infracao/gerais?data_hora=${formulario.data}&periodo=${formulario.formato_visualizacao}`;

    if (!formulario.buscar_por_grupo && formulario.equipamento !== "all")
        url += `&equipamento_id=${formulario.equipamento}`;
    if (formulario.buscar_por_grupo && formulario.grupo !== "all")
        url += `&grupo_id=${formulario.grupo}`;

    return axios.get(url);
};

export const emitirPdfRelatorioViolacoes = (formulario) => {
    let url = `/vias/v1/graficos/infracao/gerais/pdf?data_hora=${formulario.data}&periodo=${formulario.formato_visualizacao}`;

    if (!formulario.buscar_por_grupo && formulario.equipamento !== "all")
        url += `&equipamento_id=${formulario.equipamento}`;
    if (formulario.buscar_por_grupo && formulario.grupo !== "all")
        url += `&grupo_id=${formulario.grupo}`;

    return axios.get(url);
};

export const buscarDadosDoPainelDoEquipamento = (formulario) => {
    let url = `/gestao-integrada/v1/estatisticas?equipamento_id=${formulario.equipamento}`;

    if (formulario.data) url += `&data_hora=${formulario.data}`;

    return axios.get(url);
};

export const buscarRelatorioDeVolumeDiarioDeTrafego = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/volume-diario-trafego?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeVolumeDiarioDeTrafego = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/volume-diario-trafego/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeCalorDeVolumeDiarioDeTrafego = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/volume-diario-trafego?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeCalorDeVolumeDiarioDeTrafego = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/volume-diario-trafego/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeCalorDeExcessosDeVelocidade = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/excessos-de-velocidade?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeCalorDeExcessosDeVelocidade = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/excessos-de-velocidade/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeCalorDeIndiceDeLeituraDePlaca = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/indice-de-leitura?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeCalorDeIndiceDeLeituraDePlaca = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/relatorios/mapas-de-calor/indice-de-leitura/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeExcessoDeVelocidade = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/velocidade/excessos?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeExcessoDeVelocidade = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/velocidade/excessos/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeExcessoDeVelocidadePorClasse = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/velocidade/por-classe/excessos?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeExcessoDeVelocidadePorClasse = (dataDaBusca) => {
    return axios.get(
        `/vias/v1/graficos/velocidade/por-classe/excessos/pdf?data=${moment(dataDaBusca).format("YYYY-MM-DD")}`,
    );
};

export const buscarTotalDeInfracoesPorEnquadramento = () => {
    return axios.get(`/vias/v1/graficos/infracao/por-enquadramento`);
};

export const emitirPdfDoTotalDeInfracoesPorEnquadramento = () => {
    return axios.get(`/vias/v1/graficos/infracao/por-enquadramento/pdf`);
};

export const buscarTotalDeInfracoesLavradas = () => {
    return axios.get(`/vias/v1/graficos/infracao/lavradas`);
};

export const emitirPdfDoTotalDeInfracoesLavradas = () => {
    return axios.get(`/vias/v1/graficos/infracao/lavradas/pdf`);
};

export const buscarRelatorioDeImpacto = (data) => {
    return axios.get(
        `/vias/v1/graficos/impacto?data=${moment(data).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeRelatorioDeImpacto = (data) => {
    return axios.get(
        `/vias/v1/graficos/impacto/pdf?data=${moment(data).format("YYYY-MM-DD")}`,
    );
};

export const buscarRelatorioDeTotalDeOcorrenciasPorEquipamento = (data) => {
    return axios.get(
        `/vias/v1/graficos/infracao/por-equipamento?data=${moment(data).format("YYYY-MM-DD")}`,
    );
};

export const emitirPdfDeRelatorioDeTotalDeOcorrenciasPorEquipamento = (
    data,
) => {
    return axios.get(
        `/vias/v1/graficos/infracao/por-equipamento/pdf?data=${moment(data).format("YYYY-MM-DD")}`,
    );
};
