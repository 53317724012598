<template>

<div class="d-flex gap-4">
    <ViasRow>
        <ViasCol :cols="12">
            <div class="border border-radius-1 grafico-de-relatorio d-flex flex-column g-3">
                <div class="cabecalho">
                    <CabecalhoRelatorioDeFluxoVeicular
                        :periodo-de-busca="periodoDeBusca"
                        :data-hora-da-busca="dataHoraDaBusca"
                        @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaCima($event)"
                    />
                </div>
                <ViasRow>
                    <ViasCol :cols="4" class="p-4" v-if="mostrarGraficoDePizza">
                        <ViasDoughnutChart
                            :labels="labelsParaGraficoDeDoughnut"
                            :datasets="datasetsParaGraficoDeDoughnut"
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                        />
                    </ViasCol>
                    <ViasCol :cols="mostrarGraficoDePizza ? 8 : 12" class="p-4">
                        <ViasBarChart
                            v-if="mostrarGraficoEmColuna"
                            :eixo_x="eixoX"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                            @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaBaixo($event)"
                            :stacked="mostrarValoresAcumuladosNaVertical"
                        />
                        <ViasLineChart
                            v-else
                            :eixo_x="eixoX"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                            @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaBaixo($event)"
                            :stacked="mostrarValoresAcumuladosNaVertical"
                        />
                    </ViasCol>
                </ViasRow>
            </div>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <div class="border border-radius-1 grafico-de-relatorio d-flex flex-column g-3">
                <div class="cabecalho">
                    <CabecalhoRelatorioDeFluxoVeicular
                        :periodo-de-busca="periodoDeBusca"
                        :data-hora-da-busca="dataHoraDaBusca"
                        @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaCima($event)"
                    />
                </div>
                <ViasRow>
                    <ViasCol :cols="12" class="p-4">
                        <ViasHistograma
                            :apresentar-datalabels="mostrarValoresNoGrafico"
                            :eixo_x="eixoX"
                            eixo_y="Total de fluxo"
                            :labels="labels"
                            :datasets="datasets"
                            @alterar-formato-de-visualizacao="alterarFormatoDeVisualizacaoParaBaixo($event)"
                        />
                    </ViasCol>
                </ViasRow>
            </div>
        </ViasCol>
    </ViasRow>

    <ViasRow>
        <ViasCol :cols="12">
            <ViasTable
                :colunas="colunas"
                :linhas="linhas"
            />
        </ViasCol>
    </ViasRow>
</div>

</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits } from 'vue';
import { GraficoDeFluxo } from '../models/GraficoDeFluxo';
import ViasLineChart from '@/components/shared/ViasLineChart.vue';
import datetimeService from '@/core/composables/DatetimeService';
import coresService from '@/core/composables/CoresService';
import { IColunaTabela } from '@/interfaces/IColunaTabela';
import ViasTable from '@/components/shared/ViasTable.vue';
import numerosService from '@/domains/Numeros/services/NumerosService';
import CabecalhoRelatorioDeFluxoVeicular from './CabecalhoRelatorioDeFluxoVeicular.vue';
import ViasHistograma from '@/components/shared/ViasHistograma.vue';
import ViasBarChart from '@/components/shared/ViasBarChart.vue';
import ViasDoughnutChart from '@/components/shared/ViasDoughnutChart.vue';
import { PeriodoDeRelatorio } from '../models/PeriodoDoRelatorioDeFluxoVeicular';

interface Props {
    periodoDeBusca: PeriodoDeRelatorio;
    dataHoraDaBusca: string;
    mostrarGraficoEmColuna: boolean;
    mostrarValoresNoGrafico: boolean;
    mostrarGraficoPorCategoriaDeVeiculo: boolean;
    mostrarGraficoDePizza: boolean;
    mostrarValoresAcumuladosNaVertical: boolean;
    relatorio: GraficoDeFluxo;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    alterarFormatoDeVisualizacaoParaCima: [periodo: PeriodoDeRelatorio],
    alterarFormatoDeVisualizacaoParaBaixo: [{periodo: PeriodoDeRelatorio, dataHora: string}]
}>();

const possiveisCoresParaOGrafico = [
    "#7380EC",
    "#52BE80",
    "#EC7380",
    "#ECDF73",
    "#ECB673",
    "#73E9EC",
    "#EC73E6",
];

const labels = computed(() => {
    return Object.keys(props.relatorio).map((data) => {
        return datetimeService.shapeDatetimeToSpecificFormat(data, props.periodoDeBusca);
    });
});

const datasets = computed(() => {
    if (props.mostrarGraficoPorCategoriaDeVeiculo) {
        return gerarDatasetsDeFluxoPorCategoriaDeVeiculo();
    }
    return gerarDatasetsDeFluxo();
});

const labelsParaGraficoDeDoughnut = computed(() => {
    return filtrarAsCategoriasDosVeiculosDoRelatorio();
});

const datasetsParaGraficoDeDoughnut = computed(() => {
    const datasets = gerarDatasetsDeFluxoPorCategoriaDeVeiculo();
    return [{
        label: "Fluxo",
        data: datasets.map((dataset) => dataset.data.reduce((total, item) => total + item, 0)),
        backgroundColor: possiveisCoresParaOGrafico,
    }];
});

function gerarDatasetsDeFluxoPorCategoriaDeVeiculo() {
    const valoresDoRelatorio = Object.values(props.relatorio);
    const categoriasDeVeiculos = filtrarAsCategoriasDosVeiculosDoRelatorio();
    return categoriasDeVeiculos.map((categoria, i) => ({
        label: categoria,
        backgroundColor: possiveisCoresParaOGrafico[i],
        borderColor: possiveisCoresParaOGrafico[i],
        data: valoresDoRelatorio.map((valores) => valores[categoria].quantidade)
    }));
}

function gerarDatasetsDeFluxo() {
    return [{
        label: "Fluxo",
        backgroundColor: coresService.listarCoresDisponiveis().primary,
        borderColor: coresService.listarCoresDisponiveis().primary,
        data: Object.values(props.relatorio).map((data) => data.quantidade_total)
    }];
}

function filtrarAsCategoriasDosVeiculosDoRelatorio() {
    const valoresDoRelatorio = Object.values(props.relatorio);
    const categoriasDeVeiculos = Object.keys(valoresDoRelatorio[0]).filter((chave) => {
        return !["media_de_velocidade_total", "quantidade_total"].includes(chave);
    });
    return categoriasDeVeiculos;
}

const colunas = computed((): IColunaTabela[] => {
    if (props.mostrarGraficoPorCategoriaDeVeiculo) {
        return gerarColunasSeparadoPorCategoriasDeVeiculos();
    }
    return gerarColunas(); 
});

function gerarColunas() {
    return [
        {
            titulo: '#',
            referencia: 'numero_da_linha'
        },
        {
            titulo: definirColunaDeTempo(),
            referencia: 'identificador'
        },
        {
            titulo: 'Fluxo',
            referencia: 'fluxo'
        },
        {
            titulo: 'Fluxo (%)',
            referencia: 'fluxo_porcentagem'
        }
    ];
}

function gerarColunasSeparadoPorCategoriasDeVeiculos() {
    const colunasBase = [
        {
            titulo: '#',
            referencia: 'numero_da_linha'
        },
        {
            titulo: definirColunaDeTempo(),
            referencia: 'identificador'
        },
    ];
    const categoriasDeVeiculos = filtrarAsCategoriasDosVeiculosDoRelatorio();
    return categoriasDeVeiculos.reduce((colunasFinais, categoria) => {
        return colunasFinais.concat([
            {
                titulo: categoria,
                referencia: categoria
            },
            {
                titulo: `${categoria} (%)`,
                referencia: `${categoria} porcentagem`
            }
        ]);
    }, colunasBase);
}

const linhas = computed(() => {
    if (props.mostrarGraficoPorCategoriaDeVeiculo) {
        return gerarLinhasDeFluxoSeparadoPorCategoriaDeVeiculos();
    }
    
    return gerarLinhasDeFluxo();
});

function gerarLinhasDeFluxo() {
    const totalDeFluxo = calcularTotalDeFluxo();
    return Object.entries(props.relatorio).map(([identificador, valores], i) => {
        return {
            numero_da_linha: i + 1,
            identificador: datetimeService.shapeDatetimeToSpecificFormat(identificador, props.periodoDeBusca),
            fluxo: numerosService.formatarNumero(valores.quantidade_total),
            fluxo_porcentagem: numerosService.formatarNumero((valores.quantidade_total / totalDeFluxo) * 100) + "%"
        };
    });
}

function gerarLinhasDeFluxoSeparadoPorCategoriaDeVeiculos() {
    const categoriasDeVeiculos = filtrarAsCategoriasDosVeiculosDoRelatorio();
    return Object.entries(props.relatorio).map(([identificador, valores], i) => {
        let linhaBase = {
            numero_da_linha: i + 1,
            identificador: datetimeService.shapeDatetimeToSpecificFormat(identificador, props.periodoDeBusca),
        };
        categoriasDeVeiculos.forEach((categoria) => {
            linhaBase[categoria] = numerosService.formatarNumero(valores[categoria].quantidade);
            const porcentagem = valores[categoria].quantidade / Math.max(valores.quantidade_total, 1) * 100;
            linhaBase[`${categoria} porcentagem`] = numerosService.formatarNumero(porcentagem) + "%";
        });
        return linhaBase;
    });
}

const eixoX = computed(() => {
    if (props.periodoDeBusca === "completo") {
        return "Anos do contrato";
    }
    if (props.periodoDeBusca === "ano") {
        return "Meses do ano";
    }
    if (props.periodoDeBusca === "mes") {
        return "Dias do mês";
    }
    if (props.periodoDeBusca === "semana") {
        return "Dias da semana";
    }
    if (props.periodoDeBusca === "dia") {
        return "Horas do dia";
    }
    if (props.periodoDeBusca === "hora") {
        return "Minutos da hora";
    }
    return "";
});

function definirColunaDeTempo() {
    if (props.periodoDeBusca === "hora") {
        return "Horário";
    }
    if (props.periodoDeBusca === "dia") {
        return "Hora";
    }
    if (props.periodoDeBusca === "semana") {
        return "Dia";
    }
    if (props.periodoDeBusca === "mes") {
        return "Dia";
    }
    if (props.periodoDeBusca === "ano") {
        return "Mês";
    }
    if (props.periodoDeBusca === "completo") {
        return "Ano";
    }
    return "Não identificado";
}

function calcularTotalDeFluxo() {
    return Object.entries(props.relatorio).reduce((total, identificadorEValores) => {
        return total + identificadorEValores[1].quantidade_total;
    }, 0);
}

function alterarFormatoDeVisualizacaoParaCima(novoPeriodo: PeriodoDeRelatorio) {
    emit("alterarFormatoDeVisualizacaoParaCima", novoPeriodo);
}

function alterarFormatoDeVisualizacaoParaBaixo(index: number) {
    const novaDataDoRelatorio = Object.keys(props.relatorio)[index];
    const novoPeriodoDoRelatorio = definirNovoPeriodoParaBaixo();
    
    if (!novoPeriodoDoRelatorio) {
        return;
    }

    emit("alterarFormatoDeVisualizacaoParaBaixo", { periodo: novoPeriodoDoRelatorio, dataHora: novaDataDoRelatorio});
}

function definirNovoPeriodoParaBaixo() {
    if (props.periodoDeBusca === "completo") {
        return "ano";
    }
    if (props.periodoDeBusca === "ano") {
        return "mes";
    }
    if (props.periodoDeBusca === "mes") {
        return "semana";
    }
    if (props.periodoDeBusca === "semana") {
        return "dia";
    }
    if (props.periodoDeBusca === "dia") {
        return "hora";
    }
    return null;
}
</script>