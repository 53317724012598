<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Nova rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDeRotas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <FormularioNovaRotaOrigemEDestino
                    @adicionar-rota="adicionarRota($event)"
                ></FormularioNovaRotaOrigemEDestino>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import FormularioNovaRotaOrigemEDestino from "./components/FormularioNovaRotaOrigemEDestino.vue";
import { INovaRotaOrigemEDestino } from "./interfaces/INovaRotaOrigemEDestino";
import { useStore } from "@/storeTs";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { ADICIONAR_ROTAS_ORIGEM_E_DESTINO } from "@/storeTs/origem-e-destino/configuracao/type-actions";

export default defineComponent({
    name: "NovaRotaOrigemEDestinoView",
    components: {
        FormularioNovaRotaOrigemEDestino,
    },
    methods: {
        voltarParaAApresentacaoDeRotas() {
            this.router.push("/origem-e-destino/configuracao");
        },
        adicionarRota(novasRotas: INovaRotaOrigemEDestino[]) {
            this.notificarCarregamento(
                "Adicionando rotas, aguarde um momento por favor...",
            );
            this.store
                .dispatch(ADICIONAR_ROTAS_ORIGEM_E_DESTINO, novasRotas)
                .then((res) => {
                    if (res.status === 201) {
                        this.notificar(
                            "Rotas adicionadas com sucesso!",
                            "success",
                        );
                        this.voltarParaAApresentacaoDeRotas();
                    }
                });
        },
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificarCarregamento, notificar } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
