<template>

<ViasCard>
    <ViasRow class="justify-content-evenly">
        <ViasCol :cols="tamanhoDaColuna" v-for="equipamento in possiveisEquipamentos" :key="equipamento">
            <ViasButton
                :variant="isEquipamentoSelecionado(equipamento)"
                @click="selecionarEquipamento(equipamento)"
                block
            >
                {{ equipamento }}
            </ViasButton>
        </ViasCol>
    </ViasRow>
    <ViasRow v-if="equipamentoSelecionado" class="mt-2">
        <ViasCol :cols="12">
            <h3 class="text-center">
                {{ possiveisEnderecos[equipamentoSelecionado] }}
            </h3>
        </ViasCol>
    </ViasRow>
</ViasCard>

</template>

<script lang="ts" setup>
import { defineProps, defineModel, computed } from "vue";

interface Props {
    possiveisEquipamentos: string[];
    possiveisEnderecos: { [key: string]: string }
}

const props = defineProps<Props>();
const equipamentoSelecionado = defineModel<string>("equipamentoSelecionado");
const tamanhoMinimo = 2;

const tamanhoDaColuna = computed(() => {
    const maximoDeColunasPorLinha = 12;
    const tamanhoCalculado = Math.round(maximoDeColunasPorLinha / props.possiveisEquipamentos.length);
    return Math.max(tamanhoCalculado, tamanhoMinimo);
});

function selecionarEquipamento(equipamento: string) {
    equipamentoSelecionado.value = equipamento;
}

function isEquipamentoSelecionado(equipamento: string) {
    return equipamentoSelecionado.value === equipamento ? "primary" : "outline-primary";
}
</script>