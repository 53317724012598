<template>
    <div class="row">
        <div class="col col-12">
            <div class="row">
                <div class="col col-12">
                    <div
                        class="d-inline-flex p-2 border-radius-1 border controladores"
                    >
                        <div class="d-flex flex-column border-right pr-4">
                            <h3 class="text-bold">Apresentação</h3>
                            <vias-checkbox
                                class="w-auto h-auto p-0 m-0"
                                before-text="Padrão"
                                after-text="Acumulado"
                                v-model="histograma_apresentacao_acumulado"
                                switch
                            ></vias-checkbox>
                        </div>
                        <div class="d-flex flex-column">
                            <h3 class="text-bold">Frequência</h3>
                            <vias-checkbox
                                class="w-auto h-auto p-0 m-0"
                                before-text="Absoluta"
                                after-text="Relativa"
                                v-model="histograma_frequencia_relativa"
                                switch
                            ></vias-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <vias-bar-chart
                :labels="labels"
                :datasets="datasets_tratados"
                :eixo_x="eixo_x"
                :eixo_y="eixo_y"
                :apresentar-datalabels="apresentarDatalabels"
                :unidade_datalabels="hasUnidadeDatalabels"
                :stacked="stacked"
                :options-eixo-x="options_eixo_x"
                :step-size-x="1"
                :is-grafico-acumulado="histograma_apresentacao_acumulado"
                @alterar-formato-de-visualizacao="
                    alterarFormatoDeVisualizacao($event)
                "
            ></vias-bar-chart>
        </div>
    </div>
</template>

<script>
import ViasBarChart from "./ViasBarChart.vue";
import ViasCheckbox from "./ViasCheckbox.vue";

export default {
    name: "vias-histograma",
    props: [
        "labels",
        "datasets",
        "eixo_x",
        "eixo_y",
        "stacked",
        "apresentarDatalabels",
        "unidade_datalabels",
    ],
    emits: ["alterarFormatoDeVisualizacao"],
    components: {
        "vias-checkbox": ViasCheckbox,
        "vias-bar-chart": ViasBarChart,
    },
    data: () => ({
        datasets_tratados: [],
        histograma_apresentacao_acumulado: false,
        histograma_frequencia_relativa: false,
    }),
    created() {
        this.gerarHistograma();
    },
    computed: {
        hasUnidadeDatalabels() {
            return this.histograma_frequencia_relativa ? `%` : "";
        },
    },
    methods: {
        gerarHistograma() {
            let datas = this.datasets.map((dataset) => dataset.data);

            if (this.histograma_frequencia_relativa) {
                datas = datas.map((data) => {
                    const total = data.reduce((soma, dado) => soma + dado, 0);
                    return data.map((dado) => (dado / total) * 100);
                });
            }

            if (this.histograma_apresentacao_acumulado) {
                datas = datas.map((data) => {
                    return data.reduce(
                        (temp, dado) => {
                            temp.total += parseFloat(dado);
                            temp.data.push(temp.total);
                            return temp;
                        },
                        { data: [], total: 0 },
                    ).data;
                });
            }

            this.datasets_tratados = this.datasets.map((dataset, i) => ({
                ...dataset,
                data: datas[i],
                barPercentage: 1,
                categoryPercentage: 1,
            }));
        },
        alterarFormatoDeVisualizacao(e) {
            this.$emit("alterarFormatoDeVisualizacao", e);
        },
    },
    watch: {
        histograma_apresentacao_acumulado() {
            this.gerarHistograma();
        },
        histograma_frequencia_relativa() {
            this.gerarHistograma();
        },
        datasets() {
            this.gerarHistograma();
        },
    },
};
</script>

<style>
.controladores {
    gap: 1rem;
}
</style>
