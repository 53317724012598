<template>
    <div id="relatorio-violacoes">
        <vias-card column>
            <vias-formulario-relatorio-violacoes
                @buscarRelatorio="buscarRelatorio"
            ></vias-formulario-relatorio-violacoes>
        </vias-card>

        <vias-card column class="mt-4" v-if="mostrar_relatorio">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>

        <vias-relatorio-violacoes
            v-if="mostrar_relatorio"
            :identificadores="relatorio.identificadores"
            :grafico="relatorio.grafico"
            :tabela="relatorio.tabela"
            :busca="relatorio.busca"
            @alterarFormatoDeVisualizacao="alterarFormatoDeVisualizacao($event)"
            @alterarFormatoDeVisualizacaoParaBaixo="
                alterarFormatoDeVisualizacaoParaBaixo($event)
            "
            @alterar-tempo-das-informacoes="alterarTempoDasInformacoes($event)"
        ></vias-relatorio-violacoes>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasRelatorioViolacoesVue from "@/components/gestao-integrada/relatorios/relatorio-violacoes/ViasRelatorioViolacoes.vue";
import ViasFormularioRelatorioViolacoesVue from "@/components/gestao-integrada/relatorios/relatorio-violacoes/ViasFormularioRelatorioViolacoes.vue";
import moment from "moment";

export default {
    name: "relatorio-violacoes",
    components: {
        "vias-card": ViasCardVue,
        "vias-relatorio-violacoes": ViasRelatorioViolacoesVue,
        "vias-formulario-relatorio-violacoes":
            ViasFormularioRelatorioViolacoesVue,
        "vias-button": ViasButton,
    },
    data: () => ({
        relatorio: {
            identificadores: null,
            identificadores_simplificados: null,
            grafico: null,
            tabela: null,
            busca: null,
        },
        dados_brutos: null,
        formulario: null,
        mostrar_relatorio: false,
        informacoes_em_quinze_minutos: false,
        formatos_moment: {
            completo: "YYYY",
            ano: "MMMM, YYYY",
            mes: "DD, dddd",
            semana: "DD, dddd",
            dia: "HH",
            hora: "HH:mm",
        },
        formatos_moment_simplificados: {
            completo: "YYYY",
            ano: "MMMM, YYYY",
            mes: "DD",
            semana: "DD",
            dia: "HH",
            hora: "mm",
        },
    }),
    methods: {
        buscarRelatorio(formulario) {
            this.$notificacao.open("Buscando relatório, aguarde...");

            this.formulario = formulario;
            this.relatorio.busca = Object.assign({}, formulario);

            this.$gestao_integrada
                .buscarRelatorioViolacoes(formulario)
                .then((res) => res.data)
                .then((relatorio) => {
                    this.dados_brutos = relatorio;
                    this.tratarRelatorio();
                })
                .then(() => this.$notificacao.close("Procedimento finalizado"));
        },
        tratarRelatorio() {
            let identificadores = Object.keys(this.dados_brutos);
            let valores = Object.values(this.dados_brutos);

            if (
                this.relatorio.busca.formato_visualizacao === "hora" &&
                this.informacoes_em_quinze_minutos
            ) {
                const resultado = this.tratarRelatorioParaQuinzeMinutos();
                identificadores = resultado.identificadores;
                valores = resultado.valores;
            }

            this.relatorio.identificadores = identificadores.map(
                (identificador) =>
                    moment(identificador).format(
                        this.formatos_moment[
                            this.relatorio.busca.formato_visualizacao
                        ],
                    ),
            );
            this.relatorio.identificadores_simplificados = identificadores.map(
                (identificador) =>
                    moment(identificador).format(
                        this.formatos_moment_simplificados[
                            this.formulario.formato_visualizacao
                        ],
                    ),
            );
            this.relatorio.grafico = valores;
            this.relatorio.tabela = identificadores.map((identificador, i) => ({
                numero_linha: i + 1,
                identificador: moment(identificador).format(
                    this.formatos_moment[
                        this.relatorio.busca.formato_visualizacao
                    ],
                ),
                violacoes: valores[i],
            }));
            this.mostrar_relatorio = true;
        },
        emitirPdf() {
            this.$notificacao.open(
                "Emitindo relatório, aguarde um instante por favor...",
            );
            this.$gestao_integrada
                .emitirPdfRelatorioViolacoes(this.relatorio.busca)
                .then((res) => res.data)
                .then(({ mensagem }) => {
                    this.$notificacao.close(mensagem, "success");
                });
        },
        alterarFormatoDeVisualizacao(formato) {
            this.formulario.formato_visualizacao = formato;
            this.buscarRelatorio(this.formulario);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            if (this.relatorio.busca.formato_visualizacao !== "hora") {
                this.formulario.formato_visualizacao =
                    this.definirNovoFormatoDeVisualizacao();
                this.formulario.data = moment(this.relatorio.busca.data)
                    .set(
                        this.definirAtributoASerAlteradoDoMoment(),
                        this.relatorio.identificadores_simplificados[index],
                    )
                    .format("YYYY-MM-DDTHH:mm:ss");

                this.buscarRelatorio(this.formulario);
            }
        },
        alterarTempoDasInformacoes(informacoesEmQuinzeMinutos) {
            this.informacoes_em_quinze_minutos = informacoesEmQuinzeMinutos;
            this.tratarRelatorio();
        },
        tratarRelatorioParaQuinzeMinutos() {
            let i = 0;
            const identificadores = Object.keys(this.dados_brutos).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.push(valor);
                    }

                    i++;

                    if (i == 3) {
                        i = 0;
                    }
                    return temp;
                },
                [],
            );

            i = 0;
            const valores = Object.values(this.dados_brutos).reduce(
                (temp, valor) => {
                    if (i == 0) {
                        temp.somatoria = valor;
                    }

                    if (i > 0) {
                        temp.somatoria += valor;
                    }

                    if (i == 2) {
                        temp.valores.push(temp.somatoria);
                        temp.somatoria = null;
                        i = 0;
                        return temp;
                    }
                    i++;
                    return temp;
                },
                {
                    valores: [],
                    somatoria: null,
                },
            ).valores;

            return {
                identificadores,
                valores,
            };
        },
        definirNovoFormatoDeVisualizacao() {
            const formatosDeVisualizacao = {
                completo: "ano",
                ano: "mes",
                mes: "dia",
                semana: "dia",
                dia: "hora",
            };
            return formatosDeVisualizacao[
                this.relatorio.busca.formato_visualizacao
            ];
        },
        definirAtributoASerAlteradoDoMoment() {
            const atributosMoment = {
                completo: "year",
                ano: "month",
                mes: "date",
                semana: "date",
                dia: "hour",
            };
            return atributosMoment[this.relatorio.busca.formato_visualizacao];
        },
    },
};
</script>
