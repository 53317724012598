<template>
    <div id="relatorio-passagens">
        <div>
            <vias-card>
                <vias-form-relatorio-passagens
                    @buscarRelatorio="buscarRelatorio($event)"
                ></vias-form-relatorio-passagens>
            </vias-card>
        </div>
        <div v-if="relatorio_gerado" class="mt-4">
            <vias-card column class="mb-4">
                <vias-button
                    variant="outline-primary"
                    type="button"
                    @click="emitirPdf()"
                >
                    Emitir PDF
                </vias-button>
            </vias-card>
            <vias-card>
                <vias-table
                    class="overflow-x-auto"
                    :colunas="tabela.colunas"
                    :linhas="tabela.linhas"
                ></vias-table>
            </vias-card>
        </div>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasFormRelatorioPassagensVue from "@/components/tempo-de-percurso/relatorio/ViasFormRelatorioPassagens.vue";

export default {
    name: "relatorio-de-passagens",
    components: {
        "vias-card": ViasCardVue,
        "vias-form-relatorio-passagens": ViasFormRelatorioPassagensVue,
        "vias-table": ViasTableVue,
        "vias-button": ViasButton,
    },
    data: () => ({
        tabela: {
            colunas: [
                {
                    titulo: "#",
                    referencia: "numero_linha",
                    tipo: "numero",
                },
                {
                    titulo: "Equipamento",
                    referencia: "equipamento",
                    tipo: "texto",
                },
                {
                    titulo: "Faixa",
                    referencia: "faixa",
                    tipo: "texto",
                },
                {
                    titulo: "Total de Fluxo",
                    referencia: "total_de_passagens",
                    tipo: "numero",
                    unidade: "de fluxo",
                },
                {
                    titulo: "Porcentagem de Placas Lidas",
                    referencia: "porcentagem_de_placas_lidas",
                    tipo: "numero",
                    unidade: "%",
                },
                {
                    titulo: "Total de Fluxo Diurno",
                    referencia: "total_de_passagens_diurno",
                    tipo: "numero",
                    unidade: "de fluxo",
                },
                {
                    titulo: "Porcentagem de Placas Lidas Diurno",
                    referencia: "porcentagem_de_placas_lidas_diurno",
                    tipo: "numero",
                    unidade: "%",
                },
                {
                    titulo: "Total de Fluxo Noturno",
                    referencia: "total_de_passagens_noturno",
                    tipo: "numero",
                    unidade: "de fluxo",
                },
                {
                    titulo: "Porcentagem de Placas Lidas Noturno",
                    referencia: "porcentagem_de_placas_lidas_noturno",
                    tipo: "numero",
                    unidade: "%",
                },
            ],
            linhas: [],
        },
        relatorio_gerado: false,
        busca_realizada: null,
    }),
    methods: {
        buscarRelatorio(dadosDaBusca) {
            this.$notificacao.open(
                "Buscando relatório de passagens, aguarde...",
            );

            this.$tempo_de_percurso
                .buscarRelatorioDePassagens(dadosDaBusca)
                .then((res) => res.data)
                .then((passagens) => this.tratarPassagens(passagens))
                .then(() => {
                    this.$notificacao.close(
                        "Relatório apresentado com sucesso!",
                    );
                    this.relatorio_gerado = true;
                    this.busca_realizada = dadosDaBusca;
                });
        },
        tratarPassagens(passagens) {
            this.tabela.linhas = passagens.map((passagem, i) => ({
                ...passagem,
                equipamento: passagem.sitio.toUpperCase(),
                numero_linha: i + 1,
                faixa: `${passagem.faixa}`,
            }));
        },
        emitirPdf() {
            this.$notificacao.open(
                "Emitindo relatório de passagens, aguarde...",
            );

            this.$tempo_de_percurso
                .emitirPdfRelatorioDePassagens(this.busca_realizada)
                .then((res) => res.data)
                .then(({ mensagem }) => {
                    this.$notificacao.close(mensagem, "success");
                });
        },
    },
};
</script>
