const extendedTypeOf = (obj) => {
    const result = typeof obj;
    if (obj == null) {
        return "null";
    } else if (result === "object" && obj.constructor === Array) {
        return "array";
    } else {
        return result;
    }
};

const objectDiff = (obj1, obj2) => {
    const result = {};
    let score = 0;
    let equal = true;

    for (const [key] of Object.entries(obj2)) {
        if (!(key in obj1)) {
            score -= 30;
            equal = false;
        }
    }

    for (const [key, value] of Object.entries(obj1)) {
        if (key in obj2) {
            score += 20;
            const value2 = obj2[key];
            const change = diff(value, value2);

            if (!change.equal) {
                result[key] = change.result;
                equal = false;
            }

            score += Math.min(20, Math.max(-10, change.score / 5));
        }
    }

    if (equal) {
        score = 100 * Math.max(Object.keys(obj1).length, 0.5);
    } else {
        score = Math.max(0, score);
    }

    return { score, result, equal };
};

export const diff = (obj1, obj2) => {
    const type1 = extendedTypeOf(obj1);
    const type2 = extendedTypeOf(obj2);

    if (type1 === type2 && type1 == "object") {
        return objectDiff(obj1, obj2);
    }

    let score = 100;
    let result = obj1;
    const equal = obj1 === obj2;

    if (!equal) {
        score = 0;
        result = obj2;
    }

    return { score, result, equal };
};
