<template>
    <line-chart
        :chart-data="data"
        :options="options"
        class="line-chart"
        ref="lineChart"
    ></line-chart>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { mapState } from "vuex";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(...registerables, ChartDataLabels, zoomPlugin);
export default {
    name: "vias-line-chart",
    props: {
        labels: {
            type: Array,
            required: true,
            default: () => [],
        },
        datasets: {
            type: Array,
            required: true,
            default: () => [],
        },
        eixo_x: {
            type: String,
            required: false,
            default: null,
        },
        eixo_y: {
            type: String,
            required: false,
            default: null,
        },
        opcoes_y: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        elements: {
            type: Object,
            required: false,
            default: null,
        },
        stacked: {
            type: Boolean,
            required: false,
            default: false,
        },
        apresentarDatalabels: {
            type: Boolean,
            required: false,
            default: true,
        },
        unidade_datalabels: {
            type: String,
            default: "",
        },
        apresentarLegenda: {
            type: Boolean,
            required: false,
            default: true,
        },
        habilitarApresentacaoTotal: {
            type: Boolean,
            default: true,
        },
        isGraficoAcumulado: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["alterarFormatoDeVisualizacao"],
    components: {
        "line-chart": LineChart,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
    },
    data: () => ({
        data: {
            labels: [],
            datasets: [],
        },
        options: {},
    }),
    created() {
        this.verificarEAtualizarGrafico();
    },
    methods: {
        verificarEAtualizarGrafico() {
            this.data = {
                labels: this.verificarLabels(),
                datasets: this.verificarDatasets(),
            };

            this.options = {
                responsive: true,
                tension: 0.3,
                color:
                    this.theme == "dark-theme"
                        ? this.$variaveis.cores.background
                        : this.$variaveis.cores.dark,
                scales: {
                    x: {
                        stacked: !!this.stacked,
                        ticks: {
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                        title: {
                            display: typeof this.eixo_x == "string",
                            text: this.habilitarApresentacaoTotal
                                ? `${this.eixo_x} - Total: ${this.calcularTotal()}`
                                : this.eixo_x,
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                    },
                    y: {
                        ...this.opcoes_y,
                        stacked: !!this.stacked,
                        ticks: {
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                            backdropColor: "#000000",
                        },
                        title: {
                            display: typeof this.eixo_y == "string",
                            text: this.eixo_y,
                            color:
                                this.theme == "dark-theme"
                                    ? this.$variaveis.cores.background
                                    : this.$variaveis.cores.dark,
                        },
                        min: 0,
                    },
                },
                elements: this.elements,
                interaction: {
                    intersect: false,
                },
                plugins: {
                    legend: {
                        display: this.apresentarLegenda,
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true,
                            },
                            drag: {
                                enabled: true,
                            },
                            mode: "x",
                        },
                    },
                    datalabels: {
                        formatter: (value) => {
                            value = this.$numero.formatar(value);
                            return this.unidade_datalabels
                                ? `${value} ${this.unidade_datalabels}`
                                : value;
                        },
                        font: (context) => {
                            var w = context.chart.width;
                            return {
                                size: w < 512 ? 12 : 14,
                                weight: "bold",
                            };
                        },
                        align: "end",
                        anchor: "end",
                    },
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {
                        const index = elements[0].index;
                        this.$emit("alterarFormatoDeVisualizacao", index);
                    }
                },
            };

            if (this.$refs.lineChart) {
                this.$refs.lineChart.chartInstance.data = this.data;
                this.$refs.lineChart.update();
            }
        },
        verificarLabels() {
            return this.labels != null ? this.labels : [];
        },
        verificarDatasets() {
            return this.datasets != null
                ? this.tratarDatalabelDosDatasets()
                : [];
        },
        tratarDatalabelDosDatasets() {
            const newDatasets = [];
            this.datasets.forEach((dataset) => {
                newDatasets.push({
                    ...dataset,
                    datalabels: {
                        display:
                            typeof this.$props.apresentarDatalabels ===
                            "boolean"
                                ? this.$props.apresentarDatalabels
                                : true,
                        color: dataset.backgroundColor,
                    },
                });
            });

            return newDatasets;
        },
        calcularTotal() {
            let total = 0;
            if (this.isGraficoAcumulado) {
                this.data.datasets.forEach((dataset) => {
                    const dataDataset = dataset.data;
                    total += dataDataset[dataDataset.length - 1];
                });
            } else {
                this.data.datasets.forEach((dataset) => {
                    dataset.data.forEach((quantidade) => {
                        total += quantidade;
                    });
                });
            }
            return this.$numero.formatar(total);
        },
    },
    watch: {
        labels() {
            this.verificarEAtualizarGrafico();
        },
        datasets() {
            this.verificarEAtualizarGrafico();
        },
        theme() {
            this.verificarEAtualizarGrafico();
        },
        eixo_x() {
            this.verificarEAtualizarGrafico();
        },
        eixo_y() {
            this.verificarEAtualizarGrafico();
        },
        apresentarDatalabels() {
            this.verificarEAtualizarGrafico();
        },
        stacked() {
            this.verificarEAtualizarGrafico();
        },
    },
};
</script>
