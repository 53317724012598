<template>
    <slot
        :name="`cell(${referencia})`"
        :value="linha[referencia]"
        :linha="linha"
    >
        <td v-if="tipo == 'texto' || tipo == undefined">{{ texto }}</td>
        <td v-if="tipo == 'numero'">{{ hasUnit }}</td>
        <td v-if="tipo == 'data_hora'">{{ dateTime }}</td>
        <td v-if="tipo == 'dinheiro'">{{ money }}</td>
        <td v-if="tipo == 'booleano'">{{ textIsTrue }}</td>
        <td v-if="tipo == 'html'" v-html="texto"></td>
        <td v-if="tipo == 'botao'">
            <vias-button :variant="variante" @click="emitEvent">
                <font-awesome-icon :icon="icone"></font-awesome-icon>
            </vias-button>
        </td>
        <td v-if="tipo == 'opcoes'" class="table-options">
            <div>
                <vias-button
                    v-for="opcao in opcoes"
                    :key="opcao"
                    :variant="opcao.variante"
                    @click="emitEventForOption(opcao.evento)"
                >
                    {{ opcao.texto }}
                </vias-button>
            </div>
        </td>
        <td v-if="tipo == 'switch'">
            <vias-checkbox
                switch
                v-model="value"
                @change="emitChangeEvent"
            ></vias-checkbox>
        </td>
    </slot>
</template>

<script>
import ViasButtonVue from "./ViasButton.vue";
import ViasCheckboxVue from "./ViasCheckbox.vue";
import moment from "moment";
export default {
    props: [
        "texto",
        "tipo",
        "unidade",
        "variante",
        "icone",
        "evento",
        "numero_linha",
        "opcoes",
        "referencia",
        "linha",
    ],
    components: {
        "vias-button": ViasButtonVue,
        "vias-checkbox": ViasCheckboxVue,
    },
    computed: {
        hasUnit() {
            const textoVerificado = this.texto ? this.texto : 0;
            return this.unidade
                ? `${this.$numero.formatar(textoVerificado)} ${this.unidade}`
                : this.$numero.formatar(textoVerificado);
        },
        dateTime() {
            return this.texto
                ? moment(this.texto).format("DD/MM/YYYY HH:mm:ss")
                : "--/--/---- --:--:--";
        },
        money() {
            const textoValidado = this.texto ? this.texto : 0.0;
            return this.$numero.formatarDinheiro(textoValidado);
        },
        textIsTrue() {
            return this.texto ? "Sim" : "Não";
        },
    },
    data() {
        return {
            value: this.texto,
        };
    },
    methods: {
        emitEvent() {
            this.$parent.$emit(this.evento, this.numero_linha);
        },
        emitChangeEvent() {
            this.$parent.$emit(this.evento, {
                numero_linha: this.numero_linha,
                linha: this.linha,
                valor: this.value,
            });
        },
        emitEventForOption(evento) {
            this.$parent.$emit(evento, this.numero_linha);
        },
    },
};
</script>

<style></style>
