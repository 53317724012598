<template>
    <div
        class="box-marcador"
        v-if="mostrarBox"
        :style="{ bottom: posicaoBox.bottom, left: posicaoBox.left }"
    >
        <slot name="box"></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, toRaw } from "vue";
import { useStore } from "@/storeTs";
import { SET_MOSTRANDO_BOX } from "@/storeTs/type-mutations";
import { google } from "google-maps";

export default defineComponent({
    name: "ViasGoogleMapsMarker",
    emits: ["click"],
    props: {
        position: {
            type: Object,
            default: () => ({
                lat: 0,
                lng: 0,
            }),
        },
        icon: {
            type: String,
            default: () => require("@/assets/ponteiros/DF/nunca-usado.svg"),
        },
    },
    data: () => ({
        marcador: null as google.maps.Marker | null,
        mostrarBox: false,
    }),
    computed: {
        posicaoBox(): any {
            return {
                bottom: "20px",
                left: "20px",
            };
        },
        boxJaSendoApresentada(): boolean {
            return this.store.state.mostrandoBox;
        },
    },
    methods: {
        iniciarMarcador() {
            this.marcador = new this.googleApi.maps.Marker({
                position: new this.googleApi.maps.LatLng(
                    this.position.lat,
                    this.position.lng,
                ),
                map: this.map,
                icon: this.icon,
            });
            this.marcador?.addListener("click", () => this.$emit("click"));
            this.marcador?.addListener("mouseover", () => {
                if (!this.boxJaSendoApresentada) {
                    this.mostrarBox = true;
                    this.store.commit(SET_MOSTRANDO_BOX, true);
                }
            });
            this.marcador?.addListener("mouseout", () => {
                if (this.mostrarBox) {
                    this.mostrarBox = false;
                    this.store.commit(SET_MOSTRANDO_BOX, false);
                }
            });
        },
        removerMarcador() {
            if (this.marcador) {
                toRaw(this.marcador).setMap(null);
            }
        },
    },
    created() {
        this.iniciarMarcador();
    },
    beforeUnmount() {
        this.removerMarcador();
    },
    watch: {
        icon() {
            if (this.marcador && this.icon) {
                toRaw(this.marcador).setIcon(this.icon);
            }
        },
    },
    setup() {
        const store = useStore();
        const googleApi: any = inject("googleApi");
        const map: any = inject("map");

        return {
            store,
            googleApi,
            map,
        };
    },
});
</script>
