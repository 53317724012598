<template>
    <div id="relatorio-ultimos-calculos">
        <vias-card column>
            <div class="row">
                <div class="col">
                    <vias-progress-bar
                        color="primary"
                        :value="largura_da_barra_de_progresso"
                    ></vias-progress-bar>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <vias-table
                        class="mt-4 overflow-x-auto"
                        :colunas="tabela.colunas"
                        :linhas="tabela.linhas"
                        :carregando="buscando_dados_iniciais"
                    ></vias-table>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";
import ViasProgressBarVue from "@/components/shared/ViasProgressBar.vue";
import moment from "moment";
export default {
    name: "relatorio-ultimos-calculos",
    components: {
        "vias-card": ViasCardVue,
        "vias-table": ViasTableVue,
        "vias-progress-bar": ViasProgressBarVue,
    },
    data: () => ({
        tabela: {
            colunas: [
                {
                    titulo: "#",
                    referencia: "numero_linha",
                    tipo: "numero",
                },
                {
                    titulo: "Data/Hora",
                    referencia: "data_hora",
                    tipo: "texto",
                },
                {
                    titulo: "Nome da Rota",
                    referencia: "nome",
                    tipo: "texto",
                },
                {
                    titulo: "Trecho",
                    referencia: "trecho",
                    tipo: "html",
                },
                {
                    titulo: "Quantidade de Fluxo",
                    referencia: "quantidade",
                    tipo: "numero",
                    unidade: "de fluxo",
                },
                {
                    titulo: "Tempo Esperado",
                    referencia: "tempo_esperado",
                    tipo: "numero",
                    unidade: "segundos",
                },
                {
                    titulo: "Tempo Médio",
                    referencia: "tempo_medio",
                    tipo: "numero",
                    unidade: "segundos",
                },
            ],
            linhas: [],
        },
        temporizador: null,
        tempo_restante_para_realizar_a_busca: 0,
        largura_da_barra_de_progresso: 0,
        buscando_dados_iniciais: true,
    }),
    created() {
        this.buscarUltimosCalculos();
    },
    methods: {
        buscarUltimosCalculos() {
            this.$tempo_de_percurso
                .buscarUltimosCalculos(100)
                .then((res) => res.data)
                .then((passagens) => {
                    this.tabela.linhas = passagens.map(this.tratarPassagem);
                })
                .then(() => {
                    this.$notificacao.close(
                        "Relatório apresentado com sucesso!",
                    );
                })
                .finally(() => {
                    if (this.buscando_dados_iniciais)
                        this.buscando_dados_iniciais = false;

                    if (!this.temporizador)
                        this.temporizador = setInterval(() => {
                            if (
                                this.tempo_restante_para_realizar_a_busca == 0
                            ) {
                                this.buscarUltimosCalculos();
                            } else {
                                this.tempo_restante_para_realizar_a_busca -= 1;
                                this.largura_da_barra_de_progresso =
                                    (this.tempo_restante_para_realizar_a_busca /
                                        30) *
                                    100;
                            }
                        }, 1000);

                    this.tempo_restante_para_realizar_a_busca = 30;
                    this.largura_da_barra_de_progresso =
                        (this.tempo_restante_para_realizar_a_busca / 30) * 100;
                });
        },
        tratarPassagem(passagem, i) {
            return {
                numero_linha: i + 1,
                data_hora: moment(passagem.data_hora).format(
                    "DD/MM/YYYY HH:mm:ss",
                ),
                nome: passagem.sub_rota.rota.nome,
                trecho: `
                    Origem: ${passagem.sub_rota.primeiro_equipamento.sitio.toUpperCase()} - ${passagem.sub_rota.primeiro_equipamento.posicao.endereco}
                    <br>
                    Destino: ${passagem.sub_rota.segundo_equipamento.sitio.toUpperCase()} - ${passagem.sub_rota.segundo_equipamento.posicao.endereco}
                `,
                quantidade: passagem.quantidade_de_passagens,
                tempo_esperado: passagem.sub_rota.tempo_entre_equipamentos,
                tempo_medio: passagem.tempo_medio,
            };
        },
    },
    beforeUnmount() {
        if (this.temporizador) clearInterval(this.temporizador);
    },
};
</script>
