import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "text-muted" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "alert alert-danger" }
const _hoisted_5 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViasInput = _resolveComponent("ViasInput")!
  const _component_ViasCol = _resolveComponent("ViasCol")!
  const _component_ViasRow = _resolveComponent("ViasRow")!
  const _component_ViasMapaGeral = _resolveComponent("ViasMapaGeral")!
  const _component_ViasButton = _resolveComponent("ViasButton")!
  const _component_ViasTable = _resolveComponent("ViasTable")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.salvarRota()), ["prevent"]))
  }, [
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasInput, {
              type: "text",
              label: "Nome",
              modelValue: _ctx.nome,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nome) = $event)),
              valid: _ctx.isNomeValido,
              detail: _ctx.getNomeMessage,
              required: ""
            }, null, 8, ["modelValue", "valid", "detail"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, { class: "position-relative mapa-formulario-height" }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasMapaGeral, {
              "ids-equipamentos-selecionados": _ctx.idsEquipamentosSelecionados,
              rotas: _ctx.rotas,
              onClickEquipamento: _cache[1] || (_cache[1] = ($event: any) => (_ctx.alternarSelecaoDeEquipamento($event)))
            }, null, 8, ["ids-equipamentos-selecionados", "rotas"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ViasRow, { class: "mt-4 max-h-300px overflow-y-auto" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipamentos, (equipamento) => {
          return (_openBlock(), _createBlock(_component_ViasCol, {
            key: equipamento.id,
            col: "12",
            sm: "6",
            md: "4",
            lg: "3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["border border-radius-1 p-4", {
                        'bg-success': _ctx.isEquipamentoJaSelecionado(equipamento),
                    }])
              }, [
                _createElementVNode("h3", {
                  class: _normalizeClass({
                            'text-dark':
                                _ctx.isEquipamentoJaSelecionado(equipamento),
                        })
                }, _toDisplayString(equipamento.sitio), 3),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("p", _hoisted_2, _toDisplayString(equipamento.posicao.endereco), 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ViasButton, {
                    type: "button",
                    variant: "outline-secondary",
                    class: "mr-2",
                    onClick: ($event: any) => (
                                _ctx.adicionarEquipamentoNoInicioDaRota(equipamento)
                            ),
                    size: "sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" No início ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_ViasButton, {
                    type: "button",
                    variant: "outline-secondary",
                    onClick: ($event: any) => (
                                _ctx.adicionarEquipamentoNoFinalDaRota(equipamento)
                            ),
                    size: "sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" No final ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ], 2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (
                _ctx.isEquipamentosSelecionadosValido !== null &&
                !_ctx.isEquipamentosSelecionadosValido
            )
      ? (_openBlock(), _createBlock(_component_ViasRow, {
          key: 0,
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasCol, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getEquipamentosSelecionadosMessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rotas, (rota) => {
      return (_openBlock(), _createBlock(_component_ViasRow, {
        key: rota.id
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ViasCol, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_5, "Trecho " + _toDisplayString(rota.id), 1),
              _createVNode(_component_ViasRow, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViasCol, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ViasInput, {
                        type: "number",
                        label: "Tempo de percurso esperado (em segundos)",
                        modelValue: rota.tempoDePercurso,
                        "onUpdate:modelValue": ($event: any) => ((rota.tempoDePercurso) = $event),
                        required: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_ctx.validado)
      ? (_openBlock(), _createBlock(_component_ViasRow, {
          key: 1,
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViasCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_ViasTable, {
                  colunas: _ctx.colunasValidacao,
                  linhas: _ctx.validacoes,
                  "no-filter": ""
                }, null, 8, ["colunas", "linhas"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ViasRow, { class: "mt-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_ViasCol, null, {
          default: _withCtx(() => [
            _createVNode(_component_ViasButton, {
              variant: "primary",
              class: "mr-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Salvar ")
              ]),
              _: 1
            }),
            _createVNode(_component_ViasButton, {
              type: "button",
              variant: "outline-primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validarRota()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Validar ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}