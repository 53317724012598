<template>

<ViasRow>
    <ViasCol>
        <ViasCard>
            <template #header>
                <div
                    class="d-flex justify-content-between align-items-center"
                >
                    <h2>Usuários cadastrados</h2>
                    <router-link
                        to="/configuracao/usuarios/cadastrar-usuario"
                        class="btn btn-primary"
                    >
                        Adicionar
                    </router-link>
                </div>
            </template>
            <TabelaDeUsuarios/>
        </ViasCard>
    </ViasCol>
</ViasRow>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabelaDeUsuarios from "../components/TabelaDeUsuarios.vue";

export default defineComponent({
    name: "UsuariosView",
    components: {
        TabelaDeUsuarios,
    },
});
</script>
