<template>
<ViasRow>
    <ViasCol>
        <div class="form-group datepicker">
            <label :for="id">{{ label }}</label>
            <datepicker
                :id="id"
                v-model="tempo"
                time-picker
                :dark="theme"
                @update:model-value="atualizarModel()"
                locale="pt-BR"
                select-text="OK"
                cancel-text="Cancelar"
                auto-apply
            />
        </div>
    </ViasCol>
</ViasRow>
</template>

<script lang="ts" setup>
import { defineProps, defineModel, withDefaults, computed, ref, watch, onMounted } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import { useStore } from "@/storeTs";

interface Props {
    label?: string;
    id?: string;
}

withDefaults(defineProps<Props>(), {
    label: "",
    id: 'id-' + Math.round(Math.random() * 100000) + '-time-picker'
});

const store = useStore();

const modelValue = defineModel<string>();

const tempo = ref<any>("");

const theme = computed(() => store.state.theme === "dark-theme");

function atualizarValorInterno() {
    if (!modelValue.value) {
        return;
    }

    const [hours, minutes, seconds] = modelValue.value.split(":");
    tempo.value = {
        hours, minutes, seconds
    };
}

function atualizarModel() {
    const { hours, minutes, seconds } = tempo.value;
    modelValue.value = `${adicionarZeroAEsquerda(hours)}:${adicionarZeroAEsquerda(minutes)}:${adicionarZeroAEsquerda(seconds)}`;
}

function adicionarZeroAEsquerda(numero: number) {
    return numero.toLocaleString("pt-br", {
        minimumIntegerDigits: 2
    });
}

watch(() => modelValue.value, () => {
    atualizarValorInterno();
});

onMounted(() => {
    atualizarValorInterno();
});
</script>