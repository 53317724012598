import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";
import { IEquipamentoMapa } from "@/interfaces/equipamento/IEquipamentoMapa";

type EquipamentosHook = {
    tratarEquipamentosParaApresentarNoMapa: (
        equipamentos: IEquipamento[],
    ) => IEquipamentoMapa[];
};

export function useEquipamentosHook(): EquipamentosHook {
    const tratarEquipamentosParaApresentarNoMapa = (
        equipamentos: IEquipamento[],
    ): IEquipamentoMapa[] => {
        return equipamentos.map((equipamento) => ({
            id: equipamento.id,
            posicao: {
                lat: equipamento.posicao.latitude,
                lng: equipamento.posicao.longitude,
            },
            endereco: equipamento.posicao.endereco,
            sitio: equipamento.sitio,
            status: equipamento.status,
            ultima_passagem: equipamento.ultima_passagem,
            tipo: equipamento.tipo,
            selecionado: false,
        }));
    };

    return {
        tratarEquipamentosParaApresentarNoMapa,
    };
}
