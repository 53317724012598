<script setup>
import { store } from "@/storeTs";
import {
    BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO,
    BUSCAR_RESULTADOS,
} from "@/storeTs/tempo-de-percurso/mapa/type-actions";
import { BUSCAR_ROTAS_TEMPO_DE_PERCURSO } from "@/storeTs/tempo-de-percurso/configuracao/type-actions";
import { ref, computed, onBeforeUnmount } from "vue";
import moment from "moment";
import { useCores } from "@/hooks/cores.hook";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import ViasProgressBar from "@/components/shared/ViasProgressBar.vue";
import ViasInputDateAndTime from "@/components/shared/ViasInputDateAndTime.vue";

const cores = useCores().listaDeCores();

const equipamentos = ref([]);

const rotasMapa = ref([]);

const ultimoMinuto = ref(null);

const tempoSelecionado = ref(moment());

const buscaAutomatica = ref(true);

const runner = ref(null);

const progressao = ref(100);

const varianteAtualizacaoAutomatica = computed(() =>
    buscaAutomatica.value ? "primary" : "danger",
);

const tempoParaBuscaAutomatica = 30;

const decrescimoDeProgressao = 100 / tempoParaBuscaAutomatica;

let buscandoResultado = false;

const ultimoResultadoApresentado = ref("00/00/0000 00:00:00");

const secoesAdicionaisMenuLateralDoMapa = ref([
    {
        icone: "fas fa-book",
        alvo: "formulario",
        nome: "Formulário de busca de tempo de percurso",
    },
    {
        icone: "fas fa-route",
        alvo: "legenda-rotas",
        nome: "Legenda das rotas",
    },
]);

const isAtualizacaoAutomaticaEmTexto = computed(() => {
    if (buscaAutomatica.value) {
        return "ligada";
    }
    return "desligada";
});

const alterarAtualizacaoAutomatica = () => {
    buscaAutomatica.value = !buscaAutomatica.value;
    if (buscaAutomatica.value) {
        iniciarRunner();
        progressao.value = 100;
    } else if (runner.value) {
        clearInterval(runner.value);
        runner.value = null;
    }
};

store
    .dispatch(BUSCAR_ROTAS_TEMPO_DE_PERCURSO)
    .then((res) => res.data)
    .then((rotas) => {
        for (const rota of rotas) {
            for (const subRota of rota.sub_rotas) {
                adicionarEquipamento(subRota.primeiro_equipamento);
                adicionarEquipamento(subRota.segundo_equipamento);

                let tempoEsperado = new Date();
                tempoEsperado.setUTCHours(0);
                tempoEsperado.setUTCMinutes(0);
                tempoEsperado.setUTCMilliseconds(0);
                tempoEsperado.setUTCSeconds(subRota.tempo_entre_equipamentos);

                const rotaMapa = {
                    id: subRota.id,
                    id_rota: rota.id,
                    nome: rota.nome,
                    inicio: {
                        lat: subRota.primeiro_equipamento.posicao.latitude,
                        lng: subRota.primeiro_equipamento.posicao.longitude,
                    },
                    fim: {
                        lat: subRota.segundo_equipamento.posicao.latitude,
                        lng: subRota.segundo_equipamento.posicao.longitude,
                    },
                    cor: cores.background,
                    tempo_entre_equipamentos: subRota.tempo_entre_equipamentos,
                    primeiro_equipamento: subRota.primeiro_equipamento,
                    segundo_equipamento: subRota.segundo_equipamento,
                    origem: subRota.primeiro_equipamento.posicao.endereco,
                    destino: subRota.segundo_equipamento.posicao.endereco,
                    box: `
            <h3 class="text-background">Rota: ${rota.nome}</h3>
            <h4 class="text-background">Trecho</h4>
            <h4 class="text-background">Origem: ${subRota.primeiro_equipamento.sitio} - ${subRota.primeiro_equipamento.posicao.endereco}</h4>
            <h4 class="text-background">Destino: ${subRota.segundo_equipamento.sitio} - ${subRota.segundo_equipamento.posicao.endereco}</h4>
            <hr></hr>
            <h4 class="text-background">Tempo esperado: ${tempoEsperado.toISOString().substr(11, 8)}</h4>
            <h4 class="text-background">Tempo calculado: 00:00:00</h4>
          `,
                };

                rotasMapa.value = [...rotasMapa.value, rotaMapa];
            }
        }
    });

const adicionarEquipamento = (equipamento) => {
    if (!equipamentos.value.find((eq) => eq.id == equipamento.id))
        equipamentos.value = [...equipamentos.value, equipamento];
};

const buscarUltimoMinuto = () => {
    store
        .dispatch(BUSCAR_ULTIMO_MINUTO_TEMPO_DE_PERCURSO)
        .then((res) => res.data.data_hora)
        .then((data_hora) => {
            ultimoMinuto.value = data_hora;
            tempoSelecionado.value = data_hora;
            buscarResultados(true);
        });
};

buscarUltimoMinuto();

const buscarResultados = (primeiraBusca = false) => {
    buscandoResultado = true;
    store
        .dispatch(
            BUSCAR_RESULTADOS,
            buscaAutomatica.value ? null : tempoSelecionado.value,
        )
        .then((res) => res.data)
        .then((resultados) => {
            ultimoMinuto.value = resultados.reduce((tempo, resultado) => {
                if (resultado.resultados.length > 0) {
                    return moment
                        .max(
                            moment(tempo),
                            moment(resultado.resultados[0].data_hora),
                        )
                        .format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    return tempo;
                }
            }, ultimoMinuto.value);

            aplicarResultados(resultados);

            if (primeiraBusca) iniciarRunner();

            if (buscaAutomatica.value) {
                tempoSelecionado.value = ultimoMinuto.value;
            }

            progressao.value = 100;
            buscandoResultado = false;
            ultimoResultadoApresentado.value = moment(
                tempoSelecionado.value,
            ).format("DD/MM/YYYY HH:mm:ss");
        });
};

const aplicarResultados = (resultados) => {
    for (const resultado of resultados) {
        for (const calculo of resultado.resultados) {
            rotasMapa.value = rotasMapa.value.map((subRota) => {
                if (calculo.sub_rota_id == subRota.id)
                    return aplicarResultadoNaSubRota(calculo, subRota);
                return subRota;
            });
        }
    }
};

const aplicarResultadoNaSubRota = (calculo, subRota) => {
    if (calculo.tempo_medio == null) {
        subRota.cor = cores.background;
    } else if (calculo.tempo_medio <= subRota.tempo_entre_equipamentos) {
        subRota.cor = cores.success;
    } else if (calculo.tempo_medio < subRota.tempo_entre_equipamentos * 1.3) {
        subRota.cor = cores.warning;
    } else if (calculo.tempo_medio <= subRota.tempo_entre_equipamentos * 1.5) {
        subRota.cor = cores.dark_warning;
    } else {
        subRota.cor = cores.danger;
    }

    let tempoEsperado = new Date();
    tempoEsperado.setUTCHours(0);
    tempoEsperado.setUTCMinutes(0);
    tempoEsperado.setUTCMilliseconds(0);
    tempoEsperado.setUTCSeconds(subRota.tempo_entre_equipamentos);

    let tempoCalculado = new Date();
    tempoCalculado.setUTCHours(0);
    tempoCalculado.setUTCMinutes(0);
    tempoCalculado.setUTCMilliseconds(0);
    tempoCalculado.setUTCSeconds(calculo.tempo_medio);

    subRota.tempo_medio = calculo.tempo_medio;
    subRota.box = `
    <h3 class="text-background">Rota: ${subRota.nome}</h3>
    <h4 class="text-background">Trecho</h4>
    <h4 class="text-background">Origem: ${subRota.primeiro_equipamento.sitio} - ${subRota.primeiro_equipamento.posicao.endereco}</h4>
    <h4 class="text-background">Destino: ${subRota.segundo_equipamento.sitio} - ${subRota.segundo_equipamento.posicao.endereco}</h4>
    <hr></hr>
    <h4 class="text-background">Tempo esperado: ${tempoEsperado.toISOString().substr(11, 8)}</h4>
    <h4 class="text-background">Tempo calculado: ${tempoCalculado.toISOString().substr(11, 8)}</h4>
  `;

    return subRota;
};

const iniciarRunner = () => {
    if (runner.value) clearInterval(runner.value);

    runner.value = setInterval(() => {
        if (progressao.value <= 0 && !buscandoResultado) {
            buscarResultados();
        } else if (progressao.value > 0) {
            progressao.value -= decrescimoDeProgressao;
        }
    }, 1000);
};

onBeforeUnmount(() => {
    if (runner.value) clearInterval(runner.value);
});
</script>

<template>
    <ViasMapaGeral
        :equipamentos-por-propriedade="equipamentos"
        :rotas="rotasMapa"
        :habilitar-todos-os-equipamentos="false"
        :habilitar-filtro-de-equipamentos="false"
        :secoes-adicionais-menu-lateral="secoesAdicionaisMenuLateralDoMapa"
    >
        <template #formulario>
            <ViasButton
                :variant="varianteAtualizacaoAutomatica"
                size="sm"
                block
                @click="alterarAtualizacaoAutomatica()"
            >
                Atualização automática {{ isAtualizacaoAutomaticaEmTexto }}
            </ViasButton>
            <ViasProgressBar
                v-if="buscaAutomatica"
                color="primary"
                class="mt-2"
                :value="progressao"
            />
            <ViasInputDateAndTime
                class="mt-4"
                label="Data da última busca feita"
                @change="buscarResultados"
                :limit-max="ultimoMinuto"
                :disabled="buscaAutomatica"
                v-model="tempoSelecionado"
            />
            <h4 class="mt-4 text-center">
                Data do último resultado apresentado <br />
                {{ ultimoResultadoApresentado }}
            </h4>
        </template>
        <template #legenda-rotas>
            <div class="d-flex flex-column w-100">
                <h3 class="mb-2">Cores das rotas</h3>
                <ul class="list">
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--background"></div>
                        <h4>Não foi possível calcular</h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--success"></div>
                        <h4>
                            O tempo de percurso da rota está abaixo ou igual do
                            esperado
                        </h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--warning"></div>
                        <h4>
                            O tempo de percurso da rota está até 30% acima do
                            esperado
                        </h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--dark-warning"></div>
                        <h4>
                            O tempo de percurso da rota está de 30% até 50%
                            acima do esperado
                        </h4>
                    </li>
                    <li class="d-flex align-items-center justify-content-start">
                        <div class="quadrado quadrado--danger"></div>
                        <h4>
                            O tempo de percurso da rota está acima de 50% do
                            esperado
                        </h4>
                    </li>
                </ul>
            </div>
        </template>
    </ViasMapaGeral>
</template>
