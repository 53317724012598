<template>
    <div class="d-flex flex-column overflow-y-auto nowrap h-100">
        <ViasFormularioEquipamentosEstaticosMapa
            :habilitar-filtro-de-data-dos-equipamentos-estaticos="
                habilitarFiltroDeDataDosEquipamentosEstaticos
            "
        />
        <div class="row">
            <div class="col cols-12">
                <h3>Tipos de equipamentos</h3>
            </div>
        </div>
        <div class="row">
            <div class="col cols-12">
                <ul class="list">
                    <li>
                        <label class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                v-model="todosOsEquipamentosSelecionados"
                                :indeterminate="
                                    algunsTiposEquipamentosSelecionados
                                "
                                @change="altenarSelecaoTodosEquipamentos()"
                            />
                            <span class="input-checkbox"></span>
                            <h4>Todos</h4>
                        </label>
                    </li>
                    <li v-for="tipo in tiposDeEquipamentos" :key="tipo.id">
                        <label
                            class="text-capitalize d-flex align-items-center"
                        >
                            <input
                                type="checkbox"
                                v-model="tiposDeEquipamentosSelecionados"
                                :value="tipo.value"
                                @change="verificarASelecaoDeTodosOsTipos()"
                            />
                            <span class="input-checkbox"></span>
                            <h4>{{ tipo.text }}</h4>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ICheckboxOption } from "@/interfaces/checkbox/ICheckboxOption";
import { BUSCAR_TIPOS_DE_EQUIPAMENTOS } from "@/store/tipo-acoes";
import { useStore } from "@/storeTs";
import {
    SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS,
    SET_TIPOS_EQUIPAMENTOS,
} from "@/storeTs/type-mutations";
import { computed, onMounted, ref, withDefaults, defineProps } from "vue";
import ViasFormularioEquipamentosEstaticosMapa from "./ViasFormularioEquipamentosEstaticosMapa.vue";

interface Props {
    habilitarFiltroDeDataDosEquipamentosEstaticos?: boolean;
}

withDefaults(defineProps<Props>(), {
    habilitarFiltroDeDataDosEquipamentosEstaticos: true
});

const store = useStore();
const tiposDeEquipamentos = ref<ICheckboxOption[]>([]);
const tiposDeEquipamentosSelecionados = ref<string[]>([]);
const algunsTiposEquipamentosSelecionados = ref<boolean>(false);
const todosOsEquipamentosSelecionados = ref<boolean>(false);
const totalDeTiposDeEquipamentos = computed(
    () => tiposDeEquipamentos.value.length,
);
const totalDeTiposDeEquipamentosSelecionados = computed(() => {
    return tiposDeEquipamentosSelecionados.value.filter(
        (tipo) => tipo !== "TODOS",
    ).length;
});

async function buscarTiposDeEquipamentos() {
    const { data } = await store.dispatch(BUSCAR_TIPOS_DE_EQUIPAMENTOS);
    tiposDeEquipamentos.value = tratarTiposDeEquipamentosParaApresentacao(data);
    buscarEquipamentosSelecionados();
}

function buscarEquipamentosSelecionados() {
    if (store.state.primeiraBuscaDeTiposDeEquipamentos) {
        store.commit(SET_PRIMEIRA_BUSCA_TIPOS_EQUIPAMENTOS, false);
        tiposDeEquipamentosSelecionados.value = [
            ...tiposDeEquipamentos.value.map((tipo) => tipo.value),
            "TODOS",
        ];
    } else {
        tiposDeEquipamentosSelecionados.value =
            store.state.tipos_equipamentos_selecionados;
    }
    verificarASelecaoDeTodosOsTipos();
}

function tratarTiposDeEquipamentosParaApresentacao(
    tiposDeEquipamentos: string[],
): ICheckboxOption[] {
    const tiposDeEquipamentosParaApresentacao: ICheckboxOption[] = [];
    for (const tipo of tiposDeEquipamentos) {
        if (tipo !== "TODOS") {
            tiposDeEquipamentosParaApresentacao.push({
                id: tipo,
                value: tipo,
                text: tipo.toLowerCase(),
            });
        }
    }
    return tiposDeEquipamentosParaApresentacao;
}

function altenarSelecaoTodosEquipamentos() {
    const totalDeTiposDeEquipamentos = tiposDeEquipamentos.value.length;
    const totalDeTiposDeEquipamentosSelecionados =
        tiposDeEquipamentosSelecionados.value.filter(
            (tipo) => tipo !== "TODOS",
        ).length;

    if (totalDeTiposDeEquipamentos === totalDeTiposDeEquipamentosSelecionados) {
        tiposDeEquipamentosSelecionados.value = [];
        algunsTiposEquipamentosSelecionados.value = false;
        todosOsEquipamentosSelecionados.value = false;
    } else {
        tiposDeEquipamentosSelecionados.value = [
            ...tiposDeEquipamentos.value.map((tipo) => tipo.value),
            "TODOS",
        ];
        algunsTiposEquipamentosSelecionados.value = false;
        todosOsEquipamentosSelecionados.value = true;
    }

    atualizarSelecaoDosTiposNoStore();
}

function isTodosTiposDeEquipamentosSelecionados() {
    return (
        totalDeTiposDeEquipamentos.value ===
        totalDeTiposDeEquipamentosSelecionados.value
    );
}

function isNenhumTipoEquipamentoSelecionado() {
    return totalDeTiposDeEquipamentosSelecionados.value === 0;
}

function isTiposEquipamentosSelecionadosEntreZeroETodos() {
    return (
        !isTodosTiposDeEquipamentosSelecionados() &&
        !isNenhumTipoEquipamentoSelecionado()
    );
}

function isTipoTodosSelecionado() {
    return tiposDeEquipamentosSelecionados.value.find(
        (tipo) => tipo === "TODOS",
    );
}

function verificarASelecaoDeTodosOsTipos() {
    if (isTodosTiposDeEquipamentosSelecionados()) {
        if (!isTipoTodosSelecionado()) {
            tiposDeEquipamentosSelecionados.value = [
                ...tiposDeEquipamentosSelecionados.value,
                "TODOS",
            ];
        }
        algunsTiposEquipamentosSelecionados.value = false;
        todosOsEquipamentosSelecionados.value = true;
    } else if (isTiposEquipamentosSelecionadosEntreZeroETodos()) {
        tiposDeEquipamentosSelecionados.value =
            tiposDeEquipamentosSelecionados.value.filter(
                (tipo) => tipo !== "TODOS",
            );
        algunsTiposEquipamentosSelecionados.value = true;
        todosOsEquipamentosSelecionados.value = false;
    } else if (isNenhumTipoEquipamentoSelecionado()) {
        tiposDeEquipamentosSelecionados.value =
            tiposDeEquipamentosSelecionados.value.filter(
                (tipo) => tipo !== "TODOS",
            );
        algunsTiposEquipamentosSelecionados.value = false;
        todosOsEquipamentosSelecionados.value = false;
    }

    atualizarSelecaoDosTiposNoStore();
}

function atualizarSelecaoDosTiposNoStore() {
    store.commit(SET_TIPOS_EQUIPAMENTOS, tiposDeEquipamentosSelecionados.value);
}

onMounted(() => {
    buscarTiposDeEquipamentos();
});
</script>
