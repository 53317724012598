<script setup>
import ViasCamera from "./ViasCamera.vue";
import ViasFormularioPainelDoEquipamento from "./ViasFormularioPainelDoEquipamento.vue";
import ViasGraficoFluxo from "./ViasGraficoFluxo.vue";
import ViasGraficoVelocidade from "./ViasGraficoVelocidade.vue";
import ViasGraficoGap from "./ViasGraficoGap.vue";
import ViasGroupButtons from "@/components/shared/ViasGroupButtons.vue";
import ViasInformacoesPainelEquipamento from "./ViasInformacoesPainelEquipamento.vue";
import { defineProps, ref, watchEffect } from "vue";

const props = defineProps(["equipamento"]);

const equipamentoCompleto = ref(null);

const resultados = ref({
    fluxo: null,
    gap: null,
    velocidade: null,
    nivel_de_servico: null,
});

const primeiraBusca = ref(true);

const botoesPainel = ref([
    {
        variant: "outline-primary",
        text: "Painel",
        type: "button",
    },
    {
        variant: "outline-primary",
        text: "Informações",
        type: "button",
    },
]);

const botaoPainelAtivo = ref(0);

const alterarPainelAtivo = ({ index }) => {
    botaoPainelAtivo.value = index;
};

const atualizarDados = (dados) => {
    resultados.value = dados;
    primeiraBusca.value = false;
};

const removerOverlay = () => {
    primeiraBusca.value = false;
};

const atualizarEquipamento = (equipamentoAtualizado) => {
    equipamentoCompleto.value = equipamentoAtualizado;
};

const mostrarVelocidade = ref(true);

watchEffect(() => {
    if (!props.equipamento) {
        resultados.value = {
            fluxo: null,
            gap: null,
            velocidade: null,
            nivel_de_servico: null,
        };
        primeiraBusca.value = true;
        botaoPainelAtivo.value = 0;
        equipamentoCompleto.value = null;
    } else {
        mostrarVelocidade.value = !(props.equipamento.tipo == "MCI");
    }
});
</script>

<template>
    <vias-group-buttons
        :buttons="botoesPainel"
        :button-active="botaoPainelAtivo"
        @button-click="alterarPainelAtivo($event)"
        class="pl-1 pr-1 mb-4"
    />

    <div
        id="painel-do-equipamento"
        :class="{ 'd-none': botaoPainelAtivo !== 0 }"
    >
        <div class="row">
            <div class="col cols-12 col-md-6 pr-md-4">
                <div id="camera">
                    <vias-camera
                        :equipamento="equipamentoCompleto"
                        :nivel-de-servico="resultados.nivel_de_servico"
                    />
                </div>
            </div>
            <div class="col cols-12 col-md-6 pl-md-4 mt-4 mt-md-0">
                <div id="formulario">
                    <vias-formulario-painel-do-equipamento
                        v-if="equipamento"
                        :id-equipamento="equipamento.id"
                        @atualizar-dados="atualizarDados($event)"
                        @atualizar-equipamento="atualizarEquipamento($event)"
                        @remover-overlay="removerOverlay()"
                    />
                </div>
            </div>
        </div>

        <div id="fluxo" class="row mt-4">
            <div class="col">
                <vias-grafico-fluxo
                    :fluxo="resultados.fluxo"
                />
            </div>
        </div>

        <div id="velocidade" class="row mt-4" v-if="mostrarVelocidade">
            <div class="col">
                <vias-grafico-velocidade
                    :velocidade="resultados.velocidade"
                />
            </div>
        </div>

        <div id="gap" class="row mt-4">
            <div class="col">
                <vias-grafico-gap :gap="resultados.gap"/>
            </div>
        </div>
    </div>

    <div id="informacoes" :class="{ 'd-none': botaoPainelAtivo !== 1 }">
        <vias-informacoes-painel-equipamento
            v-if="equipamentoCompleto"
            :equipamento="equipamentoCompleto"
        />
    </div>

    <div class="overlay" v-if="primeiraBusca">
        <div class="spinner-carregando lg-spinner"></div>
    </div>
</template>
