<template>
    <form @submit.prevent="adicionarRota()" novalidate>
        <ViasRow>
            <ViasCol>
                <ViasInput
                    type="text"
                    label="Nome"
                    v-model="nome"
                    id="nova-rota-tempo-de-percurso-nome"
                    :valid="isNomeValido"
                    :detail="getNomeMessage"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol class="position-relative mapa-formulario-height">
                <ViasMapaGeral
                    @click-equipamento="alternarSelecaoDeEquipamento($event)"
                    :ids-equipamentos-selecionados="idsEquipamentosSelecionados"
                    :rotas="rotas"
                />
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4 max-h-300px overflow-y-auto">
            <ViasCol
                v-for="equipamento in equipamentos"
                :key="equipamento.id"
                col="12"
                sm="6"
                md="4"
                lg="3"
            >
                <div
                    class="border border-radius-1 p-4"
                    :class="{
                        'bg-success': isEquipamentoJaSelecionado(equipamento),
                    }"
                >
                    <h3
                        :class="{
                            'text-dark':
                                isEquipamentoJaSelecionado(equipamento),
                        }"
                    >
                        {{ equipamento.sitio }}
                    </h3>
                    <div class="mt-3">
                        <p class="text-muted">
                            {{ equipamento.posicao.endereco }}
                        </p>
                    </div>
                    <div class="mt-3">
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            class="mr-2"
                            @click="
                                adicionarEquipamentoNoInicioDaRota(equipamento)
                            "
                            :id="
                                'nova-rota-tempo-de-percurso-equipamento-' +
                                equipamento.sitio +
                                '-inicio'
                            "
                            size="sm"
                        >
                            No início
                        </ViasButton>
                        <ViasButton
                            type="button"
                            variant="outline-secondary"
                            @click="
                                adicionarEquipamentoNoFinalDaRota(equipamento)
                            "
                            :id="
                                'nova-rota-tempo-de-percurso-equipamento-' +
                                equipamento.sitio +
                                '-final'
                            "
                            size="sm"
                        >
                            No final
                        </ViasButton>
                    </div>
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow
            v-if="
                isEquipamentosSelecionadosValido !== null &&
                !isEquipamentosSelecionadosValido
            "
            class="mt-4"
        >
            <ViasCol>
                <div class="alert alert-danger">
                    {{ getEquipamentosSelecionadosMessage }}
                </div>
            </ViasCol>
        </ViasRow>
        <ViasRow v-for="rota in rotas" :key="rota.id">
            <ViasCol cols="12">
                <h3 class="mb-4">Trecho {{ rota.id }}</h3>
                <ViasRow>
                    <ViasCol cols="12">
                        <ViasInput
                            type="number"
                            label="Tempo de percurso esperado (em segundos)"
                            v-model="rota.tempoDePercurso"
                            required
                        />
                    </ViasCol>
                </ViasRow>
            </ViasCol>
        </ViasRow>
        <ViasRow v-if="validado" class="mt-4">
            <ViasCol>
                <ViasTable
                    :colunas="colunasValidacao"
                    :linhas="validacoes"
                    no-filter
                ></ViasTable>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mt-4">
            <ViasCol>
                <ViasButton
                    variant="primary"
                    class="mr-4"
                    id="nova-rota-tempo-de-percurso-adicionar"
                >
                    Adicionar
                </ViasButton>
                <ViasButton
                    type="button"
                    variant="outline-primary"
                    @click="validarRota()"
                    id="nova-rota-tempo-de-percurso-validar"
                >
                    Validar
                </ViasButton>
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts">
import { IRotaMapaTempoDePercurso } from "@/interfaces/IRotaMapa";
import { ISubRotaTempoDePercurso } from "../interfaces/ISubRotaTempoDePercurso";
import { IValidacaoTempoDePercurso } from "../interfaces/IValidacaoTempoDePercurso";
import { useStore } from "@/storeTs";
import { BUSCAR_EQUIPAMENTOS } from "@/storeTs/equipamentos/type-actions";
import { defineComponent } from "vue";
import { useNotificacao } from "@/hooks/notificacao.hook";
import { BUSCAR_INFORMACOES_DA_ROTA } from "@/storeTs/mapa/type-actions";
import { VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO } from "@/storeTs/tempo-de-percurso/configuracao/type-actions";
import { IColunaTabela } from "@/interfaces/IColunaTabela";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { useEquipamentosHook } from "@/hooks/equipamentos.hook";
import ViasMapaGeral from "@/components/shared/mapa/ViasMapaGeral.vue";
import { IEquipamento } from "@/interfaces/equipamento/IEquipamento";

export default defineComponent({
    name: "FormularioNovaRotaTempoDePercurso",
    emits: ["adicionarRota"],
    components: {
        ViasMapaGeral,
    },
    data: () => ({
        validacoes: [] as IValidacaoTempoDePercurso[],
        colunasValidacao: [
            {
                titulo: "Origem",
                referencia: "origem",
            },
            {
                titulo: "Destino",
                referencia: "destino",
            },
            {
                titulo: "Fluxo em ambos",
                referencia: "passagensEmAmbos",
            },
        ] as IColunaTabela[],
        validado: false,
        equipamentos: [] as IEquipamento[],
        rotas: [] as IRotaMapaTempoDePercurso[],
        equipamentosSelecionados: [] as IEquipamento[],
        idsEquipamentosSelecionados: [] as number[],
        nome: "",
    }),
    computed: {
        isNomeValido(): boolean | null {
            if (this.v$.nome.$dirty) {
                return !this.v$.nome.$invalid;
            }
            return null;
        },
        getNomeMessage(): string | null {
            return this.v$.nome.$errors[0]?.$message;
        },
        isEquipamentosSelecionadosValido(): boolean | null {
            if (this.v$.equipamentosSelecionados.$dirty) {
                return !this.v$.equipamentosSelecionados.$invalid;
            }
            return null;
        },
        getEquipamentosSelecionadosMessage(): string | null {
            return this.v$.equipamentosSelecionados.$errors[0]?.$message;
        },
    },
    methods: {
        async buscarEquipamentos(): Promise<void> {
            const response = await this.store.dispatch(BUSCAR_EQUIPAMENTOS);
            this.equipamentos = response.data;
            this.atualizarSelecoesDeEquipamentos();
        },
        buscarEquipamentoPorId(id: number): IEquipamento | undefined {
            return this.equipamentos.find((eq) => eq.id === id);
        },
        isEquipamentoJaSelecionado(equipamento: IEquipamento): boolean {
            for (const equipamentoSelecionado of this
                .equipamentosSelecionados) {
                if (equipamentoSelecionado.id === equipamento.id) {
                    return true;
                }
            }
            return false;
        },
        async alternarSelecaoDeEquipamento(
            equipamento: IEquipamento,
        ): Promise<void> {
            if (this.isEquipamentoJaSelecionado(equipamento)) {
                this.adicionarEquipamentoNoFinalDaRota(equipamento);
                return;
            }
            const escolha = await this.apresentarOpcoesDeEscolha(
                `Deseja adicionar o equipamento "${equipamento.sitio}" no início ou no final da rota?`,
                "No início",
                "No final",
            );
            console.log(escolha);
            if (escolha === "No início") {
                this.adicionarEquipamentoNoInicioDaRota(equipamento);
                return;
            }
            if (escolha === "No final") {
                this.adicionarEquipamentoNoFinalDaRota(equipamento);
                return;
            }
        },
        async adicionarEquipamentoNoInicioDaRota(
            equipamento: IEquipamento,
        ): Promise<void> {
            if (this.isEquipamentoJaSelecionado(equipamento)) {
                const isConfirmado = await this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" já está selecionado, gostaria de removê-lo da rota?`,
                );
                if (isConfirmado) {
                    this.removerEquipamentoDaRota(equipamento);
                }
            } else {
                this.equipamentosSelecionados.unshift(equipamento);
                this.atualizarSelecoesDeEquipamentos();
            }
        },
        async adicionarEquipamentoNoFinalDaRota(
            equipamento: IEquipamento,
        ): Promise<void> {
            if (this.isEquipamentoJaSelecionado(equipamento)) {
                const isConfirmado = await this.questionarAcao(
                    `O equipamento "${equipamento.sitio}" já está selecionado, gostaria de removê-lo da rota?`,
                );
                if (isConfirmado) {
                    this.removerEquipamentoDaRota(equipamento);
                }
            } else {
                this.equipamentosSelecionados.push(equipamento);
                this.atualizarSelecoesDeEquipamentos();
            }
        },
        removerEquipamentoDaRota(equipamento: IEquipamento): void {
            const novosEquipamentosSelecionados: IEquipamento[] = [];

            for (const equipamentoSelecionado of this
                .equipamentosSelecionados) {
                if (equipamentoSelecionado.id !== equipamento.id) {
                    novosEquipamentosSelecionados.push(equipamentoSelecionado);
                }
            }

            this.equipamentosSelecionados = novosEquipamentosSelecionados;
            this.atualizarSelecoesDeEquipamentos();
        },
        atualizarSelecoesDeEquipamentos(): void {
            const novosIdsEquipamentosSelecionados: number[] = [];

            for (const equipamento of this.equipamentosSelecionados) {
                novosIdsEquipamentosSelecionados.push(equipamento.id);
            }

            this.idsEquipamentosSelecionados = novosIdsEquipamentosSelecionados;
            this.atualizarRotas();
        },
        atualizarRotas(): void {
            const novasRotas: IRotaMapaTempoDePercurso[] = [];

            for (let i = 0; i < this.equipamentosSelecionados.length - 1; i++) {
                novasRotas.push({
                    id: i + 1,
                    primeiro_equipamento_id:
                        this.equipamentosSelecionados[i].id,
                    segundo_equipamento_id:
                        this.equipamentosSelecionados[i + 1].id,
                    inicio: {
                        lat: this.equipamentosSelecionados[i].posicao.latitude,
                        lng: this.equipamentosSelecionados[i].posicao.longitude,
                    },
                    fim: {
                        lat: this.equipamentosSelecionados[i + 1].posicao
                            .latitude,
                        lng: this.equipamentosSelecionados[i + 1].posicao
                            .longitude,
                    },
                    tempoDePercurso: null,
                });
            }

            this.rotas = novasRotas;
            this.buscarSugestoesDasInformacoesDasRotas();
        },
        buscarSugestoesDasInformacoesDasRotas() {
            const promises: Promise<any>[] = [];

            for (let rota of this.rotas) {
                promises.push(
                    new Promise((resolve) => {
                        this.store
                            .dispatch(BUSCAR_INFORMACOES_DA_ROTA, {
                                origem: rota.inicio,
                                destino: rota.fim,
                            })
                            .then((response) => {
                                resolve(response);
                            });
                    }),
                );
            }

            Promise.all(promises).then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    this.rotas[i].tempoDePercurso =
                        responses[i].tempoDePercurso;
                }
            });
        },
        async validarRota(): Promise<void> {
            const isFormularioValido = await this.v$.$validate();

            if (isFormularioValido) {
                const subRotas: ISubRotaTempoDePercurso[] = [];
                for (const rota of this.rotas) {
                    subRotas.push({
                        primeiro_equipamento_id: rota.primeiro_equipamento_id,
                        segundo_equipamento_id: rota.segundo_equipamento_id,
                        tempo_entre_equipamentos: rota.tempoDePercurso || 0,
                    });
                }
                this.notificarCarregamento(
                    "Validando rota, aguarde um momento por favor...",
                );

                const response = await this.store.dispatch(
                    VALIDAR_ROTAS_DE_TEMPO_DE_PERCURSO,
                    {
                        nome: this.nome,
                        sub_rotas: subRotas,
                    },
                );
                this.validacoes = response.data
                    .map((validacao): IValidacaoTempoDePercurso | null => {
                        const origem = this.buscarEquipamentoPorId(
                            validacao.primeiro_equipamento_id,
                        );
                        const destino = this.buscarEquipamentoPorId(
                            validacao.segundo_equipamento_id,
                        );
                        if (origem && destino) {
                            return {
                                origem:
                                    origem.sitio +
                                    ", " +
                                    origem.posicao.endereco,
                                destino:
                                    destino.sitio +
                                    ", " +
                                    destino.posicao.endereco,
                                passagensEmAmbos:
                                    validacao.passagens_em_ambos + " de fluxo",
                            };
                        }
                        return null;
                    })
                    .filter((validacao) => validacao);
                this.validado = true;
                this.fecharPopup();
            }
        },
        async adicionarRota(): Promise<void> {
            const isFormularioValido = await this.v$.$validate();

            if (isFormularioValido) {
                const subRotas: ISubRotaTempoDePercurso[] = [];
                for (const rota of this.rotas) {
                    subRotas.push({
                        primeiro_equipamento_id: rota.primeiro_equipamento_id,
                        segundo_equipamento_id: rota.segundo_equipamento_id,
                        tempo_entre_equipamentos: rota.tempoDePercurso || 0,
                    });
                }
                this.$emit("adicionarRota", {
                    nome: this.nome,
                    sub_rotas: subRotas,
                });
            }
        },
    },
    created() {
        this.buscarEquipamentos();
    },
    validations() {
        return {
            nome: {
                required: helpers.withMessage(
                    "É obrigatório que a rota possua um nome",
                    required,
                ),
                minLength: helpers.withMessage(
                    "O nome da rota precisa ter, pelo menos, 5 caracteres",
                    minLength(5),
                ),
            },
            equipamentosSelecionados: {
                required: helpers.withMessage(
                    "É obrigatório selecionar pelo menos 2 equipamentos para a rota",
                    required,
                ),
                minLength: helpers.withMessage(
                    "É obrigatório que a rota possua, pelo menos, 2 equipamentos selecionados",
                    minLength(2),
                ),
            },
        };
    },
    setup() {
        const store = useStore();
        const v$ = useVuelidate();
        const { tratarEquipamentosParaApresentarNoMapa } =
            useEquipamentosHook();
        const {
            notificar,
            questionarAcao,
            apresentarOpcoesDeEscolha,
            notificarCarregamento,
            fecharPopup,
        } = useNotificacao();

        return {
            store,
            v$,
            tratarEquipamentosParaApresentarNoMapa,
            notificar,
            questionarAcao,
            apresentarOpcoesDeEscolha,
            notificarCarregamento,
            fecharPopup,
        };
    },
});
</script>
