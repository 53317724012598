<template>

<BoxInsightContrato variante="warning">
    <template #cabecalho>
        Fluxo
    </template>
    <template #valorPrincipal>
        {{ totalDeFluxo }} de fluxo
    </template>
    <template #legenda>
        Nas últimas 24 horas
    </template>
    <template #rodape>
        <ModalDetalhamentoContrato
            titulo="Classificação de Fluxo"
            :itens="totalDeFluxoSeparadoPorPorte"
        />
    </template>
</BoxInsightContrato>

</template>

<script lang="ts" setup>
import { onMounted, ref, defineExpose } from 'vue';
import contratoService from '../services/ContratoService';
import BoxInsightContrato from './BoxInsightContrato.vue';
import ModalDetalhamentoContrato from './ModalDetalhamentoContrato.vue';
import { FluxoSeparadoPorPorte } from '../models/FluxoSeparadoPorPorte';
import numerosService from '@/domains/Numeros/services/NumerosService';

const totalDeFluxoSeparadoPorPorte = ref<FluxoSeparadoPorPorte>({});
const totalDeFluxo = ref("0");

async function buscarTotalDeFluxoNasUltimas24Horas() {
    const { data } = await contratoService.buscarTotalDeFluxo();
    totalDeFluxoSeparadoPorPorte.value = data.portes;
    totalDeFluxo.value = numerosService.formatarNumero(data.total);
}

onMounted(() => {
    buscarTotalDeFluxoNasUltimas24Horas();
});

function atualizarInformacoes() {
    buscarTotalDeFluxoNasUltimas24Horas();
}

defineExpose({
    atualizarInformacoes
});
</script>