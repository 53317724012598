<template>
    <vias-card class="mb-4 mt-4 custom-card">
        <div class="d-flex">
            <vias-checkbox
                class="w-auto h-auto p-0 m-0"
                afterText="Mostrar gráfico de colunas"
                v-model="opcoes.grafico_de_colunas"
                switch
            ></vias-checkbox>
            <vias-checkbox
                class="w-auto h-auto p-0 m-0"
                afterText="Mostrar os números no gráfico"
                v-model="opcoes.numeros_aparentes"
                switch
            ></vias-checkbox>
            <vias-checkbox
                v-if="busca.formato_visualizacao === 'hora'"
                class="w-auto h-auto p-0 m-0"
                afterText="Apresentar informações de 15 em 15 minutos"
                v-model="informacoes_em_quinze_minutos"
                switch
            ></vias-checkbox>
        </div>
    </vias-card>
    <vias-card titulo="Infrações" class="mt-4" column>
        <div id="vias-relatorio-violacoes">
            <div class="grafico-de-relatorio" :class="theme">
                <div class="cabecalho">
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('completo')"
                        >Contrato</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForYear"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('ano')"
                        v-if="searchForYear"
                        >{{ searchYear }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForMonth"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('mes')"
                        v-if="searchForMonth"
                        >{{ searchMonth }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForWeek"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('semana')"
                        v-if="searchForWeek"
                        >{{ searchWeek }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForDay"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('dia')"
                        v-if="searchForDay"
                        >{{ searchDay }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForHour"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('hora')"
                        v-if="searchForHour"
                        >{{ searchHour }}</vias-button
                    >
                </div>
                <vias-bar-chart
                    v-if="opcoes.grafico_de_colunas"
                    :labels="labels"
                    :datasets="datasets"
                    :eixo_x="eixoX"
                    eixo_y="Média de velocidade"
                    :apresentar-datalabels="opcoes.numeros_aparentes"
                    @alterarFormatoDeVisualizacao="
                        alterarFormatoDeVisualizacaoParaBaixo($event)
                    "
                ></vias-bar-chart>
                <vias-line-chart
                    v-else
                    :datasets="datasets"
                    :labels="labels"
                    :eixo_x="eixoX"
                    eixo_y="Total de infrações"
                    :apresentar-datalabels="opcoes.numeros_aparentes"
                    @alterarFormatoDeVisualizacao="
                        alterarFormatoDeVisualizacaoParaBaixo($event)
                    "
                ></vias-line-chart>
            </div>

            <div class="grafico-de-relatorio mt-4" :class="theme">
                <div class="cabecalho">
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('completo')"
                        >Contrato</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForYear"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('ano')"
                        v-if="searchForYear"
                        >{{ searchYear }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForMonth"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('mes')"
                        v-if="searchForMonth"
                        >{{ searchMonth }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForWeek"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('semana')"
                        v-if="searchForWeek"
                        >{{ searchWeek }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForDay"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('dia')"
                        v-if="searchForDay"
                        >{{ searchDay }}</vias-button
                    >
                    <font-awesome-icon
                        icon="fas fa-angle-right"
                        v-if="searchForHour"
                    ></font-awesome-icon>
                    <vias-button
                        variant="link"
                        @click="alterarFormatoDeVisualizacao('hora')"
                        v-if="searchForHour"
                        >{{ searchHour }}</vias-button
                    >
                </div>
                <vias-histograma
                    :labels="labels"
                    :datasets="datasets"
                    :eixo_x="eixoX"
                    eixo_y="Total de Infrações"
                    :apresentar-datalabels="opcoes.numeros_aparentes"
                    @alterarFormatoDeVisualizacao="
                        alterarFormatoDeVisualizacaoParaBaixo($event)
                    "
                ></vias-histograma>
            </div>

            <div class="overflow-x-auto">
                <vias-table
                    class="mt-8"
                    :colunas="colunas"
                    :linhas="linhas"
                ></vias-table>
            </div>
        </div>
    </vias-card>
</template>

<script>
import ViasCardVue from "@/components/shared/ViasCard.vue";
import ViasLineChartVue from "@/components/shared/ViasLineChart.vue";
import ViasTableVue from "@/components/shared/ViasTable.vue";
import ViasButtonVue from "@/components/shared/ViasButton.vue";
import ViasCheckbox from "@/components/shared/ViasCheckbox.vue";
import ViasBarChart from "@/components/shared/ViasBarChart.vue";
import ViasHistograma from "@/components/shared/ViasHistograma.vue";
import { mapState } from "vuex";
import moment from "moment";

export default {
    emits: [
        "alterarTempoDasInformacoes",
        "alterarFormatoDeVisualizacao",
        "alterarFormatoDeVisualizacaoParaBaixo",
    ],
    props: [
        "identificadores",
        "grafico",
        "tabela",
        "busca",
        "graficoBarra",
        "apresentarDatalabels",
    ],
    components: {
        "vias-card": ViasCardVue,
        "vias-line-chart": ViasLineChartVue,
        "vias-table": ViasTableVue,
        "vias-button": ViasButtonVue,
        "vias-checkbox": ViasCheckbox,
        "vias-bar-chart": ViasBarChart,
        "vias-histograma": ViasHistograma,
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
        searchForYear() {
            return ["ano", "mes", "semana", "dia", "hora"].includes(
                this.busca.formato_visualizacao,
            );
        },
        searchForMonth() {
            return ["mes", "semana", "dia", "hora"].includes(
                this.busca.formato_visualizacao,
            );
        },
        searchForWeek() {
            return ["semana", "dia", "hora"].includes(
                this.busca.formato_visualizacao,
            );
        },
        searchForDay() {
            return ["dia", "hora"].includes(this.busca.formato_visualizacao);
        },
        searchForHour() {
            return ["hora"].includes(this.busca.formato_visualizacao);
        },
        searchYear() {
            return moment(this.busca.data).format("YYYY");
        },
        searchMonth() {
            return moment(this.busca.data).format("MMMM");
        },
        searchWeek() {
            return moment(this.busca.data).format("[semana] ww");
        },
        searchDay() {
            return moment(this.busca.data).format("DD, dddd");
        },
        searchHour() {
            return moment(this.busca.data).format("HH:00");
        },
        eixoX() {
            const legendas = {
                completo: "Anos do contrato",
                ano: "Meses do ano",
                mes: "Dias do mês",
                semana: "Dias da semana",
                dia: "Horas do dia",
                hora: "Horários",
            };
            return legendas[this.busca.formato_visualizacao];
        },
        colunas() {
            return [
                {
                    titulo: "#",
                    tipo: "numero",
                    referencia: "numero_linha",
                },
                {
                    titulo: this.titulos_da_coluna_identificador[
                        this.busca.formato_visualizacao
                    ],
                    tipo: "texto",
                    referencia: "identificador",
                },
                {
                    titulo: "Infrações",
                    tipo: "numero",
                    unidade: "infrações",
                    referencia: "violacoes",
                },
            ];
        },
    },
    data: () => ({
        datasets: [],
        labels: [],
        linhas: [],
        titulos_da_coluna_identificador: {
            completo: "Ano",
            ano: "Mês",
            mes: "Dia",
            semana: "Dia",
            dia: "Hora",
            hora: "Horário",
        },
        opcoes: {
            grafico_de_colunas: false,
            numeros_aparentes: true,
        },
        informacoes_em_quinze_minutos: false,
    }),
    created() {
        this.gerarLabels();
        this.gerarDatasets();
        this.gerarLinhas();
    },
    watch: {
        identificadores() {
            this.gerarLabels();
        },
        grafico() {
            this.gerarDatasets();
        },
        tabela() {
            this.gerarLinhas();
        },
        informacoes_em_quinze_minutos() {
            this.$emit(
                "alterarTempoDasInformacoes",
                this.informacoes_em_quinze_minutos,
            );
        },
    },
    methods: {
        gerarLabels() {
            this.labels = this.identificadores;
        },
        gerarDatasets() {
            this.datasets = [
                {
                    label: "Infrações",
                    data: this.grafico,
                    backgroundColor: this.$variaveis.cores.danger,
                    borderColor: this.$variaveis.cores.danger,
                    borderRadius: 3.2,
                },
            ];
        },
        gerarLinhas() {
            this.linhas = this.tabela;
        },
        alterarFormatoDeVisualizacao(e) {
            this.$emit("alterarFormatoDeVisualizacao", e);
        },
        alterarFormatoDeVisualizacaoParaBaixo(index) {
            this.$emit("alterarFormatoDeVisualizacaoParaBaixo", index);
        },
    },
};
</script>
