import http from "@/http";
import { Horario } from "../models/Horario";
import { NovoHorario } from "../models/NovoHorario";
import { HorarioEditado } from "../models/HorarioEditado";
import { AxiosResponse } from "axios";

function buscarHorarios(): Promise<AxiosResponse<Horario[]>> {
    return http.get("/vias/v1/horarios");
}

function buscarHorarioPorId(id: number): Promise<AxiosResponse<Horario>> {
    return http.get("/vias/v1/horarios/" + id);
}

function adicionarHorario(novoHorario: NovoHorario): Promise<AxiosResponse<Horario>> {
    return http.post("/vias/v1/horarios", novoHorario);
}

function editarHorario(id: number, horarioEditado: HorarioEditado): Promise<AxiosResponse<Horario>> {
    return http.put("/vias/v1/horarios/" + id, horarioEditado);
}

function removerHorario(id: number): Promise<AxiosResponse> {
    return http.delete("/vias/v1/horarios/" + id);
}

const horariosService = {
    buscarHorarios,
    buscarHorarioPorId,
    adicionarHorario,
    editarHorario,
    removerHorario
};

export default horariosService;