<template>
<LayoutFormularioRegistro titulo="Editar horário" texto-botao-enviar="Salvar" @submit="enviarHorarioParaSerEditado()">
    <ViasRow>
        <ViasCol cols="12">
            <ViasInput type="text" label="Nome" v-model="nome" required/>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInputTime type="text" label="Data inicial" v-model="dataInicial" required/>
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInputTime type="text" label="Data final" v-model="dataFinal" required/>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12">
            <ViasCheckbox v-model="publica" after-text="Pública" switch/>
        </ViasCol>
    </ViasRow>
</LayoutFormularioRegistro>
</template>

<script lang="ts" setup>
import ViasInputTime from "@/components/shared/ViasInputTime.vue";
import horariosService from "../services/HorariosService";
import { useNotificacao } from "@/hooks/notificacao.hook";
import LayoutFormularioRegistro from "@/layouts/LayoutFormularioRegistro.vue"
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router";

const router = useRouter();
const { notificar, notificarCarregamento } = useNotificacao();

let idHorario = 0;
const nome = ref("");
const dataInicial = ref("");
const dataFinal = ref("");
const publica = ref(true);

async function buscarHorarioSelecionado() {
    const { data } = await horariosService.buscarHorarioPorId(idHorario);
    nome.value = data.nome;
    dataInicial.value = data.inicial;
    dataFinal.value = data.final;
    publica.value = data.usuario_id === null;
}

async function enviarHorarioParaSerEditado() {
    notificarCarregamento("Enviando novas informações do horário para serem salvas, aguarde um momento por favor...");
    const response = await horariosService.editarHorario(idHorario, {
        nome: nome.value,
        inicial: dataInicial.value,
        final: dataFinal.value,
        publica: publica.value
    });

    if (response.status === 204) {
        notificar("Informações do horário salvas com sucesso!", "success");
        router.push("/configuracao-geral/horarios");
    }
}

onMounted(() => {
    try {
        idHorario = parseInt(router.currentRoute.value.params.id.toString());
        buscarHorarioSelecionado();
    } catch {
        router.back();
    }
})
</script>