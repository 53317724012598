import axios from "@/plugins/axios.plugin";

export const buscarRotas = () => axios.get("/vias/v1/velocidade-media/rotas");

export const buscarUltimasOcorrencias = (rota) =>
    axios.get(
        `/vias/v1/velocidade-media/historico/rotas/${rota}/infracoes?limite=10`,
    );

export const buscarRelatorioDeOcorrencias = (dados) => {
    let url = `/vias/v1/velocidade-media/graficos/infracoes?data_inicial=${dados.data[0]}&data_final=${dados.data[1]}&periodo=${dados.formato_visualizacao}`;

    if (dados.rota !== "all") url += `&rota_id=${dados.rota}`;

    return axios.get(url);
};

export const emitirPdfRelatorioDeOcorrencias = (dados) => {
    let url = `/vias/v1/velocidade-media/graficos/infracoes/pdf?data_inicial=${dados.data[0]}&data_final=${dados.data[1]}&periodo=${dados.formato_visualizacao}`;

    if (dados.rota !== "all") url += `&rota_id=${dados.rota}`;

    return axios.get(url);
};

export const buscarRelatorioVelocidadeMedia = (dados) => {
    let url = `/vias/v1/velocidade-media/graficos/fluxo?data_inicial=${dados.data[0]}&data_final=${dados.data[1]}&periodo=${dados.formato_visualizacao}`;

    if (dados.rota !== "all") url += `&rota_id=${dados.rota}`;

    return axios.get(url);
};

export const emitirPdfRelatorioVelocidadeMedia = (dados) => {
    let url = `/vias/v1/velocidade-media/graficos/fluxo/pdf?data_inicial=${dados.data[0]}&data_final=${dados.data[1]}&periodo=${dados.formato_visualizacao}`;

    if (dados.rota !== "all") url += `&rota_id=${dados.rota}`;

    return axios.get(url);
};

export const buscarOcorrenciaEspecifica = (id) => {
    return axios.get(`/vias/v1/velocidade-media/historico/infracoes/${id}`);
};

export const gerarLinksDeImagem = (id) => {
    return {
        primeiro: axios.getUri({
            url: `/vias/v1/velocidade-media/historico/infracoes/${id}/imagens`,
            params: {
                equipamento: "primeiro",
            },
        }),
        segundo: axios.getUri({
            url: `/vias/v1/velocidade-media/historico/infracoes/${id}/imagens`,
            params: {
                equipamento: "segundo",
            },
        }),
    };
};

export const buscarHistoricoDeOcorrenciasDeVelocidadeMedia = (formulario) => {
    let url = `/vias/v1/velocidade-media/historico?data_inicial=${formulario.data[0]}`;
    url += `&data_final=${formulario.data[1]}&size=10&page=${formulario.page || 0}&incluir=${formulario.incluir}`;

    if (formulario.placa) {
        url += "&placa=" + formulario.placa;
    }

    if (formulario.rota_id) {
        url += "&rota_id=" + formulario.rota_id;
    }

    return axios.get(url);
};

export const buscarAuditoriaDeVelocidadeMedia = (rota_id) => {
    return axios.get(`/vias/v1/velocidade-media/rotas/${rota_id}/audit-logs`);
};
