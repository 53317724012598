<template>
    <vias-card>
        <form @submit.prevent="buscarRelatorio()">
            <div class="row">
                <div class="col col-12">
                    <vias-input-month-and-year
                        v-model="data_busca"
                        :max="moment().format('YYYY-MM-DDT00:00:00Z')"
                        label="Data da busca"
                    ></vias-input-month-and-year>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <vias-button type="submit" variant="primary"
                        >Buscar relatório</vias-button
                    >
                </div>
            </div>
        </form>
    </vias-card>

    <div id="relatorio-de-excesso-de-velocidade" v-if="relatorio">
        <vias-card class="mt-4">
            <vias-button variant="outline-primary" @click="emitirPdf()"
                >Emitir PDF</vias-button
            >
        </vias-card>
        <vias-card
            class="mt-4"
            v-for="equipamento in relatorio"
            :key="equipamento.sitio"
        >
            <template #header>
                <h2 class="card-title">
                    {{ equipamento.sitio }}, {{ equipamento.endereco }}
                </h2>
            </template>
            <div
                class="row"
                v-for="(mes, i) in equipamento.meses"
                :key="mes.nome + equipamento.sitio"
            >
                <div class="col col-12">
                    <div class="grafico-de-relatorio">
                        <div class="cabecalho">
                            <h3 class="text-capitalize">{{ mes.nome }}</h3>
                        </div>
                        <div class="row">
                            <div class="col col-12">
                                <h3>Média</h3>
                                <h4>{{ mes.media }} de fluxo</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-12 col-md-6">
                                <h3>
                                    Segundo desvio-padrão
                                    <ViasExplicacaoDesvioPadrao/>
                                </h3>
                                <h4>
                                    Limites: entre
                                    {{ mes.segundo_desvio_padrao_alto.minimo }}
                                    e
                                    {{ mes.segundo_desvio_padrao_alto.maximo }}
                                    de fluxo acima da média e entre
                                    {{ mes.segundo_desvio_padrao_baixo.minimo }}
                                    e
                                    {{ mes.segundo_desvio_padrao_baixo.maximo }}
                                    de fluxo abaixo da média
                                </h4>
                            </div>
                            <div class="col col-12 col-md-6">
                                <h3>
                                    Terceiro desvio-padrão
                                    <ViasExplicacaoDesvioPadrao/>
                                </h3>
                                <h4>
                                    Limites: entre
                                    {{ mes.terceiro_desvio_padrao_alto.minimo }}
                                    e
                                    {{ mes.terceiro_desvio_padrao_alto.maximo }}
                                    de fluxo acima da média e entre
                                    {{
                                        mes.terceiro_desvio_padrao_baixo.minimo
                                    }}
                                    e
                                    {{
                                        mes.terceiro_desvio_padrao_baixo.maximo
                                    }}
                                    de fluxo abaixo da média
                                </h4>
                            </div>
                        </div>

                        <vias-line-chart
                            :labels="labels[i]"
                            :datasets="mes.grafico"
                            eixo_x="Dias"
                            eixo_y="Total de infrações"
                            :opcoes_y="mes.opcoes.eixo_y"
                            :elements="mes.opcoes.elements"
                            :apresentar-legenda="false"
                        ></vias-line-chart>

                        <div class="row legenda">
                            <div class="col col-12">
                                <h4 class="d-flex align-items-center">
                                    <div class="esfera primary"></div>
                                    Valores que estão fora dos segundo e
                                    terceiro desvios-padrões
                                </h4>
                            </div>
                            <div class="col col-12">
                                <h4 class="d-flex align-items-center">
                                    <div class="esfera success"></div>
                                    Valores que estão dentro do segundo
                                    desvio-padrão
                                </h4>
                            </div>
                            <div class="col col-12">
                                <h4 class="d-flex align-items-center">
                                    <div class="esfera danger"></div>
                                    Valores que estão dentro do terceiro
                                    desvio-padrão
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vias-card>
    </div>
</template>

<script setup>
import ViasCard from "@/components/shared/ViasCard.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasInputMonthAndYear from "@/components/shared/ViasInputMonthAndYear.vue";
import ViasLineChart from "@/components/shared/ViasLineChart.vue";
import { ref } from "vue";
import moment from "moment";
import {
    buscarRelatorioDeExcessoDeVelocidade,
    emitirPdfDeExcessoDeVelocidade,
} from "@/services/integrated-management.service";
import { open, close } from "@/services/notification.service";
import { cores } from "@/services/variables.service";
import { ordenarArrayCrescente } from "@/services/array.service";
import ViasExplicacaoDesvioPadrao from "@/components/shared/explicacoes/ViasExplicacaoDesvioPadrao.vue";

const relatorio = ref(null);

const labels = ref(new Array(3).fill([]));

const data_busca = ref(moment().format("YYYY-MM-DDT00:00:00Z"));

const buscarRelatorio = () => {
    open("Buscando relatório, por favor aguarde um momento...");
    buscarRelatorioDeExcessoDeVelocidade(data_busca.value)
        .then((res) => res.data)
        .then((dados) => {
            relatorio.value = [];
            labels.value = new Array(3).fill([]);
            let setarLabelsDoMes = 0;
            for (const [sitio, informacoes] of ordenarArrayCrescente(
                Object.entries(dados),
                "0",
            )) {
                const equipamento = {
                    sitio,
                    endereco: informacoes.endereco,
                    meses: [],
                };

                const coresEmArray = [
                    cores.primary,
                    cores.success,
                    cores.danger,
                    cores.warning,
                ];
                for (const [data, objeto] of ordenarArrayCrescente(
                    Object.entries(informacoes.grafico),
                    "0",
                )) {
                    const graficoValues = Object.values(objeto.grafico);
                    const media = parseInt(
                        (
                            graficoValues.reduce(
                                (soma, numero) => soma + numero,
                            ) /
                            graficoValues.reduce(
                                (arr, n) =>
                                    typeof n === "number"
                                        ? arr.concat([n])
                                        : arr,
                                [],
                            ).length
                        ).toFixed(0),
                    );
                    const verificador = new VerificadorDesvioPadrao(
                        objeto.desvio_padrao,
                        media,
                    );
                    equipamento.meses.push({
                        nome: moment(data).format("MMMM, YYYY"),
                        media: media,
                        segundo_desvio_padrao_alto:
                            verificador.valoresSegundoDesvioPadraoAlto(),
                        terceiro_desvio_padrao_alto:
                            verificador.valoresTerceiroDesvioPadraoAlto(),
                        segundo_desvio_padrao_baixo:
                            verificador.valoresSegundoDesvioPadraoBaixo(),
                        terceiro_desvio_padrao_baixo:
                            verificador.valoresTerceiroDesvioPadraoBaixo(),
                        grafico: [
                            {
                                label: `Fluxo com excesso de velocidade`,
                                data: graficoValues
                            },
                        ],
                        opcoes: {
                            elements: {
                                line: {
                                    backgroundColor: coresEmArray[0],
                                    borderColor: coresEmArray[0],
                                    fill: true,
                                },
                                point: {
                                    backgroundColor: (context) => {
                                        const { dataIndex, dataset } = context;
                                        if (typeof dataIndex == "number") {
                                            const valor =
                                                dataset.data[dataIndex];
                                            const desvio =
                                                verificador.verificarQualDesvioOValorFazParte(
                                                    valor,
                                                );
                                            if (desvio == 2) {
                                                return coresEmArray[1];
                                            } else if (desvio == 3) {
                                                return coresEmArray[2];
                                            } else {
                                                return coresEmArray[0];
                                            }
                                        }
                                        return coresEmArray[0];
                                    },
                                    borderColor: (context) => {
                                        const { dataIndex, dataset } = context;
                                        if (typeof dataIndex == "number") {
                                            const valor =
                                                dataset.data[dataIndex];
                                            const desvio =
                                                verificador.verificarQualDesvioOValorFazParte(
                                                    valor,
                                                );
                                            if (desvio == 2) {
                                                return coresEmArray[1];
                                            } else if (desvio == 3) {
                                                return coresEmArray[2];
                                            } else {
                                                return coresEmArray[0];
                                            }
                                        }
                                        return coresEmArray[0];
                                    },
                                },
                            },
                        },
                    });

                    if (setarLabelsDoMes < 4) {
                        labels.value[setarLabelsDoMes] = Object.keys(
                            objeto.grafico,
                        ).map((key) => {
                            return moment(data)
                                .set("date", key)
                                .format("DD, dddd");
                        });
                        setarLabelsDoMes++;
                    }
                }

                relatorio.value.push(equipamento);
            }
        })
        .then(() => {
            close("Procedimento finalizado.", "success");
        });
};

const emitirPdf = () => {
    open("Emitindo PDF, por favor aguarde um momento...");
    emitirPdfDeExcessoDeVelocidade(data_busca.value)
        .then((res) => res.data.mensagem)
        .then((mensagem) => {
            close(mensagem, "success");
        });
};

class VerificadorDesvioPadrao {
    constructor(desvioPadrao, media) {
        this.media = media;
        (this.desvioPadrao1 = desvioPadrao),
            (this.desvioPadrao2 = desvioPadrao * 2),
            (this.desvioPadrao3 = desvioPadrao * 3);
        (this.desvioPadrao1Alto = this.media + this.desvioPadrao1),
            (this.desvioPadrao2Alto = this.media + this.desvioPadrao2),
            (this.desvioPadrao3Alto = this.media + this.desvioPadrao3),
            (this.desvioPadrao1Baixo = this.media - this.desvioPadrao1),
            (this.desvioPadrao2Baixo = this.media - this.desvioPadrao2),
            (this.desvioPadrao3Baixo = this.media - this.desvioPadrao3);
    }

    verificarQualDesvioOValorFazParte(valor) {
        if (
            (valor >= this.desvioPadrao1Alto &&
                valor <= this.desvioPadrao2Alto) ||
            (valor < this.desvioPadrao1Baixo &&
                valor >= this.desvioPadrao2Baixo)
        ) {
            return 2;
        }
        if (
            (valor > this.desvioPadrao2Alto &&
                valor <= this.desvioPadrao3Alto) ||
            (valor < this.desvioPadrao2Baixo &&
                valor >= this.desvioPadrao3Baixo)
        ) {
            return 3;
        }
        return 0;
    }

    valoresSegundoDesvioPadraoAlto() {
        return {
            minimo: this.desvioPadrao1Alto.toFixed(0),
            maximo: this.desvioPadrao2Alto.toFixed(0),
        };
    }

    valoresTerceiroDesvioPadraoAlto() {
        return {
            minimo: this.desvioPadrao2Alto.toFixed(0),
            maximo: this.desvioPadrao3Alto.toFixed(0),
        };
    }

    valoresSegundoDesvioPadraoBaixo() {
        return {
            minimo: this.desvioPadrao1Baixo.toFixed(0),
            maximo: this.desvioPadrao2Baixo.toFixed(0),
        };
    }

    valoresTerceiroDesvioPadraoBaixo() {
        return {
            minimo: this.desvioPadrao2Baixo.toFixed(0),
            maximo: this.desvioPadrao3Baixo.toFixed(0),
        };
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.legenda .esfera {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid $color-intense-background;
    display: inline-block;
    margin-right: 0.4rem;

    &.primary {
        background-color: $color-primary;
    }

    &.danger {
        background-color: $color-danger;
    }

    &.success {
        background-color: $color-success;
    }
}
</style>
