<template>

<form @submit.prevent="editarUsuario()">
    <ViasRow>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Nome"
                type="text"
                v-model="usuario.nome"
                id="edicao-usuario-nome"
                required
            ></ViasInput>
        </ViasCol>
        <ViasCol cols="12" md="6">
            <ViasInput
                label="Usuario"
                type="text"
                v-model="usuario.username"
                id="edicao-usuario-usuario"
                required
            ></ViasInput>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol cols="12">
            <ViasInput
                label="E-mail"
                type="email"
                v-model="usuario.email"
                id="edicao-usuario-email"
                required
            ></ViasInput>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol>
            <ViasCheckbox
                after-text="Super usuário"
                v-model="usuario.super_usuario"
                id="edicao-usuario-super-usuario"
                switch
            ></ViasCheckbox>
        </ViasCol>
    </ViasRow>
    <ViasRow>
        <ViasCol>
            <ViasButton variant="primary" id="edicao-usuario-botao-salvar">
                Salvar
            </ViasButton>
            <ViasButton
                type="button"
                variant="none-primary"
                id="edicao-usuario-botao-alterar-senha"
                @click="alternarApresentacaoFormularioDeTrocaDeSenha()"
            >
                Alterar senha
            </ViasButton>
        </ViasCol>
    </ViasRow>
</form>
<div
    v-if="mostrarFormularioDeTrocaDeSenha && idUsuario"
    class="mt-4 border border-radius-1 p-4"
>
    <FormularioTrocaDeSenhaDeUsuario
        class="mt-4"
        :id-usuario="idUsuario"
        @senha-trocada="fecharFormularioDeTrocaDeSenha()"
    />
</div>

</template>

<script lang="ts" setup>
import { ref, withDefaults, defineProps, defineEmits, onMounted, watch } from "vue";
import { type Usuario } from "../models/Usuario";
import { UsuarioParaEdicao } from "../models/UsuarioParaEdicao";
import notificacaoService from "@/domains/Notificacao/services/notificacaoService";
import usuarioService from "../services/UsuarioService";
import FormularioTrocaDeSenhaDeUsuario from "./FormularioTrocaDeSenhaDeUsuario.vue";

interface Props {
    idUsuario: number
}

const props = withDefaults(defineProps<Props>(), {
    idUsuario: 0
});

const emit = defineEmits<{
    usuarioSalvo: [],
    senhaTrocada: []
}>();

const usuario = ref<UsuarioParaEdicao>({
    nome: "",
    username: "",
    email: "",
    super_usuario: false
});
const mostrarFormularioDeTrocaDeSenha = ref(false);

async function buscarUsuarioParaEdicao() {
    const usuario = await usuarioService.buscarUsuarioPorId(props.idUsuario);
    atualizarInformacoesParaEdicaoDoUsuario(usuario);
}

function atualizarInformacoesParaEdicaoDoUsuario(usuarioParaEdicao: Usuario) {
    usuario.value = {
        nome: usuarioParaEdicao.nome,
        username: usuarioParaEdicao.username,
        email: usuarioParaEdicao.email,
        super_usuario: usuarioParaEdicao.super_usuario
    };
}

function alternarApresentacaoFormularioDeTrocaDeSenha() {
    mostrarFormularioDeTrocaDeSenha.value = !mostrarFormularioDeTrocaDeSenha.value;
}

function fecharFormularioDeTrocaDeSenha() {
    mostrarFormularioDeTrocaDeSenha.value = false;
}

async function editarUsuario() {
    notificacaoService.notificarCarregamento(
        "Estamos enviando as novas informações do usuário",
        "Aguarde um momento..."
    );
    
    await usuarioService.editarUsuario(props.idUsuario, usuario.value);

    notificacaoService.notificarSucesso(
        "As novas informações do usuário foram salvas com sucesso!"
    );

    emit("usuarioSalvo");
}

onMounted(() => {
    if (props.idUsuario) {
        buscarUsuarioParaEdicao();
    }
});

watch(() => props.idUsuario, () => {
    buscarUsuarioParaEdicao();
});
</script>