<script setup>
import ViasInputDateAndTime from "@/components/shared/ViasInputDateAndTime.vue";
import ViasButton from "@/components/shared/ViasButton.vue";
import ViasSelect from "@/components/shared/ViasSelect.vue";
import ViasRadioButton from "@/components/shared/ViasRadioButton.vue";
import ViasProgressBar from "@/components/shared/ViasProgressBar.vue";
import {
    ref,
    computed,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    onBeforeUnmount,
} from "vue";
import { useStore } from "@/storeTs";
import {
    BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO,
    EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO,
} from "@/store/tipo-acoes";
import moment from "moment";
import { close, open } from "@/services/notification.service";

const store = useStore();

const emits = defineEmits([
    "atualizarDados",
    "atualizarEquipamento",
    "removerOverlay",
]);

const props = defineProps(["idEquipamento"]);

const theme = computed(() => store.state.theme);

const atualizacaoAutomatica = ref(true);

const atualizacaoAutomaticaTexto = computed(() =>
    atualizacaoAutomatica.value ? "ligada" : "desligada",
);

const atualizacaoAutomaticaVariante = computed(() =>
    atualizacaoAutomatica.value ? "primary" : "danger",
);

const progressao = ref(100);

const tempoParaBusca = 30;

const progressaoDecrescimo = 100 / tempoParaBusca;

let runner = null;

const iniciarRunner = () => {
    if (runner) clearInterval(runner);

    runner = setInterval(() => {
        progressao.value -= progressaoDecrescimo;

        if (progressao.value <= 0 && !buscandoDados.value) {
            buscarDadosDoPainelDoEquipamento();
        }
    }, 1000);
};

const alterarAtualizacaoAutomatica = () => {
    atualizacaoAutomatica.value = !atualizacaoAutomatica.value;
    if (atualizacaoAutomatica.value) iniciarRunner();
    else {
        clearInterval(runner);
        runner = null;
        progressao.value = 0;
    }
};

const formatosDeVisualizacao = ref([
    {
        value: "um",
        text: "1 minuto",
    },
    {
        value: "cinco",
        text: "5 minutos",
    },
    {
        value: "dez",
        text: "10 minutos",
    },
    {
        value: "quinze",
        text: "15 minutos",
    },
]);

const form = ref({
    data_hora: "0000-00-00T00:00:00",
    formato_de_visualizacao: "cinco",
    faixa: null,
});

const ultimaBusca = ref({
    data_hora: null,
    formato_de_visualizacao: "cinco",
    faixa: null,
});

const dadosUltimaBusca = ref(null);

let dadosSelecionados = null;

const faixas = ref(null);

const buscandoDados = ref(false);

const emitirPdf = () => {
    open("Emitindo PDF do relatório, por favor aguarde um momento...");
    store
        .dispatch(EMITIR_PDF_DOS_DADOS_PAINEL_DO_EQUIPAMENTO, {
            equipamento: props.idEquipamento,
            data_hora: dadosUltimaBusca.value.data_hora,
        })
        .then((res) => res.data)
        .then(({ mensagem }) => {
            close(mensagem, "success");
        });
};

const buscarDadosDoPainelDoEquipamento = () => {
    if (!buscandoDados.value) {
        buscandoDados.value = true;
        return store
            .dispatch(BUSCAR_DADOS_PAINEL_DO_EQUIPAMENTO, {
                equipamento: props.idEquipamento,
                data_hora: atualizacaoAutomatica.value
                    ? null
                    : form.value.data_hora,
            })
            .then((res) => res.data)
            .then((dados) => {
                if (atualizacaoAutomatica.value) {
                    form.value.data_hora = dados.data_hora;
                }
                return dados;
            })
            .then((dados) => tratarResultadosDaBusca(dados))
            .then(() => atualizarDadosDaBusca())
            .finally(() => {
                progressao.value = 100;
                buscandoDados.value = false;
                emits("removerOverlay");
            });
    }
};

const tratarResultadosDaBusca = (dados) => {
    dadosUltimaBusca.value = dados;

    if (!faixas.value) {
        faixas.value = Object.keys(dados.grafico.cinco)
            .sort((a, b) => (a > b ? 1 : -1))
            .map((faixa) => ({
                value: faixa,
                text: "Faixa " + faixa,
            }));

        if (faixas.value.length > 0) {
            ultimaBusca.value.faixa = faixas.value[0].value;
            form.value.faixa = faixas.value[0].value;
        }
    }
};

const getValoresFluxo = () => {
    return dadosUltimaBusca.value.grafico[form.value.formato_de_visualizacao][
        form.value.faixa
    ].fluxo;
};

const getValoresGap = () => {
    return dadosUltimaBusca.value.grafico[form.value.formato_de_visualizacao][
        form.value.faixa
    ].gap;
};

const getValoresNivelDeServico = () => {
    const nivelDeServico =
        dadosUltimaBusca.value.grafico[form.value.formato_de_visualizacao][
            form.value.faixa
        ].nivel_de_servico;
    if (nivelDeServico) {
        return nivelDeServico;
    }
    return null;
};

const getValoresVelocidade = () => {
    const velocidade =
        dadosUltimaBusca.value.grafico[form.value.formato_de_visualizacao][
            form.value.faixa
        ].velocidade;
    if (velocidade) {
        return velocidade;
    }
    return null;
};

const getEquipamentoCompleto = () => {
    return dadosUltimaBusca.value.equipamento;
};

const atualizarDadosDaBusca = () => {
    dadosSelecionados = {
        fluxo: getValoresFluxo(),
        gap: getValoresGap(),
        nivel_de_servico: getValoresNivelDeServico(),
        velocidade: getValoresVelocidade(),
    };

    emits("atualizarDados", dadosSelecionados);
    emits("atualizarEquipamento", getEquipamentoCompleto());
};

watch(form.value, () => {
    if (
        form.value.formato_de_visualizacao !=
            ultimaBusca.value.formato_de_visualizacao ||
        form.value.faixa != ultimaBusca.value.faixa
    ) {
        atualizarDadosDaBusca();
    }

    if (
        form.value.data_hora != ultimaBusca.value.data_hora &&
        !atualizacaoAutomatica.value
    ) {
        buscarDadosDoPainelDoEquipamento();
    }

    ultimaBusca.value = Object.assign({}, form.value);
});

onMounted(() => {
    buscarDadosDoPainelDoEquipamento().then(() => iniciarRunner());
});

onBeforeUnmount(() => {
    clearInterval(runner);
    runner = null;
});
</script>

<template>
    <div id="formulario-painel-do-equipamento" class="d-flex flex-column">
        <div id="data-hora">
            <vias-input-date-and-time
                v-model="form.data_hora"
                label="Data e hora"
                :disabled="atualizacaoAutomatica"
            ></vias-input-date-and-time>
            <vias-button
                :variant="atualizacaoAutomaticaVariante"
                @click="alterarAtualizacaoAutomatica"
                class="mt-4 mb-2"
                block
                >Atualização automática
                {{ atualizacaoAutomaticaTexto }}</vias-button
            >
            <vias-progress-bar
                v-if="atualizacaoAutomatica"
                :value="progressao"
            ></vias-progress-bar>
        </div>

        <vias-select
            label="Formato de visualização"
            :options="formatosDeVisualizacao"
            v-model="form.formato_de_visualizacao"
            class="mt-8"
        ></vias-select>

        <vias-radio-button
            :options="faixas"
            v-model="form.faixa"
            class="mt-4 mb-4"
        ></vias-radio-button>

        <h2 class="text-center" :class="theme">
            Data e horário dos dados mostrados abaixo:
            <br />
            {{
                dadosUltimaBusca && dadosUltimaBusca.data_hora
                    ? moment(dadosUltimaBusca.data_hora).format(
                          "DD/MM/YYYY HH:mm:ss",
                      )
                    : "--/--/---- --:--:--"
            }}
        </h2>

        <div class="row mt-4 mb-4" v-if="dadosUltimaBusca">
            <div class="col col-12 p-0 d-flex justify-content-center">
                <vias-button
                    variant="none-primary"
                    type="button"
                    @click="emitirPdf()"
                    >Emitir PDF da busca</vias-button
                >
                <small class="text-center mt-4">
                    A emissão do relatório será realizada com a data e hora da
                    busca que está sendo apresentada no momento.
                </small>
            </div>
        </div>

        <h2
            class="text-center info-carregando d-flex justify-content-center align-items-center"
            :class="theme"
            v-if="buscandoDados"
        >
            <div class="spinner-carregando mr-4"></div>
            Buscando informações, aguarde...
        </h2>
    </div>
</template>
