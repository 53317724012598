<template>
    <ViasRow>
        <ViasCol>
            <ViasCard>
                <template #header>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h2>Editar rota</h2>
                        <ViasButton
                            variant="outline-primary"
                            @click="voltarParaAApresentacaoDeRotasCadastradas()"
                        >
                            Voltar
                        </ViasButton>
                    </div>
                </template>
                <ViasRow>
                    <ViasCol>
                        <FormularioEditarRotaVelocidadeMedia
                            :rota-selecionada="rotaSelecionada"
                            @editar-rota="editarRota($event)"
                        ></FormularioEditarRotaVelocidadeMedia>
                    </ViasCol>
                </ViasRow>
            </ViasCard>
        </ViasCol>
    </ViasRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { IRotaEditadaVelocidadeMedia } from "./interfaces/IRotaEditadaVelocidadeMedia";
import FormularioEditarRotaVelocidadeMedia from "./components/FormularioEditarRotaVelocidadeMedia.vue";
import { useStore } from "@/storeTs";
import { useNotificacao } from "@/hooks/notificacao.hook";
import {
    BUSCAR_ROTA_POR_ID_VELOCIDADE_MEDIA,
    EDITAR_ROTA_VELOCIDADE_MEDIA,
} from "@/storeTs/velocidade-media/configuracao/type-actions";
import { IRotaVelocidadeMedia } from "./interfaces/IRotaVelocidadeMedia";

export default defineComponent({
    name: "EditarRotaVelocidadeMediaView",
    components: {
        FormularioEditarRotaVelocidadeMedia,
    },
    data: () => ({
        rotaSelecionada: {} as IRotaVelocidadeMedia,
        idRota: 0,
    }),
    methods: {
        voltarParaAApresentacaoDeRotasCadastradas() {
            this.router.push("/velocidade-media/configuracao");
        },
        buscarRota() {
            this.store
                .dispatch(BUSCAR_ROTA_POR_ID_VELOCIDADE_MEDIA, this.idRota)
                .then((res) => {
                    this.rotaSelecionada = res.data;
                })
                .catch((err) => {
                    this.notificar(err, "info");
                    this.voltarParaAApresentacaoDeRotasCadastradas();
                });
        },
        editarRota(rotaEditada: IRotaEditadaVelocidadeMedia) {
            this.notificarCarregamento(
                "Estamos enviando os dados de atualização da rota, aguarde um momento por favor...",
            );
            this.store
                .dispatch(EDITAR_ROTA_VELOCIDADE_MEDIA, rotaEditada)
                .then((res) => {
                    if (res.status === 204) {
                        this.notificar(
                            "Rota adicionada com sucesso!",
                            "success",
                        );
                        this.voltarParaAApresentacaoDeRotasCadastradas();
                    }
                });
        },
    },
    created() {
        try {
            this.idRota = parseInt(
                this.router.currentRoute.value.params.id.toString(),
            );
            this.buscarRota();
        } catch {
            this.notificar(
                "É necessário que um id de rota existente seja informado.",
                "info",
            );
            this.voltarParaAApresentacaoDeRotasCadastradas();
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { notificar, notificarCarregamento } = useNotificacao();

        return {
            router,
            store,
            notificar,
            notificarCarregamento,
        };
    },
});
</script>
