<template>
    <form @submit.prevent="editarPlacaDaWhitelist()">
        <ViasRow class="mb-4">
            <ViasCol :cols="12" :md="6">
                <ViasInput
                    label="Placa"
                    v-model="placa"
                    type="text"
                    id="nova-placa-whitelist-placa"
                    mask="AAA#X##"
                    required
                ></ViasInput>
            </ViasCol>
            <ViasCol :cols="12" :md="6">
                <ViasInput
                    label="Veículo"
                    v-model="veiculo"
                    type="text"
                    id="nova-placa-whitelist-veiculo"
                    required
                ></ViasInput>
            </ViasCol>
        </ViasRow>
        <ViasRow class="mb-4">
            <ViasCol :cols="12">
                <ViasInputTextarea
                    label="Descrição"
                    v-model="descricao"
                    id="nova-placa-whitelist-descricao"
                    required
                ></ViasInputTextarea>
            </ViasCol>
        </ViasRow>
        <ViasRow>
            <ViasCol :cols="12">
                <ViasButton variant="primary" type="submit"
                    >Salvar placa</ViasButton
                >
            </ViasCol>
        </ViasRow>
    </form>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps, watch } from "vue";
import ViasRow from "@/components/shared/ViasRow.vue";
import ViasCol from "@/components/shared/ViasCol.vue";
import ViasInput from "@/components/shared/ViasInput.vue";
import ViasInputTextarea from "@/components/shared/ViasInputTextarea.vue";
import { IPlacaDaWhitelist } from "@/interfaces/whitelist/IPlacaDaWhitelist";

interface Props {
    placa: IPlacaDaWhitelist;
}

const props = defineProps<Props>();

const emits = defineEmits<{
    editarPlacaDaWhitelist: [form: IPlacaDaWhitelist];
}>();

let id = 0;
const placa = ref("");
const veiculo = ref("");
const descricao = ref("");

function editarPlacaDaWhitelist() {
    emits("editarPlacaDaWhitelist", {
        id: id,
        placa: placa.value,
        veiculo: veiculo.value,
        descricao: descricao.value,
    });
}

watch(
    () => props.placa,
    () => {
        if (props.placa) {
            id = props.placa.id;
            placa.value = props.placa.placa;
            veiculo.value = props.placa.veiculo;
            descricao.value = props.placa.descricao;
        }
    },
);
</script>
