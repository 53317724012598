<template>
    <div class="progress-bar">
        <div
            class="progress-bar-value"
            :class="hasColor"
            :style="{ width: `${value}%` }"
        ></div>
    </div>
</template>

<script>
export default {
    props: ["color", "value"],
    computed: {
        hasColor() {
            return this.color
                ? `${this.color}-progress-bar`
                : "primary-progress-bar";
        },
    },
};
</script>

<style></style>
