class NumerosService {

    formatarNumero(numero: number): string {
        if (typeof numero === "number") {
            return numero.toLocaleString("pt-br", {
                maximumFractionDigits: 2
            });
        }
        return numero;
    }

}

const numerosService = new NumerosService();

export default numerosService;